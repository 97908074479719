<div class="bg-2  vh-100 vw-100">
  <app-screen-title [title]="'TITLE.delete_account'" [color]="'color1'" [backTo]="'/settings'"></app-screen-title>

  <div id="Form-Motif-Delete" class="px-3" *ngIf="!showProgressDeletion">
      <div class="mb-2">
        <label class="form-label ms-2" for="delete_account_subject">{{ 'LABELS.select_subject' | translate}}</label>
        <div style="position: relative;">
          <i class='bx bxs-chevron-down m-2 p-2'
            [ngClass]="{'right': lang === 'en' , 'left': lang === 'ar' || lang == null}"></i>
          <select class="form-control" [(ngModel)]="selectedSubject" [ngModelOptions]="{standalone: true}" (change)="selectDescription(selectedSubject)">
            <ng-container *ngFor="let motif of listSubjectMotifs"  >
              <option [ngValue]="motif">{{motif}}</option>
            </ng-container>
          </select>
        </div>
      </div>

      <div class="mb-2">
        <label class="form-label ms-2" for="delete_account_body">{{ 'LABELS.delete_motif_description' | translate}}</label>
        <div style="position: relative;">
          <textarea class="form-control" [(ngModel)]="description" disabled="true" >{{description}}</textarea>
        </div>
      </div>

      <div class="text-center py-2">
        <button type="button" class="btn btn-primary btn-lg mb-2 login-btn" (click)="launchDeleteAccountProcess()">
          {{'BUTTONS.proceed' | translate}}
        </button>
        <button type="button" class="btn btn-danger btn-lg mb-2 login-btn" routerLink="/settings">
          {{'BUTTONS.back' | translate}}
        </button>
      </div>

  </div>

  <div id="progressDeletion" class="px-3" *ngIf="showProgressDeletion">
    <div class="payment-no-refresh py-4">
      <div class="payment-loader">
        <span></span>
        <span></span>
        <span></span>
      </div>
      <h2>{{'INFO.deleting_account_inprogress' |translate}}</h2>
    </div>
  </div>


</div>
