import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { LoanDocumentEntity } from "../shared/Entities/LoanDocument.entity";
import { AcmURLConstants } from "../shared/mb-constants/mb-url-constants";
import { SettingDocumentTypeEntity } from "../shared/Entities/SettingDocumentType.entity";
import { LoanEntityIB } from "../shared/Entities/loan.entity";

@Injectable({
    providedIn: 'root'
})
export class DocumentService {
    /**
     * constructor
     * @param HttpClient httpClient
     */
    constructor(private httpClient: HttpClient) {
    }
    createAcmDocument(body: LoanDocumentEntity): Observable<LoanDocumentEntity> {
        return this.httpClient.post<LoanDocumentEntity>(AcmURLConstants.CREATE_DOCUMENT, body);
    }
    saveListDocuments(uploadedFiles: any[], documentsLoanDTO: LoanDocumentEntity[]): Observable<LoanDocumentEntity[]> {
        const formdata = new FormData();
        for (const i of uploadedFiles) {
            formdata.append('uploadedFiles', i);
        }
        formdata.append('documentsLoanDTO', JSON.stringify(documentsLoanDTO));
        return this.httpClient.post<LoanDocumentEntity[]>(AcmURLConstants.SAVE_LIST_DOCUMENT, formdata);
    }
    disableDocument(loanDocumentsDTO: LoanDocumentEntity): Observable<any> {
        return this.httpClient.put<any>(AcmURLConstants.DISABLE_DOCUMENT, loanDocumentsDTO);
    }
    getDocument(idDocumentGED: string, IdLoan: string, password: string): Observable<any> {
        const params = new HttpParams().set('password', password)
          .set('id', idDocumentGED)
          .set('loanId', IdLoan);

        return this.httpClient.get<any>(AcmURLConstants.DISPLAY_DOCUMENT, { responseType: 'blob' as 'json', params });

      }
      getRequiredDocument(loanDTO: LoanEntityIB): Observable<SettingDocumentTypeEntity[]> {
        return this.httpClient.post<SettingDocumentTypeEntity[]>(AcmURLConstants.GET_REQUIRED_DOCUMENT, loanDTO);
      }
      getDocumentsByLoan(loanDocumentsDTO: LoanDocumentEntity): Observable<LoanDocumentEntity[]> {
        return this.httpClient.post<LoanDocumentEntity[]>(AcmURLConstants.GET_LIST_DOCUMENT_LOAN, loanDocumentsDTO);
      }

      getDocTest(uuidDoc: string): Observable<LoanDocumentEntity[]> {
        return this.httpClient.get<LoanDocumentEntity[]>(AcmURLConstants.GET_DETAIL_DOCUMENT + uuidDoc);
      }
}
