import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UserEntity } from '../shared/Entities/user.entity';
import { LoanEntityIB } from '../shared/Entities/loan.entity';
import { AcmURLConstants } from '../shared/mb-constants/mb-url-constants';
import { ThirdPartyHistorique } from '../shared/Entities/ThirdPartyHistorique.entity';

@Injectable({
  providedIn: 'root'
})

export class CreditService {

  constructor(public httpClient: HttpClient) {}

  validateLoanInACM(ibLoanId: string): Observable<LoanEntityIB> {
    return this.httpClient.post<LoanEntityIB>(AcmURLConstants.VALIDATE_LOAN_IN_ACM, ibLoanId);
  }

  cancelLoanInACM(acmLoanId: string): Observable<LoanEntityIB> {
    return this.httpClient.post<LoanEntityIB>(AcmURLConstants.CANCEL_LOAN_IN_ACM, acmLoanId);
  }

  //* Get Third Parties */
  getThirdParties(thirdPartyHistorique : ThirdPartyHistorique) : Observable<ThirdPartyHistorique[]> {
    return this.httpClient.post<ThirdPartyHistorique[]>(AcmURLConstants.THIRD_PARTY_HISTORY_LIST, thirdPartyHistorique);
  }

}

