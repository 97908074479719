export class IbNotification {
  idNotification: number;
  title: string;
  subject: string;
  descriptionAr: string;
  descriptionEn: string;
  sender: string;
  nidReceiver: string;
  idCustomer : number;
  creactionDate: Date;
  isSeen: number;
  redirect: string;

  constructor() {
  }
}
