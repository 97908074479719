import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AuthentificationService } from 'src/app/ib-services/authentification.service';
import { CreditService } from 'src/app/ib-services/credit.service';
import { SharedService } from 'src/app/ib-services/shared.service';
import { RequestSendSmsDeewanApiEntity } from 'src/app/shared/Entities/RequestSendSmsDeewanApi.entity';
import { CustomerEntity } from 'src/app/shared/Entities/customer.entity';
import { UserEntity } from 'src/app/shared/Entities/user.entity';
import { AcmConstants } from 'src/app/shared/mb-constants/mb-constants';
import { NativeBiometric } from '@capgo/capacitor-native-biometric';
import { environment } from 'src/environments/environment';
import { ThirdPartyService } from 'src/app/ib-services/third-party.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class ForgetPasswordComponent implements OnInit {
  public title: string = '';

  public stepOne: boolean = true;
  public stepTwo: boolean = false;
  public stepThree: boolean = false;
  public showResetValidation: boolean = false;
  public showPassword: boolean = false;
  public showConfirmPassword: boolean = false;
  public isFloatRight: Boolean = true;
  public counter = 60;
  public lang: string;

  error: Boolean = false;
  errorMsg: string;

  public nationalId: string;
  dateOfBirth: Date;
  public code: string;
  public newPwForm: FormGroup;
  passwordStrength: number = 0;
  passwordStrengthText: string = '';
  public customerNumber: string;
  public customer: CustomerEntity;
  public customerList: CustomerEntity[];

  public submitted: boolean = false;

  requestSendSms: RequestSendSmsDeewanApiEntity =
    new RequestSendSmsDeewanApiEntity();
    private logged:boolean=false;

  constructor(
    private sharedService: SharedService,
    private creditService: CreditService,
    public translate: TranslateService,
    private formBuilder: FormBuilder,
    private authService: AuthentificationService,
    private thirdPartyService : ThirdPartyService
  ) {}

  ngOnInit() {
    this.lang = this.sharedService.getLang();
    this.isFloatRight =
      localStorage.getItem('lang') === null
        ? false
        : localStorage.getItem('lang') !== 'ar';
        this.logged = this.authService.getIsLoggedIn();
  }

  onOtpChange(event) {
    this.code = event;
  }

  requestOTP() {
    this.error = false;
    this.errorMsg = '';
    this.submitted = true;
    if (this.nationalId && this.dateOfBirth) {
      if (this.logged) {
        this.thirdPartyService
          .sendOtpDeewanApi(this.requestSendSms, this.nationalId, 0, false)
          .subscribe(
            (dataSendSms) => {
            this.sharedService.setNationalId(this.nationalId);
            this.customer = this.sharedService.getCustomer();
            if (!this.customer) {
              this.customerNumber='xxxx'+dataSendSms;
            } else {
              this.customerNumber = this.customer.telephone1;
              this.customerNumber =
              this.customerNumber.substring(0, 3) + 'xxxxxxx';
            }

            this.stepOne = false;
            this.stepTwo = true;
            this.stepThree = false;
            this.countdown();
            this.submitted = false;
          },
        (error)=>{
          if (error.error.errorCode === 'ACM-00001') {
            this.sharedService.showError(
              this.translate.instant('ERR.user_not_allowed_access')
            );
          } else {
            this.sharedService.showError(
              this.translate.instant('ERR.code_otp_incorrect')
            );
          }
        }
        );
      } else {
        this.requestSendSms.messageText = String(this.dateOfBirth);
        this.thirdPartyService
          .sendSmsOtpForgetPasswordDeewanApi(this.requestSendSms, this.nationalId, 0, false)
          .subscribe(
            (dataSendSms) => {
            if (dataSendSms === null) {
              this.sharedService.showError(
                this.translate.instant('ERR.dateOfBirthAndNID')
              );
              return;
            }

            this.sharedService.setNationalId(this.nationalId);
            this.customer = this.sharedService.getCustomer();
            if (!this.customer) {
                this.customerNumber='xxxxx'+dataSendSms;
            } else {
              this.customerNumber = this.customer.telephone1;
              this.customerNumber =
                this.customerNumber.substring(0, 3) + 'xxxxxxx';
            }

            this.stepOne = false;
            this.stepTwo = true;
            this.stepThree = false;
            this.countdown();
            this.submitted = false;
          },
          (error)=>{
            if (error.error.errorCode === 'ACM-00001') {
              this.sharedService.showError(
                this.translate.instant('ERR.user_not_allowed_access')
              );
            } else {
              this.sharedService.showError(
                this.translate.instant('ERR.code_otp_incorrect')
              );
            }
          }
        );
      }
    } else if (!this.nationalId) {
      this.sharedService.showError(
        this.translate.instant('ERR.nat_id_required')
      );
    } else if (!this.dateOfBirth) {
      this.sharedService.showError(this.translate.instant('ERR.date_required'));
    }
  }

  sendOTP() {
    if (this.code != null && this.code != undefined && this.code != '') {
      this.thirdPartyService
        .checkOtpForgetPasswordDeewanApi(this.nationalId, String(this.code))
        .subscribe(
          (res) => {
            this.stepOne = false;
            this.stepTwo = false;
            this.stepThree = true;+
            this.createNewPasswordForm();
          },
          (error) => {
            if (error.error.errorCode === 'ACM-00001') {
              this.sharedService.showError(
                this.translate.instant('ERR.user_not_allowed_access')
              );
            } else {
              this.sharedService.showError(
                this.translate.instant('ERR.code_otp_incorrect')
              );
            }
          }
        );
    } else {
      this.sharedService.showError(
        this.translate.instant('ERR.enter_otp_code')
      );
    }
  }

  createNewPasswordForm() {
    this.newPwForm = this.formBuilder.group({
      newPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required],
    });
    this.newPwForm.get('newPassword')?.valueChanges.subscribe(() => {
      this.calculatePasswordStrength();
    });
  }

  updateNewPassword() {
    this.error = false;
    this.errorMsg = '';
    this.submitted = true;
    if (!this.newPwForm.valid) {
      this.sharedService.showInfo(
        this.translate.instant('ERR.enter_credentials')
      );
    } else if (
      this.newPwForm.value.newPassword === this.newPwForm.value.confirmPassword
    ) {
      let userDTO = new UserEntity();
      userDTO.login = this.nationalId;
      userDTO.password = this.newPwForm.value.newPassword;
      this.authService.updateNewForgottenPassword(userDTO).subscribe(
        (updatedUser) => {
          this.stepOne = false;
          this.stepTwo = false;
          this.stepThree = false;
          this.showResetValidation = true;
          this.disableBiometric();
          this.submitted = false;
        },
        (err) => {
          if (err.error.errorCode === AcmConstants.PASSWORD_HISTORY) {
            this.sharedService.showError(
              this.translate.instant('ERR.pwd_history')
            );
          } else {
            this.sharedService.showError(
              this.translate.instant('ERR.internal_error')
            );
          }
        }
      );
    } else {
      this.sharedService.showError(this.translate.instant('ERR.pwd_not_match'));
    }
  }

  reInitializer() {
    this.error = false;
    this.resendOTP();
    this.counter = 60;
    this.countdown();
  }

  countdown(): void {
    let countdown = setInterval(() => {
      if (this.counter > 0) {
        this.counter--;
      } else {
        clearInterval(countdown);
      }
    }, 1000);
  }

  resendOTP() {
    this.thirdPartyService
      .sendSmsOtpForgetPasswordDeewanApi(
        this.requestSendSms,
        this.nationalId.toString(),
        0,
        false
      )
      .subscribe();
  }

  calculatePasswordStrength() {
    const password = this.newPwForm.get('newPassword')?.value;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[@$!%*?&]/.test(password);
    const isLengthValid = password.length >= 8;
    const conditionsMet = [ hasUpperCase, hasLowerCase, hasNumber, hasSpecialChar, isLengthValid];

    const conditionsMetCount = conditionsMet.filter((condition) => condition).length;

    // Calculate strength as a percentage
    this.passwordStrength = (conditionsMetCount / conditionsMet.length) * 100;

    // Set password strength text based on conditions met
    if (conditionsMetCount === conditionsMet.length) {
      this.passwordStrengthText = 'Strong Password';
    } else if (conditionsMetCount === 4) {
      this.passwordStrengthText = 'Almost Valid';
    } else if (conditionsMetCount === 3) {
      this.passwordStrengthText = 'Medium';
    } else if (conditionsMetCount === 2) {
      this.passwordStrengthText = 'Almost Medium';
    } else {
      this.passwordStrengthText = 'Weak';
    }
  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }

  toggleConfirmPasswordVisibility() {
    this.showConfirmPassword = !this.showConfirmPassword;
  }

  checklength(event: any) {
    const inputValue = event.target.value;

    if (inputValue.length >= 10) {
      const limitedValue = inputValue.substring(0, 10);
      event.target.value = limitedValue;
    }
  }

  /** Disable Biometrics by deleting credentials */
  async disableBiometric() {
    try {
      await NativeBiometric.deleteCredentials({
        server: environment.oauthUrl,
      });
      console.log('Biometric authentication disabled successfully.');
      this.sharedService.showSuccess(
        this.translate.instant('BIOMETRIC.biometric_disabled')
      );
      return true;
    } catch (error) {
      console.error(
        this.translate.instant('BIOMETRIC.ERROR.biometric_disabled_failed'),
        error
      );
      return false;
    }
  }
}
