<!-- Step1: NID Entry ; Step2 : OTP ; Step3 : New Password-->
<div class="bg-2  vh-100 vw-100">
  <app-screen-title *ngIf="!stepThree && !stepTwo" [title]="'TITLE.forget_password'" [color]="'color1'"></app-screen-title>
  <app-screen-title *ngIf="stepThree" [title]="'TITLE.new_password '" [color]="'color1'"></app-screen-title>

  <!-- NID Entry Div -->
  <div id="nid-entry-div" class="px-4" *ngIf="stepOne && !stepTwo && !stepThree">

    <div class="mb-4">
      <label class="form-label ms-2 " for="nationalId">{{'LABELS.national_id' | translate}}</label>
      <div style="position: relative;">
        <img class="ib-icons m-2 pt-2" src="assets/images/icons/identity.png" alt="identity"
              style="position: absolute;">
        <input type="number" class="form-control icon-input" placeholder="{{'PLACEHOLDER.enter_nid' | translate}}" [(ngModel)]="nationalId"  (input)="checklength($event)"
          [ngClass]="{'fc-error': (nationalId == null || nationalId == '') && submitted }">
      </div>
    </div>
    <div class="mb-4">
      <label class="form-label ms-2" for="dateOfBirth">{{ 'LABELS.date_of_birth' | translate}} </label>
      <div style="position: relative;">
        <img class="ib-icons m-2 pt-2" src="assets/images/icons/calendar-no-dots.png" alt="calendar-icon" style="position: absolute;">
        <input type="date" class="form-control" [(ngModel)]="dateOfBirth" [ngClass]="{'fc-error': (dateOfBirth == undefined || nationalId == null) && submitted }">
      </div>
    </div>
    <div class="">
      <div class="text-center">
        <button type="button" class="btn btn-primary btn-lg mb-2 login-btn" (click)="requestOTP()">
        {{'BUTTONS.reset_pwd' | translate}}
      </button>
      </div>

    </div>

  </div>

  <!-- OTP DIV -->
  <div id="otp-div" class=""  *ngIf="!stepOne && stepTwo && !stepThree">
    <app-screen-title [title]="'TITLE.verify_code'" [color]="'color1'" [backTo]="'/login'" ></app-screen-title>

    <div class="px-4">
      <label class="label-info flc"> {{'INFO.enter_verif_code' | translate}} </label>

      <div class="flc mb-2">
        <label class="fw-600 mt-2" style="color:black; font-size: xx-large;"> {{customerNumber}}</label>
    </div>

      <div class="mb-4">
        <div class="flc">
          <ng-otp-input  (onInputChange)="onOtpChange($event)"  [config]="{length:4,allowNumbersOnly:true}" dir="ltr"></ng-otp-input>
        </div>

        <div class="col-11 col-sm-11 mt-auto mx-auto py-2">
          <div class="row ">
            <div class="col-auto text-center mx-auto">
              <p class="my-2"><a (click)="reInitializer()" style="text-decoration: underline;">{{'LINK.resend_code' | translate}}</a></p>
              <label class="m-2 label-info" >{{counter}}:00</label>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="text-center px-3">
      <button type="button" class="btn btn-primary btn-lg mb-2 login-btn" (click)="sendOTP()">
        {{ 'LABELS.verify_account' | translate}}
      </button>
    </div>
  </div>

  <!-- New Password -->
  <div id="new-pw-div" class=""  *ngIf="!stepOne && !stepTwo && stepThree">
    <div class="px-4">
      <form [formGroup]="newPwForm">
      <div class="mb-4">
        <div class="mb-2">
          <label class="form-label ms-2">{{ 'LABELS.new_pwd' | translate }}</label>
          <div style="position: relative;">
            <img class="ib-icons m-2 pt-2" src="assets/images/icons/lock.png" alt="lock" style="position: absolute;">
            <input [type]="showPassword ? 'text' : 'password'" class="form-control icon-input" placeholder="{{'PLACEHOLDER.enter_new_password' | translate}}"  formControlName="newPassword"
            [ngClass]="{'fc-error': (newPwForm.controls['newPassword'].invalid || this.newPwForm.value.newPassword !== this.newPwForm.value.confirmPassword) && submitted }" >
            <div class="px-2 mt-2" style="display: flex; align-items: center;gap: 5px;">
              <i class='bx bx-info-circle' ></i>
              <span style="font-size: 12px;">{{ 'password_requirments' |translate}}</span>
            </div>
            <div class="password-strength-meter">
              <div class="strength-bar" [style.width.%]="passwordStrength"
                  [ngClass]="{
                      'bg-weak': (passwordStrength < 20) || (passwordStrength >= 20 && passwordStrength < 40),
                      'bg-medium': (passwordStrength >= 40 && passwordStrength < 60) || (passwordStrength >= 60 && passwordStrength < 81),
                      'bg-strong': passwordStrength > 80
                  }">
              </div>
          </div>
          <div class="strength-text" *ngIf="passwordStrength > 80">
            <i class='bx bx-check-circle fw-bold check-icon'></i>
        </div>
          </div>

          <button class="btn" type="button" (click)="togglePasswordVisibility()"
          [ngClass]="isFloatRight ? 'float-right' : 'float-left'" style="position: relative; top: -120px;">
          <i class="bx" [ngClass]="showPassword ? 'bx-hide' : 'bx-show'" style="font-size: x-large;color: #707fc2;"></i>
        </button>
        </div>
        <div class="mb-2">
          <label class="form-label ms-2">{{ 'LABELS.confirm_password' | translate }}</label>
          <div style="position: relative;">
            <img class="ib-icons m-2 pt-2" src="assets/images/icons/lock.png" alt="lock" style="position: absolute;">
            <input [type]="showConfirmPassword ? 'text' : 'password'" class="form-control icon-input" placeholder="{{ 'PLACEHOLDER.confirm_password' | translate }}" formControlName="confirmPassword"
            [ngClass]="{'fc-error': (newPwForm.controls['confirmPassword'].invalid || this.newPwForm.value.newPassword !== this.newPwForm.value.confirmPassword) && submitted }"
            >
          </div>
          <button class="btn" type="button" (click)="toggleConfirmPasswordVisibility()"
          [ngClass]="isFloatRight ? 'float-right' : 'float-left'" style="position: relative; top: -40px;">
          <i class="bx" [ngClass]="showConfirmPassword ? 'bx-hide' : 'bx-show'" style="font-size: x-large;color: #707fc2;"></i>
        </button>
        </div>
      </div>
      </form>
    </div>
    <div class="text-center px-3">
      <button type="button" class="btn btn-primary btn-lg mb-2 login-btn" (click)="updateNewPassword()"  [disabled]="passwordStrength < 80">{{'BUTTONS.apply_new_pwd' | translate}}
      </button>
    </div>
  </div>

  <!-- Result Reset New Password -->
  <div class="py-7" *ngIf="showResetValidation" style="padding: 10px;">
    <h1 class="fc mb-2" style="margin-bottom: 20px !important;">{{'OTHER.reset_valid' | translate}}</h1>
    <span class="fc mt-4 text-secondary " style="font-size: medium;text-align: center;">{{'INFO.email_sent_pwd' | translate}}<br></span>
    <div class="fc mb-4" style="margin-top:1.5rem;text-align: center;">
      <img class="img-logo-sanad" src="assets/images/mailsent.png" alt="mailsent">
    </div>

    <div class="fixed-bottom p-3">
      <div class="row">
        <div class="col-12 d-grid ">
          <button type="button" class="btn btn-primary btn-lg mb-2 login-btn" routerLink="/login" >{{'BUTTON.backtologin' | translate}}</button>
        </div>
      </div>
    </div>
  </div>

</div>
