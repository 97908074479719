import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { catchError, EMPTY, filter, from, map, switchMap } from 'rxjs';
import { IbNotificationServiceService } from 'src/app/ib-services/IbNotificationService.service';
import { WebsocketService } from 'src/app/ib-services/Websocket.service';
import { AuthentificationService } from 'src/app/ib-services/authentification.service';
import { CreditService } from 'src/app/ib-services/credit.service';
import { CustomerService } from 'src/app/ib-services/customer.service';
import { LoanService } from 'src/app/ib-services/loan.service';
import { ParametrageService } from 'src/app/ib-services/parametrage.service';
import { SharedService } from 'src/app/ib-services/shared.service';
import { ThirdPartyService } from 'src/app/ib-services/third-party.service';
import { ThirdPartyHistorique } from 'src/app/shared/Entities/ThirdPartyHistorique.entity';
import { CustomerEntity } from 'src/app/shared/Entities/customer.entity';
import { LoanEntityIB } from 'src/app/shared/Entities/loan.entity';
import { ProductEntity } from 'src/app/shared/Entities/product.entity copy';
import { ConfirmTransactionIcsEntity } from 'src/app/shared/Entities/requestConfirmTransaction.entity';
import { ScheduleEntity } from 'src/app/shared/Entities/schedule.entity';
import { UserEntity } from 'src/app/shared/Entities/user.entity';
import { PathConstants } from 'src/app/shared/mb-constants/path-constants';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  public customer: CustomerEntity;
  public firstName: string;
  public middleName: string;
  public lastName: string;
  public showModalFirstLogin: Boolean = false;
  public loansList: LoanEntityIB[] = [];
  public user: UserEntity;
  public schedules: ScheduleEntity[] = [];
  public schedulesForLoan: ScheduleEntity[] = [];
  public schedulesLoaded = false;
  public maxAmountProduct: number;
  public product: ProductEntity;
  public listProds: ProductEntity[] = [];
  public lang: string;
  public nbNotifs: number;
  public showPopupEval: boolean = false;

  public clickableSteps: string[] = [
    'Simah consent',
    ' Customer Information & Documents',
    'Sign Sanad Elamr',
    'Sign Contract',
    'Customer decision',
  ];

  /** Dialog Style */
  public styleDialog = {
    width: '50vw',
    background: 'linear-gradient(to bottom, #75ccd6, #ebedf8)',
    padding: '10px 10px 10px 10px',
    borderRadius: '20px',
    color: 'white',
    filter: 'drop-shadow(2px 4px 6px black)',
  };

  constructor(
    public sharedService: SharedService,
    private router: Router,
    private translate: TranslateService,
    private webSocketService: WebsocketService,
    private notifService: IbNotificationServiceService,
    private parametrageService: ParametrageService,
    private customerService: CustomerService,
    private loanService: LoanService,
    private thirdPartyService: ThirdPartyService,
    private authService: AuthentificationService,
    private creditService: CreditService
  ) {}

  ngOnInit() {
    /** Check Language Set */
    this.lang = localStorage.getItem('lang') || 'ar';
    localStorage.setItem('lang', this.lang);

    const sharedLang = this.sharedService.getLang();
    if (!sharedLang) {
      this.sharedService.setLang('ar');
    }

    /** Initialize Products */
    const prod = new ProductEntity();
    this.parametrageService.getProducts(prod).subscribe({
      next: (res) => {
        if (res && res.length > 0) {
          this.listProds = [...res];
          this.product = this.listProds[0];
          if (this.product.productDetailsDTOs && this.product.productDetailsDTOs.length > 0) {
            this.maxAmountProduct = this.product.productDetailsDTOs[0].maximumAmount;
          }
        }
      },
      error: (err) => {
        console.error('Failed to fetch products', err);
      }
    });

    /** Validate User */
    this.user = this.sharedService.getUser();
    if (!this.user || !this.user.login) {
      this.router.navigate(['']);
      return;
    }

    /** Fetch Customer Information */
    if (this.user.customerId) {
      this.customerService.getCustomerById(this.user.customerId).subscribe({
        next: (res) => {
          if (res) {
            this.customer = res;
            this.sharedService.setCustomer(res);
            this.extractCustomerName();
            /** Fetch Notification List */
            this.fetchNbNotifications();
            this.initializeWebSockets();
          }
        },
        error: (err) => {
          console.error('Failed to fetch customer data', err);
        }
      });
    }

    /** Refresh Loans */
    this.refreshListLoans();
  }

  extractCustomerName(): void {
    /*** Display Name From Customer (Next TO Be Done by Updating info from nafidh in user IB ) */
    const [firstName, , middleName, lastName] =
      this.customer.customerName.split('|');
    this.customer.firstName = firstName;
    this.customer.middleName = middleName ?? '';
    this.customer.lastName = lastName ?? '';
  }

  checkLoansToDelete(loans: LoanEntityIB[]) {
    /** Filter out loans where insertionDate is older than 20 minutes */
    const currentDate = new Date();

    let loansToDelete: LoanEntityIB[] = loans.filter(
      (loan) => loan.accountNumber == null
    );
    loansToDelete.forEach((loan, index) => {
      let insertionDate = new Date(loan.dateInsertion);
      let timeDifference = currentDate.getTime() - insertionDate.getTime();
      let minutesDifference = timeDifference / (1000 * 60);
      if (minutesDifference > 20) {
        this.loanService.deleteLoanById(loan.idIbLoan).subscribe((resDel) => {
          this.sharedService.showInfo(
            this.translate.instant('INFO.loan_deleted')
          );
          this.loansList = this.loansList.filter(
            (item) => item.idIbLoan !== loan.idIbLoan
          );
        });
      }
    });
  }

  initializeWebSockets(): void {
    this.webSocketService.advance('/topic/count-notifications', () => {
      console.log('Subscribed to notifications WebSocket');
      this.fetchNbNotifications();
    });

    this.webSocketService.advance('/topic/list-loans', () => {
      console.log('Subscribed to loans WebSocket');
      this.refreshListLoans();
    });
  }

  fetchNbNotifications() {
    this.notifService
      .getNotificationsCount(this.customer.ibCustomerId)
      .subscribe((res) => {
        this.nbNotifs = res;
      });
  }

  refreshListLoans() {
    let customer = new CustomerEntity();
    customer.ibCustomerId = this.user.customerId;
    let loan = new LoanEntityIB();
    loan.customerDTO = customer;
    this.loanService.getLoanByCustomerNID(loan).subscribe(
      (loans) => {
        this.checkLoansToDelete(loans);
        this.checkNafedhLoans(loans);
        this.loansList = [...loans];
        this.getMaxAmount();
        this.loansList.reverse();
        this.sharedService.setListLoans(this.loansList);
        let nonSyncedLoan = this.loansList.find(
          (res) => res.accountNumber === null
        );
      },
      (error) => {
        console.log('🚀~ refreshListLoans ~ error:', error);
        this.sharedService.showError(
          this.translate.instant('ERR.system_error')
        );
        this.authService.logout();
      }
    );
  }

  redirectLoan(index: number) {
    this.sharedService.setLoanDeal(this.loansList[index]);
    if (this.loansList[index].stepPath == PathConstants.PATH_PENDING) {
      this.router.navigate(['/' + PathConstants.PATH_LOAN_STATUT], {
        queryParams: { status: 'pending' },
      });
    } else if (
      this.loansList[index].stepPath == PathConstants.PATH_PERSONAL_INFORMATION
    ) {
      this.router.navigate(['/' + PathConstants.PATH_INFO_ENTRY]);
    } else if (this.loansList[index].statutLibelle == 'Disburse') {
      this.sharedService.showInfo(
        this.translate.instant('INFO.waiting_for_disburse')
      );
    } else if (this.loansList[index].statutLibelle == 'Get info from Dakhli') {
      this.sharedService.showInfo(
        this.translate.instant('INFO.request_sent_to_calculate')
      );
    } else if (this.loansList[index].statut == '8') {
      this.sharedService.showSuccess(
        this.translate.instant('INFO.loan_steps_completed')
      );
      this.showPopupEval = true;
      this.sharedService.setLoanForEval(this.loansList[index]);
    } else if (
      this.loansList[index].statutLibelle == 'Check Score' ||
      this.loansList[index].statutLibelle == 'Check AML'
    ) {
      this.router.navigate(['/' + PathConstants.PATH_LOAN_STATUT], {
        queryParams: { status: 'pending' },
      });
    } else if (
      this.loansList[index].statutLibelle.toLowerCase() == 'simah consent'
    ) {
      this.router.navigate(['/' + PathConstants.PATH_CONSENT_VALID]);
    } else if (
      this.loansList[index].statutLibelle == 'Customer decision' &&
      this.sharedService.getShowFirstRequestApproved() === true
    ) {
      this.router.navigate(['/' + PathConstants.PATH_LOAN_STATUT], {
        queryParams: { status: 'success' },
      });
    } else if (
      this.loansList[index].statut == '6' ||
      this.loansList[index].statut == '5'
    ) {
      this.router.navigate(['/' + PathConstants.PATH_LOAN_STATUT], {
        queryParams: { status: 'refused' },
      });
      this.sharedService.setLoanForEval(this.loansList[index]);
    } else if (
      !this.loansList[index].accountNumber ||
      this.loansList[index].accountNumber == null
    ) {
      this.router.navigate(['/' + PathConstants.PATH_NAFEDH], {
        queryParams: { status: 'launched_nafedh' },
      });
    } else if (
      this.loansList[index].stepPath == PathConstants.PATH_BANK_DETAILS
    ) {
      this.router.navigate(['/' + PathConstants.PATH_BANK_INFO]);
    } else if (
      this.loansList[index].stepPath == PathConstants.PATH_SIGN_CONTRACT &&
      this.loansList[index].statutLibelle == 'Call Customer'
    ) {
      let confirmTransactionIcsEntity = new ConfirmTransactionIcsEntity();
      confirmTransactionIcsEntity.idNumber =
        this.loansList[index].customerDTO.identity;
      confirmTransactionIcsEntity.phoneNumber = this.customer.telephone1;
      confirmTransactionIcsEntity.transactionType = 'financing';
      this.router.navigate(['/' + this.loansList[index].stepPath]);
    } else {
      this.router.navigate(['/' + this.loansList[index].stepPath]);
    }
  }

  getMaxAmount() {
    this.loansList.forEach((loan) => {
      if (loan.approvelAmount) {
        this.maxAmountProduct = loan.approvelAmount;
      }
    });
  }

  confirmTransactionIcsApi(
    confirmTransactionIcsApi: ConfirmTransactionIcsEntity,
    lId: number
  ) {
    this.thirdPartyService
      .confirmTransactionIcsApi(confirmTransactionIcsApi, lId)
      .subscribe((res) => {});
  }

  getIfTranslationExist(statusLibelle: string): string {
    let translate: string = this.translate.instant(
      'STATUT_LIBELLE.' + statusLibelle
    );
    return translate.includes('STATUT_LIBELLE.') ? statusLibelle : translate;
  }

  handleClosePopup() {
    this.showPopupEval = false;
  }

  checkNafedhLoans(loans: LoanEntityIB[]) {
    from(loans)
      .pipe(
        filter((res) => !res.accountNumber),
        switchMap((res) => {
          const {
            idIbLoan,
            customerDTO: { ibCustomerId },
          } = res;
          const historiqueNafedhRequest = new ThirdPartyHistorique();
          historiqueNafedhRequest.idLoan = idIbLoan;
          historiqueNafedhRequest.idCustomer = ibCustomerId;
          historiqueNafedhRequest.category = 'NAFATH/sendRequest';
          return this.creditService
            .getThirdParties(historiqueNafedhRequest)
            .pipe(
              map((resultNafedhRequest) => {
                if (resultNafedhRequest.length > 0) {
                  const randomValueMatch =
                    resultNafedhRequest[0].responseValue.match(
                      /random='([^']+)'/
                    );
                  res.nafedhCode = randomValueMatch
                    ? randomValueMatch[1]
                    : null;
                }
              }),
              catchError((error) => {
                console.error('Error fetching third parties:', error);
                return EMPTY;
              })
            );
        })
      )
      .subscribe();
  }

  showPopupNafedh(index: number) {
    if (
      (this.loansList[index].accountNumber == null ||
        !this.loansList[index].accountNumber) &&
      this.loansList[index].nafedhCode
    ) {
      this.sharedService.showInfo(
        this.translate.instant('INFO.loan_still_processing_with_nafedh')
      );
    }
  }

}
