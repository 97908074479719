import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DocumentService } from 'src/app/ib-services/document.service';
import { SharedService } from 'src/app/ib-services/shared.service';
import { CustomerEntity } from 'src/app/shared/Entities/customer.entity';
import { DocumentTypeEntity } from 'src/app/shared/Entities/DocumentType.entity';
import { LoanEntityIB } from 'src/app/shared/Entities/loan.entity';
import { LoanDocumentEntity } from 'src/app/shared/Entities/LoanDocument.entity';
import { SettingDocumentTypeEntity } from 'src/app/shared/Entities/SettingDocumentType.entity';
import { AcmConstants } from 'src/app/shared/mb-constants/mb-constants';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit {

  public loan : LoanEntityIB;
  public customer : CustomerEntity;
  public costumerDocuments: LoanDocumentEntity[] = [];
  showContract : boolean = false;
  contractSrc : string;

  constructor(private sharedService : SharedService, private documentService : DocumentService, private datePipe: DatePipe) { }

  ngOnInit() {
     this.loan  = this.sharedService.getLoanDeal();
    this.customer = this.sharedService.getCustomer();
     this.downloadDoc();

  }

  downloadDoc(){

     const document: LoanDocumentEntity = new LoanDocumentEntity();
     document.idCustomer = this.customer.ibCustomerId;
     document.loanId = this.loan.idIbLoan;
     document.settingDocumentTypeDTO = new SettingDocumentTypeEntity();

    this.documentService.getDocumentsByLoan(document).subscribe((arrayDocuments) => {
      this.costumerDocuments =[... arrayDocuments.filter(
        doc => doc.settingDocumentTypeDTO.code === 'PROOF_OF_DEBT_LETTER' || doc.settingDocumentTypeDTO.code === 'CLEARANCE_LOAN_DOCUMENT'
      )];
      console.log("Documents: ",this.costumerDocuments);
     });


  }

  view(document: LoanDocumentEntity) {
    if (document.idDocument !== null ) {
      console.log('ENTERED');
      this.documentService.getDocument(document.idDocument.toString(), this.loan.idIbLoan.toString(), "").subscribe(
        (res: any) => {
          if (res instanceof Blob) {
            if (res.size == 0) {
              return;
              }
              if(res.type ==='application/pdf'){
                const reader = new FileReader();
                reader.onloadend = () => {
                  const dataUrl = reader.result as string;
                  // Now, you can set the data URL as the source for the pdf-viewer
                  this.contractSrc = dataUrl;
                };
                reader.readAsDataURL(res);
                this.showContract = true;
              }
              else{
              // Convert Blob to data URL
            const fileData = [res];
            const extension = document.name.substring(document.name.indexOf(".") + 1);
            const documentType = this.getMimeType(extension);
            const blob = new Blob(fileData, { type: documentType });
            const url = window.URL.createObjectURL(blob);
            window.open(url, "_blank");
              }
          }
        }
      );
    } else {
      console.log('CLOSED');
      // Convert file to data URL
      const reader = new FileReader();
      reader.onloadend = () => {
        const dataUrl = reader.result as string;

        // Now, you can set the data URL as the source for the pdf-viewer
        this.contractSrc = dataUrl;

      };
      reader.readAsDataURL(this.costumerDocuments[0].file);
      this.showContract = true;
    }
  }

  closeContract(){
    this.showContract = false
  }
  getMimeType(fileExtension: string) {
    switch (fileExtension) {

        case 'pdf':
            return this.sharedService.typeMimes[0];
        case 'docx':
            return this.sharedService.typeMimes[7];
        case 'doc':
            return this.sharedService.typeMimes[1];
        case 'xlsx':
            return this.sharedService.typeMimes[8];
        case 'xls':
            return this.sharedService.typeMimes[2];
        case 'pptx':
            return this.sharedService.typeMimes[5];
        case 'ppt':
            return this.sharedService.typeMimes[6];
        case "jpeg":
            return this.sharedService.typeMimes[3];
        case "png":
            return this.sharedService.typeMimes[4];
            case "jpg":
            return this.sharedService.typeMimes[3];
        default:
            return '';
    }
  }
}
