<div id="documents" class="vw-100 px-3">
  <app-screen-title [title]="'BUTTONS.documents'" [color]="'color1'"
    [backTo]="'/loan-schedule-details'"></app-screen-title>
  <div id="documents-cards" class="mt-4">

    <div class="card cu-card p-2 py-3 mb-3">
      <div class="row">
        <div class="col-2">
          <div>
            <img class="ib-icons  m-1" src="assets/images/icons/sheets.png" alt="sheets">
          </div>
        </div>
        <div class="col-6 p-1">
          {{'DOCUMENT.consumer_financing_contract' | translate}}
        </div>
        <div class="col-4" style="display: inline-block;">
          <img class="ib-icons m-1 color-change" src="assets/images/icons/download_icon1.png" alt="upload"
            (click)="getNeededDocument('عقد القرض','download')">
          <img class="ib-icons m-1" src="assets/images/icons/eye.png" alt="eye"
            (click)="getNeededDocument('عقد القرض','view')">
        </div>

      </div>
    </div>

    <div class="card cu-card p-2 py-3 mb-3">
      <div class="row">
        <div class="col-2">
          <div>
            <img class="ib-icons  m-1" src="assets/images/icons/sheets.png" alt="sheets">
          </div>
        </div>
        <div class="col-6 p-1">
          {{'LABELS.PROOF_OF_DEBT_LETTER' | translate}}
        </div>
        <div class="col-4" style="display: inline-block;">
          <img class="ib-icons m-1 color-change" src="assets/images/icons/download_icon1.png" alt="upload"
            (click)="getNeededDocument('Proof Of Debt Letter','download')">
          <img class="ib-icons m-1" src="assets/images/icons/eye.png" alt="eye"
            (click)="getNeededDocument('Proof Of Debt Letter','view')">
        </div>
      </div>
    </div>

    <div class="card cu-card p-2 py-3 mb-3">
      <div class="row">
        <div class="col-2">
          <div>
            <img class="ib-icons  m-1" src="assets/images/icons/sheets.png" alt="sheets">
          </div>
        </div>
        <div class="col-6 p-1">
          {{'LABELS.CLEARANCE_LOAN_DOCUMENT' | translate}}
        </div>
        <div class="col-4" style="display: inline-block;">
          <img class="ib-icons m-1 color-change" src="assets/images/icons/download_icon1.png" alt="upload"
            (click)="getNeededDocument('Clearance Loan Document','download')">
          <img class="ib-icons m-1" src="assets/images/icons/eye.png" alt="eye"
            (click)="getNeededDocument('Clearance Loan Document','view')">
        </div>
      </div>
    </div>

    <div class="card cu-card p-2 py-3 mb-3" hidden>
      <div class="row">
        <div class="col-3">
          <div>
            <img class="ib-icons  m-1" src="assets/images/icons/sheets.png" alt="sheets">
          </div>
        </div>
        <div class="col-6 p-1">
          {{'DOCUMENT.tawkeel_murabha' | translate}}
        </div>
        <div class="col-3" style="display: contents;">
          <img class="ib-icons m-1 color-change" src="assets/images/icons/download_icon1.png" alt="upload"
            (click)="getNeededDocument('MURABHA_CERTIFICATE','download')">
          <img class="ib-icons m-1" src="assets/images/icons/eye.png" alt="eye"
            (click)="getNeededDocument('MURABHA_CERTIFICATE','view')">
        </div>
      </div>
    </div>

    <div class="card cu-card p-2 py-3 mb-3">
      <div class="row">
        <div class="col-2">
          <div>
            <img class="ib-icons  m-1" src="assets/images/icons/sheets.png" alt="sheets">
          </div>
        </div>
        <div class="col-6 p-1">
          {{'DOCUMENT.schedule' | translate }}
        </div>
        <div class="col-4" style="display:contents">
          <img class="ib-icons m-1 color-change" src="assets/images/icons/download_icon1.png" alt="upload"
            (click)="getNeededDocument('Schedule','download')">
          <img class="ib-icons m-1" src="assets/images/icons/eye.png" alt="eye" (click)="viewSchedule()">

        </div>
      </div>
    </div>

  </div>

  <div *ngIf="!customerDocuments || customerDocuments.length == 0">
    <div class="pt-4 flc inline-flex align-items-center">
      <img class="img-logo-sanad op-20" style="width: 100px;" src="assets/images/note.png" alt="My Image">
    </div>
    <label class="label-info flc">{{'INFO.no_document_found' | translate}}</label>
  </div>

  <div *ngIf="showContract">
    <div class="fullscreen" style="padding-top: 50px">
      <div class="my-2 mt-4 d-flex justify-content-between">
        <button class="close-btn bg-danger white" (click)="closeContract()">
          <i class='bx bx-x'></i>
        </button>
      </div>


      <!-- Zoom Controls -->
      <div class="zoom-toolbar">
        <button class="btn zoom-btn" (click)="increaseZoom()">
          <i class='bx bx-zoom-in white'></i>
        </button>
        <span class="zoom-level white">{{ (zoomLevel * 100) | number: '1.0-0' }}%</span>
        <button class="btn zoom-btn" (click)="decreaseZoom()">
          <i class='bx bx-zoom-out white'></i>
        </button>
      </div>

      <!-- PDF Viewer -->
      <pdf-viewer [src]="contractSrc" [show-all]="false" class="pdfViewer"
        [zoom]="zoomLevel" [render-text]="true" [autoresize]="true"
        [page]="currentPage" (after-load-complete)="onAfterLoadComplete($event)">
      </pdf-viewer>

      <!-- Floating Toolbar -->
      <div class="floating-toolbar">
        <button class="btn" (click)="nextPage()">
          <i class='bx bx-chevron-right white'></i>
        </button>
        <span class="page-indicator white fss">{{ currentPage }}/{{ totalPages }}</span>
        <button class="btn" (click)="prevPage()">
          <i class='bx bx-chevron-left white'></i>
        </button>
      </div>

    </div>
  </div>



</div>
