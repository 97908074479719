
<div id="STEPPER">
  <div class="p-1" style="display: inline;">
    <h2>
      <div style="display: flex;">
        <div class="circle-card white mx-2 fc px-2 pb-1">{{stepIndex}}</div>
        <span class="white fw-light white mt-1">{{stepTitle}}</span>
      </div>
    </h2>
    <span class="fw-500 white"  *ngIf="nextStep !== ''">{{'LABELS.next_step' | translate}} : <span class="u">{{nextStep}}</span></span>
  </div>

  <span class="fle  white"> {{stepIndex}} {{'OTHER.of' | translate}} {{totalStepsNumber}}</span>
  <div class="progress mb-4">
    <div class="progress-bar progress-bar-striped" role="progressbar" [style]="widthProgress" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
  </div>

</div>
