<!-- OTP Div -->
<div id="otp-div" class="bg-2  vh-100 vw-100 " *ngIf="!showBiometricDiv && showOtpDiv">
  <app-screen-title [title]="'TITLE.verify_code'" [color]="'color1'" [backTo]="'/login'" *ngIf="source !=='sign-contract'" ></app-screen-title>
  <div class="px-4">
    <div class="flc">
      <label class="label-info"> {{ 'INFO.enter_verif_code' | translate }} <br>
        <label class="fw-600 mt-2 black fsxxl"> {{customerNumber}}</label></label>
    </div>
    <div >
      <div class="flc otp-class">
        <ng-otp-input (onInputChange)="onOtpChange($event)"  [config]="{length:4,allowNumbersOnly:true}" dir="ltr" #ngOtpInput></ng-otp-input>
      </div>

      <div class="text-center py-2 pt-4">
        <button type="button" class="btn btn-primary btn-lg mb-2 login-btn" (click)="nextBiometrics()" [disabled]="!disableCheckOtp">
          {{ 'BUTTONS.verify_account' | translate}}
        </button>
      </div>

      <div class="col-11 col-sm-11 mt-auto mx-auto py-2">
        <div class="row ">
          <div class="col-auto text-center mx-auto">
            <p class="my-2" *ngIf="counter === 0"><a (click)="reInitializer()">{{'LINK.resend_code' | translate}}</a></p>
            <label class="m-2 label-info" *ngIf="counter != 0" >{{counter}}
              <span *ngIf="counter < 11 && lang==='ar'">{{'LABEL.seconds' | translate}}</span>
              <span *ngIf="counter > 10 && lang==='ar'" >{{'LABEL.second' | translate}}</span>
              <span *ngIf="lang==='en'" >{{'LABEL.seconds' | translate}}</span>
            </label>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<!-- Biometrics Div -->
<div id="Biometrics-div" class="bg-2  vh-100 vw-100 " *ngIf="showBiometricDiv && !showOtpDiv">
  <app-screen-title [title]="'TITLE.biometrics_auth'" [color]="'color1'" [backTo]="'/login'"></app-screen-title>
  <div id="reset-pw-div" class="px-5">
    <label class="label-info"> {{ 'INFO.biometrics_activation' | translate }} </label>
    <div class="form-group mt-4 mb-3 py-4">
      <div class="wrapper">
        <div class="flc">
          <div class="ms-2  mb-4 flc inline-flex align-items-center">
            <img class="img-logo-sanad" src="assets/images/biometrics.png" alt="biometrics">
          </div>
        </div>
      </div>
      <label class="text-secondary text-center flc"> {{ 'INFO.sync_biometrics' | translate }} </label>
    </div>
  </div>
  <div class="text-center px-2 mb-2 fixed-bottom" >
    <button type="button" class="btn btn-primary btn-lg mb-2 login-btn" (click)="performBiometricVerification()">
      {{'BUTTONS.activate' | translate}}
    </button>
    <button type="button" class="btn btn-danger btn-lg mb-2 login-btn" (click)="connect()">
      {{'BUTTONS.later' | translate}}
    </button>
  </div>

</div>
