<div class="bg-2">
  <app-screen-title [title]="'TITLE.Review_the_request'" [color]="'color1'" [backTo]="'/home'"></app-screen-title>
  <div id="summary" class="pt-2  p-3 ">

    <!-- Personal Information -->
    <div class="row mb-4">
      <div class="col-12">
        <div class="card card-shadow">
          <div class="card-header fsb py-2">
            <div style="display: flex; align-items: center;gap: 10px;">
              <div class="icon-card">
                <!-- <i class='bx bx-user'></i> -->
                <img class="ib-icons m-2" src="assets/images/icons/cu-user.png" alt="cu-phone" style="position: absolute;">
              </div>
              <div class="d-grid py-2">
                <label class="ms-2 fsl">{{ 'LABELS.personal_information' | translate }}</label>
                <label class="form-label ms-2 text-success">{{'STATUSES.completed' | translate}}</label>
              </div>
            </div>
            <div (click)="edit('edit-personal-info')" style="display: flex; align-items: center;">
              <img class="ib-icons " src="assets/images/icons/edit-icon.png" alt="edit-icon">
            </div>
          </div>
          <div class="card-body py-2">
            <div class="fsb">
              <label class="form-label ms-2 ">{{ 'LABELS.loan_reason' | translate }}</label>
              <label class="form-label ms-2  text-primary"> {{personalInfo?.loanPurpose ? personalInfo.loanPurpose : '...'  }} </label>
            </div>
            <div class="fsb">
              <label class="form-label ms-2 ">{{ 'LABELS.marital_status' | translate }}</label>
              <label class="form-label ms-2  text-primary">{{personalInfo?.materialStatus ? personalInfo?.materialStatus: '...'}}</label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Work Information -->
    <div class="row mb-4">
      <div class="col-12">
        <div class="card card-shadow">
          <div class="card-header fsb py-2">
            <div style="display: flex; align-items: center;gap: 10px;">
              <div class="icon-card">
                <!-- <i class='bx bxs-briefcase'></i> -->
                <img class="ib-icons m-2" src="assets/images/icons/cu-pack.png" alt="cu-phone" style="position: absolute;">
              </div>
              <div class="d-grid py-2">
                <label class="fsl ms-2"> {{ 'LABELS.employer_information' | translate }} </label>
                <label class="form-label ms-2  text-success">{{'STATUSES.completed' | translate}}</label>
              </div>
            </div>
            <div (click)="edit('edit-work-info')" style="display: flex; align-items: center;">
              <img class="ib-icons " src="assets/images/icons/edit-icon.png" alt="edit-icon">
            </div>
          </div>
          <div class="card-body py-2">
            <div class="fsb">
              <label class="form-label ms-2 ">{{ 'LABELS.join_date' | translate }}</label>
              <label class="form-label ms-2  text-primary"> {{workInfo?.dateJoining ? workInfo.dateJoining: '...'}} </label>
            </div>
            <div class="fsb">
              <label class="form-label ms-2 ">{{ 'LABELS.labor_sector' | translate}}</label>
              <label class="form-label ms-2  text-primary">{{workInfo?.workSection? workInfo.workSection: '...'}}</label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Salary Details -->
    <div class="row mb-4" hidden>
      <div class="col-12">
        <div class="card card-shadow">
          <div class="card-header fsb py-2">
            <div style="display: flex; align-items: center;gap: 10px;">
              <div class="icon-card">
                <!-- <i class='bx bx-wallet'></i> -->
                <img class="ib-icons m-2" src="assets/images/icons/cu-wallet.png" alt="cu-phone" style="position: absolute;">
              </div>
              <div class="d-grid py-2">
                <label class=" ms-2 fsl"> {{ 'LABELS.income_details' | translate }} </label>
                <label class=" ms-2 text-success">{{'STATUSES.completed' | translate}}</label>
              </div>
            </div>
            <div (click)="edit('edit-income-details')" style="display: flex; align-items: center;">
              <img class="ib-icons " src="assets/images/icons/edit-icon.png" alt="edit-icon">
            </div>
          </div>
          <div class="card-body py-2">
            <div class="fsb">
              <label class="form-label ms-2 "> {{ 'LABELS.basic_salary' | translate }} </label>
              <label class="form-label ms-2  text-primary">{{incomeDetails?.basicSalary ? incomeDetails.basicSalary : '...'}} <span class="fsxs"> {{ 'CURRENCY.sar' | translate }}</span></label>
            </div>
            <div class="fsb">
              <label class="form-label ms-2 "> {{ 'LABELS.housing_allowance' | translate }} </label>
              <label class="form-label ms-2  text-primary">{{incomeDetails?.housingAllowance ? incomeDetails.housingAllowance : '...'}}<span class="fsxs"> {{ 'CURRENCY.sar' | translate }}</span></label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Charges & Expenses -->
    <div class="row mb-4">
      <div class="col-12">
        <div class="card card-shadow">
          <div class="card-header fsb py-2">
            <div style="display: flex; align-items: center;gap: 10px;">
              <div class="icon-card">
                <!-- <i class='bx bx-credit-card-alt'></i> -->
                <img class="ib-icons " src="assets/images/icons/money_icon.png" alt="cu-phone" style="position: absolute;">
              </div>
              <div class="d-grid py-2">
                <label class=" ms-2 fsl"> {{ 'LABELS.obligations_expenses' |translate }} </label>
                <label class=" ms-2  text-success">{{'STATUSES.completed' | translate}}</label>
              </div>
            </div>
            <div (click)="edit('edit-expenses-obligations')" style="display: flex; align-items: center;">
              <img class="ib-icons " src="assets/images/icons/edit-icon.png" alt="edit-icon">
            </div>
          </div>
          <div class="card-body py-2">
            <div class="fsb">
              <label class="form-label ms-2 ">  {{'LABELS.monthly_obligations' | translate}} </label>
              <label class="form-label ms-2  text-primary">{{expensesObligations?.monthlyInstallment ? expensesObligations.monthlyInstallment: '...'}} <span class="fsxs"> {{ 'CURRENCY.sar' | translate }}</span></label>
            </div>
            <div class="fsb">
              <label class="form-label ms-2 "> {{ 'LABELS.living_expenses' | translate }} </label>
              <label class="form-label ms-2  text-primary">{{monthlyObligation}}<span class="fsxs"> {{ 'CURRENCY.sar' | translate }}</span></label>
            </div>
          </div>
        </div>
      </div>
    </div>

        <!-- Iban -->
        <div class="row mb-4">
          <div class="col-12">
            <div class="card card-shadow">
              <div class="card-header fsb py-2">
                <div style="display: flex; align-items: center;gap: 10px;">
                  <div class="icon-card">
                    <i class='bx bx-credit-card-alt'></i>
                  </div>
                  <div class="d-grid py-2">
                    <label class=" ms-2 fsl"> {{ 'LABELS.iban_number' |translate }} </label>
                    <label class=" ms-2  text-success">{{'STATUSES.completed' | translate}}</label>
                  </div>
                </div>
                <div (click)="edit('edit-iban')" style="display: flex; align-items: center;">
                  <img class="ib-icons " src="assets/images/icons/edit-icon.png" alt="edit-icon">
                </div>
              </div>
              <div class="card-body py-4">
                <div class="fsb">
                  <label class="form-label ms-2 "> {{ 'LABELS.bank_name' | translate }} </label>
                  <label class="form-label ms-2  text-primary"> {{bankName}} </label>
                </div>
              </div>
            </div>
          </div>
        </div>
    <div class="text-center mt-3 pb-3 mb-4">
      <button type="button" class="btn btn-primary btn-lg mb-2 login-btn" (click)="confirm()">
        {{'BUTTONS.continue' | translate}}
      </button>
    </div>
  </div>
</div>

<!-- success popup  -->
<app-popup *ngIf="showPopup" [source]="'REQUEST_SUCCESS'" [number]="loan.accountNumber"></app-popup>
