import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, filter, interval, switchMap, take } from 'rxjs';
import { CustomerService } from 'src/app/ib-services/customer.service';
import { LoaderService } from 'src/app/ib-services/loader.service';
import { LoanService } from 'src/app/ib-services/loan.service';
import { SharedService } from 'src/app/ib-services/shared.service';
import { CustomerEntity } from 'src/app/shared/Entities/customer.entity';
import { LoanEntityIB } from 'src/app/shared/Entities/loan.entity';
import { ProductEntity } from 'src/app/shared/Entities/product.entity copy';
import { PathConstants } from 'src/app/shared/mb-constants/path-constants';

@Component({
  selector: 'app-consent-validation',
  templateUrl: './consent-validation.component.html',
  styleUrls: ['./consent-validation.component.scss']
})
export class ConsentValidationComponent implements OnInit {

  public showSimahCheck : boolean = true;
  public showSimahVerificationFromACM : boolean = false;
  public showCheckAmlPopup : boolean = false;
  public isCheckedSimah: Boolean = false;
  public showSimahConsent: Boolean = false;
  public checkNafathSucess: boolean = false;
  public randomNumber: string;
  public nationalId: string;
  public statut: string;
  public selectedProduct : ProductEntity;
  public fetchedCustomer : CustomerEntity;
  public showSimahRejection :Boolean = false;
  public showSimahPending : Boolean  = false;
  public showButtonLater : Boolean = false;
  public checker : Boolean = false;
  public loan : LoanEntityIB;
  public customer : CustomerEntity;
  private intervalSubscription: Subscription;
  private excludePaths : string[] = ['consent-validation','summary-initial-approval','simah-status'];

  constructor(public sharedService : SharedService, private loaderService : LoaderService,private router : Router,private translate: TranslateService,
              private customerService : CustomerService,private loanService : LoanService) { }

  ngOnInit() {
    this.selectedProduct = this.sharedService.getProduct();
    this.customer = this.sharedService.getCustomer();
    if (this.customer.firstName == undefined){
      this.customerService.getCustomerById(this.sharedService.getUser().customerId).subscribe(res => {
        this.sharedService.setCustomer(res);
      })
    }
  }


  confirmFollowUp() {
    let loan = this.sharedService.getLoanDeal();
    loan.stepPath = PathConstants.PATH_SIMAH_STATUS;
     loan.actionToAcm = "APPROVE";
     this.loanService.updateLoan(loan).subscribe(res => {
      this.sharedService.setLoanDeal(res);
      this.showSimahCheck = false;
      this.showSimahVerificationFromACM = true;
      this.simahStepVerification();
     });
  }

    simahStepVerification() {
      this.loaderService.disableLoader.next(true);
        let l = this.sharedService.getLoanDeal().idIbLoan;
        this.intervalSubscription = interval(5000)
          .pipe(
            switchMap(() => this.loanService.findByIdIbLoan(l)),
            filter((loanResponseSync) => this.sharedService.getLoanDeal().statutLibelle != loanResponseSync.statutLibelle ),
            take(1)
          )
          .subscribe((res) => {
                this.router.navigate(['/' + PathConstants.PATH_HOME]).then(() => {
                  // Code to be executed after navigation is complete
                  this.sharedService.showInfo(this.translate.instant('TOAST.acceptedSimahTerms'))
                  this.sharedService.setLoanDeal(res);
                });
          });
      }


    ngOnDestroy(): void {
      // Unsubscribe to prevent memory leaks when the component is destroyed
      if (this.intervalSubscription) {
        this.intervalSubscription.unsubscribe();
      }
      this.loaderService.disableLoader.next(false);
    }

}
