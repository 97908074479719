import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from 'src/app/ib-services/shared.service';
import { LoanEntityIB } from 'src/app/shared/Entities/loan.entity';

@Component({
  selector: 'app-new-stepper',
  templateUrl: './new-stepper.component.html',
  styleUrls: ['./new-stepper.component.scss']
})
export class NewStepperComponent {
  @Input() type: string;
  @Input() currentStep: number;
  public loan: LoanEntityIB;

  steps : string[];

  /** Steps Names for personal information*/
  public personalInformation : string = this.translate.instant('LABELS.personal_information');
  public incomeDetails : string = this.translate.instant('LABELS.income_details');
  public obligations : string = this.translate.instant('LABELS.obligations');
  public ibanNumber : string = this.translate.instant('LABELS.iban_number');

    /** Steps Names for Sign Contract */
  public callCustomer : string = this.translate.instant('TITLE.call_customer');
  public signContract : string = this.translate.instant('TITLE.sign_contract');
  public sanadAlAmr : string = this.translate.instant('BUTTONS.sign_sanad_al_amr');
  public remainingTime : string = this.translate.instant('LABELS.personal_information');


  constructor(public sharedService: SharedService, private translate : TranslateService) { }

  ngOnInit() {
    this.loan = this.sharedService.getLoanDeal();
    if (this.type === 'sign-contract'){
      // , this.remainingTime
      this.steps =  [ this.callCustomer , this.signContract , this.sanadAlAmr  ];
    } else if (this.type === 'information-entry'){
      // , 2 /  this.incomeDetails ,
      this.steps =  [ this.personalInformation , this.obligations , this.ibanNumber ];
    }
  }
}
