import { Injectable } from '@angular/core';
import { UserEntity } from '../shared/Entities/user.entity';
import { LoanEntityIB } from '../shared/Entities/loan.entity';
import { CustomerEntity } from '../shared/Entities/customer.entity';
import { ProductEntity } from '../shared/Entities/product.entity copy';
import { LoanDetailsEntity } from '../shared/Entities/loanDetails.entity';
import { ParametrageService } from './parametrage.service';
import { AcmConstants } from '../shared/mb-constants/mb-constants';
import { ToastrService } from 'ngx-toastr';
import { ScheduleEntity } from '../shared/Entities/schedule.entity';
import { Preferences } from '@capacitor/preferences';
import { BankInformation } from '../shared/Entities/BankInformation.entity';
import { ClaimsEntity } from '../shared/Entities/claims.entity';
import { SettingClaimsEntity } from '../shared/Entities/setting-claims.entity';
import { DeviceFocalRequestDTO } from '../shared/Entities/DeviceFocalRequest.entity';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  private readonly FIRST_LAUNCH_KEY = 'firstLaunch';
  private user: UserEntity = new UserEntity();
  loanPrep: LoanEntityIB;
  customerEntity: CustomerEntity;
  registrationData: any;
  loggedUser: any | undefined;
  minMaxConsumerProduct: any = { min: 5000, max: 30000 };
  product: ProductEntity;
  loanDetails : LoanDetailsEntity;
  username: string;
  lang: string;
  termPaid : number;
  showFirstRequestApproved :boolean = true;
  personalInfo : any;
  workInfo : any;
  incomeDetails : any;
  expensesObligations : any;

  deviceFocal  : DeviceFocalRequestDTO;
  location : string;

  userToSave: UserEntity;
  customerToSave: CustomerEntity;
  loanToSave: LoanEntityIB;
  private isMobile: Boolean;
  randomNumberNafedh: string;
  transactionId: string;
  responseRandomNumberFromNafedh: string;
  nationalId: string;
  public shouldDisplayHeader: Boolean = false;
  shcedules : ScheduleEntity[];
  rejectReason: string;
  bankInformation : BankInformation;

  public ipAddress : any;

  public claim : ClaimsEntity;
  public claimSubject : SettingClaimsEntity;
  public phoneNumberCustomer:string;
  public hasBiometricCredentials : boolean = false;
  private listLoans : LoanEntityIB[];

  // TODO DATABASE
  public typeMimes = [
    'application/pdf',
    'application/msword',
    'application/vnd.ms-excel',
    'image/jpeg',
    'image/png',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  ];
  otp: string;
  public password : string;
  constructor(public paramService: ParametrageService, private toastr: ToastrService) { }

  /** First Launch Application Splash Screen */
  async isFirstLaunch(): Promise<boolean> {
    const result = await Preferences.get({ key: this.FIRST_LAUNCH_KEY });
    return result.value !== 'true';
  }

  async setNotFirstLaunch(): Promise<void> {
    await Preferences.set({
      key: this.FIRST_LAUNCH_KEY,
      value: 'true',
    });
  }

  async removeFirstLaunchFlag(): Promise<void> {
    await Preferences.remove({ key: this.FIRST_LAUNCH_KEY });
  }

  getUsernameFirstConnection() {
    return this.username;
  }

  setUsernameFirstConnection(value: string) {
    this.username = value;
  }

  getPassword() : string{
    return this.password;
  }

  setPassword(value : string){
    this.password = value;
  }

  getLoanToSave() {
    return this.loanToSave;
  }

  setLoanToSave(loan: LoanEntityIB) {
    this.loanToSave = loan;
  }

  getUserToSave() {
    return this.userToSave;
  }

  setUserToSave(user: UserEntity) {
    this.userToSave = user;
  }

  getCustomerToSave() {
    return this.customerToSave;
  }

  setCustomerToSave(customer: CustomerEntity) {
    this.customerToSave = customer;
  }

  //Product Getter && Setter
  getProduct() {
    return this.product;
  }

  setProduct(product: ProductEntity) {
    this.product = product;
  }

  // Loan Getter && Setter
  getLoanDeal() {
    return this.loanPrep;
  }

  setLoanDeal(loan: LoanEntityIB) {
    this.loanPrep = loan;

  }

  stashPreservedLoan() {
    this.loanPrep = new LoanEntityIB();
  }

  getConsumerRange() {
    return this.minMaxConsumerProduct;
  }

  convertDateFormat(dateString: string): string {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();
    const formattedDate = day + '/' + month + '/' + year;
    return formattedDate;
  }

  getCustomer() {
    return this.customerEntity;
  }

  setCustomer(customer: CustomerEntity) {
    this.customerEntity = customer;
  }

  stashCustomer() {
    this.customerEntity = undefined;
  }
  /**
   *
   * @param type number => 0 : success / 1 : error / 2 : warning / 3 : info
   * @param message string
   */
  openSnackBar(type, message) {
    const config = {
      duration: 0,
      // horizontalPosition: this.horizontalPosition,
      // verticalPosition: this.verticalPosition,
      panelClass: [''],
    };
    switch (type) {
      case 0:
        config.panelClass = ['success-snackbar'];
        break;
      case 1:
        config.panelClass = ['danger-snackbar'];
        break;
      case 2:
        config.panelClass = ['warning-snackbar'];
        break;
      case 3:
        config.panelClass = ['info-snackbar'];
        break;
      default:
        break;
    }
  }

  /**
   * getUser
   * @param value the value to set
   */

  getUser() {
    return this.user;
  }

  setUser(value: UserEntity) {
    this.user = value;
  }

  /** NEW GETTERS 1 SETTERS FOR REGISTRATION WORKFLOW */

  getRegistration() {
    return this.registrationData;
  }

  setRegistration(reg: any) {
    console.log(reg);
    this.registrationData = reg;
  }
  getCustomerName(customer: CustomerEntity): string {
    if (customer.customerType === AcmConstants.INDIVIDUAL) {
      customer.customerNameNoPipe = customer.firstName + ' ';
      if (customer.secondName !== null) {
        customer.customerNameNoPipe += customer.secondName + ' ';
      }
      if (customer.middleName !== null) {
        customer.customerNameNoPipe += customer.middleName + ' ';
      }
      customer.customerNameNoPipe +=
        customer.lastName !== null ? customer.lastName : ' ';
    } else if (customer.customerType === AcmConstants.GROUP) {
      customer.customerNameNoPipe =
        customer.solidarityName !== null
          ? customer.solidarityName
          : customer.customerName;
    } else if (customer.customerType === AcmConstants.ORGANIZATION) {
      customer.customerNameNoPipe =
        customer.organizationName !== null
          ? customer.organizationName
          : customer.customerName;
    }
    return customer.customerNameNoPipe;
  }
  getNextStepInstance(loan: LoanEntityIB) {
    loan.loanInstancesDtos = loan.loanInstancesDtos.sort(
      (instance) => instance.orderEtapeProcess - instance.orderEtapeProcess
    );
    let nextStepIndex =
      loan.loanInstancesDtos.findIndex(
        (instance) => (instance.code = loan.etapeWorkflow)
      ) + 1;
    return loan.loanInstancesDtos[nextStepIndex];
  } /** Device Detection */

  getIsMobile() {
    return this.isMobile;
  }

  setIsMobile(res: Boolean) {
    this.isMobile = res;
  }
  getshouldDisplayHeader() {
    return this.shouldDisplayHeader;
  }

  setshouldDisplayHeader(res: Boolean) {
    this.shouldDisplayHeader = res;
  }

  setRandomNumberNafedh(randomNumber: string) {
    this.randomNumberNafedh = randomNumber;
  }

  getRandomNumberNafedh() {
    return this.randomNumberNafedh;
  }

  setTransactionIdNafedh(transId: string) {
    this.transactionId = transId;
  }

  getTransactionIdNafedh() {
    return this.transactionId;
  }

  setResponseRandomNumberNafedh(randomNumber: string) {
    this.responseRandomNumberFromNafedh = randomNumber;
  }

  getResponseRandomNumberNafedh() {
    return this.responseRandomNumberFromNafedh;
  }

  setNationalId(nationalId: string) {
    this.nationalId = nationalId;

  }

  getNationalId() {
    return this.nationalId;
  }
  setLang(lang: string) {
    this.lang = lang;
  }

  getLang() {
    return this.lang;
  }
  setLoanDetails(loanDetails: LoanDetailsEntity) {
    this.loanDetails=loanDetails;
  }

  getLoanDetails() {
    return this.loanDetails;
  }

  getOtp() {
    return this.otp;
  }

  setOtp(value: string) {
    this.otp = value;
  }

  /** TOASTR */
  showSuccess(msg : string) {
    this.toastr.success(msg);
  }

  showError(msg : string){
    this.toastr.error(msg);
  }

  showInfo(msg : string){
    this.toastr.info(msg);
  }
  // loan schedules
  getSchedules() {
    return this.shcedules;
  }

  setSchedules(  shcedules : ScheduleEntity[]) {
    this.shcedules = shcedules;
  }
  getTermPaid() {
    return this.termPaid;
  }

  setTermPaid(  term : number) {
    this.termPaid = term;
  }
  getShowFirstRequestApproved() {
    return this.showFirstRequestApproved;
  }

  setSshowFirstRequestApproved(  showFirstRequestApproved : boolean) {
    this.showFirstRequestApproved = showFirstRequestApproved;
  }

  getPersonalInfo() {
    return this.personalInfo;
  }

  setPersonalInfo(personalInfo: any) {
    this.personalInfo = personalInfo;

  }
  getWorkInfo() {
    return this.workInfo;
  }

  setWorkInfo(workInfo: any) {
    this.workInfo = workInfo;

  }
  getIncomeDetails() {
    return this.incomeDetails;
  }

  setIncomeDetails(incomeDetails: any) {
    this.incomeDetails = incomeDetails;

  }
  getExpensesObligations() {
    return this.expensesObligations;
  }

  setExpensesObligations(expensesObligations: any) {
    this.expensesObligations = expensesObligations;

  }

  setRejectReason(rejectReason: string) {
    this.rejectReason = rejectReason;
  }

  getRejectReason() {
    return this.rejectReason;
  }
  setBankInformation(bankInformation: BankInformation) {
    this.bankInformation = bankInformation;
  }
  getBankInformation() {
    return this.bankInformation;
  }

  /** Claim */
  getClaim(){
    return this.claim;
  }

  setClaim(claim : ClaimsEntity){
    this.claim = claim;
  }

  /** Claim Note */
  getSubject(){
    return this.claimSubject;
  }

  setSubject(claimSubject : SettingClaimsEntity){
    this.claimSubject = claimSubject;
  }

  /** Evaluation Screen */
  getLoanForEval(){
    return this.loanPrep;
  }

  setLoanForEval(loan : LoanEntityIB){
    this.loanPrep = loan;
  }

  getPhoneNumberCustomer(){
    return this.phoneNumberCustomer;
  }

  setPhoneNumberCustomer(phoneNumberCustomer : string){
    this.phoneNumberCustomer = phoneNumberCustomer;
  }

  getUserHadBiometricCredentials(){
    return this.hasBiometricCredentials;
  }

  setUserHadBiometricCredentials( biometric : boolean ){
    this.hasBiometricCredentials = biometric ;
  }

  getDeviceInfo(){
    return this.deviceFocal;
  }

  setDeviceInfo( device : DeviceFocalRequestDTO){
    this.deviceFocal = device;
  }

  getIpAddress(){
    return this.ipAddress;
  }

  setIpAddress(adr : any){
    this.ipAddress = adr;
  }

  getLocation(){
    return this.location;
  }

  setLocation(location  : string){
    this.location = location;
    console.log('LOCATION : ',this.location)
  }

  getListLoans(){
    return this.listLoans;
  }

  setListLoans(list : LoanEntityIB[]){
    this.listLoans = list;
  }

}
