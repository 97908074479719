<div class="fsa px-1 mt-2" style="zoom : 90%">
  <div style="display: grid;">
    <div class="icon-div" [ngClass]="{'currentStep': currentStep >= 1}">
      <i class='bx bx-time-five' *ngIf="currentStep < 1"></i>
      <i class='bx bx-loader' *ngIf="currentStep === 1"></i>
      <i class='bx bx-check-circle' *ngIf="currentStep > 1"></i>
    </div>
    <div>
      <label class="step-text mt-1">{{steps[0]}}</label>
    </div>
  </div>
  <div class="icon-line"></div>
  <div style="display: grid;">
    <div class="icon-div" [ngClass]="{'currentStep': currentStep >= 2}">
      <i class='bx bx-time-five' *ngIf="currentStep < 2"></i>
      <i class='bx bx-loader' *ngIf="currentStep === 2"></i>
      <i class='bx bx-check-circle' *ngIf="currentStep > 2"></i>
    </div>
    <div>
      <label class="step-text mt-1">{{steps[1]}}</label>
    </div>
  </div>
  <div class="icon-line"></div>
  <div style="display: grid;">
    <div class="icon-div" [ngClass]="{'currentStep': currentStep >= 3}">
      <i class='bx bx-time-five' *ngIf="currentStep < 3"></i>
      <i class='bx bx-loader' *ngIf="currentStep === 3"></i>
      <i class='bx bx-check-circle' *ngIf="currentStep > 3"></i>
    </div>
    <div>
      <label class="step-text mt-1">{{steps[2]}}</label>
    </div>
  </div>

  <div class="icon-line"></div>
  <div style="display: grid;">
    <div class="icon-div" [ngClass]="{'currentStep': currentStep >= 4}">
      <i class='bx bx-time-five' *ngIf="currentStep < 4"></i>
      <i class='bx bx-loader' *ngIf="currentStep === 4"></i>
      <i class='bx bx-check-circle' *ngIf="currentStep > 4"></i>
    </div>
    <div>
      <label class="step-text mt-1">{{steps[3]}}</label>
    </div>
  </div>

</div>
