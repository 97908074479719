import { Component, OnInit } from '@angular/core';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { TranslateService } from '@ngx-translate/core';
import { DocumentService } from 'src/app/ib-services/document.service';
import { SharedService } from 'src/app/ib-services/shared.service';
import { LoanEntityIB } from 'src/app/shared/Entities/loan.entity';
import { ScheduleEntity } from 'src/app/shared/Entities/schedule.entity';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-schedule-payment',
  templateUrl: './schedule-payment.component.html',
  styleUrls: ['./schedule-payment.component.scss'],
})
export class SchedulePaymentComponent implements OnInit {
  public schedules: ScheduleEntity[] = [];
  public loan: LoanEntityIB;
  public termPaid: number = 0;
  public donePayment: number;
  public remainingAmount: number;
  public paymentStatus: number;
  public listAmountPaid: string[] = [];

  constructor(
    public sharedService: SharedService,
    private documentService: DocumentService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.schedules = this.sharedService.getSchedules();
    this.loan = this.sharedService.getLoanDeal();
    this.termPaid = this.sharedService.getTermPaid();
    this.donePayment = this.loan.normalPayment * this.termPaid;
    this.remainingAmount = this.loan.approvelAmount - this.donePayment;
    this.paymentStatus = this.termPaid / this.loan.termPeriodNum;
    if (this.termPaid === this.loan.termPeriodNum) this.paymentStatus = 100;
    this.schedules.forEach((schedule, index) => {
      if (schedule.interestAmtPaid != null) {
        let x = this.calculateTotalAmountPaid(index);
        this.listAmountPaid.push(x);
      } else {
        this.listAmountPaid.push(schedule.totalRepayment.toString());
      }
    });
  }

  getPercentage(): number {
    return Math.round(this.paymentStatus * 100);
  }

  calculateTotalAmountPaid(index: number): string {
    let a = this.schedules[index].loanRepaymentPaid;
    let b = this.schedules[index].interestAmtPaid;
    let valueA = this.extractNumberFromAmount(a);
    let valueB = 0;
    if (b != null) {
      valueB = this.extractNumberFromAmount(b);
    }
    let res = valueB + valueA;
    let result = 'SAR' + res.toFixed(2);
    return result;
  }

  extractNumberFromAmount(amountString: string): number {
    let x = amountString.replace('SAR', '').replace(/,/g, '').trim();
    let convertedNumber = Number(x);
    return convertedNumber;
  }

  getScheduleReport() {
    if (
      Capacitor.getPlatform() === 'ios' ||
      Capacitor.getPlatform() === 'android'
    ) {
      this.documentService
        .getDocumentByReportNameToPath(this.loan.accountNumber, 'schedule')
        .subscribe((res) => {
          let pathUrl = environment.urlForCapacitor + '/assets/files/' + res;
          Browser.open({ url: pathUrl });
        });
    } else {
      this.documentService
        .getDocumentByReportName(this.loan.accountNumber, 'schedule')
        .subscribe(
          (res) => {
            const blob = new Blob([res], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            let generatedScheduleName =
              'Schedule_Report_' + new Date().toISOString();
            link.download = generatedScheduleName + '.pdf';
            link.click();
            window.URL.revokeObjectURL(url);
          },
          (error) => {
            console.log(error);
            this.sharedService.showError(
              this.translate.instant('ERROR.error_schedule_report')
            );
          }
        );
    }
  }
}
