<div class="vw-100 bg-2">
  <div class="bg-1" style="border-radius: 0px 0px 25px 25px;padding-bottom: 73px;">
    <div class="px-3">
      <app-screen-title [title]="title" [color]="'white'" [backTo]="url"></app-screen-title>
      <app-new-stepper *ngIf="mode !=='update-customer'" [type]="'information-entry'" [currentStep]="4" ></app-new-stepper>
    </div>
  </div>


  <div id="bank-information">
    <div class="pt-3 px-3">
      <legend class="fsl color1 fw-600 flc">{{'TITLE.bank_details'|translate}}</legend>

      <!-- STEP 1 -->
      <div style="overflow-y: auto;" *ngIf="!attachIbanCertif">
          <div class="mb-2">
            <label class="form-label ms-2" for="bank_name">{{ 'LABELS.choose_bank_name' | translate}}</label>
            <div style="position: relative;">
              <i class='bx bxs-chevron-down m-2 p-2' [ngClass]="{'right': lang === 'en' , 'left': lang === 'ar' || lang == null}"></i>
              <select class="form-control select-group" id="work-type" [(ngModel)]="selectedBank" (ngModelChange)="onBankSelectionChange()">
                <option value="none" selected disabled hidden>{{'OTHER.select_option' | translate}}</option>
                <ng-container *ngFor="let bank of banks">
                  <option [ngValue]="bank">{{ "BANK."+bank.code | translate }}</option>
                </ng-container>
              </select>
            </div>
          </div>
          <div class="mb-2">
            <label class="form-label ms-2 " for="iban_number">{{ 'LABELS.enter_your_iban_number' | translate}}</label>
            <div class="" style="position: relative;">
              <span class="m-2 p-2 mobile-span">SA</span>
              <input type="text" class="form-control text-right" [(ngModel)]="ibanNumber" id="ibanNumber"
                placeholder="{{'PLACEHOLDER.iban_number' | translate}}" aria-label="iban_number" pattern="[0-9]*" maxlength="22"  [value]="ibanNumber" (ngModelChange)="onIbanNumberChange()"
                aria-describedby="basic-addon1">
            </div>
          </div>

        <div class="text-center py-4">
          <button  *ngIf="mode!=='update-customer'" type="button" class="btn btn-primary btn-lg mb-2 login-btn" (click)="attachIbanCertificate()">
            {{'BUTTONS.continue' | translate}}
          </button>
          <button  *ngIf="mode==='update-customer'" type="button" class="btn btn-primary btn-lg mb-2 login-btn" (click)="attachIbanCertificate()">
            {{'BUTTONS.update' | translate}}
          </button>
        </div>

      </div>

      <!-- STEP 2 -->
      <div class="card-body cu-cbody" style="overflow-y: auto;" *ngIf="attachIbanCertif">

        <div class="card p-4" style="background: #ebedf8;">
          <div class="flc mb-1">
            <img style="width: 50px;" src="assets/images/icons/upload-icon.png" alt="My Image">
          </div>
          <label class="text-center mb-2"> {{ 'LABELS.download_file' | translate}} </label>
          <label class="text-center op-50">{{ 'INFO.supported_file_format' | translate}} </label>
          <label class="text-center op-50"> .PNG, .JPG, .PDF </label>
        </div>

        <div class="mb-2 mt-4">
          <div class="input-group">
            <input type="file" class="form-control cpl"  placeholder="{{'PLACEHOLDER.enter_iban_certificate' | translate}}" aria-describedby="basic-addon1">
            <button class="btn">
              <img class=" ib-icons pt-1 m-2" src="assets/images/icons/trash-icon.png" alt="edit-icon" >
            </button>
          </div>
        </div>

        <div class="text-center pt-4">
          <button type="button" class="btn btn-primary btn-lg mb-2 login-btn" (click)="confirm()">
            {{'BUTTONS.next' | translate}}
          </button>
          <button type="button" class="btn btn-primary btn-lg mb-2 login-btn" (click)="attachIbanCertif=!attachIbanCertif">
            {{'BUTTONS.back' | translate}}
          </button>
        </div>

      </div>
    </div>
  </div>
</div>
