import { Component, EventEmitter, Injectable, Input, OnInit, Output } from '@angular/core';
import { NgbCalendar,NgbCalendarIslamicCivil,NgbDatepickerI18n,NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import HijriDate, { toHijri } from 'hijri-date/lib/safe';
import { Observable, Subject, from } from 'rxjs';
import { ParametrageService } from 'src/app/ib-services/parametrage.service';

const WEEKDAYS = ['س', 'ج', 'خ', 'إ', 'ث', 'إ', 'أ'];

const MONTHS = ['محرم','صفر','ربيع الأول','ربيع الآخر','جمادى الأولى','جمادى الآخرة','رجب','شعبان','رمضان','شوال','ذو القعدة','ذو الحجة'];

@Injectable()
export class IslamicI18n extends NgbDatepickerI18n {
  getMonthFullName(month: number, year?: number): string {
    return MONTHS[month - 1];
  }

  getMonthShortName(month: number, year?: number): string {
      return MONTHS[month - 1];
  }

  getWeekdayLabel(weekday: number) {
    return WEEKDAYS[weekday - 1];
  }

  getMonthlyLabel(month: number) {
    return MONTHS[month - 1];
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }

}

@Component({
  selector: 'flx-datepicker-islamic',
  templateUrl: './datepicker-islamic.component.html',
  providers: [
    { provide: NgbCalendar, useClass: NgbCalendarIslamicCivil },
    { provide: NgbDatepickerI18n, useClass: IslamicI18n },
  ]
})
export class DatepickerIslamicComponent implements OnInit {
  placeholder = 'xxxx-xx-xx';

  @Input() maxDate$: Observable<Date>;
  @Input() minDate$: Observable<Date>;
  @Input() selectDate: (date: Date) => void;
  @Output() sendEventDateHijri: EventEmitter<any> = new EventEmitter<any>();

  public receivedData: any;
  public hijriDate : HijriDate;


  public maxDateStruct$: Observable<NgbDateStruct>;
  public minDateStruct$: Observable<NgbDateStruct>;

  public lang : string = '';
  public direction : string;

  public year : number;
  public month : number;
  public day : number;

  constructor(private paramService : ParametrageService){}

  ngOnInit() {
    this.lang = localStorage.getItem('lang');
    if ( this.lang = 'ar' ) this.direction = 'rtl';
    if ( this.lang = 'en' ) this.direction = 'ltr';
    this.paramService.data$.subscribe(data => {
      if (data){
        if (data.hy && data.hm && data.hd ){
          this.receivedData = data;
          this.hijriDate = new HijriDate(this.receivedData.hy,this.receivedData.hm,this.receivedData.hd);
          this.hijriDate._day = this.hijriDate._date;
          this.dateSelect(this.hijriDate);
          this.sendEventDateHijri.emit(this.hijriDate);
        }
      }
    });

  }

  dateSelect(dateStruct: NgbDateStruct,fromIslamic? : boolean) {
    let hijriDate;
    if (fromIslamic){
      hijriDate = new HijriDate(dateStruct.year, dateStruct.month, dateStruct.day);
      this.hijriDate= hijriDate;
      hijriDate._day = hijriDate._date;
      this.sendEventDateHijri.emit(hijriDate);
    }else {
      let x = new HijriDate(this.hijriDate.year, this.hijriDate.month, this.hijriDate.day);
      x._day = x._date;
      hijriDate =  x;
    }
  }

}
