<div class="bg-2 vh-100 vw-100 ">
  <app-screen-title [title]="'payment_mode'" [color]="'color1'" [backTo]="'/loan-schedule-details'"></app-screen-title>

  <div class="payment-no-refresh mb-2 py-4" *ngIf="!checkoutResponse">
    <div class="payment-loader">
      <span></span>
      <span></span>
      <span></span>
    </div>
    <h2>{{'INFO.Generating_Payment_Form' |translate}}</h2>
  </div>

  <div id="hyperpay-widget-container">
    <form
      [action]="statusPaymentPage"
      class="paymentWidgets"
      data-brands="MADA MASTER VISA"
      style="background-color:#f6f6f5;">
    </form>
  </div>

</div>

<app-footer-menu></app-footer-menu>


