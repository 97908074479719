export class TransactionRequest {
  cvv2: string;
  zipCode: string;
  country: string;
  city: string;
  trackid: string;
  terminalId: string;
  password: string;
  expYear: string;
  cutomerEmail: string;
  action: string;
  merchantIp: string;
  currency: string;
  state: string;
  instrumentType: string;
  amount: string;
  address: string;
  customerIp: string;
  cardHolderName: string;
  expMonth: string;
  requestHash: string;
  cardNumber: string;
  udf1 : string;
  udf2 : string;
  udf3 : string;

  constructor() {}

}
