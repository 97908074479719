import { Component, OnInit } from '@angular/core';
import { NativeBiometric } from '@capgo/capacitor-native-biometric';
import { TranslateService } from '@ngx-translate/core';
import { AuthentificationService } from 'src/app/ib-services/authentification.service';
import { SharedService } from 'src/app/ib-services/shared.service';
import { CustomerEntity } from 'src/app/shared/Entities/customer.entity';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-biometrics-settings',
  templateUrl: './biometrics-settings.component.html',
  styleUrls: ['./biometrics-settings.component.scss'],
})
export class BiometricsSettingsComponent implements OnInit {
  public showLang = false;
  public biometricsStatus: Boolean = false;
  public showBioSettings: boolean = false;
  public customer: CustomerEntity;
  messageError: string = '';
  messageSuccess: string = '';

  constructor(
    public translate: TranslateService,
    private authService: AuthentificationService,
    private sharedService: SharedService
  ) {}

  ngOnInit() {
    this.customer = this.sharedService.getCustomer();

    /** Biometrics CHeck */
    this.hasStoredCredentials().then((hasCredentials) => {
      if (hasCredentials) {
        console.log('Stored credentials found.');
        this.biometricsStatus = true;
      } else {
        console.log('No stored credentials found.');
        this.biometricsStatus = false;
      }
    });
  }

  /** Biometrics Check */
  async hasStoredCredentials() {
    try {
      const result = await NativeBiometric.getCredentials({
        server: environment.oauthUrl,
      });

      return result && result.username && result.password;
    } catch (error) {
      console.error('Error checking stored credentials:', error);
      return false;
    }
  }

  /** Disable Biometrics by deleting credentials */
  async disableBiometric() {
    try {
      await NativeBiometric.deleteCredentials({
        server: environment.oauthUrl,
      });
      console.log('Biometric authentication disabled successfully.');
      this.messageSuccess = 'Biometric authentication disabled successfully';
      this.sharedService.showInfo(this.translate.instant('BIOMETRIC.INFO.biometric_disabled'));
      this.biometricsStatus = false;
      this.sharedService.setUserHadBiometricCredentials(false);
      return true;
    } catch (error) {
      this.messageError = this.translate.instant('BIOMETRIC.ERROR.biometric_disabled_failed');
      this.sharedService.showError(this.translate.instant('BIOMETRIC.ERROR.biometric_disabled_failed'));
      return false;
    }
  }

  activateBiometrics(){
    this.sharedService.showInfo(this.translate.instant('BIOMETRIC.INFO.resynchronize_biometric'));
    this.authService.logout();
  }

}
