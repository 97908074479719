 <!-- Edit Work Information -->
 <div id="edi_personal_information" class="bg-2 vh-100 vw-100 ">
  <app-screen-title *ngIf="mode !=='update'" [title]="'LABELS.employer_information'" [color]="'color1'" [backTo]="'/personal-information'"></app-screen-title>
  <app-screen-title *ngIf="mode ==='update'" [title]="'LABELS.employer_information'" [color]="'color1'" [backTo]="'/summary-initial-approval'"></app-screen-title>

  <div id="edit-work-information" class="px-3 mt-4">

    <div class="mb-2">
      <label class="form-label ms-2 ">{{ 'LABELS.sector_classification' | translate}}</label>
      <div style="position: relative;">
          <i class='bx bxs-chevron-down m-2 p-2' [ngClass]="{'right': lang === 'en' , 'left': lang === 'ar' || lang == null}"></i>
        <select class="form-control" [(ngModel)]="workSection">
          <option value="null" selected disabled hidden>{{'OTHER.select_option' | translate}}</option>
          <ng-container *ngFor="let ws of workSectionList" >
            <option [ngValue]="ws" *ngIf="lang === 'ar'">{{ ws.arabicLibelle }}</option>
            <option [ngValue]="ws" *ngIf="lang != 'ar'">{{ ws.libelle }}</option>
          </ng-container>
        </select>
      </div>
    </div>
    <div class="mb-2">
      <label class="form-label ms-2 ">{{'LABELS.employer_name'|translate}}</label>
      <div style="position: relative;">
        <input type="text" class="form-control" placeholder="" [(ngModel)]="employerName">
      </div>
    </div>
    <div class="mb-2">
      <label class="form-label ms-2 "> {{'LABELS.date_of_join'|translate}}</label>
      <div style="position: relative;">
        <img class="ib-icons m-2 pt-2" src="assets/images/icons/calendar-icon.png" alt="calendar-icon" style="position: absolute;" [ngClass]="{'right': lang === 'en' , 'left': lang === 'ar' || lang == null}">
        <input type="date" class="form-control" placeholder="" [(ngModel)]="dateJoining" [ngStyle]="{'text-align' :(lang==='en') ? 'left':'right'}">
      </div>
    </div>
    <div class="mb-2">
      <label class="form-label ms-2 "> {{'QUESTIONS.military_sector' | translate}} </label>
    <div style="position: relative;">
      <div class="radio-container">
        <input class="m-1" type="radio" id="radio3" name="options2" [value]="true" [(ngModel)]="militarySector">
        <label for="radio3" class="form-label">{{'LABELS.yes' | translate}}</label>
        <input class="m-1" type="radio" id="radio4" name="options2" [value]="false" [(ngModel)]="militarySector">
        <label for="radio4" class="form-label">{{'LABELS.no' | translate}}</label>
      </div>
    </div>
  </div>
  <div class="mb-2" *ngIf="militarySector===true">
    <label class="form-label ms-2"> {{'LABELS.military_rank'|translate}} </label>
    <div style="position: relative;">
        <i class='bx bxs-chevron-down m-2 p-2' [ngClass]="{'right': lang === 'en' , 'left': lang === 'ar' || lang == null}"></i>
      <select class="form-control" [(ngModel)]="militaryRank">
        <option value="" selected disabled hidden>{{'OTHER.select_option' | translate}}</option>
        <ng-container *ngFor="let mr of militaryRankList">
          <option [ngValue]="mr" *ngIf="lang != 'ar'">{{ mr.libelle }}</option>
          <option [ngValue]="mr" *ngIf="lang === 'ar'">{{ mr.arabicLibelle }}</option>
        </ng-container>
      </select>
    </div>

  </div>
  <div class="text-center pt-2">
    <button type="button" class="btn btn-primary btn-lg mb-2 login-btn" (click)="submit()" *ngIf="mode ==='update-customer'">
      {{'BUTTONS.update_details' | translate}}
    </button>
    <button type="button" class="btn btn-primary btn-lg mb-2 login-btn" (click)="submit()" *ngIf="mode !=='update-customer'">
      {{'BUTTONS.continue' | translate}}
    </button>
  </div>
  </div>
 </div>
