export class SettingDocumentTypeEntity {

    id: number;
    code: string;
    libelle: string;
    categorie: number;
    categorieLibelle: string;
    uniqueness: boolean;
    mandatory: boolean;
    description: string;
    dateDebut: Date;
    dateFin: Date;
    enabled: boolean;

    constructor() {
    }
  }
