<div  class="overlay">
    <div class="popup-container">
      <div class="icon-circle" *ngIf="source === 'AML'" style="background-color: #fdf0dc;">
        <i class='bx bx-info-circle' ></i>
      </div>
      <div class="icon-circle" *ngIf="source === 'REQUEST_SUCCESS'" style="background-color: #F5F5F5;">
        <i class='bx bxs-check-circle' ></i>
      </div>

      <div *ngIf="source === 'EVALUATION'">

        <div class="px-2 mt-2" style="display: flex; align-items: center;gap: 5px;">
          <span class="op-70 label-info">{{ 'evaluation_info' |translate}}</span>
        </div>

        <div>
          <div class="evaluation-container">
            <div class="icon-container" style="gap: 5px;">
              <i class='bx bx-happy' (click)="ratingChecker('COMPLETELY_HAPPY')" [@iconAnimation]="iconState[0]" [ngClass]="{'green-eval' : iconState[0] == 'clicked'}"></i>
              <i class='bx bx-smile' (click)="ratingChecker('HAPPY')" [@iconAnimation]="iconState[1]"  [ngClass]="{'light-green-eval' : iconState[1] == 'clicked'}"></i>
              <i class='bx bx-meh' (click)="ratingChecker('NEUTRAL')" [@iconAnimation]="iconState[2]"  [ngClass]="{'yellow-eval' : iconState[2] == 'clicked'}"></i>
              <i class='bx bx-confused' (click)="ratingChecker('DISSATISFIED')" [@iconAnimation]="iconState[3]"  [ngClass]="{'orange-eval' : iconState[3] == 'clicked'}"></i>
              <i class='bx bx-sad' (click)="ratingChecker('COMPLETELY_DISSATISFIED')" [@iconAnimation]="iconState[4]"  [ngClass]="{'red-eval' : iconState[4] == 'clicked'}"></i>
            </div>
          </div>


          <div class="py-2 pt-2 pb-0" style="display: inline-table;">
            <label class="text-center label-info fw-650" style="margin-bottom: 1rem !important;"> {{ 'INFO.opinion_matters' | translate}} </label>
            <br>
            <label class="text-center label-info fw-650" style="margin-bottom: 1rem !important;"> {{ 'INFO.your_experience' | translate}} </label>
          </div>
        </div>

        <form [formGroup]="evalForm">
          <div style="display: flex; flex-direction: column;gap: 10px;">
            <input type="text" class="form-control" formControlName="evalStatus" value={{status}} readonly style="background-color: #fff !important;text-align: center; font-weight: 800; color: rgb(33, 53, 146);">

            <textarea class="form-control" formControlName="description"  placeholder="{{'PLACEHOLDER.leave_a_note' | translate}}"></textarea>
          </div>


      <div class="text-center pt-3">
        <button type="button" class="btn btn-primary btn-lg mb-1 login-btn" (click)="addEvaluation()">
          {{'BUTTONS.evaluate' | translate}}
        </button>
        <button type="button" class="btn btn-danger btn-lg mb-2 login-btn" style="margin-top: auto;"  (click)="redirect()">
          {{'BUTTONS.home' | translate}}
        </button>
      </div>
        </form>


      </div>

      <span *ngIf="source === 'AML'" >{{ 'your_request_under_process' | translate}}</span>

      <div *ngIf="source === 'REQUEST_SUCCESS'" style="display: flex; flex-direction: column;gap: 10px;">
        <span style="font-size: 22px;">{{ 'Congrats' | translate}}</span>
        <span style="font-size: 22px;">{{ 'The_request_has_been_submitted_successfully' | translate}}</span>
        <label>{{ 'request_number' | translate}}</label>
        <input type="text" class="form-control"  readonly style="background-color: #fff !important;text-align: center; font-weight: 800; color: rgb(33, 53, 146);" formControlName="evalStatus">
      </div>

      <button type="button" class="btn btn-primary btn-lg mb-2 login-btn" style="margin-top: auto;" routerLink="/home" *ngIf="source != 'EVALUATION'">
        {{'BUTTONS.home' | translate}}
      </button>
    </div>
  </div>
