import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IbNotification } from '../shared/Entities/ibNotification.entity';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class IbNotificationServiceService {

constructor(public httpClient: HttpClient) { }

  launchNotification(ibNotification : IbNotification): Observable<Boolean> {
    return this.httpClient.post<Boolean>(environment.oauthUrl + 'ib-notifications/launch-notification', ibNotification);
  }

  getNotificationByCustomerId(customerId : number): Observable<IbNotification[]> {
    return this.httpClient.get<IbNotification[]>(environment.oauthUrl + 'ib-notifications/find-notifications/' + customerId );
  }

  getNotificationsCount(customerId : number): Observable<number> {
    return this.httpClient.get<number>(environment.oauthUrl + 'ib-notifications/count-notifications/' + customerId );
  }

  updateSeenNotification(ibNotification : IbNotification): Observable<IbNotification> {
    return this.httpClient.post<IbNotification>(environment.oauthUrl + 'ib-notifications/update-seen-notification/', ibNotification );
  }

}
