<div id="edit_income_details" class="bg-2 px-3 pb-2" style="min-height: 100vh;">
  <app-screen-title *ngIf="inputMode != 'mode1' && mode!=='update'" [title]="'TITLE.income_details'" [color]="'color1'" [backTo]="'/personal-information'"></app-screen-title>
  <app-screen-title *ngIf="mode ==='update'" [title]="'TITLE.income_details'" [color]="'color1'" [backTo]="'/summary-initial-approval'"></app-screen-title>
  <legend  *ngIf="inputMode == 'mode1'" class="fsl color1 fw-600 fls">{{'TITLE.income_details'|translate}}</legend>

  <div id="form-edit" class="pt-0 mb-2 mt-4">
    <div class="mb-2">
      <label class="form-label ms-2 fsm">{{'LABELS.basic_salary' | translate}}</label>
      <div style="position: relative;">
        <span class="m-2 p-2" style="position: absolute; color: #808080;left:0;"> {{'CURRENCY.sar'|translate}} </span>
        <input type="number" class="form-control input-padding" placeholder="{{'PLACEHOLDER.enter_basic_salary' | translate}}"  [(ngModel)]="basicSalary" id="basicSalary" (focus)="basicSalaryError=false;showIncomeDetailsAlert=false;">
      </div>
    </div>
    <div class="mb-2">
      <label class="form-label ms-2 ">{{'LABELS.housing_allowance' | translate}}</label>
      <div style="position: relative;">
        <span class="m-2 p-2" style="position: absolute; color: #808080;left:0;"> {{'CURRENCY.sar'|translate}} </span>
        <input type="number" class="form-control input-padding" placeholder="{{'PLACEHOLDER.enter_house_allowance' | translate}}" [(ngModel)]="housingAllowance" id="housingAllowance" (focus)="housingAllowanceError=false;showIncomeDetailsAlert=false;">
      </div>
    </div>
    <div class="mb-2">
      <label class="form-label ms-2 ">{{ 'LABELS.monthly_allowance_set' | translate }}</label>
      <div style="position: relative;">
        <span class="m-2 p-2" style="position: absolute; color: #808080;left:0;"> {{'CURRENCY.sar'|translate}} </span>
        <input type="number" class="form-control input-padding" placeholder="{{'PLACEHOLDER.enter_monthly_all_set' | translate}}" [(ngModel)]="monthlyAllowance" id="monthlyAllowance" (focus)="monthlyAllowanceError=false;showIncomeDetailsAlert=false;">
      </div>
    </div>
    <div class="mb-2">
      <div>
        <div class="fsb">
          <label class="form-label ms-2 ">{{ 'LABELS.additional_income' | translate }}</label>
          <label class="form-label ms-2  text-secondary">({{'LABELS.optional' | translate}})</label>
        </div>
      </div>
      <div style="position: relative;">
        <span class="m-2 p-2" style="position: absolute; color: #808080;left:0;"> {{'CURRENCY.sar'|translate}} </span>
        <input type="number" class="form-control input-padding" placeholder="{{'PLACEHOLDER.enter_additional_income' | translate}}" [(ngModel)]="extraIncome" id="extraIncome" (focus)="extraIncomeError=false;showIncomeDetailsAlert=false;">
      </div>
    </div>
  </div>

  <div class="card p-3" *ngIf="inputMode != 'mode1'">
    <label class="ms-2 fw-bold text-center" style="opacity: 50%;" > <i class='bx bx-time-five'></i> 23 يوم متبقي</label>
    <label class="label-info text-center" >
        {{ 'info.income_details_update' | translate}}
    </label>
    <div class="flc">
      <button type="button" class="btn btn-secondary px-5">
        <div class="inline-flex">
          <i class='bx bx-phone-call m-2'></i>
          <span>{{ 'BUTTONS.call_support' | translate }}</span>
        </div>
      </button>
    </div>
  </div>

  <div class="text-center pt-4">
    <button type="button" class="btn btn-primary btn-lg mb-2 login-btn" (click)="submit()" *ngIf="mode ==='update-customer'">
      {{'BUTTONS.update_details' | translate}}
    </button>
    <button type="button" class="btn btn-primary btn-lg mb-2 login-btn" (click)="submit()" *ngIf="mode !=='update-customer'">
      {{'BUTTONS.continue' | translate}}
    </button>
  </div>


</div>
