
<div class="bg-1" style="border-radius: 0px 0px 20px 20px;padding-bottom:50px;">
  <app-screen-title [title]="'SETTINGS.about_sanad'" [color]="'white'" [backTo]="'/settings'"></app-screen-title>
  <div class=" flc  " >
  <img class="img-logo-sanad" src="assets/images/mainlogo2.png" alt="mainlogo2" style="width:150px !important">
  </div>
</div>

<div id="about-sanad" class="">
  <div class="card-body text-center">
    <legend class="color1 fw-600 text-center fsl">{{ 'SETTINGS.about_sanad' | translate}}</legend>
    <p class="op-70 fsm align-content-lg-center fw-500 op-50" style="color: gray;">
      {{'ABOUT_SANAD' | translate}}<br>
    </p>
    <legend class="color1 fw-600 text-center fsl">{{ 'OTHER.our_mission' | translate}}</legend>
    <p class="op-70 fsm align-content-lg-center fw-500 op-50" style="color: gray;" >
      {{ 'OTHER.our_mission_description' | translate}}
    </p>
    <legend class="color1 fw-600 text-center fsl"> {{ 'OTHER.our_vision' | translate}}</legend>
    <p class="op-70 fsm align-content-lg-center fw-500 op-50" style="color: gray;">
      {{ 'OTHER.our_vision_description' | translate}}
    </p>

    <div class="text-center p-2 pb-3">
      <button type="button" class="btn btn-secondary btn-lg login-btn" (click)="evaluation()" style="border-radius: 5px;">
        <label class="p-2">{{ 'BUTTONS.evaluation' | translate }}</label>
      </button>
    </div>
  </div>


<!-- EVALUATION POPUP  -->
<app-popup *ngIf="showPopup" [source]="'EVALUATION'" ></app-popup>
</div>


<app-footer-menu *ngIf="this.sharedService.getUser().login != undefined"></app-footer-menu>

