<div class="bg-2 vh-100">
  <app-screen-title [title]="'TITLE.registration_eligibility'" [color]="'color1'" [backTo]="'/home'"></app-screen-title>

  <label class="fls label-subtitle color1 fsl m-4 fw-700" > {{ 'LABELS.conditions_financing' | translate}} </label>

  <div class="px-0">
    <div class="">
      <ul class="mt-4" style="list-style-type: none;gap:5px">
        <li class="label-main mb-2 "><i class='bx bxs-circle m-2'></i> {{ 'TERMS.age_21' | translate}}</li>
        <li class="label-main mb-2 "><i class='bx bxs-circle m-2'></i> {{ 'TERMS.age_inf_59' | translate }}</li>
        <li class="label-main mb-2 "><i class='bx bxs-circle m-2'></i> {{ 'TERMS.employment_type' | translate }}</li>
        <li class="label-main mb-2 "><i class='bx bxs-circle m-2'></i> {{ 'TERMS.monthly_income_4000' | translate }}</li>
        <li class="label-main mb-2 "><i class='bx bxs-circle m-2'></i> {{ 'TERMS.min_work_2m' | translate }}</li>
        <li class="label-main mb-2 "><i class='bx bxs-circle m-2'></i> {{ 'TERMS.nafedh_registration' | translate }}</li>
      </ul>
    </div>

    <div class="text-center  p-3">

      <button type="button" class="btn btn-nafedh btn-lg login-btn my-1" (click)="downloadNafedh()">
        {{'BUTTONS.download_nafedh' | translate}}
      </button>
      <button type="button" class="btn btn-primary btn-lg login-btn my-1" (click)="checkProductsBeforeRedirection()">
        {{'BUTTONS.continue' | translate}}
      </button>
    </div>

  </div>
</div>
