import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CreditService } from 'src/app/ib-services/credit.service';
import { ParametrageService } from 'src/app/ib-services/parametrage.service';
import { SharedService } from 'src/app/ib-services/shared.service';
import { ProductEntity } from 'src/app/shared/Entities/product.entity copy';
import { UserEntity } from 'src/app/shared/Entities/user.entity';
import { PathConstants } from 'src/app/shared/mb-constants/path-constants';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss'],
})
export class ProductListComponent implements OnInit {
  public showTerms: boolean = false;
  public eligibilityInformation: boolean = false;
  public user: UserEntity;
  public products: ProductEntity[];

  constructor(
    private sharedService: SharedService,
    private creditService: CreditService,
    private router: Router,
    private parametrageService: ParametrageService
  ) {}

  ngOnInit() {
    this.user = this.sharedService.getUser();
    let product = new ProductEntity();
    this.parametrageService.getProducts(product).subscribe((res) => {
      this.products = [...res];
    });
  }

  showTermsDiv(index: number) {
    this.sharedService.setProduct(this.products[index]);
    this.showTerms = true;
  }

  showEligibilityInformation(product: ProductEntity) {
    this.sharedService.setProduct(product);
    localStorage.setItem('productId', JSON.stringify(product.id));
    this.eligibilityInformation = !this.eligibilityInformation;
  }

  nextStep(index: number) {
    this.sharedService.setProduct(this.products[index]);
    this.router.navigate(['/' + PathConstants.PATH_NAFEDH]);
  }
}
