<div class=" vh-100  px-3" style="height: 100vh !important;">
  <app-screen-title [title]="'TITLE.loan_application'" [color]="'white'" [backTo]="'/home'"></app-screen-title>
  <!-- Stepper -->
  <app-new-stepper [type]="'information-entry'" [currentStep]="currentStep" ></app-new-stepper>

  <div id="all-client-information">
    <div class="card card-wf p-2 fixed-bottom ">
      <div class="card-body" style="overflow-y: auto;overflow-x: hidden;padding:unset !important">
        <div #start id="start"></div>
        <!-- Edit Personal Information -->
        <div id="edit-personal-information" *ngIf="!this.openEditIncomeDetails && !this.openEditExpensensAndObligations" >
          <app-edit-personal-information [inputMode]="'mode1'" (messageEvent)="personalInfoResponse($event)"></app-edit-personal-information>
        </div>

        <!-- Edit Income Details -->
        <div id="edit-income-details" *ngIf="this.openEditIncomeDetails && !this.openEditExpensensAndObligations" class="py-3">
          <app-edit-income-details [inputMode]="'mode1'" (messageEvent)="incomeDetailsResponse($event)"></app-edit-income-details>
        </div>

        <!-- Edit Expenses & Obligations -->
        <div id="edit-expenses-obligations" *ngIf="!this.openEditIncomeDetails && this.openEditExpensensAndObligations" class="pb-5">
          <app-edit-expenses-obligations [inputMode]="'mode1'" (messageEvent)="expensesDetailsResponse($event)"></app-edit-expenses-obligations>
        </div>

      </div>
    </div>
  </div>
</div>
