<div class="vw-100">

  <div class="bg-1" style="border-radius: 0px 0px 25px 25px;padding-bottom: 73px;">
    <div class="px-3">
      <app-screen-title [title]="'TITLE.sign_contract'" [color]="'white'" [backTo]="'/home'"></app-screen-title>
      <app-new-stepper [type]="'sign-contract'" [currentStep]="currentStep" ></app-new-stepper>
    </div>
  </div>


  <div id="sign-contract">
    <!-- call customer -->
    <div class=" p-4" id="Call_to_verify" *ngIf="callCustomer" style="border-radius: unset !important;">
      <legend class="fw-600 fsl color1 text-center">{{ 'TITLE.verification_call' | translate }}</legend>
      <div class="card-body cu-cbody" style="overflow-y: auto; text-align: center;">
        <span>{{ 'LABELS.sanad_call_check' | translate }}</span>
      </div>
    </div>
    <!-- sign contract -->
    <div class="" id="sign_contract" *ngIf="signContract">
      <div *ngIf="!showOTP" class="py-2" style="display: flex;flex-direction: column;justify-content: space-between;">
      <app-required-documents step="sign-contract" (sendOTPClicked)="sendOTP()"></app-required-documents>
    </div>
    <div class="pt-3" >
      <app-otp-biometrics-validation *ngIf="showOTP" [source]="'sign-contract'" ></app-otp-biometrics-validation>
    </div>

    </div>
    <!-- sign sanad al amr -->
    <div class="p-4 " id="sign_sanad_al_amr" *ngIf="signSanadAlAmr">
      <legend class="fw-600 fsl color1 text-center">{{ 'TITLE.sanad_al_amr' | translate }}</legend>
      <div class="card-body cu-cbody" style="overflow-y: auto;">

        <label class="text-center fw-600 op-50" *ngIf="!sanadAlAmrId">{{'INFO.naafedh_msg_reception'|translate}}</label>
        <label class="flc fw-600 pt-2 op-50 fss" *ngIf="sanadAlAmrId">{{'INFO.click_link'|translate}}</label>
        <div class="text-center  p-6" *ngIf="sanadAlAmrId">
          <label class="labelmain u fw-500 mb-4 fsl flc" style="cursor: pointer;"><a [href]="sanadAlAmrId.url"> {{sanadAlAmrId.url}}</a></label>
        </div>
        <button type="button" class="btn btn-primary btn-lg mb-2 login-btn" routerLink="/home" *ngIf="showButtonLater">
          {{'BUTTONS.home' | translate}}
        </button>

      </div>
    </div>

    <!-- Non Synced Loan in Sign Contract -->
    <div class=" " id="Call_to_verify" *ngIf="inSyncMode">
      <legend class="fw-600 fsl color1 text-center">{{ 'LABELS.step_sign_contract_under_Treatment' | translate }}</legend>
      <div class="card-body cu-cbody" style="overflow-y: auto; text-align: center;">

        <div class="payment-no-refresh">
          <div class="payment-loader">
            <span></span>
            <span></span>
            <span></span>
          </div>
          <h2>{{'INFO.sync_application' |translate}}</h2>
        </div>

        <button type="button" class="btn btn-primary btn-lg my-4 login-btn" routerLink="/home" >
          {{'BUTTONS.home' | translate}}
        </button>
      </div>
    </div>

  </div>
</div>
