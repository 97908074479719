import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AcmURLConstants } from '../shared/mb-constants/mb-url-constants';
import { RequestTarabout } from '../shared/Entities/RequestValidatorTarabet.entity';
import { ResponseAccountVerificationTarabut } from '../shared/Entities/ResponseAccountVerificationTarabut.entity';
import { EventFocalRequestDTO } from '../shared/Entities/EventFocalRequest.entity';
import { environment } from 'src/environments/environment';
import { ConfirmTransactionIcsEntity } from '../shared/Entities/requestConfirmTransaction.entity';
import { RequestBulkCallDTO } from '../shared/Entities/RequestBulkCallDTO.entity';
import { RequestSendSmsDeewanApiEntity } from '../shared/Entities/RequestSendSmsDeewanApi.entity';
import { TransactionRequest } from '../shared/Entities/TransactionRequest.entity';
import { ResponseGetUrlDecisionSanadApiDTO } from '../shared/Entities/ResponseGetUrlDecisionSanadApiDTO';
import { ReponseAkeedApiEntity } from '../shared/Entities/ReponseAkeedApi.entity';
import { ResponseSendMsgDeewanApiEntity } from '../shared/Entities/ResponseSendMsgDeewanApi.entity';
import { PaymentApiResponseEntity } from '../shared/Entities/PaymetApiResponse.entity';
import { PaymentApiSanadEntity } from '../shared/Entities/PaymentApiSanad.entity';
import { RequestNafedhEntity } from '../shared/Entities/RequestNafedh.entity';
import { ResponseNafedhRequestEntity } from '../shared/Entities/ResponseNafedhRequest.entity';
import { RequestCheckStatusNafedhEntity } from '../shared/Entities/requestCheckStatusNafedh.entity';
import { ResponseCheckStatusNafedhEntity } from '../shared/Entities/ResponseCheckStatusNafedh.entity';

@Injectable({
  providedIn: 'root'
})
export class ThirdPartyService {

constructor(public httpClient: HttpClient) { }

  accountTarabutVerifier(requestAccountVerifierTarabut :  RequestTarabout):Observable<ResponseAccountVerificationTarabut> {
  return this.httpClient.post<ResponseAccountVerificationTarabut>(AcmURLConstants.IBAN_ACCOUNT_VERIFIER ,requestAccountVerifierTarabut );
  }

  eventRulesFocal(eventFocalRequest :  EventFocalRequestDTO):Observable<any> {
    return this.httpClient.post<any>(AcmURLConstants.FOCAL_EVENT_RULES_EVALUATION ,eventFocalRequest );
  }

  checkOtpForgetPasswordDeewanApi(identity:string, code:string): Observable<any> {
    return this.httpClient.get<any>(environment.apiUrl + 'transvers-service/deewan-api/check-fp-otp/'+identity+"/"+code);
  }

  confirmTransactionIcsApi(confirmTransactionIcsEntity : ConfirmTransactionIcsEntity,lId : number  ): Observable<any>{
    return this.httpClient.post<any>(environment.apiUrl + 'transvers-service/ics-api/confirm-transaction/'+ lId,confirmTransactionIcsEntity );
  }

  /** Not Used */
  bulkCallApi(request : RequestBulkCallDTO,loanId : number): Observable<any>{
    return this.httpClient.post<any>(environment.apiUrl + 'transvers-service/acs-api/bulk-call/'+ loanId,request );
  }

  sendSmsOtpForgetPasswordDeewanApi(request: RequestSendSmsDeewanApiEntity, nationalId:string, loanId : number,deviceChanged : boolean ): Observable<String> {
    return this.httpClient.post<String>(environment.apiUrl + 'transvers-service/deewan-api/send-fp-otp/'+nationalId+'/'+loanId+ '/' + deviceChanged , request );
  }

  sendTransaction(transactionRequest: TransactionRequest,loanId : number): Observable<any> {
    return this.httpClient.post<any>(environment.apiUrl + 'transvers-service/payement-api/payment-transaction?loanId='+loanId, transactionRequest);
  }

  createSingleSanadElAmer(request:number): Observable<boolean> {
    return this.httpClient.post<boolean>(environment.apiUrl +'transvers-service/sanad-ElAmer-api/create-single-sanad', request);
  }

  getSanadAlAmrID(loanId: number): Observable<ResponseGetUrlDecisionSanadApiDTO> {
    return this.httpClient.post<ResponseGetUrlDecisionSanadApiDTO>(environment.apiUrl + 'transvers-service/sanad-ElAmer-api/get-the-ready-to-use-sanadElAmer-validation', loanId);
  }

  /** Not Used */
  checkIban(identity :string,iban:string,loanId : number): Observable<ReponseAkeedApiEntity> {
    return this.httpClient.get<ReponseAkeedApiEntity>(environment.apiUrl + 'transvers-service/masder-api/akeed/' + identity + '/' + iban + '/' + loanId );
  }

  sendOtpDeewanApi(request: RequestSendSmsDeewanApiEntity, nationalId:string, loanId : number,deviceChanged : boolean ): Observable<ResponseSendMsgDeewanApiEntity> {
    return this.httpClient.post<any>(environment.apiUrl + 'transvers-service/deewan-api/send-otp/'+nationalId+'/'+loanId+ '/' + deviceChanged , request );
  }

  checkOtpDeewanApi(identity:string, code:string): Observable<any> {
    return this.httpClient.get<any>(environment.apiUrl + 'transvers-service/deewan-api/check-otp/'+identity+"/"+code);
  }

    //when payment success with third party we send a string contains amount and idLoan to acm
    sendParamsToPaymentLoan(payementLoan: string): Observable<string> {
      return this.httpClient.post<string>(environment.apiUrl + 'transvers-service/rabbitMq-endPoint/send-payment-loan', payementLoan);
    }

    findTargetUrlAndPaymentId(paymentApiSanadDTO: PaymentApiSanadEntity): Observable<PaymentApiResponseEntity> {
      return this.httpClient.post<PaymentApiResponseEntity>(environment.apiUrl + 'transvers-service/payment-api/find-targetUrl-paymentId', paymentApiSanadDTO);
    }

    createRequestNafedh(requestNafedhEntity: RequestNafedhEntity, uuid: string, loanId : number): Observable<ResponseNafedhRequestEntity> {
      return this.httpClient.post<ResponseNafedhRequestEntity>(environment.apiUrl +'transvers-service/nafath-api/request?local=en&requestId='+uuid+'&loanId='+loanId, requestNafedhEntity );
    }

    /** Not Used */
    checkStatusNafedh(requestNafedhEntity: RequestCheckStatusNafedhEntity): Observable<ResponseCheckStatusNafedhEntity> {
      return this.httpClient.post<ResponseCheckStatusNafedhEntity>('https://1fa9bc2e-3edb-41e6-a8ac-cf6b40cc379a.mock.pstmn.io/api/v1/mfa/request/status', requestNafedhEntity);
    }

}
