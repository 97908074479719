
<div id="general-details" class="bg-2 vh-100 vw-100" >
    <app-screen-title [title]="'SETTINGS.reset_password'" [color]="'color1'" [backTo]="'/account-details'"></app-screen-title>
    <div id="div1" class="p-4">


        <div class="mb-2">
          <label class="form-label ms-2 " for="current_pwd">{{'LABEL.current_pwd' | translate}}</label>
          <div style="position: relative;">
            <img class="ib-icons m-2 pt-2" src="assets/images/icons/lock.png" alt="lock" style="position: absolute;">
            <input  [type]="showPassword ? 'text' : 'password'" class="form-control icon-input" [(ngModel)]="currentPwd" placeholder="{{'PLACEHOLDER.enter_current_password' |translate}}" [ngClass]="{'fc-error': (currentPwd == null || currentPwd == '' ) && submitted}">
          </div>
          <button class="btn" type="button" (click)="togglePasswordVisibility()"
          [ngClass]="isFloatRight ? 'float-right' : 'float-left'" style="position: relative; top: -45px;">
          <i class="bx" [ngClass]="showPassword ? 'bx-hide' : 'bx-show'" style="font-size: 20px !important;"></i>
        </button>
        </div>
        <div class="mb-2">
          <label class="form-label ms-2" for="new_pwd">{{'LABEL.new_pwd' | translate}}</label>
          <div style="position: relative;">
            <img class="ib-icons m-2 pt-2" src="assets/images/icons/lock.png" alt="lock" style="position: absolute;">
            <input [type]="showNewPassword ? 'text' : 'password'" class="form-control icon-input" placeholder="" [(ngModel)]="newpwd" placeholder="{{'PLACEHOLDER.enter_new_password' |translate}}" [ngClass]="{'fc-error': ((newpwd == null || newpwd == '' ) ||  (newpwd !== currentPwd)) && submitted}">
          </div>
          <button class="btn" type="button" (click)="toggleNewPasswordVisibility()"
          [ngClass]="isFloatRight ? 'float-right' : 'float-left'" style="position: relative; top: -45px;">
          <i class="bx" [ngClass]="showNewPassword ? 'bx-hide' : 'bx-show'" style="font-size: 20px !important;"></i>
        </button>
        </div>
        <div class="mb-4">
          <div class="fsb">
            <label class="form-label ms-2" for="confirm_new_pwd">{{ 'LABEL.confirm_new_pwd' | translate}}</label>
          </div>
          <div style="position: relative;">
            <img class="ib-icons m-2 pt-2" src="assets/images/icons/lock.png" alt="lock" style="position: absolute;">
            <input [type]="showConfirmPassword ? 'text' : 'password'" class="form-control icon-input" placeholder="" [(ngModel)]="confirmpwd" placeholder="{{'PLACEHOLDER.enter_confirm_pw' | translate}}" [ngClass]="{'fc-error': ((confirmpwd == null || confirmpwd == '' ) ||  (newpwd !== currentPwd)) && submitted}">
          </div>
          <button class="btn" type="button" (click)="toggleConfirmPasswordVisibility()"
          [ngClass]="isFloatRight ? 'float-right' : 'float-left'" style="position: relative; top: -45px;">
          <i class="bx" [ngClass]="showConfirmPassword ? 'bx-hide' : 'bx-show'" style="font-size: 20px !important;"></i>
        </button>
        </div>
      <div class="text-center">
        <button type="button" class="btn btn-primary btn-lg mb-2 login-btn" (click)="openModal()">
          {{'BUTTONS.save_changes' | translate}}
        </button>
      </div>
    </div>

  </div>



  <div id="modal-dialog" class="modal_term" style="zoom:unset !important">
    <p-dialog [(visible)]="showModalConfirmation" [breakpoints]="{'80vh': '81vw'}" [style]="styleDialog" [closable]="false"
      [modal]="true" overlay="true">
      <div class="p-dialog-content" style="text-align: justify;">
        <h3 class="mb-4 mt-2 flc color1 fw-500">{{'INFO.disconnectOrStay' | translate}}</h3>

      </div>
      <div class="row">
        <div class="p-3">
          <button  class="btn-pill btn btn-nafedh cf-hover-btn my-2 white" (click)="saveChanges(false)">
            <i class='bx bx-check-square'></i> {{'BUTTONS.confirm_term' | translate}}
          </button>
          <button  class="btn-pill btn btn-primary cf-hover-btn my-2" (click)="saveChanges(true)">
            <i class='bx bx-check-square'></i> {{'BUTTONS.stay_logged' | translate}}
          </button>
          <button  class="btn-pill btn btn-danger cf-hover-btn my-2" (click)="close()">
            <i class='bx bx-x'></i> {{'BUTTONS.cancel' | translate}}
          </button>
        </div>
      </div>
    </p-dialog>
  </div>
