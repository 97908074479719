import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ParametrageService } from 'src/app/ib-services/parametrage.service';
import { SharedService } from 'src/app/ib-services/shared.service';
import { SettingDataStorageIbEntity } from 'src/app/shared/Entities/SettingDataStorageIb.entity';
import { TranslateService } from '@ngx-translate/core';
import { PersonalInformationEntity } from 'src/app/shared/Entities/personalInformation.entity';
import { CustomerEntity } from 'src/app/shared/Entities/customer.entity';
import { WorkInformationEntity } from 'src/app/shared/Entities/workInformation.entity';
import { LoanEntityIB } from 'src/app/shared/Entities/loan.entity';
import { PathConstants } from 'src/app/shared/mb-constants/path-constants';
import { CustomerService } from 'src/app/ib-services/customer.service';
import { LoanService } from 'src/app/ib-services/loan.service';


@Component({
  selector: 'app-edit-personal-information',
  templateUrl: './edit-personal-information.component.html',
  styleUrls: ['./edit-personal-information.component.scss'],
})
export class EditPersonalInformationComponent implements OnInit {
  /** Personal Information Modes ==> default = Settings ; mode1 = Loan Registration */
  @Input() inputMode: string = undefined;
  @Output() messageEvent = new EventEmitter<any>();

  /** DropDowns from db */
  public loanPurposes: SettingDataStorageIbEntity[] = [];
  public materialStatuses: SettingDataStorageIbEntity[] = [];
  public typesHousing: SettingDataStorageIbEntity[] = [];
  public workingStatuses: SettingDataStorageIbEntity[] = [];
  public occupations: SettingDataStorageIbEntity[] = [];
  public militaryRankList : SettingDataStorageIbEntity[] = [];
  public residentialStatusList : SettingDataStorageIbEntity[] = [];
  public workSectionList : SettingDataStorageIbEntity[]= [];

  /** Values Declaration */
  public loanPurpose: SettingDataStorageIbEntity = new SettingDataStorageIbEntity();
  public materialStatus: SettingDataStorageIbEntity = new SettingDataStorageIbEntity();
  public housingType: SettingDataStorageIbEntity = new SettingDataStorageIbEntity();
  public job: string;
  public workingStatus: SettingDataStorageIbEntity = new SettingDataStorageIbEntity();
  public militaryRank: SettingDataStorageIbEntity = new SettingDataStorageIbEntity();
  public workSection: SettingDataStorageIbEntity = new SettingDataStorageIbEntity();

  public basicSalaryError :boolean=false;
  public housingAllowanceError  :boolean=false;
  public monthlyAllowanceError :boolean=false;
  public extraIncomeError :boolean=false;


  public politicalExposition : string;
  public fmEducationalFees: number = null;
  public nbChildren: number = null;
  public nbDomesticWorkers: number = null;

  public isResponsible: boolean;
  public nbFamilyMembers: number = 2;
  public residentialStatus: SettingDataStorageIbEntity = new SettingDataStorageIbEntity();
  public politicalFigure: boolean;
  public invitationCode: string;
  public employerName: string;
  public dateJoining: Date;
  public militarySector: boolean;

  public loanPurposeError:boolean=false;
  public materialStatusError:boolean=false;
  public housingTypeError :boolean=false;
  public jobError :boolean=false;
  public workingStatusError :boolean=false;
  public nbFamilyMembersError :boolean=false;
  public fmEducationalFeesError :boolean=false;
  public nbChildrenError :boolean=false;
  public nbDomesticWorkersError :boolean=false;

  public showLoanInfoAlert:boolean=false;
  public showIncomeDetailsAlert:boolean=false;
  public showExpensesAlert:boolean=false;

  public mode : string;
  public personalInfo : PersonalInformationEntity;
  public workInfo : WorkInformationEntity;
  public customer : CustomerEntity;
  public loan : LoanEntityIB;
  public lang: string;

  constructor( private sharedService: SharedService, private loanService: LoanService, private paramService: ParametrageService, private router: Router,
               private translate : TranslateService,private route: ActivatedRoute,private customerService : CustomerService){}

  ngOnInit() {
    this.lang =  localStorage.getItem('lang');
    this.getCustomer();
    this.loan = this.sharedService.getLoanDeal();
    this.route.queryParams.subscribe(params => { this.mode = params['mode']});
    this.paramService.findAllDataStorageIb().subscribe((res) => {
          res.forEach((data) => {
            switch (data.category) {
              case 'LOAN_PURPOSE':
                this.loanPurposes.push(data);
                break;
              case 'MATERIAL_STATUS':
                this.materialStatuses.push(data);
                break;
              case 'HOUSING_TYPE':
                this.typesHousing.push(data);
                break;
              case 'OCCUPATION':
                this.occupations.push(data);
                break;
                case 'MILITARY_RANK':
              this.militaryRankList.push(data);
              break;
              case 'RESIDENTIAL_STATUS':
                this.residentialStatusList.push(data);
                break;
                case 'WORK_SECTION':
                  this.workSectionList.push(data);
                  break;
            }
          });
          if (this.inputMode == 'mode1' && this.customer?.otherInformations  ) {
            const otherInformationString = this.customer.otherInformations;
            const otherInformationObject = JSON.parse(otherInformationString);
            if (otherInformationObject){
            this.personalInfo = otherInformationObject.personalInformation? otherInformationObject.personalInformation : null;
            this.workInfo = otherInformationObject.workInformation? otherInformationObject.workInformation : null;
            if (this.personalInfo){
              this.materialStatus = this.materialStatuses.filter(materialStatus =>  materialStatus.libelle===this.personalInfo.materialStatus)[0];
              this.housingType = this.typesHousing.filter(housingType =>  housingType.libelle===this.personalInfo.housingType)[0];
              this.residentialStatus = this.residentialStatusList.filter( residentialStatus => residentialStatus.libelle === this.personalInfo.residentialStatus)[0];
              this.nbFamilyMembers = this.personalInfo.nbFamilyMembers ? this.personalInfo.nbFamilyMembers:2;
              this.isResponsible = this.personalInfo.isResponsible;
              this.invitationCode = this.personalInfo.invitationCode;
              this.politicalFigure = this.personalInfo.politicalFigure;
            }
            if(this.workInfo){
              this.workSection= this.workSectionList?.filter(workSection=> workSection.libelle === this.workInfo.workSection)[0];
              this.dateJoining= this.workInfo.dateJoining;
              this.militarySector= this.workInfo.militarySector;
              this.employerName= this.workInfo.employerName;
              this.militaryRank = this.militaryRankList?.filter(militaryRank =>  militaryRank.libelle===this.workInfo.militaryRank)[0];
            }
            }
          }
          else if (this.mode ==='update-customer' || this.mode==='update') {
            this.personalInfo = this.sharedService.getPersonalInfo();
            if(this.personalInfo){
              this.loanPurpose = this.mode==='update'? this.loanPurposes?.filter(loanPurpose =>  loanPurpose.libelle===this.loan.loanReasonDescription)[0] : null;
              this.materialStatus = this.materialStatuses.filter(materialStatus =>  materialStatus.libelle===this.personalInfo.materialStatus)[0];
              this.housingType = this.typesHousing.filter(housingType =>  housingType.libelle===this.personalInfo.housingType)[0];
              this.nbFamilyMembers = this.personalInfo.nbFamilyMembers ?this.personalInfo.nbFamilyMembers : 2;
              this.isResponsible = this.personalInfo.isResponsible;
              this.invitationCode = this.personalInfo.invitationCode;
              this.residentialStatus =  this.residentialStatusList.filter( residentialStatus => residentialStatus.libelle === this.personalInfo.residentialStatus)[0];
              this.politicalFigure = this.personalInfo.politicalFigure;
            }
          }
    });
  }

  getCustomer(){
    this.customerService.getCustomerById(this.sharedService.getUser().customerId).subscribe(res=>{
      this.sharedService.setCustomer(res);
      this.customer=res;
    })
  }

  checkMilitaryRank(){
    if (this.militarySector === true ){
      if (!this.militaryRank || !this.militaryRank.libelle) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  checkFamilyResp(){
    if (this.isResponsible === true ){
      if (this.nbFamilyMembers === null) {
         this.sharedService.showError(this.translate.instant('ERR.family_members_required'));
        return false;
      } else if ( this.nbFamilyMembers <2 ) {
        this.sharedService.showError(this.translate.instant('ERR.number_of_family_members_min'));
       return false;
     }
      else {
        return true;
      }
    } else {
      return true;
    }
  }

  submit() {
    if ( this.mode==='update-customer' || this.mode === 'update' ){
      if (!this.residentialStatus || !this.materialStatus || !this.housingType  || this.politicalFigure===null || this.isResponsible===null || !this.checkFamilyResp()) {
        this.sharedService.showError(this.translate.instant('ERR.fill_all_form'));
      } else {
        let personalInformation = {
          loanPurpose : this.mode === 'update'? this.loanPurpose.libelle : null,
          materialStatus : this.materialStatus.libelle,
          housingType : this.housingType.libelle,
          nbFamilyMembers : this.isResponsible? this.nbFamilyMembers : null,
          isResponsible : this.isResponsible,
          invitationCode : this.invitationCode,
          residentialStatus : this.residentialStatus.libelle,
          politicalFigure : this.politicalFigure
        }
        const customerAdditionalInformation = {
          personalInformation : personalInformation,
          workInformation : this.sharedService.getWorkInfo(),
          incomeDetails : this.sharedService.getIncomeDetails(),
          expensesLiabilities : this.sharedService.getExpensesObligations(),
          bankInformation: this.sharedService.getBankInformation()
         }
         this.customer.otherInformations = JSON.stringify(customerAdditionalInformation);
         if (this.mode==='update-customer') {
          console.log('Before Save Customer in epi',this.customer);
          this.customerService.updateCustomer(this.customer).subscribe(res=>{
            console.log('Saved Customer in epi',res);
            this.sharedService.setCustomer(res);
            this.router.navigate(['/' + PathConstants.PATH_PERSONAL_INFORMATION])
          });
         } else if (this.mode === 'update'){
          let loan = this.sharedService.getLoanDeal();
          loan.otherInformations = JSON.stringify(customerAdditionalInformation);
          loan.loanReasonDescription = personalInformation.loanPurpose;
          this.loanService.updateLoan(loan).toPromise().then((res) => {
            console.log('Before Save Customer in epi',this.customer);
            this.customerService.updateCustomer(this.customer).subscribe(data=>{
              console.log('Saved Customer in epi',data);
              this.sharedService.setCustomer(data);
            });
            this.sharedService.setLoanDeal(res);
            this.router.navigate([loan.stepPath]);
          })
         }

      }
    } else if (this.inputMode == 'mode1') {
      if (!this.residentialStatus ||!this.loanPurpose || !this.materialStatus || !this.housingType  || this.politicalFigure===null
         || this.isResponsible===null || !this.workSection|| !this.dateJoining|| this.militarySector===null|| !this.employerName || !this.checkMilitaryRank() || !this.checkFamilyResp())
      {
        this.sharedService.showError(this.translate.instant('ERR.fill_all_form'));
      }else {
        let personalInformation = {
          loanPurpose : this.loanPurpose.libelle,
          materialStatus : this.materialStatus.libelle,
          housingType : this.housingType.libelle,
          nbFamilyMembers : this.isResponsible? this.nbFamilyMembers : null,
          isResponsible : this.isResponsible,
          invitationCode : this.invitationCode,
          residentialStatus : this.residentialStatus.libelle,
          politicalFigure : this.politicalFigure
        };
        let workInformation = {
        workSection: this.workSection.libelle,
        dateJoining: this.dateJoining,
        militarySector: this.militarySector,
        employerName: this.employerName,
        militaryRank: this.militarySector? this.militaryRank.libelle : null
        }
        let combinedInformation = {
          personalInformation: personalInformation,
          workInformation: workInformation
        };
          /** ACTION FOR LOAN REGISTRATION */
        this.messageEvent.emit(combinedInformation);
      }
  }
  }

  increment() {
  this.nbFamilyMembers++;
  }

  decrement() {
  this.nbFamilyMembers = Math.max(2, this.nbFamilyMembers - 1);
  }

}
