<div class="vw-100">
  <div id="bank-information">
    <div class="">
      <div class="card-body cu-cbody" style="overflow-y: auto;">
        <legend class="fw-600 fsl color1 text-center">{{ 'TITLE.sign_contract' | translate }}</legend>
        <div class="mb-2 mt-4" *ngFor="let d of loanDocuments ; let i = index">
          <form class="form" [formGroup]="documentForm" *ngIf="step!=='sign-contract'">
            <div class="input-group">
              <input type="file" class="form-control cpl" aria-describedby="basic-addon1"
                (change)="onFileSelected($event,d)">
            </div>
            <div class="col-1 px-3 align-self-center">
              <button class="btn" (click)="view(d.file,d.idDocument,i)">view
                <i class="bi bi-eye-fill text-secondary size-18"></i>
              </button>
            </div>
          </form>
          <div *ngIf="step==='sign-contract'">
            <div class="card-body" style="overflow-y: auto">
              <div class="d-flex" style="gap: 5%;">
                <div class="d-flex" style="position: relative">
                  <input type="text" class="form-control"
                    placeholder="{{'LABELS.consumer_financing_contract' | translate}}" disabled
                    [ngClass]="{'border-right': lang === 'en' , 'border-left': lang === 'ar' || lang == null}">
                  <span class="input-group-text"
                    [ngClass]="{'border-left': lang === 'en' , 'border-right': lang === 'ar' || lang == null}"
                    style="color: gray;font-size: 13px;">PDF</span>
                </div>
                <button class="btn white" style="background-color: #213592" (click)="view(d.file,d.idDocument,i)">
                  <img class="ib-icons " src="assets/images/icons/scan.png" alt="lock">
                </button>
              </div>
            </div>

            <div class="text-center pt-4">
              <button type="button" class="btn btn-primary btn-lg mb-2 login-btn" (click)="sendOTP()">
                {{'BUTTONS.sign_the_contract' | translate}}
              </button>
            </div>

            <div *ngIf="showContract">
              <div class="fullscreen" style="padding-top: 70px">
                <div class="my-2 mt-4 d-flex justify-content-between">
                  <button class="close-btn bg-danger white" (click)="closeContract()">
                    <i class='bx bx-x'></i>
                  </button>
                </div>


                <!-- Zoom Controls -->
                <div class="zoom-toolbar">
                  <button class="btn zoom-btn" (click)="increaseZoom()">
                    <i class='bx bx-zoom-in white'></i>
                  </button>
                  <span class="zoom-level white">{{ (zoomLevel * 100) | number: '1.0-0' }}%</span>
                  <button class="btn zoom-btn" (click)="decreaseZoom()">
                    <i class='bx bx-zoom-out white'></i>
                  </button>
                </div>

                <!-- PDF Viewer -->
                <pdf-viewer [src]="contractSrc" [show-all]="false" class="pdfViewer"
                  [zoom]="zoomLevel" [render-text]="true" [autoresize]="true"
                  [page]="currentPage" (after-load-complete)="onAfterLoadComplete($event)">
                </pdf-viewer>

                <!-- Floating Toolbar -->
                <div class="floating-toolbar">
                  <button class="btn" (click)="nextPage()">
                    <i class='bx bx-chevron-right white'></i>
                  </button>
                  <span class="page-indicator white fss">{{ currentPage }}/{{ totalPages }}</span>
                  <button class="btn" (click)="prevPage()">
                    <i class='bx bx-chevron-left white'></i>
                  </button>
                </div>

              </div>
            </div>

            <div class="text-center pt-4" *ngIf="step!=='sign-contract'">
              <button type="button" class="btn btn-primary btn-lg mb-2 login-btn" (click)="onsave()">
                {{'BUTTONS.next' | translate}}
              </button>
            </div>

          </div>
        </div>
      </div>
    </div>
