import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IbNotificationServiceService } from 'src/app/ib-services/IbNotificationService.service';
import { WebsocketService } from 'src/app/ib-services/Websocket.service';
import { SharedService } from 'src/app/ib-services/shared.service';
import { CustomerEntity } from 'src/app/shared/Entities/customer.entity';
import { IbNotification } from 'src/app/shared/Entities/ibNotification.entity';
import { UserEntity } from 'src/app/shared/Entities/user.entity';

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss'],
})
export class NotificationListComponent implements OnInit {
  public user: UserEntity;
  public customer: CustomerEntity;
  public notificationList: IbNotification[] = [];
  public lang: string;

  constructor(
    private sharedService: SharedService,
    private webSocketService: WebsocketService,
    private notifService: IbNotificationServiceService,
    private translate : TranslateService
  ) {}

  ngOnInit() {
    this.lang = localStorage.getItem('lang');
    this.user = this.sharedService.getUser();
    this.customer = this.sharedService.getCustomer();
    this.fetchListNotification();
    this.webSocketService.advance('/topic/list-notifications', (): any => {
      console.log(
        '************ ENTERED SUBSCRIBE WS List Notification API ************'
      );
      this.fetchListNotification();
    });
  }

  fetchListNotification() {
    this.notifService
      .getNotificationByCustomerId(this.customer.ibCustomerId)
      .subscribe((res) => {
        this.notificationList = [...res];
        this.notificationList.reverse();
      });
  }

  /** Method Just For testing */
  launchNotificationTest() {
    let notification = new IbNotification();
    notification.idCustomer = this.customer.ibCustomerId;
    notification.nidReceiver = this.customer.identity;
    notification.sender = 'ACM - SYSTEM';
    notification.title = 'TEST';
    notification.isSeen = 0;
    this.notifService.launchNotification(notification).subscribe((res) => {});
  }

  calculateTimeDifference(date : any){
    const currentDate = new Date();
    const creationDate: Date = new Date(date);
    const timeDifference = currentDate.getTime() - creationDate.getTime();

    const secondsDifference = Math.floor(timeDifference / 1000);
    const minutesDifference = Math.floor(secondsDifference / 60);
    const hoursDifference = Math.floor(minutesDifference / 60);
    const daysDifference = Math.floor(hoursDifference / 24);

    if (secondsDifference < 60) {
      if (this.lang =='ar') {
        return ` منذ ${secondsDifference} ${this.translate.instant("TIME_DIFFERENCE.seconds_ago")}`;
      } else  return `${secondsDifference} ${this.translate.instant("TIME_DIFFERENCE.seconds_ago")} ago`;
    } else if (minutesDifference < 60) {
      if (this.lang =='ar') {
        return ` منذ ${minutesDifference} ${minutesDifference > 10 ? this.translate.instant("TIME_DIFFERENCE.minute") : this.translate.instant("TIME_DIFFERENCE.minutes_ago")}`;
      } else  return `${minutesDifference} ${minutesDifference === 1 ? this.translate.instant("TIME_DIFFERENCE.minute") : this.translate.instant("TIME_DIFFERENCE.minutes_ago")} ago`;
    } else if (hoursDifference < 24) {
      if (this.lang =='ar') {
        return ` منذ ${hoursDifference} ${hoursDifference === 1 || hoursDifference > 10 ? this.translate.instant("TIME_DIFFERENCE.hour") : this.translate.instant("TIME_DIFFERENCE.hours")}`;
      } else  return `${hoursDifference} ${hoursDifference === 1 ? this.translate.instant("TIME_DIFFERENCE.hour") : this.translate.instant("TIME_DIFFERENCE.hours")} ago`;
    } else {
      if (this.lang =='ar') {
        return ` منذ ${daysDifference} ${daysDifference > 10 ? this.translate.instant("TIME_DIFFERENCE.day") : this.translate.instant("TIME_DIFFERENCE.days")}`;
      } else  return `${daysDifference} ${daysDifference === 1 ? this.translate.instant("TIME_DIFFERENCE.day") : this.translate.instant("TIME_DIFFERENCE.days")} ago`;
    }
  }

  markAsSeenOrUnseenNotification(ibNotification : IbNotification){
    this.notifService.updateSeenNotification(ibNotification).subscribe(res => {
      this.fetchListNotification();
    })
  }

}
