import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CreditService } from 'src/app/ib-services/credit.service';
import { SharedService } from 'src/app/ib-services/shared.service';
import { BankInformation } from 'src/app/shared/Entities/BankInformation.entity';
import { ExpensesObligations } from 'src/app/shared/Entities/ExpensesObligations.entity';
import { CustomerEntity } from 'src/app/shared/Entities/customer.entity';
import { IncomeDetailsEntity } from 'src/app/shared/Entities/incomeDetails.entity';
import { LoanEntityIB } from 'src/app/shared/Entities/loan.entity';
import { PersonalInformationEntity } from 'src/app/shared/Entities/personalInformation.entity';
import { WorkInformationEntity } from 'src/app/shared/Entities/workInformation.entity';
import { PathConstants } from 'src/app/shared/mb-constants/path-constants';

@Component({
  selector: 'app-personal-information',
  templateUrl: './personal-information.component.html',
  styleUrls: ['./personal-information.component.scss']
})

export class PersonalInformationComponent implements OnInit {
  customer : CustomerEntity;
  public personalInfo : PersonalInformationEntity;
  public incomeDetails : IncomeDetailsEntity;
  public expensesObligations : ExpensesObligations;
  public workInformation : WorkInformationEntity;
  public monthlyObligation : number = 0
  public otherInformationString : string
  public loansList: LoanEntityIB[] = [];
  public otherInformationObject : any;
  public bankInformation : BankInformation = new BankInformation();

  constructor(private router: Router,public sharedService : SharedService) { }

 async ngOnInit() {
    this.customer = this.sharedService.getCustomer();
    const otherInformationString = this.customer.otherInformations? this.customer.otherInformations : null;
    const otherInformationObject = JSON.parse(otherInformationString);

    this.personalInfo = otherInformationObject?.personalInformation ? otherInformationObject.personalInformation : null;
    this.incomeDetails =  otherInformationObject?.incomeDetails ? otherInformationObject.incomeDetails : null;
    this.expensesObligations =  otherInformationObject?.expensesLiabilities ? otherInformationObject.expensesLiabilities : null;
    this.workInformation =  otherInformationObject?.workInformation ? otherInformationObject.workInformation : null;
    this.bankInformation = otherInformationObject?.bankInformation ? otherInformationObject.bankInformation : null;
    if (this.expensesObligations) {
      this.monthlyObligation =
        this.expensesObligations.monthlyInstallment  +
        this.expensesObligations.foodExp +
        this.expensesObligations.educExp +
        this.expensesObligations.transportAndCommunicationExp +
        this.expensesObligations.domesticExp  +
        this.expensesObligations.otherExp  +
        this.expensesObligations.internationalExp +
        this.expensesObligations.healthCareExpenses +
        this.expensesObligations.insuranceExpenses +
        this.expensesObligations.housing
      ;
    }
    this.sharedService.setPersonalInfo(this.personalInfo);
    this.sharedService.setIncomeDetails(this.incomeDetails);
    this.sharedService.setExpensesObligations(this.expensesObligations);
    this.sharedService.setWorkInfo(this.workInformation);
    this.sharedService.setBankInformation(this.bankInformation);
  }
  edit(type : string){
    const mode = 'update-customer';
     switch (type) {
     case PathConstants.PATH_EDIT_PERSONAL_INFO :
       this.router.navigate(['/' + PathConstants.PATH_EDIT_PERSONAL_INFO] , {queryParams:{mode: mode}});
       break;
       case PathConstants.PATH_EDIT_WORK_INFO :
         this.router.navigate(['/' + PathConstants.PATH_EDIT_WORK_INFO] , {queryParams:{mode: mode}});
         break;
         case PathConstants.PATH_EDIT_INCOME_DETAILS :
           this.router.navigate(['/' + PathConstants.PATH_EDIT_INCOME_DETAILS] , {queryParams:{mode: mode}});
           break;
           case PathConstants.PATH_EDIT_EXPENSES_OBLIGATIONS :
             this.router.navigate(['/' + PathConstants.PATH_EDIT_EXPENSES_OBLIGATIONS] , {queryParams:{mode: mode}});
             break;
             case 'edit-iban' :
              this.router.navigate(['/' + PathConstants.PATH_BANK_INFORMATION] , {queryParams:{mode: mode}});
              break;
     }
   }
  }
