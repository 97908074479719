import { CustomerStatus, DealApprovalStatus, DealStatus, SignatureStatus } from "../mb-constants/enums-cfs";

export const ipDetectorKey = '292f3a2f7643eda2a5b80b976eb28239';

export const nationalityOptions: string[] = [
  "سعودي",
  "مقيم"
];

export const placeOfIssue: string[] = [
 "الرياض",
 "جدة",
 "مكة المكرمة",
 "المدينة المنورة",
 "الدمام",
 "تبوك"
];

/** Data */
export const dealOne = {
  dealId : 1,
  dealRef : '0049841-0001',
  loanAmount : 3000,
  intallmentAmount : 300,
  nextRepaymentDate : '31-06-2022',
  unpaidAmount : 0,
  arrearsDays : 0,
  outstandingAmount : 1500,
  lastInstallmentDate : '01/12/2022',
  dealStatus : DealStatus.APPROVAL
  // totalAmount : 11250,
  // maxAmountProduct : 15000
}

export const dealTwo = {
  dealId : 2,
  dealRef : '00654123-0098',
  loanAmount : 7000,
  intallmentAmount : 700,
  nextRepaymentDate : '31-06-2022',
  unpaidAmount : 1000,
  arrearsDays : 30,
  outstandingAmount : 2800,
  lastInstallmentDate : '01/12/2022',
  dealStatus : DealStatus.LOAN_REQUEST
  // totalAmount : 20365,
  // maxAmountProduct : 25000
}

export const customerExample = {
  nationalId : '12345697',
  username : 'Oussema',
  password : 'oussema777',
  avatar : '',
  nationality : 'Egyptian',
  firstName: 'Oussema',
  secondName : ' - ',
  middleName : ' - ',
  lastName: 'Madiouni',
  dateOfBirth : '05/02/1996',
  gender : 'Male',
  phoneNumber : 22222222,
  email : 'omadiouni@talys.digital',
  familySituation : 'اعزب',
  address : 'Tunis',
  placeOfIssue : 'Aswen',
  expiryDate : '02/03/2025',
  status : CustomerStatus.VERIFIED
}

export const signature={
    fullNameLoanDemander : 'Oussema',
    loanDealTitle : 'LoanDeal',
    expirationDate : '23/05/2022',
    status : SignatureStatus.SIGNED,
}

export const loanDealOne = {
loanId : 1,
loanRef : '001254-0001',
customerName : 'Oussema',
amountLoan : 6000,
Term : 2,
monthlyRepayment : 3105,
description : 'LoanDeal',
approvalStatus : DealApprovalStatus.APPROVED,
dealState : DealStatus.SIGNATURE,
eSignature : signature
}

export const listUsernames = [
  {id:322643064468,username:'Oussema',lastName : 'Madiouni', avatarUrl:'assets/img/oussema.png'},
  {id:292463496445,username:'Meher',lastName : 'Khemissi', avatarUrl:'assets/img/meher.png'},
  {id:284444853479,username:'Chiheb',lastName : 'Khemakhem', avatarUrl:'assets/img/chiheb.png'},
  {id:232321212524,username:'Yassine',lastName : 'Mezrani', avatarUrl:'assets/img/user.jpg'},
  {id:266742728840,username:'Moez',lastName : 'Mhiri', avatarUrl:'assets/img/user.jpg'},
  {id:262625252124,username:'Ali',lastName : 'Ben Hadef', avatarUrl:'assets/img/user.jpg'},
  {id:21328785588,username:'Salah',lastName : 'Ben Mohamed', avatarUrl:'assets/img/salah.jpg'}
];
