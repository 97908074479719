
<div *ngIf="sharedService.getIsMobile()" >
    <div *ngIf="displayDone" class="col-11 col-sm-11 col-md-6 col-lg-5 col-xl-3 mx-auto py-4 text-center"  style="margin-top: 55%;">
      <img src="assets/img/success-35.png" alt="" class="vm mw-100" style="width: 60%; margin-bottom: 25px" />
      <p class="mb-3 text-center font-weight-bold">
        <strong class="size-24 text-uppercase">{{
          "payment_done" | translate
          }}</strong>
        <br />
        <span>{{'Thank_you_for_your_payment'|translate}}.</span>
      </p>
    </div>


    <div *ngIf="!displayDone" class="col-11 col-sm-11 col-md-6 col-lg-5 col-xl-3 mx-auto py-4 text-center" style="margin-top: 55%;">
      <img src="assets/img/failPayment.png" alt="" class="vm mw-100" style="width: 60%; margin-bottom: 25px" />
      <p class="mb-3 text-center font-weight-bold">
        <strong class="size-24 text-uppercase">{{'paymet_failed'|translate}}</strong>
        <br />
      </p>
    </div>

  </div>

  <div *ngIf="!sharedService.getIsMobile()">
    <div *ngIf="displayDone" class="col-11 col-sm-11 col-md-6 col-lg-5 col-xl-3 mx-auto py-4 text-center" >
      <img src="assets/img/success-35.png" alt="" class="vm mw-100" style="width: 60%; margin-bottom: 25px" />
      <p class="mb-3 text-center font-weight-bold">
        <strong class="size-24 text-uppercase">{{
          "payment_done" | translate
          }}</strong>
        <br />
        <span>{{'Thank_you_for_your_payment'|translate}}.</span>
      </p>
    </div>


    <div *ngIf="!displayDone" class="col-11 col-sm-11 col-md-6 col-lg-5 col-xl-3 mx-auto py-4 text-center">
      <img src="assets/img/failPayment.png" alt="" class="vm mw-100" style="width: 60%; margin-bottom: 25px" />
      <p class="mb-3 text-center font-weight-bold">
        <strong class="size-24 text-uppercase">{{'paymet_failed'|translate}}</strong>
        <br />
      </p>
    </div>

  </div>
