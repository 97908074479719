import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from 'src/app/ib-services/shared.service';
import { LoanEntityIB } from 'src/app/shared/Entities/loan.entity';

@Component({
  selector: 'app-loan-status',
  templateUrl: './loan-status.component.html',
  styleUrls: ['./loan-status.component.scss']
})
export class LoanStatusComponent {
  status : string
  public loan : LoanEntityIB
  rejectReason : string

  public showPopupEvaluation : boolean = false;

  constructor(private route: ActivatedRoute,public sharedService: SharedService,private router : Router,private translate : TranslateService ) { }

  ngOnInit() {
    this.loan = this.sharedService.getLoanDeal();
    this.sharedService.setLoanForEval(this.loan);
    this.route.queryParams.subscribe(params => {
      this.status = params['status'];
    });
    // if (this.status==='refused') {
    //   switch (this.loan.loanReasonDescription){
    //     case 'Check AML' :
    //       this.rejectReason = this.translate.instant('Reject_reason.check_aml');
    //       break;
    //       case 'Check Score' :
    //       this.rejectReason = this.translate.instant('Reject_reason.check_score');
    //       break;
    //       case 'Get info from Dakhli' :
    //       this.rejectReason = this.translate.instant('Reject_reason.dakhli');
    //       break;
    //         case 'Check Simah' :
    //           this.rejectReason = this.translate.instant('Reject_reason.check_simah');
    //           break;
    //       default :
    //       this.rejectReason = this.translate.instant('Reject_reason.default');
    //   }
    // }
  }

  goToCustomerDecision(){
   this.sharedService.setSshowFirstRequestApproved(false);
   this.router.navigate(['/'+this.loan.stepPath]);
  }

}
