<div class="bg-2">
  <div style="display: contents !important;overflow-y: hidden !important">
    <div class="bg-1" *ngIf="customer && mode != 'listLoans'"  style="border-radius: 0px 0px 20px 20px;padding-bottom:55px;padding-top: 1rem !important;">
      <div id="header-home" class="px-3 header-top-home" >
        <div class="fsb fixed-top bg-1 px-3 pt-4" style="border-radius: 0px 0px 30px 30px;">
          <label class="p-3 white op-80"><label class="label-main" class="op-50">{{'WELCOME' | translate}}</label><br>
          <div *ngIf="arFistName"><label *ngIf="lang==='en' && customer.firstName">{{customer.firstName}}</label><label *ngIf="lang==='ar' && arFistName">{{arFistName}}</label></div>
          <div *ngIf="!arFistName"><label *ngIf="customer.firstName">{{customer.firstName}}</label></div>

        </label>
          <div class="notification-container">
            <img class="ib-icons m-2"
              style="margin-top: 2.2rem !important;width: 30px !important;filter: drop-shadow(2px 4px 6px black);"
              src="assets/images/icons/notif2.png" alt="notif2" routerLink="/notifications">
            <div class="notification-dot" *ngIf="nbNotifs != 0">{{nbNotifs}}</div>
          </div>
        </div>

        <div class="card card-bg-secondary px-3" style="margin-top:100px !important">

          <label class="text-center white op-50"> {{'INFO.budget_u_can_request' | translate}}</label>

          <div class="py-2">
            <legend class="text-center white" style="font-size:xxx-large;">{{maxAmountProduct | currency:' '}}
              <span class="fss" *ngIf="lang=='en'">{{'CURRENCY.sar' | translate}}</span>
              <img class="ib-icons m-2" *ngIf="lang=='ar'" src="assets/images/icons/sarAr.png" alt="sarAr">
            </legend>
          </div>

          <div class="flc py-2 mb-2" >
            <button type="button" (click)="applyNewLoan()" class="btn btn-secondary px-4" ><i class='bx bx-up-arrow-alt me-1 fsxl'
                style="transform: rotate(45deg)"></i> {{ 'BUTTONS.apply_now' | translate }} </button>
          </div>
        </div>
      </div>
    </div>

    <div id="loan-applies-content"  style="overflow-y: auto;" [ngClass]="{'' : mode != 'listLoans' }" >
      <div class="p-3 mb-5" *ngIf="loansList">
        <legend class=" me-5 mb-2 pb-2 color1" *ngIf="mode != 'listLoans'">{{ 'LABELS.applications_status' | translate}} <i
            class='bx bx-refresh' (click)="refreshListLoans()"></i></legend>

        <!-- IF NO LOANS APPLIED -->
        <div id="no-loans-found" *ngIf="loansList.length == 0 ">
          <div class="mt-4 pt-4 flc inline-flex align-items-center">
            <img class="img-logo-sanad op-20" style="width: 160px;" src="assets/images/money_calendar.png" alt="My Image">
          </div>
          <label class="label-info flc">{{'INFO.no_loans' | translate}}</label>
        </div>

        <!-- APPLICATION -->
        <div *ngFor="let loan of loansList; let index = index">
          <div class="card px-3 py-2 mb-2" [ngClass]="{'mb-5' : index == loansList.length-1}">
            <div class="fsb " (click)="redirectLoan(index)" *ngIf="loan.accountNumber">
              <div class="d-grid" [ngClass]="{'red-dot': clickableSteps.includes(loan.statutLibelle.toLowerCase())}">
                <!-- Loan Account Number with Loading Icon -->
                <label class="mb-1 color1 fw-700 mt-1" *ngIf="loan.accountNumber" aria-label="Loan Account Number">
                  {{ loan.accountNumber }}
                  <i class="bx bx-loader m-1 bx-loader-animated" *ngIf="loan.statutLibelle === 'Disburse'"></i>
                </label>

                <!-- Processing State -->
                <label class="mb-1 color1 fw-700 fss" style="color: lightgray" *ngIf="!loan.loanId || !loan.accountNumber"
                  aria-label="Processing Information">
                  {{ 'INFO.processing' | translate }}
                  <i class="bx bx-loader m-1" *ngIf="loan.statutLibelle === 'Disburse'"></i>
                </label>
                <label class="mb-1 fss op-50 fw-650">{{loan.dateInsertion | date}}</label>
              </div>



              <button class="btn btn-status-pending   login-btn"
                *ngIf="loan.statut != '6' && loan.statut != '8' && loan.statutLibelle != 'Disburse' && loan.statut != '5'">{{'STATUSES.pending'
                | translate}}</button>
              <button class="btn btn-status-pending   login-btn"
                *ngIf="loan.statutLibelle == 'Disburse'">{{'STATUSES.pending' | translate}}</button>
              <button class="btn btn-status-rejected   login-btn"
                *ngIf="loan.statut == '5' || loan.statut == '6'">{{'STATUSES.rejected' | translate}}</button>
              <button class="btn btn-status-success   login-btn" *ngIf="loan.statut == '8'">{{'STATUSES.approved' |
                translate}}</button>

            </div>

            <div class="fsb " (click)="showPopupNafedh(index)" *ngIf="!loan.accountNumber ||  loan.accountNumber == null">
              <div class="d-grid ">
                <label class=" my-2 color1 fw-700 fsm op-80">
                  {{'INFO.loan_process_nafedh' | translate}}<i class='bx bx-loader m-1'></i>
                </label>
              </div>

              <button class="btn btn-status-pending   login-btn"
                style="background-color: #11998e !important;color:white !important">
                {{loan.nafedhCode}}
              </button>
            </div>
          </div>

        </div>
      </div>

    </div>


  </div>
</div>

<app-footer-menu *ngIf="this.sharedService.getUser().login != undefined && mode != 'listLoans'"></app-footer-menu>

<!-- EVALUATION POPUP  -->
<app-popup *ngIf="showPopupEval" [source]="'EVALUATION'" (closePopup)="handleClosePopup()"></app-popup>
