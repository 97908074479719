import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from 'src/app/ib-services/shared.service';
import { LoanEntityIB } from 'src/app/shared/Entities/loan.entity';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})
export class StepperComponent implements OnInit {

  @Input() stepName: string;

  public stepTitle: string;
  public widthProgress: any;
  public stepIndex: number;
  public nextStep: string;
  public totalStepsNumber: number;

  public loan: LoanEntityIB;
  public counterRegistration : number;
  public counterLoanReg : number;

  public stepsRegNumber : number = 1;
  public stepsRegLoanNumber : number = 8;

  lang : string;

  constructor(public sharedService: SharedService, private translate : TranslateService) { }

  ngOnInit() {
    this.loan = this.sharedService.getLoanDeal();
    this.lang = localStorage.getItem('lang');

    this.counterRegistration = 100 / this.stepsRegNumber;
    this.counterLoanReg = 100 / this.stepsRegLoanNumber;
   this.translate.onLangChange.subscribe((event) => {
    this.stepStateSelection();
  });
  this.stepStateSelection();
  }

  stepStateSelection(){
    switch (this.stepName){
      case 'registration':
      this.stepIndex = 1;
      this.stepTitle = this.translate.instant('STEP.registration');
      this.widthProgress = {width : this.counterRegistration*this.stepIndex+'%'};
      // this.nextStep = this.translate.instant('STEP.consent_validation');
      this.nextStep = '';
      this.totalStepsNumber = this.stepsRegNumber;
        break;
      case 'loan-registration':
        this.stepIndex = 1;
        this.stepTitle = this.translate.instant('STEP.loan_registration');
        this.widthProgress = {width : this.counterLoanReg*this.stepIndex+'%'};
        this.nextStep = this.translate.instant('STEP.consent_validation');
        this.totalStepsNumber = this.stepsRegLoanNumber;
          break;
      case 'consent-validation':
        this.stepIndex = 2;
        this.stepTitle = this.translate.instant('STEP.consent_validation');
        this.widthProgress = {width : this.counterLoanReg*this.stepIndex+'%'};
        this.nextStep = this.translate.instant('STEP.simah_verification_status');
        this.totalStepsNumber = this.stepsRegLoanNumber;
        break;
        case 'simah-status':
        this.stepIndex = 3;
        this.stepTitle = this.translate.instant('STEP.simah_verification_status');
        this.widthProgress = {width : this.counterLoanReg*this.stepIndex+'%'};
        this.nextStep = this.translate.instant('STEP.personal_information');
        this.totalStepsNumber = this.stepsRegLoanNumber;
        break;
      case 'personal-information':
        this.stepIndex = 4;
        this.stepTitle = this.translate.instant('STEP.personal_information');
        this.widthProgress = {width : this.counterLoanReg*this.stepIndex+'%'};
        this.nextStep = this.translate.instant('STEP.upload_documents');
        this.totalStepsNumber = this.stepsRegLoanNumber;
        break;
      case 'bank-details':
        this.stepIndex = 5;
        this.stepTitle = this.translate.instant('STEP.bank_details');
        this.widthProgress = {width : this.counterLoanReg*this.stepIndex+'%'};
        this.nextStep = this.translate.instant('STEP.upload_documents');
        this.totalStepsNumber = this.stepsRegLoanNumber;
        break;
        case 'upload-documents':
          this.stepIndex = 6;
          this.stepTitle = this.translate.instant('STEP.upload_documents');
          this.widthProgress = {width : this.counterLoanReg*this.stepIndex+'%'};
          this.nextStep = this.translate.instant('STEP.summary_approval');
          this.totalStepsNumber = this.stepsRegLoanNumber;
          break;
      case 'initial-approval':
        this.stepIndex = 7;
        this.stepTitle = this.translate.instant('STEP.summary_approval');
        this.widthProgress = {width : this.counterLoanReg*this.stepIndex+'%'};
        this.nextStep = this.translate.instant('STEP.period_selection');
        this.totalStepsNumber = this.stepsRegLoanNumber;
        break;
      case 'period':
        this.stepIndex = 8;
        this.stepTitle = this.translate.instant('STEP.period_selection');
        this.widthProgress = {width : this.counterLoanReg*this.stepIndex+'%'};
        this.nextStep = this.translate.instant('STEP.esign_contract');
        this.totalStepsNumber = this.stepsRegLoanNumber;
        break;
      case 'e-Sign':
        this.stepIndex = 9;
        this.stepTitle = this.translate.instant('STEP.esign_contract');
        this.widthProgress = {width : this.counterLoanReg*this.stepIndex+'%'};
        this.nextStep = this.translate.instant('STEP.no_steps_remaining');
        this.totalStepsNumber = this.stepsRegLoanNumber;
        break;
    }
  }

}
