<div class=" vh-100 vw-100 px-3">
  <!-- <app-screen-title [title]="'TITLE.loan_application'" [color]="'white'" [backTo]="'NONE'"></app-screen-title> -->
  <!-- Stepper -->
  <!-- <app-stepper [stepName]="'upload-documents'"></app-stepper> -->

  <div id="bank-information">
    <div class="card card-main p-2 fixed-bottom">
      <div class="card-body cu-cbody" style="overflow-y: auto;">
        <div class="mb-2 mt-4" *ngFor="let d of loanDocuments ; let i = index">
          <form class="form" [formGroup]="documentForm" *ngIf="step!=='sign-contract'">
            <div class="input-group">
              <input type="file" class="form-control cpl" aria-describedby="basic-addon1"
                (change)="onFileSelected($event,d)">
            </div>
            <div class="col-1 px-3 align-self-center">
              <button class="btn" (click)="view(d.file,d.idDocument,i)">view
                <i class="bi bi-eye-fill text-secondary size-18"></i>
              </button>
            </div>
          </form>
          <div *ngIf="step==='sign-contract'">
            <legend class="fw-600 fsl color1 text-center">{{ 'TITLE.sign_contract' | translate }}</legend>
            <div class="card-body" style="overflow-y: auto">
              <div class="d-flex" style="gap: 5%;">
                <div class="d-flex" style="position: relative">
                  <input type="text" class="form-control"
                    placeholder="{{'LABELS.consumer_financing_contract' | translate}}" disabled
                    [ngClass]="{'border-right': lang === 'en' , 'border-left': lang === 'ar' || lang == null}">
                  <span class="input-group-text"
                    [ngClass]="{'border-left': lang === 'en' , 'border-right': lang === 'ar' || lang == null}"
                    style="color: gray;font-size: 13px;">PDF</span>
                </div>
                <button class="btn white" style="background-color: #213592" (click)="view(d.file,d.idDocument,i)">
                  <img class="ib-icons " src="assets/images/icons/scan.png" alt="lock">
                </button>
              </div>
            </div>

            <div class="text-center pt-4">
              <button type="button" class="btn btn-primary btn-lg mb-2 login-btn" (click)="sendOTP()">
                {{'BUTTONS.sign_the_contract' | translate}}
              </button>
            </div>

            <div *ngIf="showContract" class="fullscreen" style="padding-top: 60px">
              <pdf-viewer [src]="contractSrc" [show-all]="true" class="pdfViewer"  [zoom]="zoomLevel" [render-text]="true"></pdf-viewer>
              <div class="confirmation" style="display: flex; justify-content: space-between; gap: 10px;">
                <button class="btn col-4 white py-1" style="background-color: #213592" (click)="decreaseZoom()"> - </button>
                <button class="btn col-4 white py-1" style="background-color: #213592" (click)="increaseZoom()"> + </button>
                <button class="btn btn-danger col-4 white py-1" (click)="closeContract()">{{'BUTTON.close' | translate}}</button>
              </div>
            </div>

            <div class="text-center pt-4" *ngIf="step!=='sign-contract'">
              <button type="button" class="btn btn-primary btn-lg mb-2 login-btn" (click)="onsave()">
                {{'BUTTONS.next' | translate}}
              </button>
            </div>

          </div>
        </div>
      </div>
    </div>
