import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CreditService } from 'src/app/ib-services/credit.service';
import { CustomerService } from 'src/app/ib-services/customer.service';
import { LoanService } from 'src/app/ib-services/loan.service';
import { SharedService } from 'src/app/ib-services/shared.service';
import { ExpensesObligations } from 'src/app/shared/Entities/ExpensesObligations.entity';
import { CustomerEntity } from 'src/app/shared/Entities/customer.entity';
import { PathConstants } from 'src/app/shared/mb-constants/path-constants';

@Component({
  selector: 'app-edit-expenses-obligations',
  templateUrl: './edit-expenses-obligations.component.html',
  styleUrls: ['./edit-expenses-obligations.component.scss']
})
export class EditExpensesObligationsComponent implements OnInit {

  /** Personal Information Modes ==> default = Settings ; mode1 = Loan Registration */
  @Input() inputMode : string = '';
  @Output() messageEvent = new EventEmitter<any>();

  /** Expenses & Liabilities Values */
  public housing : number = null;
  public livingExpenses : number = null;
  public supplyExpenses : number = null;
  public insuranceExpenses : number = null;
  public utilityExpenses : number = null;

  public communicationExpenses : number = null;
  public monthlyOblig : boolean;
  public monthlyInstallment : number = null;
  public foodExp : number = null;
  public educExp : number = null;
  public transportExp : number = null;
  public accopExp : number = null;
  public domesticExp : number = null;
  public otherExp : number = null;
  public internationalExp : number = null;
  public healthCareExpenses : number = null;

  public housingError:boolean=false;
  public livingExpensesError:boolean=false;
  public supplyExpensesError :boolean=false;
  public insuranceExpensesError :boolean=false;
  public communicationExpensesError :boolean=false;
  public utilityExpensesError:boolean=false;

  public showLoanInfoAlert:boolean=false;
  public showIncomeDetailsAlert:boolean=false;
  public showExpensesAlert:boolean=false;

  public expensesLiabilitiesInformation : any;
  public mode : string;
  public expensesLiabilities : ExpensesObligations;
  public customer : CustomerEntity;
  public totalIncome : number = 0;
  public lang : string;

  constructor(private sharedService : SharedService,private loanService : LoanService,private router : Router,private translate : TranslateService,
              private route: ActivatedRoute, private customerService : CustomerService ){}

  ngOnInit() {
    this.lang = localStorage.getItem('lang');
    if(!this.lang) this.lang = this.sharedService.getLang();
    this.customer = this.sharedService.getCustomer();
    this.route.queryParams.subscribe(params => { this.mode = params['mode']});
      if (this.inputMode == 'mode1' && this.customer.otherInformations !== null && this.customer.otherInformations !== undefined) {
        const otherInformationString = this.customer.otherInformations;
        const otherInformationObject = JSON.parse(otherInformationString);
        if (otherInformationObject){
        this.expensesLiabilities = otherInformationObject.expensesLiabilities? otherInformationObject.expensesLiabilities : null;
        if (this.expensesLiabilities){
          this.monthlyOblig = this.expensesLiabilities.monthlyOblig;
          this.monthlyInstallment = this.expensesLiabilities.monthlyInstallment;
          this.foodExp = this.expensesLiabilities.foodExp;
          this.educExp = this.expensesLiabilities.educExp;
          this.transportExp = this.expensesLiabilities.transportAndCommunicationExp;
          this.domesticExp = this.expensesLiabilities.domesticExp;
          this.otherExp = this.expensesLiabilities.otherExp;
          this.internationalExp = this.expensesLiabilities.internationalExp;
          this.insuranceExpenses = this.expensesLiabilities.insuranceExpenses;
          this.housing = this.expensesLiabilities.housing;
          this.healthCareExpenses = this.expensesLiabilities.healthCareExpenses;
        }
        }
      }
       if (this.mode==='update-customer' || this.mode==='update') {
        this.expensesLiabilities = this.sharedService.getExpensesObligations();
        let incomeDetails = this.sharedService.getIncomeDetails();
        this.totalIncome = incomeDetails ? incomeDetails.basicSalary : 0;
        if (this.expensesLiabilities){
          this.monthlyOblig = this.expensesLiabilities.monthlyOblig;
          this.monthlyInstallment = this.expensesLiabilities.monthlyInstallment;
          this.foodExp = this.expensesLiabilities.foodExp;
          this.educExp = this.expensesLiabilities.educExp;
          this.transportExp = this.expensesLiabilities.transportAndCommunicationExp;
          this.domesticExp = this.expensesLiabilities.domesticExp;
          this.otherExp = this.expensesLiabilities.otherExp;
          this.internationalExp = this.expensesLiabilities.internationalExp;
          this.insuranceExpenses = this.expensesLiabilities.insuranceExpenses;
          this.housing = this.expensesLiabilities.housing;
          this.healthCareExpenses = this.expensesLiabilities.healthCareExpenses;
      }
    }
  }

  checkMonthlyOblig(){
    if (this.monthlyOblig === true ){
      if ( this.monthlyOblig === null) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  submit(){

      /** ACTION FOR LOAN REGISTRATION */
      if (  this.monthlyOblig === null || this.educExp === null || this.foodExp === null ||
        this.transportExp === null ||this.otherExp === null || this.internationalExp === null
        || this.domesticExp === null || this.housing ===null || this.insuranceExpenses === null || this.healthCareExpenses === null || !this.checkMonthlyOblig() ){
        this.sharedService.showError(this.translate.instant('ERR.fill_all_form'));
        }else {
          this.expensesLiabilitiesInformation = {
              monthlyInstallment: this.monthlyOblig? this.monthlyInstallment : null,
              monthlyOblig: this.monthlyOblig,
              foodExp: this.foodExp,
              educExp: this.educExp,
              transportAndCommunicationExp: this.transportExp,
              domesticExp: this.domesticExp,
              otherExp: this.otherExp,
              internationalExp: this.internationalExp,
              housing : this.housing,
              insuranceExpenses : this.insuranceExpenses,
              healthCareExpenses : this.healthCareExpenses
          }
          if (this.inputMode == 'mode1'){
          this.messageEvent.emit(this.expensesLiabilitiesInformation);
          }
          if(this.mode ==='update'){
            let loan = this.sharedService.getLoanDeal();
            let updatedOtherInfo = {
              personalInformation : this.sharedService.getPersonalInfo(),
              workInformation : this.sharedService.getWorkInfo(),
              incomeDetails : this.sharedService.getIncomeDetails(),
              expensesLiabilities : this.expensesLiabilitiesInformation,
              bankInformation: this.sharedService.getBankInformation()
            }
            loan.otherInformations = JSON.stringify(updatedOtherInfo);
            this.customer.otherInformations = JSON.stringify(updatedOtherInfo);
            this.loanService.updateLoan(loan).toPromise().then((res) => {
              this.customerService.updateCustomer(this.customer).subscribe(data=>{
                this.sharedService.setCustomer(data);
              });
              this.sharedService.setLoanDeal(res);
              this.router.navigate([loan.stepPath]);
            })
          }
          else if (this.mode === 'update-customer') {
            const customerAdditionalInformation = {
                personalInformation : this.sharedService.getPersonalInfo(),
                workInformation : this.sharedService.getWorkInfo(),
                incomeDetails : this.sharedService.getIncomeDetails(),
                expensesLiabilities : this.expensesLiabilitiesInformation,
                bankInformation: this.sharedService.getBankInformation()
               }

          this.customer.otherInformations = JSON.stringify(customerAdditionalInformation);
          this.customerService.updateCustomer(this.customer).subscribe(res=>{
            this.sharedService.setCustomer(res);
            this.router.navigate(['/' +  PathConstants.PATH_PERSONAL_INFORMATION])
          });
         }
        }

  }

}
