import { DebtorSanadElAmerApiRequestEntity } from "./DebtorSanadElAmerApiRequest.entity";
import { SanadRequestSanadElAmerEntity } from "./SanadRequestSanadElAmer.entity";

export class SanadElAmerRequestEntity{

  debtor: DebtorSanadElAmerApiRequestEntity;
  city_of_issuance: string;
  debtor_phone_number: string;
  total_value: number;
  currency: string;
  max_approve_duration: string;
  reference_id: string;
  sanad: SanadRequestSanadElAmerEntity[];


}
