import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CreditService } from 'src/app/ib-services/credit.service';
import { ParametrageService } from 'src/app/ib-services/parametrage.service';
import { SharedService } from 'src/app/ib-services/shared.service';
import { ThirdPartyService } from 'src/app/ib-services/third-party.service';

@Component({
  selector: 'app-payment-status',
  templateUrl: './payment-status.component.html',
  styleUrls: ['./payment-status.component.scss'],
})
export class PaymentStatusComponent implements OnInit {
  displayDone: boolean = false;
  loanid = '';
  amount = '';
  usernameAbacusPayment: string;

  constructor(
    private route: ActivatedRoute,
    public sharedService: SharedService,
    public parametrageService: ParametrageService,
    public creditService: CreditService,
    public translate: TranslateService,
    public router: Router,
    private thirdPartyService : ThirdPartyService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (params['responseCode'] === '000') {
        this.loanid = params['idLoan'];
        this.amount = params['amount'];
        this.displayDone = true;

        this.parametrageService
          .getEnvirementValueByKey('USER_PAYMENT_ABACUS')
          .toPromise()
          .then((usernamePaymentData) => {
            if (
              usernamePaymentData.value !== undefined &&
              usernamePaymentData.value !== null
            ) {
              //we are in the case of sanad ( we must use only this user: E-Payment)
              //  this.usernameAbacusPayment = usernamePaymentData.value;
            }

            const sendParms =
              this.loanid +
              ';' +
              this.amount.toString() +
              ';' +
              this.usernameAbacusPayment;
            this.thirdPartyService
              .sendParamsToPaymentLoan(sendParms)
              .toPromise()
              .then((data) => {
                this.sharedService.showSuccess(
                  this.translate.instant('ERR.payment_success')
                );
              });
          });
      }
    });
  }

  redirect() {
    location.replace('/home');
  }
}
