import { Injectable } from '@angular/core';
import { CustomerEntity } from '../shared/Entities/customer.entity';
import { Observable } from 'rxjs';
import { AcmURLConstants } from '../shared/mb-constants/mb-url-constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { KycEntity } from '../shared/Entities/kyc.entity';
import { IbRegistration } from '../shared/Entities/ibRegistration.entity';
import { ResponseCheckEntity } from '../shared/Entities/ResponseCheck.entity';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  constructor(public httpClient: HttpClient) {}

  /** Not Used */
  getCustomerInformation(id: number): Observable<CustomerEntity> {
    let qheader = new HttpHeaders();
    qheader = qheader.append('Authorization', 'Bearer ' + localStorage.getItem('accesToken'));
    return this.httpClient.get<CustomerEntity>(environment.apiUrl + 'credit-service/customers/' + id, { headers: qheader });
  }

  /** Not Used */
  getCustomers(customerEntity: CustomerEntity): Observable<CustomerEntity[]> {
    return this.httpClient.post<CustomerEntity[]>(
      AcmURLConstants.GET_CUSTOMERS,
      customerEntity
    );
  }

  getCustomerById(id: number): Observable<CustomerEntity> {
    return this.httpClient.get<CustomerEntity>(
      environment.apiUrl + 'credit-service/customers/' + id
    );
  }

  /** Not Used */
  VerifyOTP(codeOtp: string): Observable<string> {
    return this.httpClient.get<string>(
      environment.apiUrl + 'credit-service/customers/verify-otp/' + codeOtp
    );
  }

  /** Not Used */
  getKYC(kycEntity: KycEntity): Observable<CustomerEntity> {
    return this.httpClient.post<CustomerEntity>(
      environment.apiUrl + 'credit-service/customers/get-KYC/',
      kycEntity
    );
  }

  addCustomer(customerEntity: CustomerEntity): Observable<CustomerEntity> {
    return this.httpClient.post<CustomerEntity>(
      environment.apiUrl + 'credit-service/customers/create',
      customerEntity
    );
  }

  // ADD CUSTOMER & LOAN & USER API (Still in TESTING) ==> /** Not Used */
  ibRegistration(ibRegistration: IbRegistration): Observable<IbRegistration> {
    return this.httpClient.post<IbRegistration>(
      environment.apiUrl + 'credit-service/customers/ib-registration',
      ibRegistration
    );
  }

  checkExistCustomer(customerEntity: CustomerEntity): Observable<ResponseCheckEntity> {
    return this.httpClient.post<ResponseCheckEntity>(environment.apiUrl + 'credit-service/customers/check-exist-customer', customerEntity);
  }

  updateCustomer(customerDTO: CustomerEntity): Observable<CustomerEntity> {
    return this.httpClient.put<CustomerEntity>(environment.apiUrl +'credit-service/customers/update', customerDTO);
  }

  getCustomerByIdentity(identity: string): Observable<CustomerEntity> {
    return this.httpClient.post<CustomerEntity>(environment.apiUrl + 'credit-service/customers/getCustomerByIdentity',identity );
  }
}
