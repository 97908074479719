export class SettingDataStorageIbEntity {
  /** The id. */
  id: number;
  /** The categorie. REJET / REVIEW / AUTRE */
  category: string;
  /** The code. */
  code: string;
  /** The libelle. */
  libelle: string;
  /** The Arabic libelle */
  arabicLibelle : string;
  /** The description. */
  description: string;
  /** The code external from abacus. */
  codeExternal: number;
  /** The enable setting. */
  enabled: boolean;

  constructor() {
    this.category = '';
    this.code = '';
    this.libelle = '';
    this.description = '';
  }
}
