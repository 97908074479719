import { trigger, transition, style, query, animate, group, animateChild } from '@angular/animations';

export const routeAnimations = trigger('routeAnimations', [
  transition('* <=> *', [
    style({ position: 'relative' }),
    query(':enter, :leave', [
      style({
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
      }),
    ]),
    query(':enter', [
      style({ transform: 'translateY(20%)', opacity: 0 }),
    ]),
    query(':leave', animateChild(), { optional: true }),
    group([
      query(':leave', [
        animate('300ms ease-in', style({ transform: 'translateY(20%)', opacity: 0 })),
      ], { optional: true }),
      query(':enter', [
        animate('300ms ease-in', style({ transform: 'translateY(0)', opacity: 1 })),
      ]),
    ]),
    query(':enter', animateChild()),
  ]),
]);
