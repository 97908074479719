import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-change-language',
  templateUrl: './change-language.component.html',
  styleUrls: ['./change-language.component.scss'],
})
export class ChangeLanguageComponent {
  changedLang: string;
  constructor(public translate: TranslateService) {}

  ngOnInit() {
    this.changedLang = localStorage.getItem('lang');
    if (this.changedLang === 'en') {
      this.selectLang('en');
    } else {
      this.selectLang('ar');
    }
  }

  langEn() {
    this.translate.use('en');
    localStorage.setItem('lang', 'en');
    AppComponent.direction = 'ltr';
  }

  langAr() {
    this.translate.use('ar');
    localStorage.setItem('lang', 'ar');
    AppComponent.direction = 'rtl';
  }

  selectLang(lang: string) {
    if (lang === 'en') {
      this.changedLang = 'en';
    } else if (lang === 'ar') {
      this.changedLang = 'ar';
    }
  }

  save() {
    if (this.changedLang === 'ar') {
      this.langAr();
    } else if (this.changedLang === 'en') {
      this.langEn();
    }
  }
}
