<div id="documents" class="bg-2 vh-100 vw-100 px-3">
  <app-screen-title [title]="'BUTTONS.documents'" [color]="'color1'" [backTo]="'/loan-schedule-details'"></app-screen-title>

  <div id="documents-cards" class="mt-4"  *ngFor="let document of costumerDocuments">
    <div class="card cu-card p-2 py-3 mb-3" (click)="view(document)">
      <div class="row">
        <div class="col-2">
          <div>
            <img class="ib-icons  m-1" src="assets/images/icons/sheets.png" alt="sheets">
          </div>
        </div>
        <div class="col-8 p-1">
             {{'LABELS.'+document.settingDocumentTypeDTO.code|translate}}
        </div>
        <div class="col-2">
          <img class="ib-icons m-1"  src="assets/images/icons/eye.png" alt="eye">
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="showContract" class="fullscreen" style="padding-top: 70px; padding-bottom: 80px;">
    <pdf-viewer [src]="contractSrc" [show-all]="true" style="width: 90%; zoom: 50%;height: 100%;box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px; ">
    </pdf-viewer>
    <div class="confirmation">

      <button class="btn col-12 white" style="background-color: #213592;" (click)="closeContract()">{{'BUTTON.close' | translate}}</button>

  </div>
</div>

</div>
