import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SettingClaimsService } from 'src/app/ib-services/claims.service';
import { SharedService } from 'src/app/ib-services/shared.service';
import { ClaimNoteEntity } from 'src/app/shared/Entities/ClaimNote.entity';
import { ClaimsEntity } from 'src/app/shared/Entities/claims.entity';
import { SettingClaimsEntity } from 'src/app/shared/Entities/setting-claims.entity';

@Component({
  selector: 'app-view-claim',
  templateUrl: './view-claim.component.html',
  styleUrls: ['./view-claim.component.scss'],
})

export class ViewClaimComponent implements OnInit {
  public claim: ClaimsEntity;
  public claimSubject : SettingClaimsEntity;
  public noteForm: FormGroup;
  public lang : string;
  public claimNotes: ClaimNoteEntity[] = [];
  public submittedForm : boolean = false;

  constructor(
    private route: ActivatedRoute,
    private claimService: SettingClaimsService,
    private sharedService: SharedService,
    private fb: FormBuilder,
    private translate : TranslateService
  ) {}

  ngOnInit() {
    this.lang = localStorage.getItem('lang');
    this.claim = this.sharedService.getClaim();
    this.claimSubject = this.sharedService.getSubject();
    this.noteForm = this.fb.group({
      comments: ['', Validators.required],
    });
    this.fetchListNotes();
  }

  fetchListNotes() {
    const claimNote: ClaimNoteEntity = new ClaimNoteEntity();
    claimNote.claimId = this.claim.id;
    this.claimService.getNotesByClaimId(claimNote).subscribe((data) => {
      this.claimNotes = data;
    });
  }

  addNote() {
    this.submittedForm = true;
    if (this.noteForm.valid) {
      const formData = this.noteForm.controls['comments'].value;
      const claimNoteEntity: ClaimNoteEntity = new ClaimNoteEntity();
      claimNoteEntity.comment = formData;
      claimNoteEntity.visibility = 'public';
      claimNoteEntity.claimId = this.claim.id;
      claimNoteEntity.insertBy = this.sharedService.getUser().fullName;
      this.claimService.createClaimNote(claimNoteEntity).subscribe((res) => {
        this.sharedService.showSuccess(this.translate.instant('TOAST.added_note'));
        this.noteForm.reset();
        this.submittedForm = false;
        this.fetchListNotes();
      });
    }else {
      this.sharedService.showError(this.translate.instant('TOAST.note_blank'));
    }
  }

  calculateTimeDifference(date : any){
    const currentDate = new Date();
    const creationDate: Date = new Date(date);
    const timeDifference = currentDate.getTime() - creationDate.getTime();

    const secondsDifference = Math.floor(timeDifference / 1000);
    const minutesDifference = Math.floor(secondsDifference / 60);
    const hoursDifference = Math.floor(minutesDifference / 60);
    const daysDifference = Math.floor(hoursDifference / 24);

    if (secondsDifference < 60) {
      if (this.lang =='ar') {
        return ` منذ ${secondsDifference} ${this.translate.instant("TIME_DIFFERENCE.seconds_ago")}`;
      } else  return `${secondsDifference} ${this.translate.instant("TIME_DIFFERENCE.seconds_ago")} ago`;
    } else if (minutesDifference < 60) {
      if (this.lang =='ar') {
        return ` منذ ${minutesDifference} ${minutesDifference > 10 ? this.translate.instant("TIME_DIFFERENCE.minute") : this.translate.instant("TIME_DIFFERENCE.minutes_ago")}`;
      } else  return `${minutesDifference} ${minutesDifference === 1 ? this.translate.instant("TIME_DIFFERENCE.minute") : this.translate.instant("TIME_DIFFERENCE.minutes_ago")} ago`;
    } else if (hoursDifference < 24) {
      if (this.lang =='ar') {
        return ` منذ ${hoursDifference} ${hoursDifference === 1 || hoursDifference > 10 ? this.translate.instant("TIME_DIFFERENCE.hour") : this.translate.instant("TIME_DIFFERENCE.hours")}`;
      } else  return `${hoursDifference} ${hoursDifference === 1 ? this.translate.instant("TIME_DIFFERENCE.hour") : this.translate.instant("TIME_DIFFERENCE.hours")} ago`;
    } else {
      if (this.lang =='ar') {
        return ` منذ ${daysDifference} ${daysDifference > 10 ? this.translate.instant("TIME_DIFFERENCE.day") : this.translate.instant("TIME_DIFFERENCE.days")}`;
      } else  return `${daysDifference} ${daysDifference === 1 ? this.translate.instant("TIME_DIFFERENCE.day") : this.translate.instant("TIME_DIFFERENCE.days")} ago`;
    }
  }

}
