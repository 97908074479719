
<div id="change-language" class="bg-2 vh-100 vw-100">
    <app-screen-title [title]="'TITLE.change_language'" [color]="'color1'" [backTo]="'/settings'"></app-screen-title>
    <div class="mb-4 mt-4 text-center px-2">
      <label class="label-info ms-2 fw-650 mt-2 mb-4 fsl">{{'LABELS.choose_language' | translate}}</label>
      <div class=" pb-4 mt-4">
        <div class="row">
          <div class="col-6">
            <div class="text-center">
              <button style="font-size: medium !important;"  type="button" class="btn btn-primary btn-lg mb-2  py-2 fw-650" [ngClass]="{'white-btn': changedLang == 'en'}" (click)="selectLang('ar')" >
                العربي
              </button>
            </div>
          </div>
          <div class="col-6">
            <div class="text-center">
              <button style="font-size: medium !important;" type="button"  class="btn btn-primary btn-lg mb-2  py-2 fw-650" [ngClass]="{'white-btn': changedLang == 'ar'}" (click)="selectLang('en')">
                English
              </button>
            </div>
          </div>

        </div>
      </div>
      <div class="text-center pt-4 mt-auto">
        <button type="button" class="btn btn-primary btn-lg mb-2 login-btn" (click)="save()">
          {{'BUTTONS.save' | translate}}
        </button>
      </div>
</div>
