<div class="bg-2">
  <div class="bg-1" style="border-radius: 0px 0px 25px 25px;padding-bottom: 73px;">
    <div class="px-3">
      <app-screen-title [title]="'TITLE.loan_application'" [color]="'white'" [backTo]="'/home'"></app-screen-title>
      <!-- Stepper -->
      <app-new-stepper [type]="'information-entry'" [currentStep]="currentStep" ></app-new-stepper>
    </div>

  </div>


  <div id="all-client-information" class="pt-4">
    <div class="">
      <div class="card-body" style="overflow-y: auto;overflow-x: hidden;padding:unset !important">
        <div #start id="start"></div>
        <!-- Edit Personal Information -->
        <div id="edit-personal-information" *ngIf="!this.openEditIncomeDetails && !this.openEditExpensensAndObligations" >
          <app-edit-personal-information [inputMode]="'mode1'" (messageEvent)="personalInfoResponse($event)"></app-edit-personal-information>
        </div>

        <!-- Edit Income Details -->
        <div id="edit-income-details" *ngIf="this.openEditIncomeDetails && !this.openEditExpensensAndObligations">
          <app-edit-income-details [inputMode]="'mode1'" (messageEvent)="incomeDetailsResponse($event)"></app-edit-income-details>
        </div>

        <!-- Edit Expenses & Obligations -->
        <div id="edit-expenses-obligations" *ngIf="!this.openEditIncomeDetails && this.openEditExpensensAndObligations">
          <app-edit-expenses-obligations [inputMode]="'mode1'" (messageEvent)="expensesDetailsResponse($event)"></app-edit-expenses-obligations>
        </div>

      </div>
    </div>
  </div>
</div>
