import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import { SettingClaimsEntity } from '../shared/Entities/setting-claims.entity';
import { AcmURLConstants } from '../shared/mb-constants/mb-url-constants';
import { ClaimsEntity } from '../shared/Entities/claims.entity';
import { ProductLoanReasonEntity } from '../shared/Entities/productLoanReason.entity';
import { LoanSourceOfFundsEntity } from '../shared/Entities/loanSourceOfFunds.entity';
import { ProductEntity } from '../shared/Entities/product.entity copy';
import { ClaimNoteEntity } from '../shared/Entities/ClaimNote.entity';

@Injectable({
    providedIn: 'root'
})
export class SettingClaimsService {
    /**
     *
     * @param httpClient HttpClient
     */
    constructor(private httpClient: HttpClient) {
    }

    /**
     * List of claims
     */
    findsettingClaimsByCategory(settingClaimsEntity: SettingClaimsEntity ): Observable<SettingClaimsEntity[]> {
        return this.httpClient.post<SettingClaimsEntity[]>(AcmURLConstants.FIND_CLAIMS_SETTING, settingClaimsEntity);
    }

    saveClaims(claims : ClaimsEntity){
        return this.httpClient.post<ClaimsEntity>(AcmURLConstants.SAVE_CLAIMS, claims);
    }
    findClaims(claims : ClaimsEntity) : Observable<ClaimsEntity[]>{
        return this.httpClient.post<ClaimsEntity[]>(AcmURLConstants.FIND_CLAIMS, claims);
    }

    getProductLoanReason(): Observable<ProductLoanReasonEntity[]> {
        return this.httpClient.get<ProductLoanReasonEntity[]>(AcmURLConstants.LOAD_PRODUCT_LOAN_REASON);
    }
    getLoanSourceOfFunds(): Observable<LoanSourceOfFundsEntity[]> {
        return this.httpClient.get<LoanSourceOfFundsEntity[]>(AcmURLConstants.LOAD_SOURCE_OF_FUNDS);
    }
    getProducts(productEntity: ProductEntity): Observable<ProductEntity[]> {
        return this.httpClient.post<ProductEntity[]>(AcmURLConstants.PRODUCT_DETAILS, productEntity);
    }
       /**
       * Get Notes By Claim Id
       */
    getNotesByClaimId(ClaimNoteEntity : ClaimNoteEntity):Observable<ClaimNoteEntity[]>{
        return this.httpClient.post<ClaimNoteEntity[]>(AcmURLConstants.GET_CLAIM_NOTE,ClaimNoteEntity);
    }
     /**
      * Create Claim note
      */
     createClaimNote(ClaimNoteEntity : ClaimNoteEntity):Observable<ClaimNoteEntity> {
        return this.httpClient.post<ClaimNoteEntity>(AcmURLConstants.CREATE_CLAIM_NOTE,ClaimNoteEntity);
      }
}
