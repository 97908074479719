import { Injectable } from '@angular/core';
import * as SockJS from 'sockjs-client';
import { environment } from 'src/environments/environment';
import * as Stomp from 'stompjs';
import { AuthentificationService } from './authentification.service';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {
  public wsUrl = environment.webSocketEndPoint;
  public socket : any;
  sessionId;

  public stompClient: any;

  constructor() {
    this.socket = new SockJS(this.wsUrl);
    this.stompClient = Stomp.over(this.socket);
        console.log('withCredentials:', this.stompClient.withCredentials);
    this.connect(() => {
      console.log('StompClient connected:', this.stompClient.connected);
    });
  }

  private connect(callback: () => void): void {
    this.stompClient.connect({}, () => {
      callback();
    });
  }

  advance(topic: string, callback: any): void {
    if (this.stompClient.connected) {
      this.subscribeToTopic(topic, callback);
    } else {
      this.connect(() => {
        this.subscribeToTopic(topic, callback);
      });
    }
  }

  private subscribeToTopic(topic: string, callback: any): void {
    this.stompClient.subscribe(topic, () => {
      callback();
    });
  }
}
