<div class="bg-2 vh-100">

  <!-- My Applies Menu -->
  <div id="request_status">
    <app-screen-title [title]="'FOOTER_MENU.loan_status'" [color]="'color1'" [backTo]="'/home'"></app-screen-title>
  </div>
  <div class="p-2">
    <!-- Pending -->
    <div *ngIf="status==='pending'" class="main-div">
      <div class="icon-circle" style="background-color: #fdf0dc;">
        <i class='bx bx-info-circle'></i>
      </div>
      <div class="mt-4">
        <span class="fw-600"> {{'FOOTER_MENU.loan_status' | translate}} </span>
        <div class="mt-2 pending">{{'STATUSES.pending' | translate}}</div>
      </div>
      <div class="mt-4">
        <span class="fw-600">{{'request_number' | translate}} </span>
        <input type="text" class="form-control mt-2" value={{loan.accountNumber}} readonly>
      </div>
    </div>
    <!-- success  -->
    <div *ngIf="status==='success'" class="main-div" style="min-height: 75vh;">
      <div class="icon-circle" style="background-color: #F5F5F5;">
        <i class='bx bxs-check-circle'></i>
      </div>
      <div class="mt-4">
        <span class="fw-600"> {{'FOOTER_MENU.loan_status' | translate}} </span>
        <div class="mt-2 success">{{'STATUSES.accepted' | translate}}</div>
      </div>
      <div class="mt-4">
        <span class="fw-600">{{'request_number' | translate}} </span>
        <input type="text" class="form-control mt-2" value={{loan.accountNumber}} readonly>
      </div>
      <div class="fixed-bottom px-2 mb-2">
      <button type="button" class="btn btn-primary btn-lg mb-2 login-btn mt-4" style="margin-top: auto;"
        (click)="goToCustomerDecision()">
        {{'BUTTONS.continue' | translate}}
      </button>
    </div>
  </div>
    <!-- refused -->
    <div *ngIf="status==='refused'" class="main-div" style="min-height: 75vh;">
      <div class="icon-circle" style="background-color: #FFD2D2;">
        <i class='bx bx-x-circle'></i>
      </div>
      <div class="mt-4">
        <span class="fw-600"> {{'FOOTER_MENU.loan_status' | translate}} </span>
        <div class="mt-2 refused">{{'STATUSES.refused' | translate}}</div>
      </div>
      <div>
        <div class="mt-4">
          <span class="fw-600">{{'request_number' | translate}} </span>
          <input type="text" class="form-control mt-2" value={{loan.accountNumber}} readonly>
        </div>
        <div class="mt-4">
          <span class="fw-600">{{'reject_reason' | translate}} </span>
          <textarea rows="3" type="text" class="form-control mt-2" style="background-color: #fff; resize: none;"
            readonly> {{'LABELS.reject_reason' | translate}} </textarea>
        </div>
      </div>

      <div class="text-center p-2 mb-2">
        <button type="button" class="btn btn-secondary btn-lg login-btn" (click)="this.showPopupEvaluation = true" style="border-radius: 5px;">
          <label class="p-2">{{ 'BUTTONS.evaluation' | translate }}</label>
        </button>
      </div>
    </div>

  </div>

</div>

<!-- EVALUATION POPUP  -->
<app-popup *ngIf="showPopupEvaluation" [source]="'EVALUATION'" ></app-popup>
