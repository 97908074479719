export const environment = {
  authentificationUserName: 'ibclient',
  authentificationPassword: 'appclient@123',
  oauthUrl: 'https://sanaduat.sanadfinance.com/AuthServ/',
  apiUrl: 'https://sanaduat.sanadfinance.com/ApiServ/',
  urlMockThirdParty:'https://sanaduat.sanadfinance.com/MockServ/',
  urlForCapacitor: 'https://sanaduat.sanadfinance.com',
  webSocketEndPoint: 'https://sanaduat.sanadfinance.com/AuthServ/ib-websocket',
  production: false
};
