<!-- Edit Personal Information -->
<div id="edi_personal_information" class="bg-2 px-2">
  <app-screen-title *ngIf="inputMode != 'mode1'  && mode !=='update'" [title]="'TITLE.personal_information'"
    [color]="'color1'" [backTo]="'/personal-information'"></app-screen-title>
  <app-screen-title *ngIf="mode ==='update'" [title]="'TITLE.personal_information'" [color]="'color1'"
    [backTo]="'/summary-initial-approval'"></app-screen-title>
  <div id="edit-personal-information" class="px-2">
    <legend *ngIf="inputMode == 'mode1'" class="fsl color1 fw-600 fls">{{'TITLE.personal_information'|translate}}
    </legend>
    <div id="personal-information-form" class="mt-4">
      <div class="mb-2" *ngIf="mode !=='update-customer'">
        <label class="form-label ms-2 ">{{'LABELS.purpose_of_financing' | translate}}</label>
        <div style="position: relative;">
          <i class='bx bxs-chevron-down m-2 p-2'
            [ngClass]="{'right': lang === 'en' , 'left': lang === 'ar' || lang == null}"></i>
          <select class="form-control select-group" id="loanPurpose" [(ngModel)]="loanPurpose"
            (focus)="loanPurposeError=false;showLoanInfoAlert=false;">
            <ng-container *ngFor="let lp of loanPurposes">
              <option [ngValue]="lp" *ngIf="lang != 'ar'">{{lp.libelle}}</option>
              <option [ngValue]="lp" *ngIf="lang === 'ar'">{{lp.arabicLibelle}}</option>
            </ng-container>
          </select>
        </div>

      </div>
      <div class="mb-2">
        <label class="form-label ms-2 ">{{'LABELS.marital_status' | translate}}</label>
        <div style="position: relative;">
          <i class='bx bxs-chevron-down m-2 p-2'
            [ngClass]="{'right': lang === 'en' , 'left': lang === 'ar' || lang == null}"></i>
          <select class="form-control select-group" id="materialStatus" [(ngModel)]="materialStatus"
            (focus)="materialStatusError=false;showLoanInfoAlert=false;">
            <ng-container *ngFor="let ms of materialStatuses">
              <option [ngValue]="ms" *ngIf="lang != 'ar'">{{ms.libelle}}</option>
              <option [ngValue]="ms" *ngIf="lang === 'ar'">{{ms.arabicLibelle}}</option>
            </ng-container>
          </select>
        </div>

      </div>
      <div class="mb-2">
        <label class="form-label ms-2">{{'OTHER.first_responsible_qt' | translate}} </label>
        <div style="position: relative;">
          <div class="radio-container">
            <input class="m-1" type="radio" id="radio1" name="options1" [(ngModel)]="isResponsible" [value]="true">
            <label for="radio1" class="form-label">{{'LABELS.yes' | translate}}</label>
            <input class="m-1" type="radio" id="radio2" name="options1" [(ngModel)]="isResponsible" [value]="false">
            <label for="radio2" class="form-label">{{'LABELS.no' | translate}}</label>
          </div>
        </div>
      </div>
      <div class="mb-2" *ngIf="isResponsible===true">
        <div class="fsb">
          <label class="form-label ms-2 ">{{'LABELS.nb_family' | translate}}</label>
          <label class="form-label ms-2  text-secondary">({{'LABELS.including_you' | translate}})</label>
        </div>
        <div style="position: relative;">
          <input type="tel" [ngClass]="{'rtl-input': lang =='ar'}" class="form-control" placeholder="{{'PLACEHOLDER.enter_nb_family' | translate}}"
            [(ngModel)]="nbFamilyMembers" (focus)="nbFamilyMembersError=false;showLoanInfoAlert=false;">
          <div class="input-group-append m-1 px-3" style="position: absolute;
          top: -1px;
          display: flex;
          flex-direction: column;"
            [ngClass]="{'right border-left': lang === 'en' , 'left border-right': lang === 'ar' || lang == null}">
            <span (click)="increment()">+</span>
            <span (click)="decrement()">-</span>
          </div>
        </div>
      </div>
      <div class="mb-2">
        <label class="form-label ms-2 ">{{ 'LABELS.residencial_status' | translate }}</label>
        <div style="position: relative;">
          <i class='bx bxs-chevron-down m-2 p-2'
            [ngClass]="{'right': lang === 'en' , 'left': lang === 'ar' || lang == null}"></i>
          <select class="form-control" [(ngModel)]="residentialStatus">
            <ng-container *ngFor="let rs of residentialStatusList">
              <option [ngValue]="rs" *ngIf="lang != 'ar'">{{rs.libelle}}</option>
              <option [ngValue]="rs" *ngIf="lang === 'ar'">{{rs.arabicLibelle}}</option>
            </ng-container>
          </select>
        </div>
      </div>
      <div class="mb-2">
        <label class="form-label ms-2 ">{{'LABELS.accomodation_type' | translate}}</label>
        <div style="position: relative;">
          <i class='bx bxs-chevron-down m-2 p-2'
            [ngClass]="{'right': lang === 'en' , 'left': lang === 'ar' || lang == null}"></i>
          <select class="form-control select-group" id="housingType" [(ngModel)]="housingType"
            (focus)="housingTypeError=false;showLoanInfoAlert=false;">
            <ng-container *ngFor="let th of typesHousing">
              <option [ngValue]="th" *ngIf="lang != 'ar'">{{th.libelle}}</option>
              <option [ngValue]="th" *ngIf="lang === 'ar'">{{th.arabicLibelle}}</option>
            </ng-container>
          </select>
        </div>
      </div>


      <div id="edit-work-information" *ngIf="mode !=='update-customer'&& mode!=='update'">

        <div class="mb-2">
          <label class="form-label ms-2 ">{{ 'LABELS.labor_sector' | translate}}</label>
          <div style="position: relative;">
            <i class='bx bxs-chevron-down m-2 p-2'
              [ngClass]="{'right': lang === 'en' , 'left': lang === 'ar' || lang == null}"></i>
            <select class="form-control" [(ngModel)]="workSection">
              <option value="null" selected disabled hidden>{{'OTHER.select_option' | translate}}</option>
              <ng-container *ngFor="let ws of workSectionList">
                <option [ngValue]="ws" *ngIf="lang === 'ar'">{{ ws.arabicLibelle }}</option>
                <option [ngValue]="ws" *ngIf="lang != 'ar'">{{ ws.libelle }}</option>
              </ng-container>
            </select>
          </div>
        </div>
        <div class="mb-2">
          <label class="form-label ms-2 ">{{'LABELS.employer_name'|translate}}</label>
          <div style="position: relative;">
            <input type="text" class="form-control" placeholder="" [(ngModel)]="employerName">
          </div>
        </div>
        <div class="mb-2">
          <label class="form-label ms-2 "> {{'LABELS.date_joining'|translate}}</label>
          <div style="position: relative;">
            <img class="ib-icons m-2 pt-2" src="assets/images/icons/calendar-icon.png" alt="calendar-icon"
              style="position: absolute;">
            <input type="date" class="form-control" placeholder="" [(ngModel)]="dateJoining">
          </div>
        </div>
        <div class="mb-2">
          <label class="form-label ms-2 "> {{'QUESTIONS.military_sector' | translate}} </label>
          <div style="position: relative;">
            <div class="radio-container">
              <input class="m-1" type="radio" id="radio3" name="options2" [value]="true" [(ngModel)]="militarySector">
              <label for="radio3" class="form-label">{{'LABELS.yes' | translate}}</label>
              <input class="m-1" type="radio" id="radio4" name="options2" [value]="false" [(ngModel)]="militarySector">
              <label for="radio4" class="form-label">{{'LABELS.no' | translate}}</label>
            </div>
          </div>
        </div>
        <div class="mb-2" *ngIf="militarySector===true">
          <label class="form-label ms-2"> {{'LABELS.military_rank'|translate}} </label>
          <div style="position: relative;">
            <i class='bx bxs-chevron-down m-2 p-2'
              [ngClass]="{'right': lang === 'en' , 'left': lang === 'ar' || lang == null}"></i>
            <select class="form-control" [(ngModel)]="militaryRank">
              <ng-container *ngFor="let mr of militaryRankList">
                <option [ngValue]="mr" *ngIf="lang === 'ar'">{{ mr.arabicLibelle }}</option>
                <option [ngValue]="mr" *ngIf="lang != 'ar'">{{ mr.libelle }}</option>
              </ng-container>
            </select>
          </div>
        </div>
      </div>

      <div class="mb-2">
        <label class="form-label ms-2 "> {{ 'QUESTIONS.a_political_figure' | translate }} </label>
        <div style="position: relative;">
          <div class="radio-container">
            <input class="m-1" type="radio" id="radio5" name="option3" [value]="true" [(ngModel)]="politicalFigure">
            <label for="radio5" class="form-label">{{'LABELS.yes' | translate}}</label>
            <input class="m-1" type="radio" id="radio6" name="option3" [value]="false" [(ngModel)]="politicalFigure">
            <label for="radio6" class="form-label">{{'LABELS.no' | translate}}</label>
          </div>
        </div>
      </div>
      <div class="mb-2" *ngIf="mode !=='update-customer'">
        <label class="form-label ms-2 ">{{ 'OTHER.invitation_code' | translate }}</label>
        <div style="position: relative;">
          <input type="text" class="form-control" placeholder="" [(ngModel)]="invitationCode">
        </div>
      </div>

    </div>
    <div class="text-center mt-4">
      <button type="button" class="btn btn-primary btn-lg mb-2 login-btn" (click)="submit()"
        *ngIf="mode ==='update-customer'">
        {{'BUTTONS.update_details' | translate}}
      </button>
      <button type="button" class="btn btn-primary btn-lg mb-2 login-btn" (click)="submit()"
        *ngIf="mode !=='update-customer'">
        {{'BUTTONS.continue' | translate}}
      </button>
    </div>
  </div>
</div>
