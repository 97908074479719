import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { AuthentificationService } from 'src/app/ib-services/authentification.service';
import { SharedService } from 'src/app/ib-services/shared.service';
import { UserService } from 'src/app/ib-services/user.service';
import { CustomerEntity } from 'src/app/shared/Entities/customer.entity';
import { UserEntity } from 'src/app/shared/Entities/user.entity';
import { listMotifDesciptions, listMotifs } from 'src/app/shared/mb-constants/ib_constants';

@Component({
  selector: 'app-delete-account',
  templateUrl: './delete-account.component.html',
  styleUrls: ['./delete-account.component.scss']
})
export class DeleteAccountComponent implements OnInit {

  public lang: string;
  public selectedSubject: any;
  public deleteAccountForm: FormGroup;
  public submittedForm : boolean = false;
  public listSubjectMotifs : string[] = [];
  public descriptionMotifs : string[] = [];
  public description : string;
  public customer : CustomerEntity;
  public user : UserEntity;

  public showProgressDeletion : boolean = false;


  constructor(public sharedService : SharedService,private userService : UserService,
    private authService : AuthentificationService,private translate : TranslateService) { }

  ngOnInit() {
    this.customer = this.sharedService.getCustomer();
    this.user = this.sharedService.getUser();
    this.listSubjectMotifs = listMotifs;
    this.descriptionMotifs = listMotifDesciptions;
    this.lang = localStorage.getItem('lang');
  }

  selectDescription(str : string){
    this.listSubjectMotifs.forEach((res,index) => {
      if (res === str) {
        this.description = this.descriptionMotifs[index];
        return;
      }
    })
  }

  launchDeleteAccountProcess(){
    if(this.selectedSubject){
      this.customer.motifDeleteAccount = this.selectedSubject;
      this.userService.deleteAccount(this.customer).subscribe(res => {
        if (res.message === "ACCEPTED"){
          setTimeout(() => {
            this.sharedService.showSuccess(this.translate.instant('TOAST.user_deleted'));
          }, 5000);
          this.showProgressDeletion = true;
          setTimeout(() => {
            this.authService.logout();
          }, 6000);
        }else {
          this.sharedService.showInfo(this.translate.instant('TOAST.user_deletion_rejected'));
        }
      });
    }else {
      this.sharedService.showInfo(this.translate.instant('TOAST.select_deletion_reason'));
    }
  }

}
