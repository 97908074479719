import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppComponent } from 'src/app/app.component';
import { AuthentificationService } from 'src/app/ib-services/authentification.service';
import { SharedService } from 'src/app/ib-services/shared.service';
import { CustomerEntity } from 'src/app/shared/Entities/customer.entity';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  public showLang = false;
  public customer: CustomerEntity;
  public lang : string;

  messageError : string = '';
  messageSuccess : string = '';

  constructor(public translate: TranslateService, public sharedService: SharedService,public authService: AuthentificationService) {}

  ngOnInit() {
    this.lang = this.sharedService.getLang();
    this.customer = this.sharedService.getCustomer();
  }

  langEn() {
    this.translate.use('en');
    localStorage.setItem('lang', 'en');
    this.sharedService.setLang('en');
    AppComponent.direction = 'ltr';
  }

  langAr() {
    this.translate.use('ar');
    localStorage.setItem('lang', 'ar');
    this.sharedService.setLang('ar');
    AppComponent.direction = 'rtl';
  }

  showLangSelection() {
    this.showLang = !this.showLang;
  }

  logout() {
    this.authService.logout();
  }

  resetFirstLauncherForTesting(){
    this.sharedService.removeFirstLaunchFlag();
  }

}
