<div id="Biometric_Settings" class="bg-2 vh-100 vw-100">
  <app-screen-title [title]="'TITLE.biometrics_auth'" [color]="'color1'" [backTo]="'/settings'"></app-screen-title>
  <div *ngIf="!showBiomConfiguration">
    <div id="reset-pw-div" class="px-5">
      <div class="form-group mt-4 mb-3 py-4">
        <div class="wrapper">
          <div class="flc">
            <div class="ms-2  mb-4 flc inline-flex align-items-center">
              <img class="img-logo-sanad" src="assets/images/biometrics.png" alt="biometrics">
            </div>
          </div>
        </div>
        <label class="text-secondary text-center flc" *ngIf="!biometricsStatus"> {{ 'INFO.activate_Bio' | translate }}
        </label>
        <label class="text-secondary text-center flc" *ngIf="biometricsStatus"> {{ 'INFO.desavtivate_Bio' | translate }}
        </label>
      </div>
    </div>
    <div class="text-center px-2 py-4 mb-2 fixed-bottom">
      <button type="button" class="btn btn-primary btn-lg mb-2 login-btn" *ngIf="!biometricsStatus"
        (click)="activateBiometrics()">
        {{'BUTTONS.activate' | translate}}
      </button>
      <button type="button" class="btn btn-danger btn-lg mb-2 login-btn" *ngIf="biometricsStatus"
        (click)="disableBiometric()">
        {{'BUTTONS.desactivate' | translate}}
      </button>
    </div>
  </div>

  <div class="px-2" *ngIf="showBiomConfiguration">


    <form [formGroup]="biometricForm" (ngSubmit)="configureBiometrics()">
      <div class="mb-2">
        <label class="form-label ms-2">{{'LABELS.national_id_iqama' | translate}}</label>
        <div style="position: relative;">
          <img class="ib-icons m-2 pt-2" src="assets/images/icons/identity.png" alt="identity"
            style="position: absolute;">
            <input type="tel" class="form-control icon-input"
            placeholder="{{'PLACEHOLDER.enter_nid' | translate}}"
            formControlName="username" appDigitLimit>
        </div>
      </div>

      <div class="mb-1">
        <label class="form-label ms-2">{{'LABELS.password' | translate}}</label>
        <div style="position: relative;">
          <img class="ib-icons m-2 pt-2" src="assets/images/icons/lock.png" alt="lock" style="position: absolute;">
          <input [type]="showPassword ? 'text' : 'password'" class="form-control icon-input"
            [ngClass]="{'fc-error': submittedLogin && biometricForm.controls['password'].invalid || passwordError}"
            placeholder="{{'PLACEHOLDER.enter_pwd' | translate}}" formControlName="password">
        </div>
        <button class="btn" type="button" (click)="togglePasswordVisibility()"
          [ngClass]="isFloatRight ? 'float-right' : 'float-left'" style="position: relative; top: -40px;">
          <i class="bx" [ngClass]="showPassword ? 'bx-hide' : 'bx-show'" style="font-size: 20px !important;"></i>
        </button>
      </div>

      <div class="text-center">
        <button class="btn btn-primary btn-lg mb-2 login-btn" type="submit">
          {{'BUTTONS.save' | translate}}
        </button>
      </div>
    </form>


  </div>





</div>


<!-- Dialog Terms & Agreements-->
<div id="modal-dialog" class="modal_term" style="zoom:unset !important">
  <p-dialog [(visible)]="showModal" [breakpoints]="{'80vh': '90vw'}" [style]="styleDialog" [closable]="false"
    [modal]="true" >
    <div class="p-dialog-content">
      <h3 class="mb-4 mt-2 flc color1 fw-500">{{'LABELS.Configuration_done' | translate}}</h3>
      <div class="row px-2">
        <div class="col-12 py-1">
          <button class="btn-pill btn  btn-outline-primary color1 w-100" routerLink="/home">
             {{'BUTTONS.stay' | translate}}
          </button>
        </div>
        <div class="col-12 py-1">
          <button class="btn-pill btn  btn-outline-danger colordanger w-100" (click)="logout()">
             {{'BUTTONS.logout' | translate}}
          </button>
        </div>
      </div>
    </div>


  </p-dialog>
</div>
