import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CreditService } from 'src/app/ib-services/credit.service';
import { CustomerService } from 'src/app/ib-services/customer.service';
import { LoanService } from 'src/app/ib-services/loan.service';
import { SharedService } from 'src/app/ib-services/shared.service';
import { CustomerEntity } from 'src/app/shared/Entities/customer.entity';
import { IncomeDetailsEntity } from 'src/app/shared/Entities/incomeDetails.entity';
import { PathConstants } from 'src/app/shared/mb-constants/path-constants';

@Component({
  selector: 'app-edit-income-details',
  templateUrl: './edit-income-details.component.html',
  styleUrls: ['./edit-income-details.component.scss']
})
export class EditIncomeDetailsComponent implements OnInit {

  /** Personal Information Modes ==> default = Settings ; mode1 = Loan Registration */
  @Input() inputMode : string = '';
  @Output() messageEvent = new EventEmitter<any>();


  public basicSalaryError :boolean=false;
  public housingAllowanceError  :boolean=false;
  public monthlyAllowanceError :boolean=false;
  public extraIncomeError :boolean=false;

  public basicSalary : number = null;
  public housingAllowance : number = null;
  public monthlyAllowance : number = null;
  public extraIncome : number = null;

  public showLoanInfoAlert:boolean=false;
  public showIncomeDetailsAlert:boolean=false;
  public showExpensesAlert:boolean=false;

  public incomeDetailsInformation : any;
  public mode : string;
  public incomeDetails : IncomeDetailsEntity;
  public customer : CustomerEntity;
  public lang: string;

  constructor(private sharedService : SharedService,private loanService : LoanService,private router : Router,private translate : TranslateService,
              private route: ActivatedRoute,private customerService : CustomerService){}

  ngOnInit() {
    this.lang= localStorage.getItem('lang');
    if(!this.lang)this.sharedService.getLang();
    this.customer = this.sharedService.getCustomer();
    this.route.queryParams.subscribe(params => { this.mode = params['mode']});
      if (this.inputMode == 'mode1' && this.customer.otherInformations !== null && this.customer.otherInformations !== undefined) {
        const otherInformationString = this.customer.otherInformations;
        const otherInformationObject = JSON.parse(otherInformationString);
        if (otherInformationObject){
        this.incomeDetails = otherInformationObject.incomeDetails? otherInformationObject.incomeDetails : null;
        if (this.incomeDetails){
          this.basicSalary = this.incomeDetails.basicSalary;
          this.housingAllowance = this.incomeDetails.housingAllowance;
          this.monthlyAllowance = this.incomeDetails.monthlyAllowance;
          this.extraIncome = this.incomeDetails.extraIncome;
        }
        }
      }
      if (this.mode==='update'){
      this.incomeDetails = this.sharedService.getIncomeDetails();
      this.basicSalary = this.incomeDetails.basicSalary;
      this.housingAllowance = this.incomeDetails.housingAllowance;
      this.monthlyAllowance = this.incomeDetails.monthlyAllowance;
      this.extraIncome = this.incomeDetails.extraIncome;
      } else if (this.mode === 'update-customer') {
        this.incomeDetails = this.sharedService.getIncomeDetails();
        if (this.incomeDetails){
        this.basicSalary = this.incomeDetails.basicSalary;
        this.housingAllowance = this.incomeDetails.housingAllowance;
        this.monthlyAllowance = this.incomeDetails.monthlyAllowance;
        this.extraIncome = this.incomeDetails.extraIncome;
      }
    }
  }

  submit(){
      /** ACTION FOR LOAN REGISTRATION MOVING TO NEXT STEP */
      if (this.basicSalary === null || this.housingAllowance === null || this.monthlyAllowance === null){
        this.sharedService.showError(this.translate.instant('ERR.fill_all_form'));
        }else {
          this.incomeDetailsInformation = {
            basicSalary : this.basicSalary,
            housingAllowance : this.housingAllowance,
            monthlyAllowance : this.monthlyAllowance,
            extraIncome : this.extraIncome
          }
          if (this.inputMode == 'mode1'){
          this.messageEvent.emit(this.incomeDetailsInformation);
          }
          if (this.mode === 'update'){
            let loan = this.sharedService.getLoanDeal();
            let updatedOtherInfo = {
              personalInformation : this.sharedService.getPersonalInfo(),
              workInformation : this.sharedService.getWorkInfo(),
              incomeDetails : this.incomeDetailsInformation,
              expensesLiabilities : this.sharedService.getExpensesObligations(),
              bankInformation: this.sharedService.getBankInformation()
            }
            loan.otherInformations = JSON.stringify(updatedOtherInfo);
            this.customer.otherInformations = JSON.stringify(updatedOtherInfo);
            this.loanService.updateLoan(loan).toPromise().then((res) => {
              this.customerService.updateCustomer(this.customer).subscribe(data=>{
                this.sharedService.setCustomer(data);
              });
              this.sharedService.setLoanDeal(res);
              this.router.navigate([loan.stepPath]);
            })
          }
          else if (this.mode==='update-customer') {
            const customerAdditionalInformation = {
                personalInformation : this.sharedService.getPersonalInfo(),
                workInformation : this.sharedService.getWorkInfo(),
                incomeDetails :  this.incomeDetailsInformation,
                expensesLiabilities : this.sharedService.getExpensesObligations(),
                bankInformation: this.sharedService.getBankInformation()
               }

          this.customer.otherInformations = JSON.stringify(customerAdditionalInformation);
          this.customerService.updateCustomer(this.customer).subscribe(res=>{
            this.sharedService.setCustomer(res);
            this.router.navigate(['/' + PathConstants.PATH_PERSONAL_INFORMATION])
          });
         }
        }
  }

}
