import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CreditService } from 'src/app/ib-services/credit.service';
import { ParametrageService } from 'src/app/ib-services/parametrage.service';
import { SharedService } from 'src/app/ib-services/shared.service';
import { ThirdPartyService } from 'src/app/ib-services/third-party.service';
import { HpPaymentStatusRequestDTO } from 'src/app/shared/Entities/HpPaymentStatusRequestDTO.entity';

@Component({
  selector: 'app-payment-status',
  templateUrl: './payment-status.component.html',
  styleUrls: ['./payment-status.component.scss'],
})
export class PaymentStatusComponent implements OnInit {
  loanid = '';
  amount = '';
  checkoutId : string;
  resourcePath : string;
  responsePaymentStatus : any;
  transactionSuccess : boolean = false;
  transactionRejected : boolean = false;

  constructor(
    private route: ActivatedRoute,
    public sharedService: SharedService,
    public parametrageService: ParametrageService,
    public creditService: CreditService,
    public translate: TranslateService,
    public router: Router,
    private thirdPartyService : ThirdPartyService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.checkoutId = params['id'];
      this.resourcePath = params['resourcePath'];
      let hpPaymentStatusRequestDTO = new HpPaymentStatusRequestDTO();
      hpPaymentStatusRequestDTO.checkoutId = this.checkoutId;
      hpPaymentStatusRequestDTO.testMode = "EXTERNAL";
      let loanId = localStorage.getItem('loanId');
      let loanIdd = Number(loanId);
      this.thirdPartyService.getPaymentStatus(hpPaymentStatusRequestDTO,loanIdd).subscribe(res => {
        this.responsePaymentStatus = res;
        localStorage.removeItem('loanId');
        this.paymentTreatment();
      })
    });
  }

  paymentTreatment(){
    /**
     * Request successfully processed in 'Merchant in Connector Test Mode' : 000.100.112
     * Transaction succeeded : 000.000.000
     */
    switch (this.responsePaymentStatus.result.code){
      case "000.100.112":
        console.log("Request successfully processed in 'Merchant in Connector Test Mode");
        this.sharedService.showSuccess("Request successfully processed in 'Merchant in Connector Test Mode");
        this.transactionSuccess = true;
        break;
      case "000.000.000":
        console.log("Request successfully processed in 'Merchant in Connector Test Mode");
        this.sharedService.showSuccess("Transaction succeeded");
        this.transactionSuccess = true;
        break;
      default:
        console.log("Transaction Payment Rejected Or Declined Or Pending");
        this.sharedService.showError("Request successfully processed in 'Merchant in Connector Test Mode");
        this.transactionRejected = true;
        break;
    }
  }

}
