import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CreditService } from 'src/app/ib-services/credit.service';
import { LoanService } from 'src/app/ib-services/loan.service';
import { SharedService } from 'src/app/ib-services/shared.service';
import { ExpensesObligations } from 'src/app/shared/Entities/ExpensesObligations.entity';
import { IncomeDetailsEntity } from 'src/app/shared/Entities/incomeDetails.entity';
import { LoanEntityIB } from 'src/app/shared/Entities/loan.entity';
import { PersonalInformationEntity } from 'src/app/shared/Entities/personalInformation.entity';
import { PathConstants } from 'src/app/shared/mb-constants/path-constants';

@Component({
  selector: 'app-summary-init-approval',
  templateUrl: './summary-init-approval.component.html',
  styleUrls: ['./summary-init-approval.component.scss'],
})
export class SummaryInitApprovalComponent implements OnInit {
  public launchCheckApproval: boolean = false;
  public showInitialApproval: boolean = false;
  public showRejection: boolean = false;
  public showPending: boolean = false;
  public retourDakhli: number = 60;
  public loan: LoanEntityIB;
  public checker: boolean = false;
  public showButtonLater: boolean = false;
  public showPopup: boolean = false;
  public personalInfo: PersonalInformationEntity;
  public workInfo: any;
  public incomeDetails: IncomeDetailsEntity;
  public expensesObligations: ExpensesObligations;
  public bankName: string;
  public monthlyObligation: number;

  constructor(
    private router: Router,
    public sharedService: SharedService,
    private loanService: LoanService
  ) {}

  ngOnInit() {
    if (!this.sharedService.getUser()) {
      this.router.navigate(['login']);
    }
    this.loan = this.sharedService.getLoanDeal();
    const otherInformationString = this.loan.otherInformations;
    const otherInformationObject = JSON.parse(otherInformationString);

    const bankInformationString = this.loan.bankInformation;
    const bankInformationObject = bankInformationString
      ? JSON.parse(bankInformationString)
      : null;
    this.bankName = bankInformationObject.bankName;

    this.personalInfo = otherInformationObject.personalInformation;
    this.workInfo = otherInformationObject.workInformation;
    this.incomeDetails = otherInformationObject.incomeDetails;
    this.expensesObligations = otherInformationObject.expensesLiabilities;

    this.sharedService.setPersonalInfo(this.personalInfo);
    this.sharedService.setWorkInfo(this.workInfo);
    this.sharedService.setIncomeDetails(this.incomeDetails);
    this.sharedService.setExpensesObligations(this.expensesObligations);
    this.sharedService.setBankInformation(bankInformationObject);
    // Calculate the total monthly obligation using the new variables
    this.monthlyObligation =
      this.expensesObligations.monthlyInstallment +
      this.expensesObligations.foodExp +
      this.expensesObligations.educExp +
      this.expensesObligations.transportAndCommunicationExp +
      this.expensesObligations.domesticExp +
      this.expensesObligations.otherExp +
      this.expensesObligations.internationalExp +
      this.expensesObligations.healthCareExpenses +
      this.expensesObligations.insuranceExpenses +
      this.expensesObligations.housing;
  }

  confirm() {
    this.loan.actionToAcm = 'APPROVE';
    this.loanService.updateLoan(this.loan).subscribe((res) => {
      this.sharedService.setLoanDeal(res);
      this.showPopup = true;
    });
  }

  async synchronizeStepPath() {
    let loanChecker = new LoanEntityIB();
    this.loan = this.sharedService.getLoanDeal();
    loanChecker.idIbLoan = this.loan.idIbLoan;
    let currentIndex = 0;
    const syncApiRequest = async () => {
      if (currentIndex < 4) {
        try {
          const response = await this.loanService
            .getLoan(loanChecker)
            .toPromise();
          if (
            response[0].maxInstallment !== undefined &&
            response[0].maxInstallment !== null &&
            response[0].maxInstallment !== 0
          ) {
            this.sharedService.setLoanDeal(response[0]);
            this.router.navigate[response[0].stepPath];
          }

          if (currentIndex == 3) {
            if (!this.checker) {
              this.showButtonLater = true;
            }
          }
          currentIndex++;
        } catch (error) {
          console.error('API error:', error);
        }
      }
    };
    while (currentIndex < 4) {
      await syncApiRequest();
      await new Promise((resolve) => setTimeout(resolve, 3000));
    }
  }

  backnav() {
    window.history.back();
    return false;
  }

  nextStepRedirection() {
    let loanChecker = new LoanEntityIB();
    this.loan = this.sharedService.getLoanDeal();
    loanChecker.idIbLoan = this.loan.idIbLoan;
    this.loanService.getLoan(loanChecker).subscribe((loan) => {
      if (loan.stepPath === PathConstants.PATH_SUMMARY_INIT_APPROVAL) {
        this.router.navigate(['/' + PathConstants.PATH_HOME]);
      } else {
        this.router.navigate([loan.stepPath]);
      }
    });
  }

  edit(type: string) {
    const mode = 'update';
    switch (type) {
      case PathConstants.PATH_EDIT_PERSONAL_INFO:
        this.router.navigate(['/' + PathConstants.PATH_EDIT_PERSONAL_INFO], {
          queryParams: { mode: mode },
        });
        break;
      case PathConstants.PATH_EDIT_WORK_INFO:
        this.router.navigate(['/' + PathConstants.PATH_EDIT_WORK_INFO], {
          queryParams: { mode: mode },
        });
        break;
      case PathConstants.PATH_EDIT_INCOME_DETAILS:
        this.router.navigate(['/' + PathConstants.PATH_EDIT_INCOME_DETAILS], {
          queryParams: { mode: mode },
        });
        break;
      case PathConstants.PATH_EDIT_EXPENSES_OBLIGATIONS:
        this.router.navigate(
          ['/' + PathConstants.PATH_EDIT_EXPENSES_OBLIGATIONS],
          { queryParams: { mode: mode } }
        );
        break;
      case 'edit-iban':
        this.router.navigate(['/' + PathConstants.PATH_BANK_INFORMATION], {
          queryParams: { mode: mode },
        });
        break;
    }
  }
}
