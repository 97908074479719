import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoaderService } from 'src/app/ib-services/loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  static LoaderNumber = 0;
  public requests: HttpRequest<any>[] = [];

  constructor(public loaderService: LoaderService) {}

  removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
    this.loaderService.isLoading.next(this.requests.length > 0);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // ✅ Check if loader is disabled before enabling it
    if (this.loaderService.disableLoader.value) {
      return next.handle(req);
    }

    this.requests.push(req);
    this.loaderService.isLoading.next(true);
    LoaderInterceptor.LoaderNumber++;

    return new Observable(observer => {
      const subscription = next.handle(req).subscribe(
        event => {
          if (event instanceof HttpResponse) {
            this.removeRequest(req);
            observer.next(event);
            LoaderInterceptor.LoaderNumber--;
            if (LoaderInterceptor.LoaderNumber === 0) {
              this.loaderService.isLoading.next(false);
            }
          }
        },
        err => {
          this.removeRequest(req);
          observer.error(err);
          LoaderInterceptor.LoaderNumber--;
          if (LoaderInterceptor.LoaderNumber === 0) {
            this.loaderService.isLoading.next(false);
          }
        },
        () => {
          this.removeRequest(req);
          observer.complete();
          if (LoaderInterceptor.LoaderNumber === 0) {
            this.loaderService.isLoading.next(false);
          }
        }
      );

      return () => {
        this.removeRequest(req);
        subscription.unsubscribe();
      };
    });
  }
}
