<div style="display: contents !important;">
  <div id="main-authentication" class="p-2 pb-0">
    <div class=" text-center">
      <div class=" flc pt-5" >
        <img class="img-logo-sanad" src="assets/images/mainlogo2.png" alt="mainlogo2" style="width:150px !important">
      </div>
      <label *ngIf="authService.getIsLoggedIn()" class="text-center white fw-500 fsxl">  {{customer.firstName}} {{customer.lastName}} </label>
    </div>
  </div>
</div>

<div id="settings" class="card card-settings fixed-bottom p-3 mb-2">
  <ul class="modern-list py-5 pt-1 mt-0 fsl mb-4">
    <li routerLink="/account-details"><img class="ib-icons m-2"  src="assets/images/icons/personal.png" alt="money_case_icon"> {{ 'SETTINGS.account_details' | translate}}</li>
    <li routerLink="/personal-information"><img class="ib-icons m-2"  src="assets/images/icons/personal-info.png" alt="personal-info">  {{ 'SETTINGS.personal_information' | translate}}</li>
    <li routerLink="/biometrics-settings"><img class="ib-icons m-2"  src="assets/images/icons/biomet.png" alt="biomet" style="width:23px !important">  {{ 'SETTINGS.biometric_authentication' | translate}}</li>
    <hr style="color:rgb(43, 39, 39)">
    <li routerLink="/change-language"><img class="ib-icons m-2"  src="assets/images/icons/globe.png" alt="globe"> {{ 'SETTINGS.language_selection' | translate}}</li>
    <li  routerLink="/contact-us"><img class="ib-icons m-2"  src="assets/images/icons/call.png" alt="call">  {{ 'SETTINGS.contact_us' | translate}}</li>
    <li  routerLink="/faq"><img class="ib-icons m-2"  src="assets/images/icons/quests.png" alt="quests"> {{ 'SETTINGS.frequent_questions' | translate}}</li>
    <li  routerLink="/about-sanad"><img class="ib-icons m-2"  src="assets/images/icons/about.png" alt="about">  {{ 'SETTINGS.about_sanad' | translate}}</li>
    <li  routerLink="/claims"><img class="ib-icons m-2"  src="assets/images/icons/cu-pc.png" alt="cu-pc">  {{ 'SETTINGS.claims' | translate}}</li>
    <li  routerLink="/delete-account"><img class="ib-icons m-2"  src="assets/images/icons/x.png" alt="x">  {{ 'SETTINGS.delete_account' | translate}}</li>
    <hr style="color:rgb(43, 39, 39)">
    <li class="colordanger pb-3" (click)="logout()"><i class='bx bx-log-out px-2' style="color: #B83131;"></i> {{'BUTTONS.sign_out' | translate}}</li>
  </ul>
</div>
<app-footer-menu *ngIf="this.sharedService.getUser().login != undefined"></app-footer-menu>
