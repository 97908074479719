import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AuthentificationService } from './authentification.service';
import { AcmConstants } from '../shared/mb-constants/mb-constants';
import { SharedService } from './shared.service';
import { UserEntity } from '../shared/Entities/user.entity';

/**
 * The Authentification interceptor
 */
@Injectable()
export class AuthentificationInterceptor implements HttpInterceptor {
  /**
   * AuthentificationInterceptor ; add Http Header (Taken) + catch error by status
   * @param router Router
   * @param sharedService SharedService
   */
  constructor(private router: Router, private sharedService: SharedService, private authService: AuthentificationService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (localStorage.getItem('currentUser') != null) {
      req = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + localStorage.getItem('currentUser'))
      });
    } else if (localStorage.getItem('currentUser') == null) {
      return next.handle(req.clone());
    }
    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401) {
          this.router.navigateByUrl('index');
          localStorage.clear();
          localStorage.setItem('hasSeenAd', 'true');
          this.sharedService.setUser(new UserEntity());
          return null;
        } else if (err.status === 500) {
          this.sharedService.openSnackBar(1, 'alert.error');

           if (err.error.errorCode === "ACM-00006") {
            // check if list or error is not empty
            if (err.error.errors !== null) {
            } else {

            }
          }
          // return next.handle(req.clone());
          return null;
        } else if (err.status === 403) {
          this.router.navigateByUrl(AcmConstants.UNAUTHORIZED);
        } else if (err.status === 400) {
          /** The logOut logic in interceptor is is causing a regression in the biometric Authentication logic in the app / Commented for now  */
          // if (location.pathname != '/otp-biometrics' && location.pathname != '/sign-contrat' && location.pathname != '/forgetpassword'
          //   && location.pathname != '/login' && location.pathname != '/home'){
          //   this.authService.logout();
          // }
        }
        return throwError(err);
      },
      ),
      // We could check the type of object/error to show
      // in our case to send error from backend to front
      tap((succ) => {
      },
        (err) => {
         // if (err.status === 500) {
            //return next.handle(req.clone());
            return throwError(err);
         // }
        }),
    );
  }

}
