<div id="personal_information" class="bg-2 pb-2">
  <app-screen-title [title]="'TITLE.personal_information'" [color]="'color1'" [backTo]="'/settings'"></app-screen-title>
  <!-- Menu Personal Information Cards -->
  <div id="personal-information-menu" class="px-3">

    <!-- Personal Information -->
    <div class="row mb-2">
      <div class="col-12">
        <div class="card card-shadow">
          <div class="card-header fsb py-2">
            <div style="display: flex; align-items: center;gap: 10px;">
              <div  class="icon-card">
                <!-- <i class='bx bx-user'></i> -->
                <img class="ib-icons m-2" src="assets/images/icons/cu-user.png" alt="cu-phone" style="position: absolute;">
              </div>
              <div style="display:grid" class="py-2">
                <label class="fsl ms-2 ">{{ 'LABELS.personal_information' | translate }}</label>
                <label class="form-label ms-2 text-success">{{'STATUSES.completed' | translate}}</label>
              </div>
            </div>
            <div style="display: flex; align-items: center;" (click)="edit('edit-personal-info')" >
              <img class="ib-icons "  src="assets/images/icons/edit-icon.png" alt="edit-icon">
            </div>
          </div>
          <div class="card-body py-2">
            <div class="fsb">
              <label class="form-label ms-2 fss">{{ 'LABELS.purpose_of_financing' | translate }}</label>
              <label class="form-label ms-2 fss text-primary"> {{personalInfo?.loanPurpose ? personalInfo.loanPurpose : '...'  }} </label>
            </div>
            <div class="fsb">
              <label class="form-label ms-2 fss">{{ 'LABELS.marital_status' | translate }}</label>
              <label class="form-label ms-2 fss text-primary">{{personalInfo?.materialStatus ? personalInfo?.materialStatus: '...'}}</label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Work Information -->
    <div class="row mb-2">
      <div class="col-12">
        <div class="card card-shadow">
          <div class="card-header fsb py-2">
            <div style="display: flex; align-items: center;gap: 10px;">
              <div  class="icon-card">
                <!-- <i class='bx bxs-briefcase'></i> -->
                <img class="ib-icons m-2" src="assets/images/icons/cu-pack.png" alt="cu-phone" style="position: absolute;">
              </div>
              <div style="display:grid" class="py-2">
                <label class="fsl ms-2 "> {{ 'LABELS.employer_information' | translate }} </label>
                <label class="form-label ms-2 text-success">{{'STATUSES.completed' | translate}}</label>
              </div>
            </div>
            <div style="display: flex; align-items: center;" (click)="edit('edit-work-info')">
              <img class="ib-icons "  src="assets/images/icons/edit-icon.png" alt="edit-icon">
            </div>
          </div>
          <div class="card-body py-2">
            <div class="fsb">
              <label class="form-label ms-2 fss">{{ 'LABELS.date_of_join' | translate }}</label>
              <label class="form-label ms-2 fss text-primary"> {{workInformation?.dateJoining ? workInformation.dateJoining: '...'}} </label>
            </div>
            <div class="fsb">
              <label class="form-label ms-2 fss">{{ 'LABELS.labor_sector' | translate}}</label>
              <label class="form-label ms-2 fss text-primary">{{workInformation?.workSection? workInformation.workSection: '...'}}</label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Salary Details -->
    <div class="row mb-2">
      <div class="col-12">
        <div class="card card-shadow">
          <div class="card-header fsb py-2">
            <div style="display: flex; align-items: center;gap: 10px;">
              <div  class="icon-card">
                <!-- <i class='bx bx-wallet'></i> -->
                <img class="ib-icons m-2" src="assets/images/icons/cu-wallet.png" alt="cu-phone" style="position: absolute;">
              </div>
              <div style="display:grid" class="py-2">
                <label class="fsl ms-2 "> {{ 'LABELS.income_details' | translate }} </label>
                <label class="form-label ms-2  text-success">{{'STATUSES.completed' | translate}}</label>
              </div>
            </div>
            <div style="display: flex; align-items: center;" (click)="edit('edit-income-details')">
              <img class="ib-icons "  src="assets/images/icons/edit-icon.png" alt="edit-icon">
            </div>
          </div>
          <div class="card-body py-2">
            <div class="fsb">
              <label class="form-label ms-2 fss"> {{ 'LABELS.basic_salary' | translate }} </label>
              <label class="form-label ms-2 fss text-primary">{{incomeDetails?.basicSalary ? incomeDetails.basicSalary : '...'}} <span class="fsxs"> {{ 'CURRENCY.sar' | translate }}</span></label>
            </div>
            <div class="fsb">
              <label class="form-label ms-2 fss"> {{ 'LABELS.housing_allowance' | translate }} </label>
              <label class="form-label ms-2 fss text-primary">{{incomeDetails?.housingAllowance ? incomeDetails.housingAllowance : '...'}}<span class="fsxs"> {{ 'CURRENCY.sar' | translate }}</span></label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Charges & Expenses -->
    <div class="row pb-4">
      <div class="col-12">
        <div class="card card-shadow">
          <div class="card-header fsb py-2">
            <div style="display: flex; align-items: center;gap: 10px;">
              <div  class="icon-card">
                <img class="ib-icons m-2" src="assets/images/icons/money_icon.png" alt="cu-phone" style="position: absolute;">
              </div>
              <div class="d-grid py-2 ">
                <label class="fsl ms-2 "> {{ 'LABELS.obligations_expenses' |translate }} </label>
                <label class="form-label ms-2  text-success">{{'STATUSES.completed' | translate}}</label>
              </div>
            </div>
            <div style="display: flex; align-items: center;" (click)="edit('edit-expenses-obligations')">
              <img class="ib-icons "  src="assets/images/icons/edit-icon.png" alt="edit-icon">
            </div>
          </div>
          <div class="card-body py-2">
            <div class="fsb">
              <label class="form-label ms-2 fss">  {{'LABELS.monthly_obligations' | translate}} </label>
              <label class="form-label ms-2 fss text-primary">{{expensesObligations?.monthlyInstallment ? expensesObligations.monthlyInstallment: '...'}} <span class="fsxs"> {{ 'CURRENCY.sar' | translate }}</span></label>
            </div>
            <div class="fsb">
              <label class="form-label ms-2 fss"> {{ 'LABELS.living_expenses' | translate }} </label>
              <label class="form-label ms-2 fss text-primary">{{monthlyObligation}}<span class="fsxs"> {{ 'CURRENCY.sar' | translate }}</span></label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-4">
      <div class="col-12">
        <div class="card card-shadow">
          <div class="card-header fsb py-2">
            <div style="display: flex; align-items: center;gap: 10px;">
              <div class="icon-card">
                <i class='bx bx-credit-card-alt'></i>
              </div>
              <div class="d-grid py-2">
                <label class=" ms-2 fsl"> {{ 'LABELS.iban_number' |translate }} </label>
                <label class=" ms-2  text-success">{{'STATUSES.completed' | translate}}</label>
              </div>
            </div>
            <div (click)="edit('edit-iban')" style="display: flex; align-items: center;">
              <img class="ib-icons " src="assets/images/icons/edit-icon.png" alt="edit-icon">
            </div>
          </div>
          <div class="card-body py-4">
            <div class="fsb">
              <label class="form-label ms-2 "> {{ 'LABELS.bank_name' | translate }} </label>
              <label class="form-label ms-2  text-primary"> {{bankInformation?.bankName}} </label>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

</div>

