import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserEntity } from '../shared/Entities/user.entity';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CustomerEntity } from '../shared/Entities/customer.entity';

@Injectable({
  providedIn: 'root'
})
export class UserService {

constructor(private httpClient : HttpClient) { }

  //ADD USER API
  addUser(userEntity: UserEntity): Observable<UserEntity> {
    return this.httpClient.post<UserEntity>(environment.oauthUrl + 'users/create-user-ib', userEntity)
  }

/** Not Used */
getUserByLogin(login: string): Observable<UserEntity> {
  let qheader = new HttpHeaders();
  qheader = qheader.append('Authorization', 'Bearer ' + localStorage.getItem('accesToken'));
  return this.httpClient.get<UserEntity>(environment.oauthUrl + 'users/' + login, { headers: qheader });
}

/** Not Used */
setLoginPw(user: UserEntity): Observable<UserEntity> {
  let qheader = new HttpHeaders();
  qheader = qheader.append('Authorization', 'Bearer ' + localStorage.getItem('accesToken'));
  return this.httpClient.post<UserEntity>(environment.oauthUrl + 'users/update-pwd/', user , { headers: qheader });
}

checkDevice(user: UserEntity): Observable<string> {
  let qheader = new HttpHeaders();
  qheader = qheader.append('Authorization', 'Bearer ' + localStorage.getItem('accesToken'));
  return this.httpClient.post<string>(environment.oauthUrl + 'users/check-device/', user , { headers: qheader });
}

deleteAccount(customer: CustomerEntity): Observable<{ message: string }> {
  return this.httpClient.post<{ message: string }>(environment.oauthUrl + 'users/delete-account',customer)
}

}
