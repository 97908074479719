import { GroupeEntity } from "./groupe.entity";

export class UserEntity {
  login: string;
  nom: string;
  prenom: string;
  email: string;
  fullName: string;
  accountPortfolioId: string;
  responsableId: string;
  userExternId: string;
  userProfilId: string;
  enabled: boolean;
  branchID: number;
  branchName: string;
  branchDescription: string;
  groupes: GroupeEntity[];
  customerId: number;
  simpleName: string;
  /** The temporary pwd. */
  temporaryPwd: boolean;
  /** The pwd. */
  pwd: string;
  /** password sync with back entity */
  password : string;
  /** The pwd new. */
  pwdNew: string;
  /** The Pwd confirm. */
  pwdConfirm: string;
  category: string;
  defaultLang: string;
  avatarUrl?: any;
  firstLogin : boolean;
  deviceId : string;
}
