import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserEvaluation } from '../shared/Entities/UserEvaluation.entity';
import { AcmURLConstants } from '../shared/mb-constants/mb-url-constants';

@Injectable({
  providedIn: 'root'
})
export class EvaluationService {
    /**
     *
     * @param httpClient HttpClient
     */
    constructor(private httpClient: HttpClient) {
    }

addUserEvaluation(userEvaluation : UserEvaluation){
  return this.httpClient.post<UserEvaluation>(AcmURLConstants.ADD_USER_EVALUATION, userEvaluation);
}

}
