import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/ib-services/shared.service';
import { LoanEntityIB } from 'src/app/shared/Entities/loan.entity';
import { ScheduleEntity } from 'src/app/shared/Entities/schedule.entity';

@Component({
  selector: 'app-schedule-payment',
  templateUrl: './schedule-payment.component.html',
  styleUrls: ['./schedule-payment.component.scss']
})
export class SchedulePaymentComponent implements OnInit {
  public schedules : ScheduleEntity[] = [];
  public loan : LoanEntityIB;
  public termPaid :number=0;
  public donePayment : number;
  public remainingAmount : number;
  public paymentStatus : number;
  public listAmountPaid : string[] = [];

  constructor(public sharedService : SharedService) { }

  ngOnInit() {
    this.schedules = this.sharedService.getSchedules();
    this.loan = this.sharedService.getLoanDeal();
    this.termPaid = this.sharedService.getTermPaid();
    this.donePayment = this.loan.normalPayment * this.termPaid;
    this.remainingAmount = this.loan.approvelAmount - this.donePayment;
    this.paymentStatus = (this.termPaid / this.loan.termPeriodNum) ;
    if (this.termPaid === this.loan.termPeriodNum  ) this.paymentStatus = 100;
    this.schedules.forEach((schedule,index )=> {
      if (schedule.interestAmtPaid != null){
        let x = this.calculateTotalAmountPaid(index);
        this.listAmountPaid.push(x);
      }else {
        this.listAmountPaid.push(schedule.totalRepayment.toString());
      }
    })
  }

  getPercentage(): number {
    return Math.round(this.paymentStatus * 100);
  }

  calculateTotalAmountPaid( index:number ) : string{
    let a = this.schedules[index].loanRepaymentPaid;
    let b = this.schedules[index].interestAmtPaid;
    let valueA = this.extractNumberFromAmount(a);
    let valueB = 0;
    if (b != null){
      valueB = this.extractNumberFromAmount(b);
    }
    let res = valueB + valueA;
    let result = "SAR"+res.toFixed(2);
    return result;
  }

  extractNumberFromAmount(amountString: string): number {
    let x = amountString.replace('SAR', '').replace(/,/g, '').trim();
    let convertedNumber = Number(x);
    return convertedNumber;
}

}
