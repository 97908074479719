import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CreditService } from 'src/app/ib-services/credit.service';
import { CustomerService } from 'src/app/ib-services/customer.service';
import { SharedService } from 'src/app/ib-services/shared.service';
import { ThirdPartyService } from 'src/app/ib-services/third-party.service';
import { RequestSendSmsDeewanApiEntity } from 'src/app/shared/Entities/RequestSendSmsDeewanApi.entity';
import { CustomerEntity } from 'src/app/shared/Entities/customer.entity';
import { UserEntity } from 'src/app/shared/Entities/user.entity';
import { PathConstants } from 'src/app/shared/mb-constants/path-constants';

@Component({
  selector: 'app-account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.scss'],
})
export class AccountDetailsComponent implements OnInit {
  public showGlobalDetails: boolean = true;
  public showEditPhoneNumber: boolean = false;
  public customer: CustomerEntity;
  public user: UserEntity;
  public accountDetailsForm: FormGroup;
  public phoneNumberForm: FormGroup;
  public lang: string;
  public nationalIdPattern = /^(1|2)+\d{9}$/;
  public mobileNumberPattern = /^5[013456789]\d{7}$/;
  public showOTP: boolean = false;
  public requestSendSms: RequestSendSmsDeewanApiEntity =
    new RequestSendSmsDeewanApiEntity();

  constructor(
    public sharedService: SharedService,
    private thirdPartyService: ThirdPartyService,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    public router: Router,
    private customerService: CustomerService
  ) {}

  ngOnInit() {
    this.lang = localStorage.getItem('lang');
    this.customer = this.sharedService.getCustomer();
    this.createAccountDetailsFrom();
    this.fillFormByCustomerData();
  }

  createAccountDetailsFrom() {
    this.accountDetailsForm = this.formBuilder.group({
      fullName: [{ value: '', disabled: true }, Validators.required],
      phoneNumber: [{ value: '', disabled: true }, Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: [{ value: '', disabled: true }, Validators.required],
    });
  }

  fillFormByCustomerData() {
    this.accountDetailsForm.controls['fullName'].setValue(
      this.customer.firstName + ' ' + this.customer.lastName
    );
    this.accountDetailsForm.controls['phoneNumber'].setValue(
      this.customer.telephone1
    );
    this.accountDetailsForm.controls['email'].setValue(this.customer.email);
  }

  editPhoneNumber() {
    this.showEditPhoneNumber = !this.showEditPhoneNumber;
    this.showGlobalDetails = !this.showGlobalDetails;
  }

  sendOTP() {
    this.thirdPartyService
      .sendOtpDeewanApi(this.requestSendSms, this.customer.identity, 0, false)
      .subscribe();
    this.showOTP = true;
    this.showGlobalDetails = !this.showGlobalDetails;
  }
  otpResponse(event: any) {
    if (event) {
      this.createPhoneNumberForm();
      this.showOTP = false;
      this.showEditPhoneNumber = !this.showEditPhoneNumber;
    }
  }
  createPhoneNumberForm() {
    this.phoneNumberForm = this.formBuilder.group({
      nationalId: [
        '',
        [Validators.required, Validators.pattern(this.nationalIdPattern)],
      ],
      phoneNumber: [
        '',
        [Validators.required, Validators.pattern(this.mobileNumberPattern)],
      ],
    });
  }
  updatePhoneNumber() {
    const checkIdentity =
      this.customer.identity ===
      this.phoneNumberForm.controls['nationalId'].value;
    if (this.phoneNumberForm.valid && checkIdentity) {
      this.customer.telephone1 =
        this.phoneNumberForm.controls['phoneNumber'].value;
      this.customer.sendToACM = true;
      this.customerService.updateCustomer(this.customer).subscribe((res) => {
        if (res) {
          this.sharedService.setCustomer(res);
          this.sharedService.showSuccess(
            this.translate.instant(
              'TOAST.customer_phone_number_updated_successfully'
            )
          );
          this.router.navigate(['/' + PathConstants.PATH_HOME]);
        }
      });
    } else {
      if (!checkIdentity) {
        this.sharedService.showError(
          this.translate.instant('ERR.nat_id_wrong')
        );
      } else {
        Object.keys(this.phoneNumberForm.controls).forEach((field) => {
          const control = this.phoneNumberForm.get(field);
          if (control.invalid) {
            if (field === 'nationalId') {
              if (control.errors['required']) {
                this.sharedService.showError(
                  this.translate.instant('ERR.nat_id_required')
                );
              } else if (control.errors['pattern']) {
                this.sharedService.showError(
                  this.translate.instant('ERR.nat_id_invalid_format')
                );
              }
            } else if (field === 'phoneNumber') {
              if (control.errors['required']) {
                this.sharedService.showError(
                  this.translate.instant('ERR.phone_number_required')
                );
              } else if (control.errors['pattern']) {
                this.sharedService.showError(
                  this.translate.instant('ERR.phone_number_invalid_pattern')
                );
              }
            }
          }
        });
      }
    }
  }

  updateEmail() {
    const emailControl = this.accountDetailsForm.get('email');
    const checkEmailChanged = emailControl.value === this.customer.email;
    if (emailControl.valid && !checkEmailChanged) {
      this.customer.email = emailControl.value;
      this.customer.sendToACM = true;
      this.customerService.updateCustomer(this.customer).subscribe((res) => {
        if (res) {
          this.sharedService.setCustomer(res);
          this.sharedService.showSuccess(
            this.translate.instant('TOAST.customer_email_updated_successfully')
          );
          this.router.navigate(['/home']);
        }
      });
    } else {
      if (checkEmailChanged) {
        this.sharedService.showError(
          this.translate.instant('ERR.email_not_changed')
        );
      } else if (emailControl.hasError('required')) {
        this.sharedService.showError(
          this.translate.instant('ERR.email_required')
        );
      } else if (emailControl.hasError('email')) {
        this.sharedService.showError(this.translate.instant('ERR.email_error'));
      }
    }
  }
  checklength(event: any) {
    const inputValue = event.target.value;

    const limitedValue = inputValue.substring(0, 9);
    this.phoneNumberForm.controls['phoneNumber'].setValue(limitedValue);
  }
}
