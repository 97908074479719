<div class=" vh-100 px-3" style="overflow-x: unset !important;">
  <app-screen-title [title]="'TITLE.choose_amount'" [color]="'white'" [backTo]="'/home'"></app-screen-title>
  <!-- Stepper -->
  <div class="card card-bg-secondary px-2 mt-3">
    <label class="label-info-secondary text-center white"> {{'INFO.maximum_amount' | translate}}</label>
    <div class="py-0 pt-0">
      <legend class="text-center white fsxxl">{{maxAmount  | currency:' '}}
        <span class="fss" *ngIf="lang=='en'">{{'CURRENCY.sar' | translate}}</span>
        <img class="ib-icons m-2" *ngIf="lang=='ar'" src="assets/images/icons/sarAr.png" alt="sarAr">
       </legend>
    </div>
  </div>

  <div id="period-selection">
    <div class="card simulator-card fixed-bottom" style="overflow-y: auto !important;overflow-x: unset !important;">
      <legend  class="fsl color1 fw-600 flc">{{'TITLE.calculate_installment'|translate}}</legend>
      <div class="card-body cu-cbody"  style="overflow-x: hidden !important;">
          <div class="mb-2">
            <div id="tooltip_amout_value">
              <div *ngIf="lang=='ar'" class="custom-tooltip" [style.right]="tooltipPositionAmount">
                <p>{{ valueAmount }} {{'CURRENCY.sar' | translate}}</p>
              </div>
              <div *ngIf="lang=='en'" class="custom-tooltip" [style.left]="tooltipPositionAmount">
                <p>{{ valueAmount }} {{'CURRENCY.sar' | translate}} </p>
              </div>
            </div>
            <label class="form-label ms-2  fsm">{{'LABELS.choose_amount' | translate}}</label>
            <div style="position: relative;">
              <input type="range" class="form-range" [max]="maxAmount" [min]="minAmount" [step]="1" (ngModelChange)="updateAmount($event)" [(ngModel)]="valueAmount">
            </div>
          </div>
          <div class="mb-2 fsb">
            <label class="form-label ms-2 pt-3 fw-650 op-50">{{'LABELS.amount' | translate }}</label>
            <div style="position: relative;">
              <label class="label-info" style="position: absolute;margin: 10px !important ;"> {{'CURRENCY.sar' | translate}} </label>
              <input type="number" class="form-control  input-number"  [(ngModel)]="valueAmount"   (ngModelChange)="updateAmount({value: valueAmount})">
            </div>
          </div>
          <div class="mb-2">
            <div id="tooltip_term_value">
              <div *ngIf="lang=='ar'" class="custom-tooltip" [style.right]="tooltipPositionTerm">
                <p>{{ valueTerm }}</p>
              </div>
              <div *ngIf="lang=='en'" class="custom-tooltip" [style.left]="tooltipPositionTerm">
                <p>{{ valueTerm }}</p>
              </div>
            </div>
            <label class="form-label ms-2  fsm">{{'LABELS.choose_the_duration' | translate}}</label>
            <div style="position: relative;">
              <input type="range" class="form-range" [max]="maxTerm" [min]="minTerm" [step]="1" (ngModelChange)="updateTerm($event)" [(ngModel)]="valueTerm">
            </div>
          </div>
          <div class="mb-4 fsb">
            <label class="form-label ms-2 pt-3 fw-650 op-50">{{'LABELS.duration' | translate }}</label>
            <div style="position: relative;">
              <label class="label-info" style="position: absolute;margin: 10px !important ;"> {{'LABELS.months' | translate}} </label>
              <input type="number" class="form-control input-number"  [(ngModel)]="valueTerm"  (ngModelChange)="updateTerm(valueTerm)">
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="card">
                <div class="text-center align-items-center flc px-4">
                  <img class="ib-icons "  src="assets/images/icons/money_icon.png" alt="money_icon" >
                  <label class="form-label p-2">{{'LABELS.monthly_installment'|translate}}</label>
                </div>
                <div class="text-center">
                  <label class="form-label ms-2 p-2 fw-650 text-primary fsl"  [ngClass]="{'text-success' : greenFlag, 'text-danger' : redFlag}">{{repaymentMonthly  | currency:' '}}&nbsp;<span class="fss">{{'CURRENCY.sar' | translate}}</span></label>
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="card">
                <div class="text-center align-items-center flc px-4">
                  <img class="ib-icons"  src="assets/images/icons/money_round.png" alt="money_round">
                  <label class="form-label ms-2 p-2 "> {{'LABELS.total_amount' | translate}}</label>
                </div>
                <div class="text-center">
                  <label class="form-label ms-2 p-2 fw-650 color1 fsl">{{ repaymentMonthly * valueTerm | currency:' '}} <span class="fss">{{'CURRENCY.sar' | translate}}</span> </label>
                </div>

              </div>
            </div>
          </div>
          <div class="row mt-2" >
            <div class="col-5 block text-center mt-4">
              <label class="mb-1 color1 fw-650">{{ 'LABELS.first_installment' |translate}}</label>
              <label class="mb-1 d-block"> {{repaymentMonthly | currency:' '}}&nbsp;{{'CURRENCY.sar' | translate}}</label>
              <label class="fss op-50">  {{firstDueDate | date}}</label>
            </div>
            <div class="col-2 flc">
              <hr>
            </div>
            <div class="col-5 block text-center mt-4">
              <label class="mb-1 fw-650 color1">   {{'LABELS.final_installment' | translate}}</label>
              <label class="mb-1 d-block"> {{repaymentMonthly | currency:' '}} {{'CURRENCY.sar' | translate}}</label>
              <label class="fss op-50"> {{lastDueDate | date}}  </label>

            </div>
          </div>

        <div class="text-center py-2  ">
          <button type="button" class="btn btn-primary btn-lg mb-4 login-btn" (click)="next()" [disabled]="!greenFlag">
            {{'BUTTONS.next' | translate}}
          </button>
        </div>

      </div>

    </div>
  </div>
</div>

