import { Component, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CreditService } from 'src/app/ib-services/credit.service';
import { LoanService } from 'src/app/ib-services/loan.service';
import { ParametrageService } from 'src/app/ib-services/parametrage.service';
import { SharedService } from 'src/app/ib-services/shared.service';
import { ThirdPartyService } from 'src/app/ib-services/third-party.service';
import { Billing, Customer, HPRequestCheckoutDTO} from 'src/app/shared/Entities/HPRequestCheckoutDTO.entity';
import { HPResponseCheckoutDTO } from 'src/app/shared/Entities/HPResponseCheckoutDTO.entity';
import { CustomerEntity } from 'src/app/shared/Entities/customer.entity';
import { LoanEntityIB } from 'src/app/shared/Entities/loan.entity';

@Component({
    selector: 'app-new-transaction',
  templateUrl: './new-transaction.component.html',
  styleUrls: ['./new-transaction.component.scss'],
})

export class NewTransactionComponent {
  public loan: LoanEntityIB = new LoanEntityIB();
  public amount: number;
  public customer: CustomerEntity;
  public mode: string;
  public checkoutResponse: HPResponseCheckoutDTO;
  public lang: string;
  public statusPaymentPage: string;
  public widgetUrl : string;
  public isBrandSelected : boolean = false;
  public selectedBrand : string;
  paymentMethods = [
    { brand: 'APPLEPAY', label: 'Apple Pay', image: 'assets/images/paiementTypes/applePay.png' },
    { brand: 'MADA', label: 'Mada', image: 'assets/images/paiementTypes/madaPay.png' },
    { brand: 'VISA', label: 'Visa', image: 'assets/images/paiementTypes/visa.png' },
    { brand: 'MASTER', label: 'MasterCard', image: 'assets/images/paiementTypes/masterCard.png' },
  ];

  constructor( public translate: TranslateService, public creditService: CreditService, public sharedService: SharedService, private thirdPartyService: ThirdPartyService,
    private renderer: Renderer2, private route: ActivatedRoute, private router : Router,private loanService : LoanService, private paramService : ParametrageService ) {}

  ngOnInit(): void {
    this.statusPaymentPage = '/statut-payment';
    this.lang = localStorage.getItem('lang');
    this.route.queryParams.subscribe((params) => {
      this.mode = params['mode'];
    });
    this.loan = this.sharedService.getLoanDeal();
    /** INITIALIZE PAYOUT AS NULL IN CASE NORMAL PAYMENT */
    if (this.mode != 'PAYOUT' && this.loan.payout){
        this.loan.actionToAcm = null;
        this.loan.payout = null;
        this.loan.motifPayout = null;
        this.loanService.updateLoan(this.loan).subscribe(res => {
          this.loan = res;
        });
    }

    this.paramService.getEnvirementValueByKey('HYPER_PAY_API_PAYMENT_WIDGET_URL').subscribe(res =>{
      this.widgetUrl = res.value;
    });

    this.paramService.getEnvirementValueByKey('')

    this.customer = this.sharedService.getCustomer();
  }

  selectPaymentMethod(method: any) {
    this.selectedBrand = method.brand;
  }

  loadHyperpayScript(checkoutResponse: HPResponseCheckoutDTO): void {
    const script = this.renderer.createElement('script');
    script.src = this.widgetUrl + checkoutResponse.id;
    script.integrity = checkoutResponse.integrity;
    script.crossOrigin = 'anonymous';
    script.async = true;
    window['wpwlOptions'] = {
      locale: this.lang === 'ar' ? 'ar' : 'en',
      numberFormatting:false,
      onReady: () => {
        console.log('Widget is ready.');
        localStorage.setItem('checkout',checkoutResponse.id);
      }
    };
    script.onload = () => {console.log('HyperPay widget script loaded');};
    script.onerror = () => {console.error('Failed to load HyperPay widget script');};
    this.renderer.appendChild(document.body, script);
  }

  generateCheckoutId() {
    let hpRequestCheckoutDTO = new HPRequestCheckoutDTO();
    hpRequestCheckoutDTO.customer = new Customer();
    hpRequestCheckoutDTO.billing = new Billing();
    hpRequestCheckoutDTO.amount = (this.mode === 'PAYOUT' ? this.loan.payout : this.loan.normalPayment).toFixed(2);
    hpRequestCheckoutDTO.testMode = 'EXTERNAL'; /** TO BE SET IN BACKEND */
    hpRequestCheckoutDTO.customer.givenName = this.customer.firstName;
    hpRequestCheckoutDTO.customer.email = this.customer.email;
    hpRequestCheckoutDTO.customer.surname = this.customer.lastName;

    this.thirdPartyService.getCheckoutPaymentProcess(hpRequestCheckoutDTO, this.loan.idIbLoan,this.customer.ibCustomerId).subscribe((res) => {
        this.checkoutResponse = res;
        if (this.checkoutResponse) {
          localStorage.setItem('loanId',this.loan.idIbLoan.toString());
          this.loadHyperpayScript(res);
        } else {
          this.sharedService.showError(this.translate.instant('ERR.error_generate_checkout'));
          this.router.navigate(['/loan-schedule-details']);
        }
      });
  }

  next(){
    this.isBrandSelected = true;
    this.generateCheckoutId();
  }

}
