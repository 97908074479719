<div class="bg-1" style="height: 110vh !important;">
  <div class="bg-1">
    <div class="bg-1 pt-4">
      <div class="fsb" [ngClass]="{'mt-5' : sharedService.getDeviceInfo().os.toLowerCase() === 'ios'}">
        <label class="label-info  m-3 white" (click)="closeAd()"> {{ 'BUTTONS.skip' | translate}} </label>
        <label class="label-info  m-3 white" (click)="langSelection()"> {{lang==="EN" ? "AR":"EN"}} </label>
      </div>
      <div class="py-3">
        <ngb-carousel [interval]="0" #carousel [keyboard]="false" (slid)="carouselSlide($event)">
          <ng-template ngbSlide *ngFor="let image of advImages; let i = index">
            <div class="p-5 mt-4 flc">
              <img style="width: 260px;" [src]="image.src" [alt]="image.alt">
            </div>
          </ng-template>
        </ngb-carousel>
      </div>


    </div>
    <div class="fixed-bottom bg-1">

      <!-- Adv 1 -->
      <div class="card card-main p-4" *ngIf="adv1 && !adv2 && !adv3">
        <label class="label-main fw-650 fsl text-center mb-4"> {{ 'ADVERTISEMENT.adv1' | translate}} </label>
        <div class="text-center  mb-2 px-2">
          <button type="button" class="btn btn-primary btn-lg mb-2 login-btn" (click)="showAdv2(true)">
            {{'BUTTONS.next' | translate}}
          </button>
        </div>
      </div>

      <!-- Adv 2 -->
      <div class="card card-main p-4" *ngIf="!adv1 && adv2 && !adv3">
        <label class="label-main fw-650 fsl text-center mb-4"> {{ 'ADVERTISEMENT.adv2' | translate}} </label>
        <div class="text-center  mb-2 px-2">
          <button type="button" class="btn btn-primary btn-lg mb-2 login-btn" (click)="showAdv3(true)">
            {{'BUTTONS.next' | translate}}
          </button>
        </div>
      </div>

      <!-- Adv 3 -->
      <div class="card card-main p-4" *ngIf="!adv1 && !adv2 && adv3">
        <label class="label-main fw-650 fsl text-center mb-4"> {{ 'ADVERTISEMENT.adv3' | translate}} </label>
        <div class="text-center  mb-2 px-2">
          <button type="button" class="btn btn-primary btn-lg mb-2 login-btn" (click)="closeAd()">
            {{'BUTTONS.next' | translate}}
          </button>
        </div>
      </div>

    </div>
  </div>
</div>
