import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoanEntity } from '../shared/Entities/loanPrep.entity';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoanEntityIB } from '../shared/Entities/loan.entity';
import { ScheduleEntity } from '../shared/Entities/schedule.entity';

@Injectable({
  providedIn: 'root'
})
export class LoanService {

constructor(public httpClient: HttpClient) { }

  /** Not Used */
  createLoan(loanEntity: LoanEntity): Observable<LoanEntity> {
    let qheader = new HttpHeaders();
    qheader = qheader.append('Authorization', 'Bearer ' + localStorage.getItem('accesToken'));
    return this.httpClient.post<LoanEntity>(environment.apiUrl + 'credit-service/loans-ib/create', loanEntity, { headers: qheader });
  }

  getLoanByCustomerNID(loanEntity: LoanEntityIB): Observable<LoanEntityIB[]> {
    return this.httpClient.post<LoanEntityIB[]>(environment.apiUrl + 'credit-service/loans/', loanEntity);
  }

  /** Not Used */
  validate(idLoan: number): Observable<LoanEntity> {
    let qheader = new HttpHeaders();
    qheader = qheader.append('Authorization', 'Bearer ' + localStorage.getItem('accesToken'));
    return this.httpClient.post<LoanEntity>('http://172.16.4.110:8080/credit-service/loans/validateById/' + idLoan, {}, { headers: qheader });
  }

  /** Not Used */
  addLoan(loanEntity: LoanEntityIB): Observable<LoanEntityIB> {
    return this.httpClient.post<LoanEntityIB>(environment.apiUrl + 'credit-service/loans/create', loanEntity);
  }

  updateLoan(loan: LoanEntityIB): Observable<LoanEntityIB> {
    return this.httpClient.put<LoanEntityIB>(environment.apiUrl + 'credit-service/loans/update-loanIb', loan);
  }

  getLoan(loan: LoanEntityIB): Observable<LoanEntityIB> {
    return this.httpClient.post<LoanEntityIB>(environment.apiUrl + 'credit-service/loans/', loan);
  }

  /** Schedules  */

  getLoansSchedules(customerId: string): Observable<LoanEntityIB> {
    return this.httpClient.get<LoanEntityIB>(environment.apiUrl + 'transvers-service/rabbitMq-endPoint/fetch-loan-schedules/' + customerId);
  }

  getSchedulesByLoanId(id: number): Observable<ScheduleEntity[]> {
    return this.httpClient.get<ScheduleEntity[]>(environment.apiUrl + 'credit-service/loan-schedule/schedules/' + id);
  }

  deleteLoanById(loanId : number) : Observable<Boolean>{
    return this.httpClient.delete<Boolean>(environment.apiUrl + 'credit-service/loans/delete-loan/' + loanId);
  }

}
