import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ipDetectorKey } from '../shared/Entities/constants';

@Injectable({
  providedIn: 'root'
})
export class IpService {

  private thirdPartyIpInfo = 'http://api.ipstack.com';
  private key = ipDetectorKey;

  constructor(private http: HttpClient) {}

  getIpInfo(): Observable<any> {
    return this.http.get(`${this.thirdPartyIpInfo}/check?access_key=${this.key}`);
  }

}
