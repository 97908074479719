import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CreditService } from 'src/app/ib-services/credit.service';
import { DocumentService } from 'src/app/ib-services/document.service';
import { LoanService } from 'src/app/ib-services/loan.service';
import { SharedService } from 'src/app/ib-services/shared.service';
import { ThirdPartyService } from 'src/app/ib-services/third-party.service';
import { DebtorSanadElAmerApiRequestEntity } from 'src/app/shared/Entities/DebtorSanadElAmerApiRequest.entity';
import { DocumentTypeEntity } from 'src/app/shared/Entities/DocumentType.entity';
import { LoanDocumentEntity } from 'src/app/shared/Entities/LoanDocument.entity';
import { RequestSendSmsDeewanApiEntity } from 'src/app/shared/Entities/RequestSendSmsDeewanApi.entity';
import { ResponseGetUrlDecisionSanadApiDTO } from 'src/app/shared/Entities/ResponseGetUrlDecisionSanadApiDTO';
import { SanadElAmerRequestEntity } from 'src/app/shared/Entities/SanadElAmerRequest.entity';
import { SanadRequestSanadElAmerEntity } from 'src/app/shared/Entities/SanadRequestSanadElAmer.entity';
import { SettingDocumentTypeEntity } from 'src/app/shared/Entities/SettingDocumentType.entity';
import { CustomerEntity } from 'src/app/shared/Entities/customer.entity';
import { LoanEntityIB } from 'src/app/shared/Entities/loan.entity';
import { AcmConstants } from 'src/app/shared/mb-constants/mb-constants';
import { PathConstants } from 'src/app/shared/mb-constants/path-constants';

@Component({
  selector: 'app-sign-contract',
  templateUrl: './sign-contract.component.html',
  styleUrls: ['./sign-contract.component.scss'],
})
export class SignContractComponent implements OnInit {
  public callCustomer: Boolean = false;
  public signContract: Boolean = false;
  public signSanadAlAmr: Boolean = false;
  public waitingPeriodDisbursment: Boolean = false;
  public callCustomerDone: Boolean = false;
  public signContractDone: Boolean = false;
  public signSanadAlAmrDone: Boolean = false;
  public waitingPeriodDisbursmentDone: Boolean = false;
  allStepsDone: boolean = false;
  showContract = false;
  contractSrc = 'assets/files/Contract.pdf';
  contractRead = false;
  signButtonDisabled = true;
  lang: string;
  public loan: LoanEntityIB;
  showOTP: boolean = false;
  showButtonLater: boolean = false;
  public checker: boolean = false;
  public requestSendSms: RequestSendSmsDeewanApiEntity =
    new RequestSendSmsDeewanApiEntity();
  sanadAlAmrId: ResponseGetUrlDecisionSanadApiDTO;
  currentStep: number = 0;
  customer: CustomerEntity;
  private costumerDocuments: DocumentTypeEntity[] = [];
  private allDocuments: DocumentTypeEntity[] = [];
  public loanDocuments: DocumentTypeEntity[] = [];
  public foundLoan: LoanEntityIB;

  constructor(
    public sharedService: SharedService,
    private router: Router,
    private documentService: DocumentService,
    private creditService: CreditService,
    private translate: TranslateService,
    private datePipe: DatePipe,
    private loanService: LoanService,
    private thirdPartyService : ThirdPartyService
  ) {}

  ngOnInit() {
    this.lang = localStorage.getItem('lang');
    this.customer = this.sharedService.getCustomer();
    this.foundLoan = this.sharedService.getLoanDeal();
    if (this.foundLoan) {
      this.loan = this.sharedService.getLoanDeal();
      this.loan.statutLibelle = this.loan.statutLibelle.toLowerCase();
      if (this.loan.statutLibelle.includes('call')) {
        this.callCustomer = true;
        this.signContract = false;
        this.signSanadAlAmr = false;
        this.waitingPeriodDisbursment = false;
        this.currentStep = 1;
      } else if (this.loan.statutLibelle.includes('contract')) {
        this.callCustomer = false;
        this.signContract = true;
        this.callCustomerDone = true;
        this.currentStep = 2;
      } else if (this.loan.statutLibelle.includes('amr')) {
        this.callCustomer = false;
        this.signContract = false;
        this.signSanadAlAmr = true;
        this.callCustomerDone = true;
        this.signContractDone = true;
        this.checkSanadAlAmr();
        this.currentStep = 3;
      } else if (this.loan.statutLibelle.includes('period')) {
        this.callCustomer = false;
        this.signContract = false;
        this.signSanadAlAmr = false;
        this.waitingPeriodDisbursment = true;
        this.callCustomerDone = true;
        this.signContractDone = true;
        this.signSanadAlAmrDone = true;
        this.currentStep = 4;
      } else if (this.loan.statutLibelle.includes('disburse')) {
        this.callCustomer = false;
        this.signContract = false;
        this.signSanadAlAmr = false;
        this.waitingPeriodDisbursment = false;
        this.allStepsDone = true;
        this.callCustomerDone = true;
        this.signContractDone = true;
        this.signSanadAlAmrDone = true;
        this.waitingPeriodDisbursmentDone = true;
        this.currentStep = 5;
      }
    } else {
      this.sharedService.showError(this.translate.instant('ERR.system_error'));
      this.router.navigate(['/' + PathConstants.PATH_HOME]);
    }
  }
  openContract() {
    this.showContract = true;
  }
  closeContract() {
    this.showContract = false;
  }
  updateSignButton() {
    this.signButtonDisabled = !this.contractRead;
  }
  eSignContract() {
    this.loan.actionToAcm = 'APPROVE_SIGN';
    this.loanService.updateLoan(this.loan).subscribe((res) => {
      this.sharedService.setLoanDeal(res);
    });
    this.sharedService.showInfo(
      this.translate.instant('INFO.contract_signing')
    );
    this.router.navigate(['/' + PathConstants.PATH_HOME]);
  }

  async signContractSanadAlAmr() {
    const requestSanadElAmer: SanadElAmerRequestEntity =
      new SanadElAmerRequestEntity();

    let debtor: DebtorSanadElAmerApiRequestEntity =
      new DebtorSanadElAmerApiRequestEntity();
    // debtor.national_id = this.loan.customerDTO.identity;
    debtor.national_id = '1000473387';
    requestSanadElAmer.debtor = debtor;
    //  requestSanadElAmer.debtor_phone_number = this.loan.customerDTO.telephone1;
    requestSanadElAmer.debtor_phone_number = '0546258295';
    requestSanadElAmer.total_value = this.loan.approvelAmount;
    requestSanadElAmer.currency = this.loan.currencySymbol;
    requestSanadElAmer.reference_id = String(this.loan.loanId);
    requestSanadElAmer.max_approve_duration = '10080';
    let sanadRequest: SanadRequestSanadElAmerEntity[] = [];
    let dueDate = this.calculateLastRepaymentDate(this.loan);
    let sanadRequestToSend: SanadRequestSanadElAmerEntity =
      new SanadRequestSanadElAmerEntity();
    sanadRequest.push(sanadRequestToSend);
    sanadRequest[0].due_type = 'date';
    sanadRequest[0].due_date = dueDate;
    sanadRequest[0].reference_id = this.loan.accountNumber;
    sanadRequest[0].total_value = this.loan.approvelAmount;
    requestSanadElAmer.sanad = sanadRequest;

    await this.thirdPartyService
      .createSingleSanadElAmer(this.loan.loanId)
      .toPromise()
      .then((dataSanadElAmer) => {
        if (dataSanadElAmer) {
          this.loan.actionToAcm = 'APPROVE';
          this.loanService.updateLoan(this.loan).subscribe((res) => {
            this.sharedService.setLoanDeal(res);
          });
        }
        this.router.navigate(['/' + PathConstants.PATH_HOME]);
      });
    this.sharedService.showInfo(
      this.translate.instant('TITLE.sanad_al_amr') + '...'
    );
  }

  calculateLastRepaymentDate(loan: LoanEntityIB): string {
    if (loan && loan.initialPaymentDate && loan.termPeriodNum) {
      const initialPaymentDate = new Date(loan.initialPaymentDate);
      initialPaymentDate.setMonth(
        initialPaymentDate.getMonth() + loan.termPeriodNum
      );

      const year = initialPaymentDate.getFullYear();
      const month = (initialPaymentDate.getMonth() + 1)
        .toString()
        .padStart(2, '0');
      const day = initialPaymentDate.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    }
    return '';
  }
  sendOTP() {
    this.thirdPartyService
      .sendOtpDeewanApi(
        this.requestSendSms,
        this.sharedService.getNationalId(),
        this.loan.idIbLoan,
        false
      )
      .subscribe();
    this.showOTP = true;
  }
  checkSanadAlAmr() {
    let counter = 0;
    setTimeout(() => {
      this.synchronizeCheckID();
      counter++;
    }, 2000);
  }
  async synchronizeCheckID() {
    this.loan = this.sharedService.getLoanDeal();
    let currentIndex = 0;
    const syncApiRequest = async () => {
      if (currentIndex < 2) {
        try {
          this.thirdPartyService
            .getSanadAlAmrID(this.loan.idIbLoan)
            .subscribe((res) => {
              if (res.url != '' && res != null && res != undefined) {
                this.sanadAlAmrId = res;
              }
            });

          if (currentIndex == 1) {
            if (!this.checker) {
              this.showButtonLater = true;
            }
          }
          currentIndex++;
        } catch (error) {
          console.error('API error:', error);
        }
      }
    };
    while (currentIndex < 2) {
      await syncApiRequest();
      await new Promise((resolve) => setTimeout(resolve, 2000));
    }
  }

  sanadAlAmrUrl() {
    this.router.navigate([this.sanadAlAmrId]);
  }

  getDocuments() {
    const document: LoanDocumentEntity = new LoanDocumentEntity();
    document.idCustomer = this.customer.ibCustomerId;
    document.loanId = this.loan.idIbLoan;
    document.settingDocumentTypeDTO = new SettingDocumentTypeEntity();
    document.settingDocumentTypeDTO.categorie =
      AcmConstants.TYPE_DOCUMENT_CATEGORIE_CUSTOMER;
    this.documentService
      .getDocumentsByLoan(document)
      .subscribe((arrayDocuments) => {
        if (arrayDocuments.length > 0) {
          arrayDocuments.map((loanDocument) => {
            let found = false;
            this.costumerDocuments.map((type) => {
              if (
                loanDocument.titre === type.settingDocumentType.libelle &&
                loanDocument.documentIndex === type.documentIndex
              ) {
                type.name = loanDocument.name;
                type.settingDocumentType = loanDocument.settingDocumentTypeDTO;
                type.date = this.datePipe.transform(
                  loanDocument.dateCreation,
                  'yyyy/MM/dd'
                );
                type.idfile = loanDocument.idDocumentGED;
                type.file = loanDocument.idDocumentGED;
                type.idDocument = loanDocument.idDocument;
                type.documentIndex = loanDocument.documentIndex;
                found = true;
              }
            });
            if (!found) {
              if (
                loanDocument.settingDocumentTypeDTO.categorie ===
                AcmConstants.TYPE_DOCUMENT_CATEGORIE_CUSTOMER
              ) {
                const newDocument: DocumentTypeEntity =
                  new DocumentTypeEntity();
                newDocument.settingDocumentType =
                  loanDocument.settingDocumentTypeDTO;
                newDocument.title = loanDocument.titre;
                newDocument.name = loanDocument.name;
                newDocument.date = this.datePipe.transform(
                  loanDocument.dateCreation,
                  'yyyy/MM/dd'
                );
                newDocument.idfile = loanDocument.idDocumentGED;
                newDocument.file = loanDocument.idDocumentGED;
                newDocument.idDocument = loanDocument.idDocument;
                newDocument.documentIndex = loanDocument.documentIndex;
                this.costumerDocuments.push(newDocument);
              }
            }
          });
          this.check();
        } else {
          const documentIdCustomer: LoanDocumentEntity =
            new LoanDocumentEntity();
          documentIdCustomer.idCustomer = this.customer.ibCustomerId;
          documentIdCustomer.processLoanDocuments = true;
          documentIdCustomer.settingDocumentTypeDTO =
            new SettingDocumentTypeEntity();
          documentIdCustomer.settingDocumentTypeDTO.categorie =
            AcmConstants.TYPE_DOCUMENT_CATEGORIE_CUSTOMER;
          this.documentService
            .getDocumentsByLoan(documentIdCustomer)
            .subscribe((arrayDocumentsCustomer) => {
              if (arrayDocumentsCustomer.length > 0) {
                arrayDocumentsCustomer.map((customerDocument) => {
                  let found = false;
                  if (customerDocument.loanId === null) {
                    this.costumerDocuments.map((type) => {
                      if (
                        customerDocument.titre ===
                          type.settingDocumentType.libelle &&
                        customerDocument.documentIndex === type.documentIndex
                      ) {
                        type.name = customerDocument.name;
                        type.settingDocumentType =
                          customerDocument.settingDocumentTypeDTO;
                        type.date = this.datePipe.transform(
                          customerDocument.dateCreation,
                          'yyyy/MM/dd'
                        );
                        type.idfile = customerDocument.idDocumentGED;
                        type.file = customerDocument.idDocumentGED;
                        type.idDocument = null;
                        type.documentIndex = customerDocument.documentIndex;
                        type.exist = true;
                        found = true;
                      }
                    });
                    if (!found) {
                      if (
                        customerDocument.settingDocumentTypeDTO.categorie ===
                        AcmConstants.TYPE_DOCUMENT_CATEGORIE_CUSTOMER
                      ) {
                        const newDocument: DocumentTypeEntity =
                          new DocumentTypeEntity();
                        newDocument.settingDocumentType =
                          customerDocument.settingDocumentTypeDTO;
                        newDocument.title = customerDocument.titre;
                        newDocument.name = customerDocument.name;
                        newDocument.date = this.datePipe.transform(
                          customerDocument.dateCreation,
                          'yyyy/MM/dd'
                        );
                        newDocument.idfile = customerDocument.idDocumentGED;
                        newDocument.file = customerDocument.idDocumentGED;
                        newDocument.idDocument = null;
                        newDocument.documentIndex =
                          customerDocument.documentIndex;
                        newDocument.exist = true;
                        this.costumerDocuments.push(newDocument);
                      }
                    }
                  }
                });
                this.check();
              }
            });
        }
      });
  }
  check() {
    this.allDocuments = [];
    this.costumerDocuments.map((value) => {
      if (typeof value.file === 'string' && value.file !== '') {
        this.documentService
          .getDocument(value.file, this.loan.loanId.toString(), null)
          .subscribe(
            async function (val, result) {
              const file0: File = new File([result], val.name);
              val.file = file0;
              this.allDocuments.push(val);
            }.bind(this, value)
          );
      } else {
        this.allDocuments.push(value);
      }
    });
    this.loanDocuments.map((value) => {
      if (value.file !== '' && value.idDocument === null) {
        this.allDocuments.push(value);
      }
    });
  }
}
