import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthentificationService } from 'src/app/ib-services/authentification.service';
import { SettingClaimsService } from 'src/app/ib-services/claims.service';
import { SharedService } from 'src/app/ib-services/shared.service';
import { ClaimsEntity } from 'src/app/shared/Entities/claims.entity';
import { CustomerEntity } from 'src/app/shared/Entities/customer.entity';
import { SettingClaimsEntity } from 'src/app/shared/Entities/setting-claims.entity';
import { PathConstants } from 'src/app/shared/mb-constants/path-constants';

@Component({
  selector: 'app-claims',
  templateUrl: './claims.component.html',
  styleUrls: ['./claims.component.scss'],
})
export class ClaimsComponent implements OnInit {
  public showAddClaim: boolean = false;
  public showListClaims: boolean = false;

  public selectedSubject: any;
  public lang: string;
  public isLogged: boolean;
  public claimForm: FormGroup;

  /** Claims Values Declaration */
  public settingClaims: SettingClaimsEntity = new SettingClaimsEntity();
  public lstClaimsSetting: SettingClaimsEntity[] = [];
  public newClaim: ClaimsEntity = new ClaimsEntity();
  public lstClaims: ClaimsEntity[] = [];
  public claimsForFind: ClaimsEntity = new ClaimsEntity();
  public submittedForm : boolean = false;
  public mobileNumberPattern = /^5[013456789]\d{7}$/;

  constructor( public sharedService: SharedService, private claimsService: SettingClaimsService, private authService: AuthentificationService,
               private formBuilder: FormBuilder, private router : Router, private translate : TranslateService) {}

  async ngOnInit() {
    this.isLogged = this.authService.getIsLoggedIn();
    this.lang = localStorage.getItem('lang');
    this.settingClaims.category = this.isLogged ? 'CUSTOMER' : 'PROSPECT';
    await this.claimsService.findsettingClaimsByCategory(this.settingClaims).subscribe((result) => {
        this.lstClaimsSetting = result;
      });

    if (!this.isLogged) {
      this.createClaimFormForNonConnectedUser();
      this.showAddClaim = true;
      this.showListClaims = false;
    } else {
      this.createClaimFormForConnectedUser();
      this.loadListclaims();
    }
  }

  createClaimFormForNonConnectedUser() {
    this.claimForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      phone_number: ['', Validators.required],
      subject: ['', Validators.required],
      body: ['', Validators.required],
    });
  }

  createClaimFormForConnectedUser() {
    this.claimForm = this.formBuilder.group({
      subject: ['', Validators.required],
      body: ['', Validators.required],
    });
  }

  submitClaim() {
    this.submittedForm = true;
    this.claimForm.controls['subject'].setValue(this.selectedSubject);

    if (!this.isLogged) {
      if (this.claimForm.valid){
        this.newClaim.status = 'NEW';
        this.newClaim.subject = this.claimForm.controls['subject'].value.id;
        this.newClaim.body = this.claimForm.controls['body'].value;
        this.newClaim.idCustomer = null;
        this.newClaim.name = this.claimForm.controls['name'].value;
        this.newClaim.phone = this.claimForm.controls['phone_number'].value;
        this.newClaim.email = this.claimForm.controls['email'].value;
        this.claimsService.saveClaims(this.newClaim).subscribe((res) => {
          this.sharedService.showSuccess(this.translate.instant('INFO.claim_added'));
          this.claimForm.reset();
          this.submittedForm = false;
          this.router.navigate(['']);
        });
      }else {
        this.sharedService.showError(this.translate.instant('ERR.fill_required_fields'));
      }

    } else {
      if (this.claimForm.valid){
        let customer: CustomerEntity;
        customer = this.sharedService.getCustomer();
        this.newClaim.status = 'NEW';
        this.newClaim.subject = this.claimForm.controls['subject'].value.id;
        this.newClaim.body = this.claimForm.controls['body'].value;
        this.newClaim.idCustomer = customer.ibCustomerId;
        this.newClaim.name = customer.customerName;
        this.newClaim.phone = customer.telephone1;
        this.newClaim.email = customer.email;
        this.claimsService.saveClaims(this.newClaim).subscribe((res) => {
          this.sharedService.showSuccess(this.translate.instant('INFO.claim_added'));
          this.claimForm.reset();
          this.submittedForm = false;
          this.loadListclaims();
          this.showAddClaim = false;
          this.showListClaims = false;
        });
      }else {
        this.sharedService.showError(this.translate.instant('ERR.fill_required_fields'));
      }
    }


  }

  checklength(event: any, type: string) {
    const inputValue = event.target.value;
    if (type === 'phone_number' && inputValue.length >= 9) {
      const limitedValue = inputValue.substring(0, 9);
      this.claimForm.controls['phone_number'].setValue(limitedValue);
    }
  }

  async loadListclaims() {
    this.claimsForFind.idCustomer = this.sharedService.getCustomer().ibCustomerId;
    await this.claimsService.findClaims(this.claimsForFind).subscribe((res) => {
      this.lstClaims = res;
      this.lstClaims.forEach((claim) => {
        this.lstClaimsSetting.forEach((setting) => {
          if (setting.id == parseInt(claim.subject))
            claim.subjectString = setting.subject;
        });
      });
    });
  }

  viewClaim(index : number, claim : ClaimsEntity){
    this.sharedService.setClaim(claim);
    let subjectEntity = this.lstClaimsSetting.find( (res) => res.id == parseInt(claim.subject));
    this.sharedService.setSubject(subjectEntity);
    this.router.navigate(['/' + PathConstants.PATH_VIEW_CLAIM]);
  }

  returnSubjectCategory(claim : ClaimsEntity): string{
    let subjectEntity = this.lstClaimsSetting.find( (res) => res.id == parseInt(claim.subject));
    return subjectEntity.subject;
  }

}
