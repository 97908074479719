<div id="edit_income_details" class="bg-2 px-3">
  <app-screen-title *ngIf="inputMode != 'mode1'&& mode!=='update'" [title]="'TITLE.expenses_obligations'" [color]="'color1'" [backTo]="'/personal-information'"></app-screen-title>
  <app-screen-title *ngIf="mode ==='update'" [title]="'TITLE.expenses_obligations'" [color]="'color1'" [backTo]="'/summary-initial-approval'"></app-screen-title>
  <!-- <legend  *ngIf="inputMode == 'mode1'" class="fsl color1 fw-600 fls">{{'TITLE.expenses_obligations'|translate}}</legend> -->

  <div id="obligations_expenses" class="mt-4">


    <div class="card cu-card p-2 mb-2" *ngIf="inputMode != 'mode1'">
      <label class="ms-2  text-center" > {{'LABELS.total_income' | translate}} </label>
      <label class="ms-2  text-center color1 fsxxl fw-600"> {{totalIncome}} </label>
      <label class="ms-2 text-center" style="font-size: x-small;"> {{ 'CURRENCY.monthly_sar' | translate }}  </label>
    </div>

    <!-- Monthly Obligations -->
    <div id="monthly-obligations" class="mb-4">
      <label class="label-subtitle mb-2 ms-2">{{ 'LABELS.monthly_obligations' | translate }}</label>
      <div class="mb-2">
        <label class="form-label ms-2 ">  {{ 'LABELS.QUESTIONS.monthly_commitments' | translate }}  </label>
        <div style="position: relative;">
          <div class="radio-container">
            <input class="m-1" type="radio" id="radio1" name="options" [(ngModel)]="monthlyOblig" [value]="true">
            <label for="radio1" class="form-label">{{'LABELS.yes' | translate}}</label>
            <input class="m-1" type="radio" id="radio2" name="options" [(ngModel)]="monthlyOblig" [value]="false">
            <label for="radio2" class="form-label">{{'LABELS.no' | translate}}</label>
          </div>
        </div>
      </div>
      <div class="mb-2" *ngIf="monthlyOblig===true">
        <label class="form-label ms-2 ">{{ 'LABELS.total_monthly_installment' | translate }}</label>
        <div style="position: relative;">
          <span class="m-2 p-2" style="position: absolute; color: #808080; left:0;"> {{'CURRENCY.sar'|translate}} </span>
          <input type="number" class="form-control input-padding" placeholder="{{ 'PLACEHOLDER.enter_total_monthly_installments' | translate }}" [(ngModel)]="monthlyInstallment" >
        </div>
      </div>
    </div>

    <hr>

    <!-- Living Expenses -->
    <div id="living-expenses">
      <label class="label-subtitle mb-2 ms-2">{{ 'LABELS.living_expenses' | translate }}</label>
      <div class="mb-2">
        <label class="form-label ms-2 ">{{ 'LABELS.food_expenses' | translate }}</label>
        <div style="position: relative;">
          <span class="m-2 p-2" style="position: absolute; color: #808080;left:0;"> {{'CURRENCY.sar'|translate}} </span>
          <input type="number" class="form-control input-padding" placeholder="{{ 'PLACEHOLDER.enter_food_expenses' | translate }}" [(ngModel)]="foodExp" >
        </div>
      </div>
      <div class="mb-2">
        <label class="form-label ms-2 ">{{ 'LABELS.housing_expenses_and_services' | translate }}</label>
        <div style="position: relative;">
          <span class="m-2 p-2" style="position: absolute; color: #808080;left:0;"> {{'CURRENCY.sar'|translate}} </span>
          <input type="number" class="form-control input-padding" placeholder="{{ 'PLACEHOLDER.enter_housing_expenses' | translate }}" [(ngModel)]="housing">
        </div>
      </div>
      <div class="mb-2">
        <label class="form-label ms-2 ">{{ 'LABELS.education_expenses' | translate }}</label>
        <div style="position: relative;">
          <span class="m-2 p-2" style="position: absolute; color: #808080;left:0;"> {{'CURRENCY.sar'|translate}} </span>
          <input type="number" class="form-control input-padding" placeholder="{{ 'PLACEHOLDER.enter_education_expenses' | translate }}" [(ngModel)]="educExp">
        </div>
      </div>
      <div class="mb-2">
        <label class="form-label ms-2 ">{{ 'LABELS.transport_and_communication_expenses' | translate }}</label>
        <div style="position: relative;">
          <span class="m-2 p-2" style="position: absolute; color: #808080;left:0;"> {{'CURRENCY.sar'|translate}} </span>
          <input type="number" class="form-control input-padding" placeholder="{{ 'PLACEHOLDER.enter_transport_and_communication_expenses' | translate }}" [(ngModel)]="transportExp">
        </div>
      </div>
      <div class="mb-2">
        <label class="form-label ms-2 ">{{ 'LABELS.insurance_expenses' | translate }}</label>
        <div style="position: relative;">
          <span class="m-2 p-2" style="position: absolute; color: #808080;left:0;"> {{'CURRENCY.sar'|translate}} </span>
          <input type="number" class="form-control input-padding" placeholder="{{ 'PLACEHOLDER.enter_insurance_expenses' | translate }}" [(ngModel)]="insuranceExpenses">
        </div>
      </div>
      <div class="mb-2">
        <label class="form-label ms-2 ">{{ 'LABELS.health_care_expenses' | translate }}</label>
        <div style="position: relative;">
          <span class="m-2 p-2" style="position: absolute; color: #808080;left:0;"> {{'CURRENCY.sar'|translate}} </span>
          <input type="number" class="form-control input-padding" placeholder="{{ 'PLACEHOLDER.enter_health_care_expenses' | translate }}" [(ngModel)]="healthCareExpenses">
        </div>
      </div>
      <div class="mb-2">
        <label class="form-label ms-2 ">{{ 'LABELS.domestic_labor_expenses' | translate }}</label>
        <div style="position: relative;">
          <span class="m-2 p-2" style="position: absolute; color: #808080;left:0;"> {{'CURRENCY.sar'|translate}} </span>
          <input type="number" class="form-control input-padding" placeholder="{{ 'PLACEHOLDER.enter_domestic_labor_expenses' | translate }}" [(ngModel)]="domesticExp">
        </div>
      </div>
      <div class="mb-2">
        <label class="form-label ms-2 ">{{ 'LABELS.other_monthly_expenses' | translate }}</label>
        <div style="position: relative;">
          <span class="m-2 p-2" style="position: absolute; color: #808080;left:0;"> {{'CURRENCY.sar'|translate}} </span>
          <input type="number" class="form-control input-padding" placeholder="{{ 'PLACEHOLDER.enter_other_monthly_expenses' | translate }}" [(ngModel)]="otherExp">
        </div>
      </div>
      <div class="mb-2">
        <label class="form-label ms-2 ">{{ 'LABELS.monthly_international_transfer' | translate }}</label>
        <div style="position: relative;">
          <span class="m-2 p-2" style="position: absolute; color: #808080;left:0;"> {{'CURRENCY.sar'|translate}} </span>
          <input type="number" class="form-control input-padding" placeholder="{{ 'PLACEHOLDER.enter_international_tranfer' | translate }}" [(ngModel)]="internationalExp">
        </div>
      </div>

      <div class="text-center pt-2  pb-5">
        <button type="button" class="btn btn-primary btn-lg mb-2 login-btn" (click)="submit()">
          {{'BUTTONS.update' | translate}}
        </button>
      </div>

    </div>

  </div>

  <!-- <div id="obligations_expenses_old_sanad" class="px-3"> -->
    <!-- <div class="mb-2">
      <label class="form-label ms-2 fw-650">{{'LABELS.housing' | translate}}</label>
      <div style="position: relative;">
        <input type="number" class="form-control" placeholder="" [(ngModel)]="housing" id="housing" (focus)="housingError=false;showExpensesAlert=false;">
      </div>
    </div>
    <div class="mb-2">
      <label class="form-label ms-2 fw-650">{{'LABELS.food_living_expenses' | translate}}</label>
      <div style="position: relative;">
        <input type="number" class="form-control" placeholder="" [(ngModel)]="livingExpenses" id="livingExpenses" (focus)="livingExpensesError=false;showExpensesAlert=false;">
      </div>
    </div>
    <div class="mb-2">
      <label class="form-label ms-2 fw-650">{{'LABELS.education_supplies' | translate}}</label>
      <div style="position: relative;">
        <input type="number" class="form-control" placeholder=""  [(ngModel)]="supplyExpenses" id="supplyExpenses" (focus)="supplyExpensesError=false;showExpensesAlert=false;">
      </div>
    </div>
    <div class="mb-2">
      <label class="form-label ms-2 fw-650">{{'LABELS.healthcare_insurance' | translate}}</label>
      <div style="position: relative;">
        <input type="number" class="form-control" placeholder="" [(ngModel)]="insuranceExpenses" id="insuranceExpenses" (focus)="insuranceExpensesError=false;showExpensesAlert=false;">
      </div>
    </div>
    <div class="mb-2">
      <label class="form-label ms-2 fw-650">{{'LABELS.transportation_communication' | translate}}</label>
      <div style="position: relative;">
        <input type="number" class="form-control" placeholder="" [(ngModel)]="communicationExpenses" id="insuranceExpenses" (focus)="communicationExpensesError=false;showExpensesAlert=false;">
      </div>
    </div>
    <div class="mb-2">
      <label class="form-label ms-2 fw-650">{{'LABELS.utility_expenses' | translate}}</label>
      <div style="position: relative;">
        <input type="number" class="form-control" placeholder="" [(ngModel)]="utilityExpenses" id="utilityExpenses" (focus)="utilityExpensesError=false;showExpensesAlert=false;">
      </div>
    </div> -->
    <!-- <div class="text-center pt-2">
      <button type="button" class="btn btn-primary btn-lg mb-2 login-btn" (click)="submit()" *ngIf="inputMode != 'mode1'">
        {{'BUTTONS.update' | translate}}
      </button> -->
      <!-- <button type="button" class="btn btn-primary btn-lg  login-btn" (click)="submit()" *ngIf="inputMode == 'mode1'">
        {{'BUTTONS.next' | translate}}
      </button> -->
    <!-- </div> -->
  <!-- </div> -->

</div>
