import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthentificationService } from 'src/app/ib-services/authentification.service';
import { SharedService } from 'src/app/ib-services/shared.service';
import { CustomerEntity } from 'src/app/shared/Entities/customer.entity';
import { LoanEntityIB } from 'src/app/shared/Entities/loan.entity';
import { NativeBiometric } from '@capgo/capacitor-native-biometric';
import { environment } from 'src/environments/environment';
import { RequestSendSmsDeewanApiEntity } from 'src/app/shared/Entities/RequestSendSmsDeewanApi.entity';
import { PathConstants } from 'src/app/shared/mb-constants/path-constants';
import { CustomerService } from 'src/app/ib-services/customer.service';
import { LoanService } from 'src/app/ib-services/loan.service';
import { ThirdPartyService } from 'src/app/ib-services/third-party.service';

@Component({
  selector: 'app-otp-biometrics-validation',
  templateUrl: './otp-biometrics-validation.component.html',
  styleUrls: ['./otp-biometrics-validation.component.scss'],
})
export class OtpBiometricsValidationComponent implements OnInit {
  @Input() source: string;
  @Output() messageEvent = new EventEmitter<any>();
  @ViewChild('ngOtpInput') ngOtpInputRef: any;

  public showBiometricDiv: boolean = false;
  public showOtpDiv: boolean = true;
  public counter = 60;
  public title: string = '';
  public code: string;
  public customer: CustomerEntity;
  public customerList: CustomerEntity[];
  public customerNumber: string;
  public loan: LoanEntityIB;
  public biometricErrorMessage: string = '';
  public biometricsStatus: Boolean = false;
  public lang: string;
  public disableCheckOtp: boolean = true;
  public logged: boolean = false;

  constructor(
    private translate: TranslateService,
    private router: Router,
    private sharedService: SharedService,
    private authService: AuthentificationService,
    private customerService: CustomerService,
    private loanService: LoanService,
    private thirdPartyService: ThirdPartyService
  ) {}

  ngOnInit() {
    this.lang = this.sharedService.getLang();
    let lang = localStorage.getItem('lang');
    if (lang === 'null' || lang === 'undefined')
      localStorage.setItem('lang', this.lang);
    this.logged = this.authService.getIsLoggedIn();
    let nationalId = this.sharedService.getNationalId();
    if (!this.customer && nationalId) {
      this.customer = new CustomerEntity();
      this.customer.identity = this.sharedService.getNationalId();
      this.customerService
        .getCustomerByIdentity(this.customer.identity)
        .subscribe((res) => {
          this.customerNumber = res.telephone1;
          this.customerNumber = 'xxxxxx' + this.customerNumber.slice(-3);
          this.sharedService.setCustomer(res);
        });
    } else {
      this.customerNumber = this.customer.telephone1;
      this.customerNumber = 'xxxxxx' + this.customerNumber.slice(-3);
    }
    this.loan = this.sharedService.getLoanDeal();
    this.countdown();
  }

  async nextBiometrics() {
    try {
      if (this.source === 'sign-contract') this.logged = true;
      if (this.code != null && this.code != undefined && this.code != '') {
        if (this.logged) {
          this.thirdPartyService
            .checkOtpDeewanApi(this.sharedService.getNationalId(), this.code)
            .subscribe(
              async (response) => {
                if (this.source === 'sign-contract') {
                  this.loan.actionToAcm = 'APPROVE_SIGN';
                  this.loanService.updateLoan(this.loan).subscribe((res) => {
                    this.sharedService.setLoanDeal(res);
                    this.sharedService.showInfo(
                      this.translate.instant('INFO.contract_signing')
                    );
                    this.router.navigate(['/' + PathConstants.PATH_HOME]);
                  });
                } else if (this.source === 'account-details') {
                  this.messageEvent.emit(true);
                } else {
                  const user = await this.authService.curentUser().toPromise();
                  this.sharedService.setUser(user);
                  this.authService.setIsLoggedIn(true);
                  if (this.sharedService.getUserHadBiometricCredentials()) {
                    this.router.navigate(['/' + PathConstants.PATH_HOME]);
                  } else {
                    this.showBiometricDiv = true;
                    this.showOtpDiv = false;
                  }
                }
              },
              (error) => {
                this.disableCheckOtp = false;
                if (error.error.errorCode === 'ACM-00001') {
                  this.ngOtpInputRef.setValue('');
                  this.sharedService.showError(
                    this.translate.instant('ERR.otp_not_valid')
                  );
                  this.counter = 0;
                } else {
                  this.sharedService.showError(
                    this.translate.instant('ERR.code_otp_incorrect')
                  );
                }
              }
            );
        } else {
          this.thirdPartyService
            .checkOtpForgetPasswordDeewanApi(
              this.sharedService.getNationalId(),
              this.code
            )
            .subscribe(
              async (response) => {
                this.ngOtpInputRef.setValue('');
                const user = await this.authService.curentUser().toPromise();
                this.sharedService.setUser(user);
                this.authService.setIsLoggedIn(true);
                if (this.sharedService.getUserHadBiometricCredentials()) {
                  this.router.navigate(['/' + PathConstants.PATH_HOME]);
                } else {
                  this.showBiometricDiv = true;
                  this.showOtpDiv = false;
                }
              },
              (error) => {
                this.disableCheckOtp = false;
                if (error.error.errorCode === 'ACM-00001') {
                  this.sharedService.showError(
                    this.translate.instant('ERR.otp_not_valid')
                  );
                } else {
                  this.sharedService.showError(
                    this.translate.instant('ERR.code_otp_incorrect')
                  );
                }
              }
            );
        }
      } else {
        this.sharedService.showError(
          this.translate.instant('ERR.enter_otp_code')
        );
      }
    } catch (error) {
      this.disableCheckOtp = false;
      if (error.error.errorCode === 'ACM-00001') {
        this.sharedService.showError(
          this.translate.instant('ERR.otp_not_valid')
        );
      } else {
        this.sharedService.showError(
          this.translate.instant('ERR.code_otp_incorrect')
        );
      }
    }
  }

  /*** Method for setting Biometric credentials */
  async performBiometricVerification() {
    await NativeBiometric.setCredentials({
      username: this.sharedService.getNationalId(),
      password: this.sharedService.getPassword(),
      server: environment.oauthUrl,
    });

    this.sharedService.setPassword('');

    const result = await NativeBiometric.isAvailable();

    if (!result.isAvailable) {
      console.log('Biometric authentication not available on this device');
      this.sharedService.showInfo(this.translate.instant('TOAST.INFO.biom_not_availble'))
      return false;
    }

    // const isFaceID = result.biometryType == BiometryType.FACE_ID;

    // if (!isFaceID) {
    //   console.log('Face ID not available on this device');
    //   this.biometricErrorMessage = 'Face ID not available on this device';
    //   return false;
    // }

    const verified = await NativeBiometric.verifyIdentity({
      reason: 'For easy login',
      title: 'Synchronize your account & Secure',
    })
      .then(() => true)
      .catch(() => false);

    if (!verified) {
      this.biometricErrorMessage = this.translate.instant(
        'BIOMETRIC.ERROR.error_fp'
      );
      return false;
    }
    this.sharedService.setUserHadBiometricCredentials(true);
    this.connect();
    return true;
  }

  reInitializer() {
    const request: RequestSendSmsDeewanApiEntity =
      new RequestSendSmsDeewanApiEntity();
    this.thirdPartyService
      .sendOtpDeewanApi(
        request,
        this.customer.identity,
        Number(this.customer.identity),
        false
      )
      .subscribe(
        (data) => {
          this.disableCheckOtp = true;
          this.ngOtpInputRef.setValue('');
          this.counter = 60;
          this.countdown();
        },
        (error) => {
          this.sharedService.showError(this.translate.instant('ERR.otp.limit'));
        }
      );
  }

  countdown(): void {
    let countdown = setInterval(() => {
      if (this.counter > 0) {
        this.counter--;
      } else {
        clearInterval(countdown);
      }
    }, 1000);
  }

  onOtpChange(event) {
    this.code = event;
  }

  async connect() {
    if (localStorage.getItem('currentUser') != null) {
      if (localStorage.getItem('type') === 'user') {
       await this.authService.curentUser().toPromise()
        .then(
          (data) => {
            this.sharedService.setUser(data);
            this.authService.setIsLoggedIn(true);
            this.router.navigate(['/' + PathConstants.PATH_HOME]);
          }
        );
      }
    }
  }

}
