import { ProductDetailsEntity } from "./productDetails.entity";


export class ProductEntity {
    /** The id. */
    id: number;
    /** The code. */
    code: string;
    /** The description. */
    description: string;
    /** The maximum balance. */
    maximumBalance: number;
    /** The minimum term. */
    minimumTerm: number;
    /** The maximum term. */
    maximumTerm: number;
    /** The id product abacus. */
    productIdAbacus: number;
    /** The product individual. */
    productIndiv: boolean;
    /** The product group. */
    productGrp: boolean;
    /** The product organization. */
    productOrg: boolean;
    /** The rate start date. */
    rateStartDate: Date;
    /** The rate end date. */
    rateEndDate: Date;
    /** The rate. */
    rate: number;
    decimal: number;
    currency: string;
    productDetailsDTOs: ProductDetailsEntity[];
    issueFeePercentage1: number;
    issueFeePercentage2: number;
    minimumBalance: number;
    /** The maximum age. */
    maximumAge: number;
    /** The minimum age. */
    minimumAge: number;
    /** The max accounts. */
    maxAccounts: number;
    /** The maximum deferred period. */
    maximumDeferredPeriod: number;
    /** The minimum deferred period. */
    minimumDeferredPeriod: number;
    cuInsuranceID: number;
    issueFeeVAT1: number;
    issueFeeVAT2: number;
    /** The max accounts. */
    selectedProduct:boolean;
    /** roundType (UP/DOWN) */
    roundType: string;
    issueFeeAmount1: number;
    issueFeeAmount2: number;
    flatInterestRate: number;
    aboutProduct : string;
}
