<div class="bg-2 vh-100 vw-100 ">
  <app-screen-title [title]="'payment_mode'" [color]="'color1'" [backTo]="'/loan-schedule-details'"></app-screen-title>

  <!-- Card Type Selection -->
  <div id="card-type-selection" *ngIf="showCardTypesList">
    <div class="cu-cbody" style="overflow-y: auto;overflow-x: hidden;padding : 5px !important">
      <ng-container *ngIf="currentStep === 0 && !showPaymentFrame">
        <br>
        <div class="accordion">
          <div class="card mb-2 px-2" style="filter: drop-shadow(1px 1px 1px gray);">
            <div class="card-body">
              <!-- Apple Pay -->
              <div class="row my-2 mb-2">
                <div class="col-1 align-self-center">
                  <input name="inputName" type="radio" (change)="onOptionSelected('Apple Pay')" />
                </div>
                <div class="col-3">
                  <div class="avatar-newTransaction avatar avatar-60 p-1 shadow-sm shadow-secondary rounded-15">
                    <div class="icons bg-master-card text-white rounded-12">
                      <img src="assets/images/paiementTypes/applePay.PNG" alt=""
                        class="vm mw-100 displayPaymentIcons" />
                    </div>
                  </div>
                </div>
                <div class="col-8 align-self-center">
                  <p>Apple Pay<br /></p>
                </div>
              </div>

              <!-- Visa Card -->
              <div class="row my-2 mb-2">
                <div class="col-1 align-self-center">
                  <input name="inputName" type="radio" (change)="onOptionSelected('Visa Card')" />
                </div>
                <div class="col-3">
                  <div class="avatar-newTransaction avatar avatar-60 p-1 shadow-sm shadow-secondary rounded-15">
                    <div class="icons bg-visa-card text-white rounded-12">
                      <img src="assets/images/paiementTypes/visa.png" alt="" class="vm mw-100 displayPaymentIcons" />
                    </div>
                  </div>
                </div>
                <div class="col-8 align-self-center">
                  <p>Visa card<br /></p>
                </div>
              </div>

              <!-- Master Card -->
              <div class="row my-2 mb-2">
                <div class="col-1 align-self-center">
                  <input name="inputName" type="radio" (change)="onOptionSelected('Master Card')" />
                </div>
                <div class="col-3">
                  <div class="avatar-newTransaction avatar avatar-60 p-1 shadow-sm shadow-secondary rounded-15">
                    <div class="icons bg-master-card text-white rounded-12">
                      <img src="assets/images/paiementTypes/masterCard.png" alt=""
                        class="vm mw-100 displayPaymentIcons" />
                    </div>
                  </div>
                </div>
                <div class="col-8 align-self-center">
                  <p>Master card<br /></p>
                </div>
              </div>

              <!-- MADA card -->
              <div class="row my-2 mb-2">
                <div class="col-1 align-self-center">
                  <input name="inputName" type="radio" (change)="onOptionSelected('Mada Card')" />
                </div>
                <div class="col-3">
                  <div class="avatar-newTransaction avatar avatar-60 p-1 shadow-sm shadow-secondary rounded-15">
                    <div class="icons bg-master-card text-white rounded-12">
                      <img src="assets/images/paiementTypes/madaPay.PNG" alt="" class="vm mw-100 displayPaymentIcons" />
                    </div>
                  </div>
                </div>
                <div class="col-8 align-self-center">
                  <p>MADA card<br /></p>
                </div>
              </div>
              <!-- STC card -->
              <div class="row my-2 mb-2">
                <div class="col-1 align-self-center">
                  <input name="inputName" type="radio" (change)="onOptionSelected('STC Card')" />
                </div>
                <div class="col-3">
                  <div class="avatar-newTransaction avatar avatar-60 p-1 shadow-sm shadow-secondary rounded-15">
                    <div class="icons bg-master-card text-white rounded-12">
                      <img src="assets/images/paiementTypes/stcPay.PNG" alt="" class="vm mw-100 displayPaymentIcons" />
                    </div>
                  </div>
                </div>
                <div class="col-8 align-self-center">
                  <p>STC Pay<br /></p>
                </div>
              </div>
              <!-- Qitaf card -->
              <div class="row my-2 mb-2">
                <div class="col-1 align-self-center">
                  <input name="inputName" type="radio" (change)="onOptionSelected('Qitaf Card')" />
                </div>
                <div class="col-3">
                  <div class="avatar-newTransaction avatar avatar-60 p-1 shadow-sm shadow-secondary rounded-15">
                    <div class="icons bg-master-card text-white rounded-12">
                      <img src="assets/images/paiementTypes/qitafPay.PNG" alt=""
                        class="vm mw-100 displayPaymentIcons" />
                    </div>
                  </div>
                </div>
                <div class="col-8 align-self-center">
                  <p>Qitaf Points<br /></p>
                </div>
              </div>

              <!-- American express card -->
              <div class="row my-2 mb-2">
                <div class="col-1 align-self-center">
                  <input name="inputName" type="radio" (change)="onOptionSelected('American Express Card')" />
                </div>
                <div class="col-3">
                  <div class="avatar-newTransaction avatar avatar-60 p-1 shadow-sm shadow-secondary rounded-15">
                    <div class="icons bg-master-card text-white rounded-12">
                      <img src="assets/images/paiementTypes/americanExpressPay.PNG" alt=""
                        class="vm mw-100 displayPaymentIcons" />
                    </div>
                  </div>
                </div>
                <div class="col-8 align-self-center">
                  <p>American Express card<br /></p>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div class="text-center pb-5">
          <button type="button" class="btn btn-primary btn-lg pb-3 mb-4 login-btn" (click)="confirmCardSelection()"
            [disabled]="!isOptionSelected">
            {{'BUTTONS.next' | translate}}
          </button>
        </div>
        <!-- Space Padding if user connected -->
        <!-- <div *ngIf="sharedService.getUser().login !== undefined" style="margin-bottom: 8rem;"></div> -->

        <!-- <div id="iframeContainer" style="position: fixed;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 90%;
          height: 70%;z-index: -1;"></div> -->
      </ng-container>

      <div *ngIf="showPaymentFrame" class="frame-style">
        <iframe [src]="iframeUrl" width="100%" height="500px" frameborder="0"></iframe>
      </div>

      <ng-container *ngIf="currentStep === 1">
        <div class="col-11 col-sm-11 col-md-6 col-lg-5 col-xl-3 mx-auto py-4" style="text-align: center">
          <img src="assets/img/success-35.png" alt="" class="vm mw-100" style="width: 60%; margin-bottom: 25px" />
          <p class="mb-3 text-center font-weight-bold">
            <strong class="size-24 text-uppercase">{{"payment_done" | translate}}</strong>
            <br />
            <span>Thank you for your payment.</span>
          </p>
        </div>
      </ng-container>
    </div>

  </div>

  <!-- New Approach Transaction -->
  <div id="transaction-form" *ngIf="!showCardTypesList">
    <div class="container " *ngIf="!transactionSuccess && !transactionPending && !transactionFailed">
      <div class="row mb-1">
        <div class="col-xs-12 col-md-4 col-md-offset-4">
          <div class="panel panel-default">
            <div class="panel-heading">
              <div class="row ">
                <label class="form-label ms-2 " for="cardOwner"> {{'LABELS.selected_card_type' | translate}}  <span class="ms-1 red">*</span></label>
                <div class="inlineimage mb-1 flc" style="margin : 0 !important">
                  <img class="img-responsive images" [src]="srcSelectedCard">
                  <!-- <label class="label-main m-3 fsl"> {{selectedCardType}} </label> -->
                </div>

              </div>
            </div>
            <div class="panel-body">
              <form role="form" [formGroup]="transactionForm">
                <div class="row mb-1">
                  <div class="col-xs-12">
                    <label class="form-label ms-2 " for="cardOwner">{{ 'PAYMENT.name_on_card' | translate}} <span class="ms-1 red">*</span></label>
                     <input type="text" class="form-control" placeholder="{{'PLACEHOLDER.enter_card_name' | translate}}" formControlName="cardOwner" />
                  </div>
                </div>
                <div class="row mb-1">
                  <div class="col-xs-12">
                      <label class="form-label ms-2 " for="cardNumber">{{ 'PAYMENT.card_number' | translate}} <span class="ms-1 red">*</span></label>
                      <input type="text" class="form-control" placeholder="{{'PLACEHOLDER.enter_card_number' | translate}}" formControlName="cardNumber" />
                  </div>
                </div>
                <div class="row mb-1">
                  <div class="col-xs-12">
                     <label class="form-label ms-2 " for="expirationDate">{{ 'PAYMENT.exp_date' | translate}} <span class="ms-1 red">*</span></label>
                     <div class="row">
                      <div class="col-6 text-center">
                        <input type="text" class="form-control" maxlength="2" placeholder="{{'LABELS.month' | translate}}" formControlName="expirationMonth"/>
                        <!-- <label style="color:red;font-size: small;"> mask error </label> -->
                      </div>
                      <!-- <div class="col-2">  </div> -->
                      <div class="col-6">
                        <input type="text" class="form-control" maxlength="4" placeholder="{{'LABELS.year' | translate}}" formControlName="expirationYear"/>
                      </div>
                     </div>
                  </div>
                </div>
                <div class="row mb-1">
                  <div class="col-xs-12">
                    <label class="form-label ms-2 " for="cvvCode">{{ 'PAYMENT.CVV' | translate}} <span class="ms-1 red">*</span></label>
                    <input type="text" class="form-control" maxlength="3" placeholder="{{'PLACEHOLDER.enter_cvv_code' | translate}}" formControlName="cvvCode" />
                  </div>
                </div>

              </form>
            </div>
              <div class="text-center pb-4 mt-4">
                <button type="button" class="btn btn-primary btn-lg mb-2  login-btn" (click)="sendTransaction()">
                  {{'BUTTONS.pay' | translate}} {{loan.normalPayment}} {{ 'CURRENCY.sar' |translate}}
                </button>
                <!-- <button type="button" class="btn btn-primary btn-lg mb-2  login-btn" (click)="test()">
                  TEST Third Party
                </button> -->
              </div>
          </div>
        </div>
      </div>
  </div>

  <div id="paymentSuccess" *ngIf="transactionSuccess" class="px-2">
    <div class="card p-3 flc">
      <div class="animated-success-icon animated-success-icon__outer-wrapper">
        <div class="animated-success-icon__middle-wrapper">
            <div class="animated-success-icon__inner-wrapper">
                <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M41.4104 0.991406C43.6282 2.46549 44.2312 5.45841 42.7571 7.6763L20.3236 41.4297C19.4567 42.734 18.0103 43.536 16.4448 43.5806C14.8793 43.6251 13.3897 42.9065 12.4501 41.6536L1.13029 26.559C-0.467441 24.4284 -0.0355215 21.4061 2.09501 19.8083C4.22554 18.2106 7.2479 18.6425 8.84563 20.7731L16.0702 30.4068L34.7255 2.33818C36.1995 0.1203 39.1925 -0.482674 41.4104 0.991406Z" fill="#CECED2"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M40.5792 0.975226C42.7027 2.41795 43.28 5.34722 41.8687 7.51793L20.3897 40.5533C19.5597 41.8299 18.1749 42.6149 16.676 42.6585C15.1771 42.7021 13.7509 41.9988 12.8513 40.7725L2.0132 25.999C0.483455 23.9137 0.896994 20.9557 2.93687 19.3919C4.97674 17.8282 7.87048 18.2509 9.40023 20.3361L16.3173 29.7649L34.1788 2.29336C35.5901 0.122649 38.4557 -0.467499 40.5792 0.975226Z" fill="white"/>
    </svg>
            </div>
        </div>
    </div>
      <legend class="fsxl text-center text-success mt-2"> {{'INFO.transaction_success' | translate }}</legend>
      <label class="label-info p-0"> {{'LABELS.transaction' | translate }} #{{paymentId}}   </label>
      <div class="ms-2  mb-4 flc inline-flex align-items-center">
        <img class="img-logo-sanad op-80" src="assets/images/payment_success.png" alt="payment_success">
      </div>

      <div class="text-center mt-2">
        <button type="button" class="btn btn-primary btn-lg mb-2 login-btn" routerLink="/home">
          {{'BUTTONS.home' | translate}}
        </button>
      </div>

    </div>
  </div>

  <div id="paymentPending" *ngIf="transactionPending" class="px-2">
    <div class="card p-3 flc">
      <div class="animated-pending-icon animated-pending-icon__outer-wrapper">
        <div class="animated-pending-icon__middle-wrapper">
            <div class="animated-pending-icon__inner-wrapper">
                <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M41.4104 0.991406C43.6282 2.46549 44.2312 5.45841 42.7571 7.6763L20.3236 41.4297C19.4567 42.734 18.0103 43.536 16.4448 43.5806C14.8793 43.6251 13.3897 42.9065 12.4501 41.6536L1.13029 26.559C-0.467441 24.4284 -0.0355215 21.4061 2.09501 19.8083C4.22554 18.2106 7.2479 18.6425 8.84563 20.7731L16.0702 30.4068L34.7255 2.33818C36.1995 0.1203 39.1925 -0.482674 41.4104 0.991406Z" fill="#CECED2"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M40.5792 0.975226C42.7027 2.41795 43.28 5.34722 41.8687 7.51793L20.3897 40.5533C19.5597 41.8299 18.1749 42.6149 16.676 42.6585C15.1771 42.7021 13.7509 41.9988 12.8513 40.7725L2.0132 25.999C0.483455 23.9137 0.896994 20.9557 2.93687 19.3919C4.97674 17.8282 7.87048 18.2509 9.40023 20.3361L16.3173 29.7649L34.1788 2.29336C35.5901 0.122649 38.4557 -0.467499 40.5792 0.975226Z" fill="white"/>
    </svg>
            </div>
        </div>
    </div>
      <legend class="fsxl text-center text-warning mt-2"> {{'INFO.pending_transaction' | translate }}</legend>
      <label class="label-info p-0"> {{'LABELS.transaction' | translate }} #{{paymentId}}  </label>

      <div class="text-center  p-6" (click)="triggerClickLink()" *ngIf="!showRedirectLink">
        <label class="labelmain u fw-500 mb-4 fsl flc" style="cursor: pointer;"><a [href]="securityLink" target="_blank"> {{securityLink}}</a></label>
      </div>

      <div class="payment-no-refresh mb-2" *ngIf="showRedirectLink">
        <div class="payment-loader">
          <span></span>
          <span></span>
          <span></span>
        </div>
        <h2>{{'INFO.processing' |translate}}</h2>
      </div>


      <div class="text-center mt-2">
        <button type="button" class="btn btn-primary btn-lg mb-2 login-btn" routerLink="/home">
          {{'BUTTONS.home' | translate}}
        </button>
      </div>

    </div>
  </div>

  <div id="paymentFailed" *ngIf="transactionFailed" class="px-2">
    <div class="card p-3 flc">
      <div class="animated-failed-icon animated-failed-icon__outer-wrapper">
        <div class="animated-failed-icon__middle-wrapper">
            <div class="animated-failed-icon__inner-wrapper">
                <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M41.4104 0.991406C43.6282 2.46549 44.2312 5.45841 42.7571 7.6763L20.3236 41.4297C19.4567 42.734 18.0103 43.536 16.4448 43.5806C14.8793 43.6251 13.3897 42.9065 12.4501 41.6536L1.13029 26.559C-0.467441 24.4284 -0.0355215 21.4061 2.09501 19.8083C4.22554 18.2106 7.2479 18.6425 8.84563 20.7731L16.0702 30.4068L34.7255 2.33818C36.1995 0.1203 39.1925 -0.482674 41.4104 0.991406Z" fill="#CECED2"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M40.5792 0.975226C42.7027 2.41795 43.28 5.34722 41.8687 7.51793L20.3897 40.5533C19.5597 41.8299 18.1749 42.6149 16.676 42.6585C15.1771 42.7021 13.7509 41.9988 12.8513 40.7725L2.0132 25.999C0.483455 23.9137 0.896994 20.9557 2.93687 19.3919C4.97674 17.8282 7.87048 18.2509 9.40023 20.3361L16.3173 29.7649L34.1788 2.29336C35.5901 0.122649 38.4557 -0.467499 40.5792 0.975226Z" fill="white"/>
    </svg>
            </div>
        </div>
    </div>
      <legend class="fsxl text-center text-danger mt-2"> {{ 'INFO.transaction_failed' |translate }}</legend>
      <label class="label-info p-0"> {{'LABELS.transaction' | translate}} #{{paymentId}}  </label>
      <div class="ms-2  mb-4 flc inline-flex align-items-center">
        <img class="img-logo-sanad op-80" src="assets/images/payment_success.png" alt="payment_failed">
      </div>

      <div class="text-center mt-2">
        <button type="button" class="btn btn-primary btn-lg mb-2 login-btn" routerLink="/home">
          {{'BUTTONS.home' | translate}}
        </button>
      </div>

    </div>
  </div>
  </div>


</div>

<app-footer-menu></app-footer-menu>
