<div class="bg-2 vh-100 vw-100 ">
  <app-screen-title [title]="'payment_mode'" [color]="'color1'" [backTo]="'/loan-schedule-details'"></app-screen-title>

  <div id="SELECT_BRANDS" *ngIf="!isBrandSelected" class="px-3">
    <div class="mb-2">
      <label class="form-label ms-2 ">{{'LABELS.select_brand_payment' | translate}} : </label>
      <div class="payment-container px-1 mb-2 mt-2">
        <div *ngFor="let method of paymentMethods" (click)="selectPaymentMethod(method)"
          [class.selected]="selectedBrand === method.brand" class="payment-card">
          <img [src]="method.image" [alt]="method.brand" class="payment-logo" />
          <span class="payment-label">{{ method.label }}</span>
        </div>
      </div>
      <div class="text-center py-2  ">
        <button type="button" class="btn btn-primary btn-lg mb-4 login-btn" (click)="next()"
          [disabled]="!selectedBrand">
          {{'BUTTONS.next' | translate}}
        </button>
      </div>
    </div>
  </div>

  <div id="CHECKOUT_WIDGET" *ngIf="isBrandSelected">
    <div class="payment-no-refresh mb-2 py-4" *ngIf="!checkoutResponse">
      <div class="payment-loader">
        <span></span>
        <span></span>
        <span></span>
      </div>
      <h2>{{'INFO.Generating_Payment_Form' |translate}}</h2>
    </div>

    <div id="hyperpay-widget-container" style="background : #f6f8ff">
      <form [action]="statusPaymentPage" *ngIf="selectedBrand != 'APPLEPAY'" class="paymentWidgets"
        [attr.data-brands]="selectedBrand"></form>
      <label class="form-label m-2" *ngIf="selectedBrand === 'APPLEPAY' && checkoutResponse">
        {{'LABELS.payment_process_apple_pay' | translate}}
      </label>
      <form *ngIf="selectedBrand === 'APPLEPAY'" [action]="statusPaymentPage" class="paymentWidgets"
        data-brands="APPLEPAY" data-applePay='{
      "merchantIdentifier": "merchant.com.sanad.finance.test",
      "merchantCapabilities": ["supports3DS"],
      "supportedNetworks": ["visa", "masterCard", "mada"],
      "countryCode": "SA",
      "currencyCode": "SAR",
    }'>
      </form>

    </div>

  </div>




</div>

<app-footer-menu></app-footer-menu>
