<div *ngIf="isLoading | async" class="overlay" [dir]="'ltr'">
  <div class="loader"></div>
</div>


  <!-- <div id="preloader">
    <div id="loader"></div>
  </div> -->
  <!-- <div class="ce">
    <div class="banter-loader ">
      <div class="banter-loader__box"></div>
      <div class="banter-loader__box"></div>
      <div class="banter-loader__box"></div>
      <div class="banter-loader__box"></div>
      <div class="banter-loader__box"></div>
      <div class="banter-loader__box"></div>
      <div class="banter-loader__box"></div>
      <div class="banter-loader__box"></div>
      <div class="banter-loader__box"></div>
    </div>
  </div> -->
