import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/ib-services/shared.service';

@Component({
  selector: 'app-about-sanad',
  templateUrl: './about-sanad.component.html',
  styleUrls: ['./about-sanad.component.scss']
})
export class AboutSanadComponent implements OnInit {

  public showPopup : boolean = false;

  constructor(public sharedService : SharedService) { }

  ngOnInit() {
  }

  evaluation(){
    this.showPopup = true;
  }

}
