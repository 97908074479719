<div id="view-claim" class="bg-2 vw-100 " >

  <app-screen-title  [title]="'SETTINGS.claim_details'" [color]="'color1'" [backTo]="'/claims'"></app-screen-title>

  <div id="claim_details_content px-2 pt-4" *ngIf="claim">
    <div class="m-2 fsb">
      <label class="label-main fw-600 m-1"> {{ 'LABELS.customer_claim' | translate }} </label>
      <label class="label-info fw-650 m-1" > #{{claim.idCustomer}} </label>
    </div>
    <hr>
    <div class="m-2 fsb">
      <label class="label-main fw-600 m-1"> {{ 'LABELS.claim_subject' | translate }} </label>
      <label class="label-info fw-650 m-1"> {{claimSubject.subject}} </label>
    </div>
    <hr>
    <div class="m-2">
      <label class="label-main fw-600 m-1"> {{ 'LABELS.claim_description' | translate }} </label>
      <textarea class="form-control" [(ngModel)]="claim.body" disabled></textarea>
    </div>
    <hr>
  </div>

  <div id="list_notes">
    <div class="m-2 fsb">
      <label class="label-main fw-600 m-1"> {{ 'LABELS.notes_replies' | translate }} </label>
    </div>

    <div id="no-claims-found" *ngIf="claimNotes.length == 0">
      <div class="pt-4 flc inline-flex align-items-center">
        <img class="img-logo-sanad op-20" style="width: 150px;" src="assets/images/note.png" alt="My Image">
      </div>
      <label class="label-info flc">{{'INFO.no_notes' | translate}}</label>
    </div>

    <div id="my_list" class="px-2" *ngIf="claimNotes.length > 0">
      <div *ngFor="let note of claimNotes;let i = index ">
        <div class="flex">
          <div class="m-1 fsb">
            <label class="label-main fw-600 m-1 black"> {{ note.insertBy }} </label>
            <label class="label-info m-1 black fss op-70 fst-italic"> {{ calculateTimeDifference(note.dateInsertion) }}  </label>
          </div>
          <div id="body_content_message" class="px-4">
            <label class="label-main black op-70 fst-italic" style="margin-bottom: unset !important;">{{ note.comment }}</label>
          </div>
        </div>

      </div>
    </div>

  </div>

  <hr>

  <div id="add_note" class="mt-1 pb-2">
    <div class="m-2 fsb">
      <label class="label-main fw-600 m-1"> {{'LABELS.add_new_note' | translate}} </label>
    </div>

    <form [formGroup]="noteForm">
      <div class="m-2">
        <textarea formControlName="comments" class="form-control" rows="4" placeholder="{{'PLACEHOLDER.enter_new_comment' | translate}}"
        [ngClass]="{'fc-error': submittedForm && noteForm.controls['comments'].invalid}"></textarea>
      </div>

      <div class="py-2 px-2">
        <button type="button" class="btn btn-primary btn-sm" (click)="addNote()"><i class='bx bxs-message-square-add m-1'></i>{{'LABELS.add_new_note' |translate}}</button>
      </div>
    </form>
  </div>

</div>
