<div class="bg-2 vw-100 vh-100">

  <!-- Schedule Services Menu -->
  <div id="menu-applications" *ngIf="showServicesMenu && !showEarlyPayment && !showRefinance">
    <app-screen-title [title]="'BUTTONS.services'" [color]="'color1'"
      [backTo]="'/loan-schedule-details'"></app-screen-title>

    <div class="px-4 mt-4">
      <div class="row ">
        <div class="col-6">
          <div class="card text-center" (click)="earlyPayment()">
            <div class="mt-4 p-2 pt-0 flc inline-flex align-items-center">
              <img class="img-logo" style="width: 80px;padding:0px" src="assets/images/save-time.png"
                alt="money-up">
            </div>
            <label class="form-label ms-2 pb-2 fw-500 fsm"> {{'LABELS.early_payment' | translate}} </label>
          </div>
        </div>
        <div class="col-6">
          <div class="card text-center" (click)="refinance()">
            <div class="mt-4 p-2 pt-0 flc inline-flex align-items-center">
              <img class="img-logo" style="width: 80px;padding:0px" src="assets/images/agreement.png" alt="stats">
            </div>
            <label class="form-label ms-2 pb-2 fw-500 fsm">{{ 'LABELS.refinancing' | translate}}</label>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Early Payment Term Period -->
  <div id="early-payment" *ngIf="!showServicesMenu && showEarlyPayment && !showRefinance">
    <!-- App Header Title Page -->
      <div id="app-screen-title">
        <div class="flc align-items-center p-3 pt-5 pb-4">
          <div class="back-div" style="background-color:#E9EDF8">
            <i class='bx bx-arrow-back m-1 fsxl' [style]="{'color':'#213592'}" (click)="back()" [ngStyle]="{'transform': (lang === 'ar' || lang == null) ? 'rotate(180deg)' : 'none'}"></i>
          </div>
          <legend class="text-center fw-650" [style]="{'color':'#213592'}">{{'TITLE.existing_financing' | translate}}</legend>
        </div>
       </div>
    <div class="px-3 mt-4" *ngIf="!showEarlyPaymentCalculation">
      <div class="card cu-card text-center p-2 pt-3 mb-3">

        <!-- Curved Progress Bar Percentage -->
        <div class="flc pb-4">
          <div class="cpb" role="progressbar" [attr.aria-valuenow]="getPercentage()" aria-valuemin="0"
            aria-valuemax="100" [style.--value]="getPercentage()">
            <div class="grid">
              <label class="fw-700 color3 text-center mb-0 fss" style="margin-bottom:unset">{{ 'LABELS.payment_status' |
                translate }}</label>
              <span class="flc mb-1 fw-650 color2 fsxl" *ngIf="paymentStatus === 100"> {{paymentStatus }}% </span>
              <span class="flc mb-1 fw-650 color2 fsxl" *ngIf="paymentStatus != 100"> {{paymentStatus |
                percent:'1.2-2'}}</span>
              <span class="flc mb-1 fw-650 color2 fsxl" *ngIf="paymentStatus == undefined"> 0%</span>
            </div>
          </div>
        </div>


        <div class="row">
          <div class="col-6">
            <div class="fbaseline">
              <div>
                <img class="ib-icons ib-icons  pt-3 m-2 " src="assets/images/icons/money_case_icon.png" alt="My Image">
              </div>
              <div class="d-grid py-2">
                <label class="form-label ms-2 fw-650 fss">{{ 'LABELS.remaining_amount' | translate }}</label>
                <label class="form-label ms-2 fw-650 fss text-primary">{{remainingAmount  | number:'1.2-2'}} {{ 'CURRENCY.sar' |
                  translate}}</label>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="fbaseline">
              <div>
                <img class="ib-icons ib-icons pt-3 m-2 " src="assets/images/icons/money_icon.png" alt="My Image">
              </div>
              <div class="d-grid py-2">
                <label class="form-label ms-2 fw-650 fss">{{ 'LABELS.payment_done' | translate }}</label>
                <label class="form-label ms-2 fw-650 fss text-primary"> {{donePayment  | number:'1.2-2'}} {{ 'CURRENCY.sar' |
                  translate}}</label>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <div class="fbaseline">
              <div>
                <img class="ib-icons ib-icons  pt-3 m-2 " src="assets/images/icons/check2.png" alt="My Image">
              </div>
              <div class="d-grid py-2">
                <label class="form-label fw-650 fss">{{ 'LABELS.premium_paid' | translate }}</label>
                <label class="form-label fw-650 text-primary fss"> {{termPaid}} / {{loan.termPeriodNum}} </label>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="fbaseline">
              <div>
                <img class="ib-icons ib-icons  pt-3 m-2" src="assets/images/icons/check1.png" alt="My Image">
              </div>
              <div class="d-grid py-2">
                <label class="form-label fss fw-650">{{ 'LABELS.total_amount' | translate }}</label>
                <label class="form-label fw-650 fss text-primary">{{loan.approvelAmount}} {{ 'CURRENCY.sar' |
                  translate}}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="card sticky-card flc p-4"  style="height: 176px;">
            <label class="mb-1 color1 fw-650"> {{ 'LABELS.Last_installment' | translate }}</label>
            <label class="mb-1 fw-650"> {{loan.normalPayment  | number:'1.2-2'}} {{ 'CURRENCY.sar' | translate }}</label>
            <label class="fss op-50"> {{lastRepaymentDate  | number:'1.2-2'}} </label>
          </div>
        </div>
        <div class="col-6">
          <div class="card sticky-card flc p-4"  style="height: 176px;">
            <label class="mb-1 color1 fw-650"> {{ 'LABELS.next_installment' | translate
              }}</label>
            <label class="mb-1 fw-650">{{loan.normalPayment  | number:'1.2-2'}} {{ 'CURRENCY.sar' | translate }}</label>
            <label class="op-50 fw-500 fss"> {{nextRepaymentDate  | number:'1.2-2'}} </label>
          </div>
        </div>
      </div>

      <div class="card my-3 py-2">

        <div class="row p-1">

          <div class="col-6">
            <label class="mb-1 color1 fw-650 mx-2"> {{ 'PAYOUT_CALCULATION' | translate}} </label>
          </div>

          <div class="col-6" *ngIf="loan.payout">
            <label class="mb-1 fw-650">  {{loan.payout }} {{ 'CURRENCY.sar' | translate }} </label>
          </div>

          <div class="col-6" *ngIf="!loan.payout || loan.payout === null">
            <div class="scloader loader--style8 pb-0 mb-0">
              <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                 width="24px" height="30px" viewBox="0 0 24 30" style="enable-background:new 0 0 50 50;" xml:space="preserve">
                <rect x="0" y="10" width="4" height="10" fill="#333" opacity="0.2">
                  <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0s" dur="0.6s" repeatCount="indefinite" />
                  <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0s" dur="0.6s" repeatCount="indefinite" />
                  <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0s" dur="0.6s" repeatCount="indefinite" />
                </rect>
                <rect x="8" y="10" width="4" height="10" fill="#333"  opacity="0.2">
                  <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
                  <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
                  <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.15s" dur="0.6s" repeatCount="indefinite" />
                </rect>
                <rect x="16" y="10" width="4" height="10" fill="#333"  opacity="0.2">
                  <animate attributeName="opacity" attributeType="XML" values="0.2; 1; .2" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
                  <animate attributeName="height" attributeType="XML" values="10; 20; 10" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
                  <animate attributeName="y" attributeType="XML" values="10; 5; 10" begin="0.3s" dur="0.6s" repeatCount="indefinite" />
                </rect>
              </svg>
            </div>

          </div>

        </div>

      </div>

      <div class="text-center py-3 pb-5">
        <button type="button" class="btn btn-primary btn-lg mb-2 login-btn" (click)="nextStep()" [disabled]="!loan.payout">
         {{'LAUNCH_PAYOUT_PROCESS' | translate}}
        </button>
      </div>

    </div>

    <div class="px-3 mt-4" *ngIf="showEarlyPaymentCalculation">

      <div>

        <div class="mb-2">
          <label class="form-label ms-2 " for="motifEarlyPayment">{{'LABEL.source_funds' | translate}} <span
              class="ms-1 red">*</span></label>
          <div style="position: relative;">
            <input type="text" class="form-control" placeholder="{{'LABEL.source_funds' | translate}}"
              [(ngModel)]="sourceOfFunds">
          </div>
        </div>

        <div class="mb-2">
          <label class="form-label ms-2 " for="motifEarlyPayment">{{'MOTIF_EARLY_PAYMENT' | translate}} <span
              class="ms-1 red">*</span></label>
          <div style="position: relative;">
            <input type="text" class="form-control" placeholder="{{'MOTIF_EARLY_PAYMENT' | translate}}"
              [(ngModel)]="motifEarlyPayment">
          </div>
        </div>

        <div class="mb-2">
          <label class="form-label ms-2 " for="motifDescriptionEarlyPayment"> {{'MOTIF_EARLY_PAYMENT_DESCRIPTION' | translate}} <span
              class="ms-1 red">*</span></label>
          <div style="position: relative;">
            <textarea class="form-control" [(ngModel)]="motifDescriptionEarlyPayment"></textarea>
          </div>
        </div>

        <div class="mb-2">
          <label class="form-label ms-2">{{'OTHER.is_bank_account_owner' | translate}} </label>
          <div style="position: relative;">
            <div class="radio-container">
              <input class="m-1" type="radio" id="radio1" name="options1" [(ngModel)]="isBankOwner" [value]="true">
              <label for="radio1" class="form-label">{{'LABELS.yes' | translate}}</label>
              <input class="m-1" type="radio" id="radio2" name="options1" [(ngModel)]="isBankOwner" [value]="false">
              <label for="radio2" class="form-label">{{'LABELS.no' | translate}}</label>
            </div>
          </div>
        </div>

        <div class="mb-2" *ngIf="!isBankOwner">
          <label class="form-label ms-2 " for="cardOwnerName">{{'LABEL.card_owner_name' | translate}} <span
              class="ms-1 red">*</span></label>
          <div style="position: relative;">
            <input type="text" class="form-control" placeholder="{{'LABEL.card_owner_name' | translate}}"
              [(ngModel)]="cardOwnerName">
          </div>
        </div>

        <div class="mb-2" *ngIf="!isBankOwner">
          <label class="form-label ms-2 " for="cardOwnerRelation">{{'LABEL.card_owner_relation' | translate}} <span
              class="ms-1 red">*</span></label>
          <div style="position: relative;">
            <input type="text" class="form-control" placeholder="{{'LABEL.card_owner_relation' | translate}}"
              [(ngModel)]="cardOwnerRelation">
          </div>
        </div>


        <div class="text-center pt-4 px-3">
          <button type="button" class="btn btn-primary btn-lg mb-2 login-btn" (click)="nextStepNewTransaction()">
            {{'LABELS.next_step' |translate}}
          </button>
        </div>

      </div>

    </div>


  </div>

  <!-- Refinance Term Period -->
  <div id="refinance" class="px-2" *ngIf="!showServicesMenu && !showEarlyPayment && showRefinance">
    <app-screen-title [title]="'TITLE.refinancing'" [color]="'color1'"
      [backTo]="'/loan-schedule-details'"></app-screen-title>
    <!-- <p class="text-center"> Screen not available !</p> -->
  </div>

</div>
