<div style="display: contents !important;">
  <div class="bg-1" style="border-radius: 0px 0px 20px 20px;padding-bottom:23px;">
    <app-screen-title [title]="'SETTINGS.contact_us'" [color]="'white'" [backTo]="'/settings'"></app-screen-title>
    <div class=" flc  " >
      <img class="img-logo-sanad" src="assets/images/contactus.png" alt="mainlogo2" style="width:150px !important">
    </div>

  </div>

  <div id="contact-us">
    <div class="p-4" style="overflow: auto;">
      <label class="mb-1 text-center">{{'FAQ.for_any_questions' | translate }}</label>

      <div id="our-contacts" class="pt-4 mt-2">
        <!-- PHONE CONTACT -->
        <div class="card contact-card p-2 mb-3" (click)="copyToClipboard('8001240559','phone_number')">
          <div class="row">
            <div class="col-2">
            <div  class="icon-card">
              <img class="ib-icons m-2" src="assets/images/icons/cu-phone.png" alt="cu-phone" style="position: absolute;">
            </div>
          </div>
            <div class="col-8">
              <label class="contact-label py-1 pb-2">8001240559</label>
            </div>
            <div class="col-2">
              <i class='bx bx-copy pt-2 fsl color1' style="cursor: pointer;" ></i>
            </div>
          </div>
        </div>

        <!-- EMAIL CONTACT -->
        <div class="card contact-card p-2 mb-3" (click)="copyToClipboard('customercare@sanadfinance.com','email')">
          <div class="row">
            <div class="col-2">
              <div  class="icon-card">
                <!-- <i class='bx bx-envelope'></i> -->
                <img class="ib-icons m-2" src="assets/images/icons/cu-message.png" alt="cu-message" style="position: absolute;">
              </div>
            </div>
            <div class="col-8">
              <label class="contact-label py-1 pb-1 fss" style="margin-top: 0.2rem;"> customercare@sanadfinance.com </label>
            </div>
            <div class="col-2">
              <i class='bx bx-copy pt-2 fsl color1' style="cursor: pointer;" ></i>
            </div>
          </div>
        </div>

        <!-- WEBSITE CONTACT -->
        <div class="card contact-card p-2 mb-3" (click)="redirectToLinks('https://www.sanadfinance.com/')">
          <div class="row">
            <div class="col-2">
              <div  class="icon-card" >
                <!-- <i class='bx bx-laptop' ></i> -->
                <img class="ib-icons m-2" src="assets/images/icons/cu-pc.png" alt="cu-pc" style="position: absolute;">
              </div>
            </div>
            <div class="col-8">
              <label class="contact-label py-1 pb-2"> www.sanadfinance.com </label>
            </div>
            <div class="col-2">
              <i class='bx bx-arrow-back pt-2 fsl color1'></i>
            </div>
          </div>
        </div>

        <!-- Twitter CONTACT -->
        <div class="card contact-card p-2 mb-5" (click)="redirectToLinks('https://x.com/sanadfinance')">
          <div class="row">
            <div class="col-2">
              <div  class="icon-card">
                <!-- <i class='bx bxl-xing'></i> -->
                <img class="ib-icons m-2" src="assets/images/icons/cu-x.png" alt="cu-x" style="position: absolute;">
              </div>
            </div>
            <div class="col-8">
              <label class="contact-label py-1 pb-2"> https://x.com/sanadfinance </label>
            </div>
            <div class="col-2">
              <i class='bx bx-arrow-back pt-2 fsl color1'></i>
            </div>
          </div>
        </div>

      </div>

    </div>
  </div>


</div>

<app-footer-menu *ngIf="this.sharedService.getUser().login != undefined"></app-footer-menu>
