export class BankInformation {
    bankName : string

    bankDescription : string

    bankCode : string

    iban : string
    
    constructor(){

    }
}