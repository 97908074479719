export enum DealStatus {
  'LOAN_REQUEST',
  'APPROVAL',
  'SIGNATURE'
}

export enum DealApprovalStatus {
  'APPROVED',
  'REJECTED',
  'PENDING',
  'SIGNED'
}

export enum SignatureStatus {
  'NOT_YET_SIGNED',
  'SIGNED'
}

export enum CustomerStatus {
  'VERIFIED',
  'NOT_VERFIED',
  'REJECTED'
}
