import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter, interval, Subscription, switchMap, takeWhile, tap } from 'rxjs';
import { LoanService } from 'src/app/ib-services/loan.service';
import { SharedService } from 'src/app/ib-services/shared.service';
import { CustomerEntity } from 'src/app/shared/Entities/customer.entity';
import { LoanEntityIB } from 'src/app/shared/Entities/loan.entity';
import { ScheduleEntity } from 'src/app/shared/Entities/schedule.entity';

@Component({
  selector: 'app-schedule-services',
  templateUrl: './schedule-services.component.html',
  styleUrls: ['./schedule-services.component.scss']
})

export class ScheduleServicesComponent implements OnInit {

  public showServicesMenu : boolean = true;
  public showEarlyPayment : boolean = false;
  public showEarlyPaymentCalculation : boolean = false;
  public showRefinance : boolean = false;

  public loan : LoanEntityIB;
  public paymentStatus : number;
  public schedules : ScheduleEntity[] = [];
  public termPaid :number=0;
  public donePayment : number;
  public remainingAmount : number;
  public listAmountPaid : string[] = [];
  public lastRepaymentDate : string;
  public nextRepaymentDate : string;
  public intervalSubscription: Subscription;
  public lang: string;
  public customer : CustomerEntity;

  /** PAYOUT VALUES */
  public motifEarlyPayment : string;
  public motifDescriptionEarlyPayment : string;
  public isBankOwner : boolean = true;
  public cardOwnerName : string;
  public cardOwnerRelation : string;
  public sourceOfFunds : string;

  constructor(public sharedService : SharedService,private router: Router,private loanService : LoanService,private translate : TranslateService) { }

  ngOnInit() {
    this.lang = localStorage.getItem('lang');
    this.loan = this.sharedService.getLoanDeal();
    this.schedules = this.sharedService.getSchedules();
    this.customer = this.sharedService.getCustomer();
    /** INITIALIZE PAYOUT AS NULL */
    if (this.loan.payout){
      this.loan.actionToAcm = null;
      this.loan.payout = null;
      this.loan.motifPayout = null;
      this.loanService.updateLoan(this.loan).subscribe(res => {
        this.loan = res;
      });
    }
    this.processSchedules();
    this.calculatePaymentStatus();
    this.calculateRemainingAmount();
  }

  private processSchedules(): void {
    this.lastRepaymentDate = this.schedules[this.schedules.length - 1].repaymentDate;
    this.nextRepaymentDate = this.schedules[0].repaymentDate;

    this.schedules.forEach((schedule, index) => {
      const amountPaid = schedule.interestAmtPaid != null ? this.calculateTotalAmountPaid(index) : schedule.loanRepaymentPaid;
      this.listAmountPaid.push(amountPaid);

      if (schedule.statusLabel === 'Fully Paid') {
        this.termPaid++;
        if (index < this.schedules.length - 1) {
          this.nextRepaymentDate = this.schedules[index + 1].repaymentDate;
        }
      }
    });
  }

  private calculatePaymentStatus(): void {
    this.paymentStatus = this.termPaid / this.loan.termPeriodNum;
    if (this.termPaid === this.loan.termPeriodNum) {
      this.paymentStatus = 100;
    }
  }

  private calculateRemainingAmount(): void {
    this.donePayment = this.loan.normalPayment * this.termPaid;
    this.remainingAmount = this.loan.approvelAmount - this.donePayment;
  }

  calculateTotalAmountPaid( index:number ) : string{
    let a = this.schedules[index].loanRepaymentPaid;
    let b = this.schedules[index].interestAmtPaid;
    let valueA = this.extractNumberFromAmount(a);
    let valueB = 0;
    if (b != null){
      valueB = this.extractNumberFromAmount(b);
    }
    let res = valueB + valueA;
    let result = "SAR"+res.toFixed(2);
    return result;
  }

  extractNumberFromAmount(amountString : string) : number {
    let x = amountString.replace('SAR','');
    let convertedNumber = Number(x);
    return   convertedNumber;
  }

  earlyPayment(){
    this.showServicesMenu = false;
    this.showEarlyPayment = true;
    this.showRefinance = false;
    /** launch ExecuteLoanPayout &&  CheckIfPayoutExist */
    this.loanService.executeLoanPayout(this.loan.idIbLoan).subscribe(res => {
      this.checkIfPayoutExist();
    },error => {
      this.sharedService.showError(this.translate.instant('ERR.launch_execute_loan_payout'));
    })

  }

  checkIfPayoutExist() {
    let loanChecker = new LoanEntityIB();
    let loan = this.sharedService.getLoanDeal();
    loanChecker.idIbLoan = loan.idIbLoan;
    this.intervalSubscription = interval(5000)
      .pipe(
        switchMap(() => this.loanService.getLoan(loanChecker)),
        tap(check => console.log('Intermediate Result Loan:', check[0].payout)),
        takeWhile(check2 => {
          return !check2[0].payout;
        }, true),
        filter(check3 => check3[0].payout)
      )
      .subscribe(res => {
          this.sharedService.setLoanDeal(res[0]);
        this.loan = res[0];
      });
  }

  refinance(){
    this.sharedService.showInfo(this.translate.instant('TOAST.feature_not_available'))
  }

  nextStep(){
    this.sharedService.setLoanDeal(this.loan);
    this.showEarlyPaymentCalculation = true;
  }

  nextStepNewTransaction(){
    if (this.motifDescriptionEarlyPayment && this.motifEarlyPayment && this.sourceOfFunds){
      if (!this.cardOwnerName && !this.cardOwnerRelation){this.cardOwnerName = this.customer.customerName;this.cardOwnerRelation = "ME"}
      let json = {"motifPayout" : this.motifEarlyPayment,"motifDescription" : this.motifDescriptionEarlyPayment,"cardOwnerName":this.cardOwnerName,"cardOwnerRelation":this.cardOwnerRelation};
      this.loan.motifPayout = JSON.stringify(json);
      this.loanService.updateLoan(this.loan).subscribe(res => {
        this.sharedService.setLoanDeal(res);
        this.router.navigate(['/new-transaction'], { queryParams: { mode: 'PAYOUT' } });
      },error => {
        this.sharedService.showError(
          this.translate.instant('ERR.updateLoanMotif')
        );
      })
    }else{
      this.sharedService.showError(
        this.translate.instant('ERR.fill_required_fields')
      );
    }
  }

  getPercentage(): number {
    return Math.round(this.paymentStatus * 100);
  }

}
