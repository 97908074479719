export class ClaimsEntity {
  id: number;
    subject : string;
  body: string;
  idCustomer  : number ;
  status : string  ;
  dateInsertion : Date ;
  name :string ;
 email :string ;
 phone :string ;
 subjectString : string;
}
