export class PaymentApiSanadEntity {

  action:string ;

	/** The trackid. */
  trackid:string ;

	/** The terminal id. */
  terminalId:string ;

	/** The password. */
  password:string ;

	/** The amount. */
  amount:string ;

	/** The currency. */
  currency:string ;

	/** The zip code. */
  zipCode:string ;

	/** The country. */
	country :string ;

	/** The city. */
  city:string ;

	/** The state. */
	state :string ;

	/** The address. */
  address:string ;

	/** The customer email. */
	customerEmail :string ;

	/** The contact number. */
	contactNumber :string ;

	/** The meta data. */
  metaData:string ;

	/** The request hash. */
	requestHash :string ;

}
