  <div class="bg-2 vh-100 vw-100 ">
    <app-screen-title [title]="'payment_mode'" [color]="'color1'" [backTo]="'/home'"></app-screen-title>

    <div class="payment-no-refresh mb-2 py-4" *ngIf="!transactionSuccess && !transactionRejected">
      <div class="payment-loader">
        <span></span>
        <span></span>
        <span></span>
      </div>
      <h2>{{'INFO.transaction_result' |translate}}</h2>
    </div>

    <div id="paymentSuccess" *ngIf="transactionSuccess" class="px-2">
      <div class="card p-3 flc">
        <div class="animated-success-icon animated-success-icon__outer-wrapper">
          <div class="animated-success-icon__middle-wrapper">
              <div class="animated-success-icon__inner-wrapper">
                  <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M41.4104 0.991406C43.6282 2.46549 44.2312 5.45841 42.7571 7.6763L20.3236 41.4297C19.4567 42.734 18.0103 43.536 16.4448 43.5806C14.8793 43.6251 13.3897 42.9065 12.4501 41.6536L1.13029 26.559C-0.467441 24.4284 -0.0355215 21.4061 2.09501 19.8083C4.22554 18.2106 7.2479 18.6425 8.84563 20.7731L16.0702 30.4068L34.7255 2.33818C36.1995 0.1203 39.1925 -0.482674 41.4104 0.991406Z" fill="#CECED2"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M40.5792 0.975226C42.7027 2.41795 43.28 5.34722 41.8687 7.51793L20.3897 40.5533C19.5597 41.8299 18.1749 42.6149 16.676 42.6585C15.1771 42.7021 13.7509 41.9988 12.8513 40.7725L2.0132 25.999C0.483455 23.9137 0.896994 20.9557 2.93687 19.3919C4.97674 17.8282 7.87048 18.2509 9.40023 20.3361L16.3173 29.7649L34.1788 2.29336C35.5901 0.122649 38.4557 -0.467499 40.5792 0.975226Z" fill="white"/>
      </svg>
              </div>
          </div>
      </div>
        <legend class="fsxl text-center text-success mt-2"> {{'INFO.transaction_success' | translate }}</legend>
        <label class="label-info p-0"> {{'LABELS.transaction' | translate }} #{{responsePaymentStatus.id}}   </label>
        <div class="ms-2  mb-4 flc inline-flex align-items-center">
          <img class="img-logo-sanad op-80" src="assets/images/payment_success.png" alt="payment_success">
        </div>

        <div class="text-center mt-2">
          <button type="button" class="btn btn-primary btn-lg mb-2 login-btn" routerLink="/home">
            {{'BUTTONS.home' | translate}}
          </button>
        </div>

      </div>
    </div>

    <div id="paymentFailed" *ngIf="transactionRejected" class="px-2">
      <div class="card p-3 flc">
        <div class="animated-failed-icon animated-failed-icon__outer-wrapper">
          <div class="animated-failed-icon__middle-wrapper">
              <div class="animated-failed-icon__inner-wrapper">
                  <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M41.4104 0.991406C43.6282 2.46549 44.2312 5.45841 42.7571 7.6763L20.3236 41.4297C19.4567 42.734 18.0103 43.536 16.4448 43.5806C14.8793 43.6251 13.3897 42.9065 12.4501 41.6536L1.13029 26.559C-0.467441 24.4284 -0.0355215 21.4061 2.09501 19.8083C4.22554 18.2106 7.2479 18.6425 8.84563 20.7731L16.0702 30.4068L34.7255 2.33818C36.1995 0.1203 39.1925 -0.482674 41.4104 0.991406Z" fill="#CECED2"/>
          <path fill-rule="evenodd" clip-rule="evenodd" d="M40.5792 0.975226C42.7027 2.41795 43.28 5.34722 41.8687 7.51793L20.3897 40.5533C19.5597 41.8299 18.1749 42.6149 16.676 42.6585C15.1771 42.7021 13.7509 41.9988 12.8513 40.7725L2.0132 25.999C0.483455 23.9137 0.896994 20.9557 2.93687 19.3919C4.97674 17.8282 7.87048 18.2509 9.40023 20.3361L16.3173 29.7649L34.1788 2.29336C35.5901 0.122649 38.4557 -0.467499 40.5792 0.975226Z" fill="white"/>
      </svg>
              </div>
          </div>
      </div>
        <legend class="fsxl text-center text-danger mt-2"> {{ 'INFO.transaction_failed' |translate }}</legend>
        <label class="label-info p-0"> {{'LABELS.transaction' | translate}} #{{responsePaymentStatus.id}}  </label>
        <div class="ms-2  mb-4 flc inline-flex align-items-center">
          <img class="img-logo-sanad op-80" src="assets/images/payment_success.png" alt="payment_failed">
        </div>

        <div class="text-center mt-2">
          <button type="button" class="btn btn-primary btn-lg mb-2 login-btn" routerLink="/home">
            {{'BUTTONS.home' | translate}}
          </button>
        </div>

      </div>
    </div>

  </div>

  <app-footer-menu *ngIf="this.sharedService.getUser().login != undefined"></app-footer-menu>
