<div id="claims" class="bg-2 vw-100" [ngClass]="{'vh-100': !showListClaims && !showAddClaim || showAddClaim || showListClaims &&  lstClaims.length <= 2}">
  <app-screen-title *ngIf="isLogged" [title]="'SETTINGS.claims'" [color]="'color1'" [backTo]="'/settings'"></app-screen-title>
  <app-screen-title *ngIf="!isLogged" [title]="'SETTINGS.claims'" [color]="'color1'" [backTo]="''"></app-screen-title>

  <!-- Claims Menu Connected Mode -->
  <div id="menu-cards" class="mt-4 px-2" *ngIf="!showListClaims && !showAddClaim ">
    <div class="card cu-card p-2 py-3 mb-3" (click)="showListClaims = true;showAddClaim = false">
      <div class="row">
        <div class="col-2">
          <div>
            <img class="ib-icons op-80 m-1" src="assets/images/note.png" alt="sheets">
          </div>
        </div>
        <div class="col-8 p-1 text-center fw-bold">
          {{'LABELS.my_claims' | translate }}
        </div>
        <div class="col-2">
          <img class="ib-icons m-1" src="assets/images/icons/eye.png" alt="eye">
        </div>
      </div>
    </div>
    <div class="card cu-card p-2 py-3" (click)="showListClaims = false;showAddClaim = true">
      <div class="row">
        <div class="col-2">
          <div>
            <img class="ib-icons op-80 m-1" src="assets/images/claim.png" alt="sheets">
          </div>
        </div>
        <div class="col-8 p-1 text-center fw-bold">
          {{'LABELS.add_new_claim' | translate}}
        </div>
        <div class="col-2">
          <img class="ib-icons m-1" src="assets/images/icons/eye.png" alt="eye">
        </div>
      </div>
    </div>
  </div>

  <!-- Global Div for menus content-->
  <div id="content-claim-div" class="mt-2">


  <!-- LIST CLAIMS -->
  <div id="list-claims" class="px-3 " *ngIf="showListClaims">

      <div class="px-2 mt-2 flc py-2 mb-2">
        <i class='bx bx-info-circle mt-1 color1'></i>
        <span style="text-align: justify;" class="op-70 black">{{ 'INFO.claims_info' |translate}}</span>
      </div>

      <div id="no-claims-found" *ngIf="lstClaims.length == 0">
        <div class="mt-4 pt-4 flc inline-flex align-items-center">
          <img class="img-logo-sanad op-20" style="width: 180px;" src="assets/images/claim.png" alt="My Image">
        </div>
        <label class="label-info flc">{{'INFO.no_claims' | translate}}</label>
      </div>

      <div id="claims-found" *ngIf="lstClaims.length > 0">

            <div *ngFor="let claim of lstClaims;let index='index'">
              <div class="card  p-2 mb-2" style="border-radius: 15px !important;background-color: azure;">
                <div class="card-header" style="padding:5px !important">
                  <label class="label-subtitle color1 fw-650">{{'LABELS.claim_id' | translate}} : #{{claim.id}} </label>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-6"><label class="label-subtitle fw-650">{{'LABELS.claim_subject' | translate}}</label></div>
                    <div class="col-6"> {{returnSubjectCategory(claim)}}</div>
                  </div>
                  <div class="row">
                    <div class="col-6"><label class="label-subtitle fw-650">{{'LABELS.claim_status'| translate}}</label></div>
                    <div class="col-6" *ngIf="claim.status == 'NEW'"> <span class="badge bg-success">{{ 'STATUS.NEW' | translate}}</span> </div>
                    <div class="col-6" *ngIf="claim.status == 'IN PROGRESS'"> <span class="badge bg-primary">{{ 'STATUS.in_progress' | translate}}</span> </div>
                    <div class="col-6" *ngIf="claim.status == 'CLOSED'"> <span class="badge bg-danger">{{ 'STATUS.closed' | translate}}</span> </div>
                  </div>
                  <div class="row">
                    <div class="col-6"><label class="label-subtitle fw-650">{{'LABELS.creation_date' | translate}}</label></div>
                    <div class="col-6"> {{claim.dateInsertion | date: 'dd-MM-yyyy' }} </div>
                  </div>
                  <div class="flc py-1 pt-2" >
                    <button type="button" class="btn btn-secondary px-4" (click)="viewClaim(index,claim)">
                      {{ 'BUTTONS.view' | translate }}
                    </button>
                  </div>
                </div>

            </div>
          </div>
      </div>


      <div class="text-center py-2">
        <button type="button" class="btn btn-danger btn-lg mb-2 login-btn"
          (click)="showAddClaim = false; showListClaims = false">
          {{'BUTTONS.back' | translate}}
        </button>
      </div>

  </div>

  <!-- ADD NEW CLAIM -->
  <div id="add-claim" class="px-3" *ngIf="showAddClaim">

        <form [formGroup]="claimForm">

          <div class="mb-2" *ngIf="!isLogged">
            <label class="form-label ms-2" for="name">{{ 'LABELS.name' | translate}}</label>
            <div style="position: relative;">
              <input type="text" class="form-control" placeholder="{{'PLACEHOLDER.enter_first_name'| translate}}"
                formControlName="name" [ngClass]="{'fc-error': submittedForm && claimForm.controls['name'].invalid}">
            </div>
          </div>

          <div class="mb-2" *ngIf="!isLogged">
            <label class="form-label ms-2" for="email">{{ 'LABELS.email_address' | translate}}</label>
            <div style="position: relative;">
              <input type="text" class="form-control" placeholder="{{'PLACEHOLDER.enter_email'| translate}}"
                formControlName="email" [ngClass]="{'fc-error': submittedForm && claimForm.controls['email'].invalid}">
            </div>
          </div>

          <div class="mb-2" *ngIf="!isLogged">
            <label class="form-label ms-2" for="phone_number">{{ 'LABELS.phone_number' | translate}}</label>
            <div style="position: relative;">
              <span class="m-2 p-2 mobile-span" *ngIf=" sharedService.getLang() != 'ar' " >+ 966</span>
              <span class="m-2 p-2 mobile-span" *ngIf=" sharedService.getLang() == 'ar' ">966 +</span>
                <input type="tel" class="form-control mobile-number-input" placeholder="5 XXXX XXXX" [ngClass]="{'fc-error': submittedForm && claimForm.controls['phone_number'].invalid,'rtl-input': lang =='ar'}"
                    aria-label="phone_number" formControlName="phone_number" maxlength="9"  (input)="checklength($event,'phone_number')" dir="ltr">
            </div>
          </div>

          <div class="mb-2">
            <label class="form-label ms-2" for="bank_name">{{ 'LABELS.select_subject' | translate}}</label>
            <div style="position: relative;">
              <i class='bx bxs-chevron-down m-2 p-2'
                [ngClass]="{'right': lang === 'en' , 'left': lang === 'ar' || lang == null}"></i>
              <select class="form-control" [(ngModel)]="selectedSubject" [ngModelOptions]="{standalone: true}">
                <ng-container *ngFor="let claims of lstClaimsSetting">
                  <option [ngValue]="claims">{{claims.subject}}</option>
                </ng-container>
              </select>
            </div>
          </div>

          <div class="mb-2">
            <label class="form-label ms-2" for="claim_body">{{ 'LABELS.subject_context' | translate}}</label>
            <div style="position: relative;">
              <textarea class="form-control" formControlName="body" [ngClass]="{'fc-error': submittedForm && claimForm.controls['body'].invalid}"></textarea>
            </div>
          </div>

          <div class="text-center py-2">
            <button type="button" class="btn btn-primary btn-lg mb-2 login-btn" (click)="submitClaim()">
              {{'BUTTONS.add_claim' | translate}}
            </button>
            <button type="button" class="btn btn-danger btn-lg mb-2 login-btn"
              (click)="showAddClaim = false; showListClaims = false" *ngIf="isLogged">
              {{'BUTTONS.back' | translate}}
            </button>
          </div>

        </form>

  </div>

  </div>

</div>
