
<div class="bg-2 vh-100 vw-100">
<app-screen-title  [title]="'TITLE.select_your_product'" [color]="'color1'"></app-screen-title>
  <!-- Product List  -->
  <div id="product-list" class="p-2">
    <div class="card px-3 mt-4"   *ngFor="let product of products;let index = index">
      <div class="row" (click)="nextStep(index)">
        <div class="col-3 m-1">
          <img class="card-img-left example-card-img-responsive" src="assets/images/stats.png" style="width:100px; " />
        </div>
        <div  class="col-8 px-3 py-2">
          <div class="card-body m-2">
            <h3 class="card-title h6 ">{{product.code}}</h3>
            <p class="card-text">{{product.description}} Description</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
