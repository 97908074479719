import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NativeBiometric } from '@capgo/capacitor-native-biometric';
import { TranslateService } from '@ngx-translate/core';
import { AuthentificationService } from 'src/app/ib-services/authentification.service';
import { SharedService } from 'src/app/ib-services/shared.service';
import { CustomerEntity } from 'src/app/shared/Entities/customer.entity';
import { PasswordVerificationDTO } from 'src/app/shared/Entities/passwordVerificationDTO.entity';
import { UserEntity } from 'src/app/shared/Entities/user.entity';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-biometrics-settings',
  templateUrl: './biometrics-settings.component.html',
  styleUrls: ['./biometrics-settings.component.scss'],
})
export class BiometricsSettingsComponent implements OnInit {
  public showLang = false;
  public biometricsStatus: Boolean = false;
  public showBioSettings: boolean = false;
  public customer: CustomerEntity;
  public showBiomConfiguration: boolean = false;
  public biometricForm: FormGroup;
  public showPassword: boolean = false;
  public submittedLogin: boolean = false;
  public passwordError: boolean = false;
  public isFloatRight: Boolean = true;
  public user: UserEntity;
  public showModal: boolean = false;
    /** Dialog Style */
    public styleDialog = {
      width: '50vw',
      background: '#ebedf8',
      padding: '10px 10px 10px 10px',
      borderRadius: '20px',
      color: 'white',
      filter: 'drop-shadow(2px 4px 3px black)',
    };



  constructor(
    public translate: TranslateService,
    private authService: AuthentificationService,
    private sharedService: SharedService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.isFloatRight =
      localStorage.getItem('lang') === null
        ? false
        : localStorage.getItem('lang') !== 'ar';
    this.customer = this.sharedService.getCustomer();
    this.user = this.sharedService.getUser();
    console.log('USER', this.user);
    this.createBiomConfigurationForm();
    this.biometricsStatus =  this.sharedService.getUserHadBiometricCredentials();
  }


  /** Disable Biometrics by deleting credentials */
  async disableBiometric() {
    try {
      await NativeBiometric.deleteCredentials({
        server: environment.oauthUrl,
      });
      console.log('Biometric authentication disabled successfully.');
      this.sharedService.showInfo(
        this.translate.instant('BIOMETRIC.INFO.biometric_disabled')
      );
      this.biometricsStatus = false;
      this.sharedService.setUserHadBiometricCredentials(false);
      return true;
    } catch (error) {
      this.sharedService.showError(
        this.translate.instant('BIOMETRIC.ERROR.biometric_disabled_failed')
      );
      return false;
    }
  }

  activateBiometrics() {
    this.showBiomConfiguration = true;
  }

  createBiomConfigurationForm() {
    this.biometricForm = this.formBuilder.group({
      username: [
        { value: this.customer.identity, disabled: true },
        Validators.required,
      ],
      password: ['', Validators.required],
    });
  }

  configureBiometrics() {
    let pwdVerif = new PasswordVerificationDTO();
    if (this.biometricForm.valid) {
      pwdVerif.rawPwd = this.biometricForm.controls['password'].value;
      pwdVerif.user = this.user;
      this.authService.verifyPassword(pwdVerif).subscribe(
        (res) => {
          if (res) {
            NativeBiometric.setCredentials({
              username: this.customer.identity,
              password: this.biometricForm.controls['password'].value,
              server: environment.oauthUrl,
            });

            NativeBiometric.verifyIdentity({
              reason: 'For easy login',
              title: 'Synchronize your account & Secure',
            })
              .then(() => {
                this.showModal = true;
                this.sharedService.setUserHadBiometricCredentials(true);
              })
              .catch(() => {
                this.sharedService.showError(this.translate.instant('BIOMETRIC.ERROR.error_fp'));
                return;
              });
          } else {
            this.sharedService.showError(this.translate.instant('TOAST.ERR.password_not_match'));
          }
        },
        (error) => {
          console.error('Error during password verification:', error);
          this.sharedService.showError(this.translate.instant('TOAST.ERR.general_error'));
        }
      );

    }
  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }

  logout(){
    this.authService.logout();
  }

}
