import { SettingDocumentTypeEntity } from "./SettingDocumentType.entity";

 
export class LoanDocumentEntity {

    idDocument: number;
    idDocumentGED: string;
    idCustomer: number;
    settingDocumentTypeDTO: SettingDocumentTypeEntity;
    titre: string;
    description: string;
    auteur: string;
    dateCreation: string;
    loanId: number;
    file: any;
    insertBy: string;
    accountNumberExtern: string;
    customerName: string;
    mandatory: boolean;
    documentIndex: number;
    name: string;
    processLoanDocuments: boolean;
    customerIdentity: number;
    customerNumber: number;
    documentSize: number;
    password : string;
    constructor() {
    }
}
