import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppComponent } from 'src/app/app.component';
import { AuthentificationService } from 'src/app/ib-services/authentification.service';
import { SharedService } from 'src/app/ib-services/shared.service';
import { CustomerEntity } from 'src/app/shared/Entities/customer.entity';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  public showLang = false;
  public customer: CustomerEntity;
  public lang : string;
  public arFistName : string;
  public arLastName : string;
  public messageError : string = '';
  public messageSuccess : string = '';

  constructor(public translate: TranslateService, public sharedService: SharedService,public authService: AuthentificationService) {}

  ngOnInit() {
    this.lang = localStorage.getItem('lang');
    this.customer = this.sharedService.getCustomer();
    this.extractCustomerAltName();
  }

  langEn() {
    this.translate.use('en');
    localStorage.setItem('lang', 'en');
    this.sharedService.setLang('en');
    AppComponent.direction = 'ltr';
  }

  extractCustomerAltName(): void {
    if (this.customer.altName){
      const [firstName, , middleName, lastName] =
      this.customer.altName.split('|');
      this.arFistName = firstName;
      this.arLastName = lastName ?? '';
    }
  }

  langAr() {
    this.translate.use('ar');
    localStorage.setItem('lang', 'ar');
    this.sharedService.setLang('ar');
    AppComponent.direction = 'rtl';
  }

  showLangSelection() {
    this.showLang = !this.showLang;
  }

  logout() {
    this.authService.logout();
  }

  resetFirstLauncherForTesting(){
    this.sharedService.removeFirstLaunchFlag();
  }

}
