import { Component, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CreditService } from 'src/app/ib-services/credit.service';
import { CustomerService } from 'src/app/ib-services/customer.service';
import { LoanService } from 'src/app/ib-services/loan.service';
import { SharedService } from 'src/app/ib-services/shared.service';
import { PathConstants } from 'src/app/shared/mb-constants/path-constants';

@Component({
  selector: 'app-information-customer-entry',
  templateUrl: './information-customer-entry.component.html',
  styleUrls: ['./information-customer-entry.component.scss']
})
export class InformationCustomerEntryComponent implements OnInit {

  public openEditIncomeDetails : boolean = false;
  public openEditExpensensAndObligations : boolean = false;
  public loanAdditionalInformation : any;
  public personalInformation : any;
  public incomeDetailsInformation : any;
  public expensesLiabilitiesInformation : any;
  public currentStep :number = 1;

  constructor(private router:Router,private sharedService : SharedService, private loanService : LoanService,
    private el: ElementRef, private customerService : CustomerService) { }

  ngOnInit() {
    let loan = this.sharedService.getLoanDeal();
    let data = loan.otherInformations ? JSON.parse(loan.otherInformations):null;
    if (data) {
      if (data.incomeDetails) {
        this.currentStep = 3;
        this.openEditIncomeDetails = false;
        this.openEditExpensensAndObligations = true;
      } else {
        this.currentStep = 2;
        this.openEditIncomeDetails = true;
        this.openEditExpensensAndObligations = false;
      }
    }
  }

  personalInfoResponse(event: any) {
    let loan = this.sharedService.getLoanDeal();
    let customer = this.sharedService.getCustomer();
    let personalInformation = event.personalInformation;
    let workInformation = event.workInformation;
    let existingLoanInfo = JSON.parse(loan.otherInformations) || {};

    existingLoanInfo.personalInformation = personalInformation;
    existingLoanInfo.workInformation = workInformation;
    loan.otherInformations =  JSON.stringify(existingLoanInfo);
    loan.loanReasonDescription = personalInformation.loanPurpose;
    let existingCustomerInfo = customer.otherInformations?  JSON.parse(customer.otherInformations)  : {};
    existingCustomerInfo.personalInformation = personalInformation;
    existingCustomerInfo.workInformation = workInformation;
    customer.otherInformations = JSON.stringify(existingCustomerInfo);

    this.loanService.updateLoan(loan).toPromise().then((res) => {
      this.customerService.updateCustomer(customer).subscribe(data=>{
        this.sharedService.setCustomer(data);
        this.sharedService.setLoanDeal(res);
        this.scrollToSection('#start');
        this.openEditIncomeDetails = true;
        this.currentStep = 2;
      });
    })
  }

  incomeDetailsResponse(fromEditIncomeDetails: any) {
    this.incomeDetailsInformation = fromEditIncomeDetails;
    let loan = this.sharedService.getLoanDeal();
    let customer = this.sharedService.getCustomer();
    let existingLoanInfo = JSON.parse(loan.otherInformations);
    let existingCustomerInfo = customer.otherInformations ? JSON.parse(customer.otherInformations) : {};

    existingLoanInfo.incomeDetails = this.incomeDetailsInformation;
    existingCustomerInfo.incomeDetails = this.incomeDetailsInformation;
    loan.otherInformations = JSON.stringify(existingLoanInfo);
    customer.otherInformations = JSON.stringify(existingCustomerInfo);

    this.loanService.updateLoan(loan,).toPromise().then(res => {
      this.customerService.updateCustomer(customer).subscribe(data=>{
        this.sharedService.setCustomer(data);
        this.sharedService.setLoanDeal(res);
        this.openEditIncomeDetails = false;
        this.openEditExpensensAndObligations = true;
        this.scrollToSection('#start');
        this.currentStep = 3;
      });
    })

  }

  expensesDetailsResponse( fromExpensesObligations : any){
    let loan = this.sharedService.getLoanDeal();
    let customer = this.sharedService.getCustomer();
    this.expensesLiabilitiesInformation = fromExpensesObligations;
    this.openEditExpensensAndObligations = false;
    let existingLoanInfo = JSON.parse(loan.otherInformations);
    let existingCustomerInfo = customer.otherInformations ? JSON.parse(customer.otherInformations) : {};

    existingLoanInfo.expensesLiabilities = this.expensesLiabilitiesInformation;
    existingCustomerInfo.expensesLiabilities = this.expensesLiabilitiesInformation;
    loan.otherInformations = JSON.stringify(existingLoanInfo);
    customer.otherInformations = JSON.stringify(existingCustomerInfo);

    loan.stepPath = PathConstants.PATH_BANK_INFO;
    loan.actionToAcm = null;
    this.loanService.updateLoan(loan,).toPromise().then(res => {
      this.customerService.updateCustomer(customer).subscribe(data=>{
        this.sharedService.setCustomer(data);
        this.sharedService.setLoanDeal(res);
        this.router.navigate([loan.stepPath]);
      });
    })
  }

  /** Scroll To Specific section by Tag Id */
  scrollToSection(section: string) {
    const startElement = this.el.nativeElement.querySelector(section);
    if (startElement) {
      startElement.scrollIntoView({ behavior: 'smooth' });
    }
  }

}
