import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  BehaviorSubject,
  Subscription,
  filter,
  interval,
  switchMap,
  take,
  takeWhile,
} from 'rxjs';
import { CreditService } from 'src/app/ib-services/credit.service';
import { CustomerService } from 'src/app/ib-services/customer.service';
import { LoaderService } from 'src/app/ib-services/loader.service';
import { LoanService } from 'src/app/ib-services/loan.service';
import { SharedService } from 'src/app/ib-services/shared.service';
import { ThirdPartyService } from 'src/app/ib-services/third-party.service';
import { RequestNafedhEntity } from 'src/app/shared/Entities/RequestNafedh.entity';
import { ThirdPartyHistorique } from 'src/app/shared/Entities/ThirdPartyHistorique.entity';
import { CustomerEntity } from 'src/app/shared/Entities/customer.entity';
import { LoanEntityIB } from 'src/app/shared/Entities/loan.entity';
import { UserEntity } from 'src/app/shared/Entities/user.entity';
import { PathConstants } from 'src/app/shared/mb-constants/path-constants';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-nafedh',
  templateUrl: './nafedh.component.html',
  styleUrls: ['./nafedh.component.scss'],
})
export class NafedhComponent implements OnInit {
  private intervalSubscription: Subscription;
  public nafedhForm: FormGroup;
  public showNafedhOtp: boolean = false;
  public customer: CustomerEntity;
  public user: UserEntity;
  public lang: string;
  public nationalIdPattern = /^(1)+\d{9}$/;
  public mobileNumberPattern = /^5[013456789]\d{7}$/;
  public showNafathVerification: boolean = false;
  public randomNumber: string;
  public isCheckedTerms: boolean = false;
  public showTerms: Boolean = false;
  public showModalTerms: boolean = false;
  public isPopupOpen: boolean = false;
  public isCheckedTermsRecognition: Boolean = false;
  public uuidNumber: number;
  public nafedhCode: string;
  public mode: string;
  public nafedhDeepLinkAndroid: string = 'nic://nafath';
  public nafedhDeepLinkIos : string = 'nafath://home';
  public showHomeButton : boolean = false;

  /** Dialog Style */
  public styleDialog = {
    width: '50vw',
    background: '#ebedf8',
    padding: '10px 10px 10px 10px',
    borderRadius: '20px',
    color: 'white',
    filter: 'drop-shadow(2px 4px 6px black)',
  };

  constructor( private translate: TranslateService, private datePipe: DatePipe, private creditService: CreditService, private formBuilder: FormBuilder,
    private router: Router, public sharedService: SharedService, private route: ActivatedRoute, private customerService: CustomerService,
    private loanService : LoanService, private thirdPartyService : ThirdPartyService , private loaderService : LoaderService) {}

  async ngOnInit() {
    this.lang = localStorage.getItem('lang');

    // Check Param If Code Nafedh Already Exist Or Not
    this.route.queryParams.subscribe((params) => {
      this.mode = params['status'];
    });

    if (this.mode == 'launched_nafedh') {
      let codeLs = localStorage.getItem('nafedh_code');
      this.nafedhCode = codeLs;
      this.webhookHandlerNafedh();
      this.showNafedhOtp = true;
    } else {
      this.createNafedhForm();
      this.user = this.sharedService.getUser();
      await this.customerService
        .getCustomerById(this.user.customerId)
        .subscribe((res) => {
          this.customer = res;
          this.nafedhForm.controls['nationalId'].setValue(
            this.customer.identity
          );
          this.nafedhForm.controls['mobileNumber'].setValue(
            this.customer.telephone1
          );
          let formattedDate = this.datePipe.transform(
            this.customer.dateOfBirth,
            'yyyy-MM-dd'
          );
          this.nafedhForm.controls['dateOfBirth'].setValue(formattedDate);
        });
    }
  }

  createNafedhForm() {
    this.nafedhForm = this.formBuilder.group({
      nationalId: [ '', [Validators.required, Validators.pattern(this.nationalIdPattern)]],
      mobileNumber: [ '', [Validators.required, Validators.pattern(this.mobileNumberPattern)]],
      dateOfBirth: ['', Validators.required],
      isCheckedTerms: [ { value: false, disabled: true }, Validators.requiredTrue]
    });
  }

  async next() {
    let loan = new LoanEntityIB();
    const newDate = new Date();
    newDate.setMonth(newDate.getMonth() + 1);
    loan.initialPaymentDate = newDate;
    loan.customerDTO = new CustomerEntity();
    loan.customerDTO = this.customer;
    loan.branchID = 1;
    loan.branchName = 'Head Office';
    loan.idLoanExtern = '0';
    loan.customerName = this.customer.customerName;
    let prod = this.sharedService.getProduct();
    loan.productDTO = prod;
    loan.productCode = prod.code;
    loan.productId = prod.id;

    //Set Intial Data For simulation default
    loan.applyAmountTotal = prod.maximumBalance;
    loan.approvelAmount = prod.productDetailsDTOs[0].maximumAmount;
    loan.termPeriodNum = prod.maximumTerm;
    loan.termPeriodID = prod.minimumTerm;
    loan.normalPayment = Math.ceil(
      (loan.applyAmountTotal +
        loan.applyAmountTotal * (0.20963 * (loan.termPeriodNum / 12))) /
        loan.termPeriodNum
    );
    loan.customerDTO.sendToACM = false;
    this.sharedService.setLoanDeal(loan);
    this.uuidNumber = uuidv4();
    let user = this.sharedService.getUser();
    this.customerService
      .getCustomerById(user.customerId)
      .subscribe((customer) => {
        loan.customerName = customer.customerName;
        loan.customerDTO = new CustomerEntity();
        loan.customerDTO = customer;
        loan.actionToAcm = null;
        loan.interestFreq = 1;
        loan.periodsDeferredType = 0;
        loan.paymentFreq = '1';
        this.loanService
          .updateLoan(loan)
          .toPromise()
          .then(
            (createdLoan) => {
              this.sharedService.setLoanDeal(createdLoan);
              this.showNafathVerification = true;
              const reqNafedh: RequestNafedhEntity = new RequestNafedhEntity();
              reqNafedh.nationalId = this.customer.identity;
              reqNafedh.service = 'OpenAccount';
              this.thirdPartyService
                .createRequestNafedh(
                  reqNafedh,
                  this.uuidNumber.toString(),
                  createdLoan.idIbLoan
                )
                .subscribe(
                  (responseNafedhAuth) => {
                    this.nafedhCode = responseNafedhAuth.random;
                    localStorage.setItem('nafedh_code', this.nafedhCode);
                    this.sharedService.setTransactionIdNafedh(
                      responseNafedhAuth.transId
                    );
                    this.sharedService.setCustomer(this.customer);
                    this.webhookHandlerNafedh();
                    this.showNafedhOtp = true;
                    this.loaderService.disableLoader.next(true);
                  },
                  (error) => {
                    this.loanService
                      .deleteLoanById(createdLoan.idIbLoan)
                      .subscribe((resDel) => {
                        this.sharedService.showError(
                          this.translate.instant('ERROR.error_nafedh_request')
                        );
                      });
                  }
                );
            },
            (errorLoanCreation) => {
              console.log('errorLoanCreation', errorLoanCreation);
            }
          );
      });
  }

  webhookHandlerNafedh() {
    let nafadhResponse = new ThirdPartyHistorique();
    nafadhResponse.category = 'NAFATH/webhook';
    nafadhResponse.idLoan = this.sharedService.getLoanDeal().idIbLoan;
    nafadhResponse.idCustomer = this.sharedService.getCustomer().ibCustomerId;
    let l = this.sharedService.getLoanDeal().idIbLoan;
    this.intervalSubscription = interval(5000)
      .pipe(
        switchMap(() => this.loanService.findByIdIbLoan(l)),
        filter((loanResponseSync) => loanResponseSync.accountNumber !== null),
        take(1)
      )
      .subscribe((res) => {
        if (res.stepPath == PathConstants.PATH_PERSONAL_INFORMATION){
               this.router.navigate(['/' + PathConstants.PATH_INFO_ENTRY]).then(() => {
                this.customerService.getCustomerById(this.user.customerId).subscribe((customer) => {
                  this.sharedService.setLoanDeal(res);
                  this.sharedService.setCustomer(customer);
                  localStorage.removeItem('nafedh_code');
                });

            });
        }else {
          this.router.navigate(['/' + res.stepPath]).then(() => {
            this.customerService.getCustomerById(this.user.customerId).subscribe((customer) => {
              this.sharedService.setLoanDeal(res);
              this.sharedService.setCustomer(customer);
              localStorage.removeItem('nafedh_code');
            });
          });
        }
      });
  }

  fetchStatusFromStringJSON(jsonString: string) {
    let match = jsonString.match(/status=(\w+)/);
    if (match) {
      const status = match[1];
      return status;
    } else {
      return null;
    }
  }

  // Confirm Terms Method
  confirmTerms() {
    this.nafedhForm.controls['isCheckedTerms'].setValue(true);
    this.nafedhForm.controls['isCheckedTerms'].enable();
    this.showModalTerms = false;
  }

  // Open Dialog Method
  openModalTerms() {
    this.showModalTerms = true;
  }

  ngOnDestroy(): void {
    // Unsubscribe to prevent memory leaks when the component is destroyed
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }
    this.loaderService.disableLoader.next(false);
  }

  checklength(event: any) {
    const inputValue = event.target.value;
    if (inputValue.length >= 9) {
      const limitedValue = inputValue.substring(0, 9);
      this.nafedhForm.controls['mobileNumber'].setValue(limitedValue);
    }
  }

  launchNafedhApp() {
    const iosUrl = 'https://apps.apple.com/sa/app/%D9%86%D9%81%D8%A7%D8%B0-nafath/id1598909871';
    const androidUrl = 'https://play.google.com/store/apps/details?id=sa.gov.nic.myid&hl=fr';
    const os = this.sharedService.getDeviceInfo().os.toLowerCase();
    const linkToOpen = os === 'ios' ? iosUrl : androidUrl;
    const appToOpen = os === 'ios'? this.nafedhDeepLinkIos : this.nafedhDeepLinkAndroid;
    try {
      this.showHomeButton = true;
      window.open(appToOpen, '_self');
    } catch (error) {
      console.error('Error launching Nafedh app:', error);
      window.open(linkToOpen, '_blank');
    }
  }


}
