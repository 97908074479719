<div class="bg-2" style="position: sticky !important;">
  <app-screen-title [title]="'TITLE.schedule_payments'" [color]="'color1'" [backTo]="'/loan-schedule-details'"></app-screen-title>
  <div class="px-3">
    <div class="card text-center p-2 pt-3">

       <!-- Curved Progress Bar Percentage -->
      <div class="flc">
        <div class="cpb" role="progressbar" [attr.aria-valuenow]="getPercentage()" aria-valuemin="0" aria-valuemax="100"
          [style.--value]="getPercentage()">
          <div class="grid">
            <label class="fw-700 color3 text-center mb-0 fss" style="margin-bottom:unset">{{ 'LABELS.payment_status' | translate }}</label>
                   <span class="flc mb-1 fw-650 color2 fsxl" *ngIf="paymentStatus === 100"> {{ loan.percentagePayment / 100 | percent:'1.0-0' }} </span>
                   <span class="flc mb-1 fw-650 color2 fsxl" *ngIf="paymentStatus != 100"> {{paymentStatus | percent:'1.2-2'}}</span>
                   <span class="flc mb-1 fw-650 color2 fsxl" *ngIf="paymentStatus == undefined"> 0%</span>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <div class="fbaseline">
            <div>
              <img class=" ib-icons pt-3 m-2 " src="assets/images/icons/money_icon.png" alt="My Image">
            </div>
            <div class="d-grid py-2">
              <label class="form-label ms-2 fw-650">{{ 'LABELS.payment_done' | translate }}</label>
              <label class="form-label ms-2 fw-650 text-primary">{{donePayment  | number:'1.2-2'}} {{ 'CURRENCY.sar' | translate}}</label>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="fbaseline">
            <div>
              <img class=" ib-icons  pt-3 m-2 " src="assets/images/icons/money_case_icon.png"
                alt="My Image">
            </div>
            <div class="d-grid py-2">
              <label class="form-label ms-2 fw-650">{{ 'LABELS.remaining_amount' | translate }}</label>
              <label class="form-label ms-2 fw-650 text-primary">{{remainingAmount  | number:'1.2-2'}} {{ 'CURRENCY.sar' |
                translate}}</label>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <div class="fbaseline">
            <div>
              <img class=" ib-icons  pt-3 m-2" src="assets/images/icons/check1.png" alt="My Image">
            </div>
            <div class="d-grid py-2">
              <label class="form-label  fw-650">{{ 'LABELS.total_amount' | translate }}</label>
              <label class="form-label fw-650 text-primary">{{loan.approvelAmount  | number:'1.2-2'}} {{ 'CURRENCY.sar' |
                translate}}</label>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="fbaseline">
            <div>
              <img class=" ib-icons  pt-3 m-2 " src="assets/images/icons/check2.png" alt="My Image">
            </div>
            <div class="d-grid py-2">
              <label class="form-label fw-650">{{ 'LABELS.premium_paid' | translate }}</label>
              <label class="form-label fw-650 text-primary"> {{termPaid }} / {{loan.termPeriodNum}} </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="px-3 mb-1 mt-2 pb-2">
    <div class="card p-2" *ngFor="let schedule of schedules; let index = index" style="margin-bottom: 10px;">
      <div class="row">
        <div class="col-2">
          <div>
            <img class=" ib-icons  pt-2 m-2 " src="assets/images/icons/verified.png" alt="My Image"
              *ngIf="schedule.statusLabel==='Fully Paid' ">
            <img class=" ib-icons  pt-2 m-2 " src="assets/images/icons/pending.png" alt="My Image"
              *ngIf="schedule.statusLabel !=='Fully Paid' ">
          </div>
        </div>
        <div class="col-6">
          <label class="form-label py-0 fw-650"> {{ 'LABELS.term_schedule' | translate }} {{index +1}}
            <span style="color:green;font-size: small;" *ngIf="schedule.statusLabel==='Fully Paid' ">
              {{ 'LABELS.payed' | translate }}
            </span>
            <span style="color:#c18832;font-size: small;" *ngIf="schedule.statusLabel!=='Fully Paid' ">
             ( {{ 'LABELS.not_paid' | translate }} )
            </span>
          </label>
          <label class="form-label py-0 op-50" style="display: block;"> {{schedule.repaymentDate}} </label>
        </div>
        <div class="col-4">
          <label class="form-label py-0   fw-650"> {{ 'LABELS.amount_paid' | translate }} </label>
          <span class="py-0 fss fw-600 color3"> {{listAmountPaid[index]}}</span>
        </div>
      </div>
    </div>
  </div>

  <div class="text-center py-4 px-3 mb-5">
    <button type="button" class="btn btn-primary btn-lg  login-btn mb-5" (click)="getScheduleReport()">
     <img class="ib-icons " src="assets/images/icons/scan.png" alt="lock"> {{'LABELS.download_schedule' |translate}}
    </button>
  </div>

</div>

<app-footer-menu></app-footer-menu>
