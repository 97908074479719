/**
 * ACM Constants.
 */
export class AcmConstants {

  /** The Constant STATUT_WORKFLOW_UPDATE_LOAN_DATA. */
  public static readonly STATUT_WORKFLOW_UPDATE_LOAN_DATA = 1;

  /** The Constant STATUT_WORKFLOW_GURANTOR. */
  public static readonly STATUT_WORKFLOW_GURANTOR = 2;

  /** The Constant STATUT_WORKFLOW_ADD_DOCUMENT. */
  public static readonly STATUT_WORKFLOW_ADD_DOCUMENT = 3;

  /** The Constant STATUT_WORKFLOW_APPROVAL. */
  public static readonly STATUT_WORKFLOW_APPROVAL = 4;

  /** The Constant STATUT_WORKFLOW_PAY_ADMIN_FEES. */
  public static readonly STATUT_WORKFLOW_PAY_ADMIN_FEES = 5;

  /** The Constant STATUT_WORKFLOW_UPLOAD_AGREEMENT. */
  public static readonly STATUT_WORKFLOW_UPLOAD_AGREEMENT = 6;

  /** The Constant TYPE_DOCUMENT_CATEGORIE_SIGNED_AGREEMENT. */
  public static readonly TYPE_DOCUMENT_CATEGORIE_SIGNED_AGREEMENT = 2;

  /** The Constant TYPE_DOCUMENT_CATEGORIE_CUSTOMER. */
  public static readonly TYPE_DOCUMENT_CATEGORIE_CUSTOMER = 1;

  /** The Constant TYPE_DOCUMENT_CATEGORIE_LOAN. */
  public static readonly TYPE_DOCUMENT_CATEGORIE_LOAN = 0;

  /** The Constant STATUT_WORKFLOW_REVIEW. */
  public static readonly STATUT_WORKFLOW_REVIEW = 16;

  /** The Constant AUTEUR. */
  public static readonly AUTEUR = 'brjmf';

  /** The Constant INDIVIDUAL. */
  public static readonly INDIVIDUAL = 'INDIV';

  /** The Constant GROUP. */
  public static readonly GROUP = 'GRP';

  /** The Constant HEAD. */
  public static readonly HEAD = 'Head';

  /** The Constant ORGANIZATION. */
  public static readonly ORGANIZATION = 'ORG';

  /** The Constant UDF_GROUP_NATIONALITY. */
  public static readonly UDF_GROUP_NATIONALITY = 2;

  /** The Constant UDF_GROUP_ORGANISATION. */
  public static readonly UDF_GROUP_ORGANISATION = 4;

  /** The Constant UDF_GROUP_BANK_REPAYMENT_ACCOUNTS. */
  public static readonly UDF_GROUP_BANK_REPAYMENT_ACCOUNTS = 9;

  /** The Constant FIELD_NAME_NATIONALITY. */
  public static readonly FIELD_NAME_NATIONALITY = 'Nationality';

  /** The Constant 'SUBJECT_IB'. */
  public static readonly SUBJECT_IB = 'SUBJECT_IB';

  /** The Constant CUSTOMER_TYPE_RELATION. */
  public static readonly CUSTOMER_TYPE_RELATION = 'RELATIONSHIP';

  /** The Constant CUSTOMER_TYPE_LINK. */
  public static readonly CUSTOMER_TYPE_LINK = 'LINK';

  /** The Constant RELATION_GUARANTOR. */
  public static readonly RELATION_GUARANTOR = 'GUARANTOR';

  /** The Constant MEMBERS. */
  public static readonly MEMBERS = 'MEMBERS';

  /** The Constant IHM_LOAN_APPLICATION. */
  public static readonly IHM_LOAN_APPLICATION = 'loan-application';

  /** The Constant IHM_NEW_LOAN_APPLICATION. */
  public static readonly IHM_NEW_LOAN_APPLICATION = 'new-loan-application';

  /** The Constant UNAUTHORIZED. */
  public static readonly UNAUTHORIZED = '/unauthorized';

  /** The Constant WEBSITE_KEY. */
  public static readonly WEBSITE_KEY = 'WEBSITE_LINK';

  /** The Constant FACEBOOK_KEY. */
  public static readonly FACEBOOK_KEY = 'FACEBOOK_LINK';

  /** The Constant TWITTER_KEY. */
  public static readonly TWITTER_KEY = 'TWITTER_LINK';

  /** The Constant INSTAGRAM_LINK. */
  public static readonly INSTAGRAM_LINK = 'INSTAGRAM_LINK';

  /** The Constant RESIDENT_COUNTRY. */
  public static readonly RESIDENT_COUNTRY = 'جمهورية مصر العربية';

  // Calcule methode simulation
  /** The Constant LOAN_AMOUNT_CALULATION_TYPE. */
  public static readonly LOAN_AMOUNT_CALULATION_TYPE = '1';

  /** The Constant REPAYMENT_AMOUNT_CALULATION_TYPE. */
  public static readonly REPAYMENT_AMOUNT_CALULATION_TYPE = '0';

  /** The Constant TERM_CALULATION_TYPE. */
  public static readonly TERM_CALULATION_TYPE = '2';

  /** The Constant BRANCH_MANAGER. */
  public static readonly BRANCH_MANAGER = 'BRANCH_MANAGER';
  /** The Constant CUSTOMER_CATEGORY_CUSTOMER. */
  public static readonly CUSTOMER_CATEGORY_CUSTOMER = 'CUSTOMER';

  /** The Constant CHANGE_PWD. */
  public static readonly CHANGE_PWD = 'change-pwd';
  /** The Constant CUSTOMER_APPLICATION. */
  public static readonly CUSTOMER_APPLICATION = '/customer-application';

  /** The Constant MURABAHA_PRODUCT. */
  public static readonly MURABAHA_PRODUCT = 'Murabaha Loans';

  /** The Constant HOME_BL_PRODUCT. */
  public static readonly HOME_BL_PRODUCT = 'Home BL';

  /** The Constant GROUP_LOANS_PRODUCT. */
  public static readonly GROUP_LOANS_PRODUCT = 'Group Loans';

  /** The Constant UDF_FIELD_HIJRI_DOF. */
  public static readonly UDF_FIELD_HIJRI_DOF = 'Hijiri BirthDate';

  /** The Constant _MAX */
  public static readonly MEMBERS_NUMBER_MAX = 'MEMBERS_NUMBER_MAX';

  /** The Constant MEMBERS_NUMBER_MIN */
  public static readonly MEMBERS_NUMBER_MIN = 'MEMBERS_NUMBER_MIN';

  /** The Constant CODE_API_ABACUS_MSG */
  public static readonly CODE_API_ABACUS_MSG = 'ACM-00508';

  /** The Constant SECURITY_PDF ID */
  public static readonly SECURITY_PDF = 'security';

  /** The Constant CAREER_PDF PDF ID */
  public static readonly CAREER_PDF = 'career';

  /** The Constant CUSTOMER_PROTECTION PDF ID */
  public static readonly CUSTOMER_PROTECTION = 'customerProtection';

  /** The Constant CUSTOMER_GUARANTOR */
  public static readonly CUSTOMER_GUARANTOR = 'CUSTOMER_GUARANTOR';
  /** The Constant ROUND_UP */
  public static readonly ROUND_UP = 'UP';
  /** The Constant ROUND_DOWN */
  public static readonly ROUND_DOWN = 'DOWN';
  /** The Constant ROUND */
  public static readonly ROUND = 'ROUND';
  /** The Constant DONT_ROUND */
  public static readonly DONT_ROUND = 'DONT ROUND';

  /** The ar. */
  public static readonly AR = 'AR';
  /** The fr. */
  public static readonly FR = 'FR';
  /** The en. */
  public static readonly EN = 'EN';
  /** COPYRIGHTS */
  public static readonly COPYRIGHTS = 'TALYS_COPYRIGHTS';
  /** The COMMUNICATION_MB_IB */
  public static readonly COMMUNICATION_MB_IB = 'COMMUNICATION_MB_IB';
  /** INDUSTRY CODE GROUP */
  public static readonly INDUSTRY_CODE_GROUP = 'Industry Code';
  /** DIFFERENCE_PERIOD_OF_EXPIRY_DATE_AND_ISSUE_DATE */
  public static readonly DIFFERENCE_PERIOD_OF_EXPIRY_DATE_AND_ISSUE_DATE = 'DIFFERENCE_PERIOD_OF_EXPIRY_DATE_AND_ISSUE_DATE';
  /** NATIONALITY */
  public static readonly NATIONALITY = 'Nationality';
  /** NATIONAL_ID */
  public static readonly NATIONAL_ID = 'National ID';
  /** RESIDENCE_ID */
  public static readonly RESIDENCE_ID = 'Resident ID';
  /** RESIDENCE_RELATION_STATUS */
  public static readonly RESIDENCE_RELATION_STATUS = 'Resident Relation Status';
  /** EXPIRY_DATE */
  public static readonly EXPIRY_DATE = 'Expiry date';
  /** FAMILY_SITUATION */
  public static readonly FAMILY_SITUATION = 'Family situation';
  /** PLACE_OF_ISSUE */
  public static readonly PLACE_OF_ISSUE = 'Place of issue';
  /** ADD_ISSUE_DATE */
  public static readonly ADD_ISSUE_DATE = 'Add Issue Date';
  /** CUSTOMER_NATIONALITY_CODE */
  public static readonly CUSTOMER_NATIONALITY_CODE = 'Customer Nationality';
  /** DISBURSEMENT_METHOD */
  public static readonly DISBURSEMENT_METHOD = 'Disbursment Method';
  /** NO_CARD */
  public static readonly NO_CARD = 'No Card';

  public static readonly ADMIN_FEE ='ADMIN_FEE';

  /**
   * Loan application routes
   */
  public static readonly COMPLETE_CUSTOMER_DATA_PATH = 'complete-customer-data';
  public static readonly HOME_CONNECTED_USER_PATH = 'home-connected-user';

  /** GED : The Constant Loan. */
  public static readonly LOAN = 'loan';

  public static readonly ACTION_DELETE='D';

  public static readonly PASSWORD_HISTORY = 'ACM-07018';
  public static readonly PASSWORD_DONT_MATCH = 'ACM-00511';
  public static readonly PASSWORD_INVALID = 'ACM-00510';
}
