<div id="Biometric_Settings" class="bg-2 vh-100 vw-100">
  <app-screen-title [title]="'TITLE.biometrics_auth'" [color]="'color1'" [backTo]="'/settings'"></app-screen-title>
  <div id="reset-pw-div" class="px-5" >
    <div class="form-group mt-4 mb-3 py-4">
      <div class="wrapper">
        <div class="flc">
          <div class="ms-2  mb-4 flc inline-flex align-items-center">
            <img class="img-logo-sanad" src="assets/images/biometrics.png" alt="biometrics">
          </div>
        </div>
      </div>
      <label class="text-secondary text-center flc" *ngIf="!biometricsStatus"> {{ 'INFO.activate_Bio' | translate }} </label>
      <label class="text-secondary text-center flc" *ngIf="biometricsStatus"> {{ 'INFO.desavtivate_Bio' | translate }} </label>
    </div>
  </div>
  <div class="text-center px-2 py-4 mb-2 fixed-bottom">
    <button type="button" class="btn btn-primary btn-lg mb-2 login-btn" *ngIf="!biometricsStatus" (click)="activateBiometrics()">
      {{'BUTTONS.activate' | translate}}
    </button>
    <button type="button" class="btn btn-danger btn-lg mb-2 login-btn" *ngIf="biometricsStatus" (click)="disableBiometric()" >
      {{'BUTTONS.desactivate' | translate}}
    </button>
  </div>




</div>


