
export class PathConstants {

    /** The Constant PATH loan-statut */
    public static readonly PATH_LOAN_STATUT = 'loan-statut';

    /** The Constant PATH nafedh-auth */
    public static readonly PATH_NAFEDH = 'nafedh-auth';

    /** The Constant PATH bank-information */
    public static readonly PATH_BANK_INFO = 'bank-information';

    /** The Constant PATH consent-validation */
    public static readonly PATH_CONSENT_VALID = 'consent-validation';

    /** The Constant PATH information-entry */
    public static readonly PATH_INFO_ENTRY = 'information-entry';

    /** The Constant PATH personal-information */
    public static readonly PATH_PERSONAL_INFORMATION = 'personal-information';

    /** The Constant PATH bank-details */
    public static readonly PATH_BANK_DETAILS = 'bank-details';

    /** The Constant PATH sign-contract */
    public static readonly PATH_SIGN_CONTRACT = 'sign-contract';

    /** The Constant PATH home */
    public static readonly PATH_HOME = 'home';

    /** The Constant PATH otp-biometrics */
    public static readonly PATH_OTP_BIOMETRICS = 'otp-biometrics';

    /** The Constant PATH loan-schedule-details */
    public static readonly PATH_LOAN_SCHEDULE_DETAILS = 'loan-schedule-details';

    /** The Constant PATH loan-applications */
    public static readonly PATH_LOAN_APPLICATIONS = 'loan-applications';

    /** The Constant PATH login */
    public static readonly PATH_LOGIN = 'login';

    /** The Constant PATH view-claim */
    public static readonly PATH_VIEW_CLAIM = 'view-claim';

    /** The Constant PATH edit-personal-info */
    public static readonly PATH_EDIT_PERSONAL_INFO = 'edit-personal-info';

    /** The Constant PATH edit-work-info */
    public static readonly PATH_EDIT_WORK_INFO = 'edit-work-info';

    /** The Constant PATH edit-income-details */
    public static readonly PATH_EDIT_INCOME_DETAILS = 'edit-income-details';

    /** The Constant PATH edit-expenses-obligations */
    public static readonly PATH_EDIT_EXPENSES_OBLIGATIONS = 'edit-expenses-obligations';

    /** The Constant PATH bank-information */
    public static readonly PATH_BANK_INFORMATION = 'bank-information';

    /** The Constant PATH application-terms */
    public static readonly PATH_APPLICATION_TERMS = 'application-terms';

    /** The Constant PATH product-list */
    public static readonly PATH_PRODUCT_LIST = 'product-list';

    /** The Constant PATH summary-initial-approval */
    public static readonly PATH_SUMMARY_INIT_APPROVAL = 'summary-initial-approval';

    /** The Constant PATH simah-status */
    public static readonly PATH_SIMAH_STATUS = 'simah-status';

    /** The Constant PATH pending */
    public static readonly PATH_PENDING = 'pending';

}
