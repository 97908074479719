
<div id="general-details" class="bg-2 vh-100 vw-100 " *ngIf="showGlobalDetails && !showEditPhoneNumber">
  <app-screen-title [title]="'SETTINGS.account_details'" [color]="'color1'" [backTo]="'/settings'"></app-screen-title>
  <div id="div1" class="px-3 mt-4">
    <form [formGroup]="accountDetailsForm" (ngSubmit)="updateEmail()">
      <div class="mb-2">
        <label class="form-label ms-2 fw-500" for="fullName">{{'LABELS.full_name' | translate}}</label>
        <div style="position: relative;">
          <input type="text" class="form-control bg-white" formControlName="fullName">
        </div>
      </div>
      <div class="mb-2">
        <div class="fsb">
          <label class="form-label ms-2 fw-500" for="phoneNumber">{{ 'LABELS.phone_number' | translate}}</label>
          <label class="form-label ms-2 fw-500 cu-link" (click)="sendOTP()">{{'LINK.edit' | translate}}</label>
        </div>
        <div class=""  style="position: relative;">
          <span class="m-2 p-2 mobile-span" *ngIf=" sharedService.getLang() != 'ar' " >+ 966</span>
          <span class="m-2 p-2 mobile-span" *ngIf=" sharedService.getLang() == 'ar' ">966 +</span>
          <input type="number" class="form-control cpl bg-white mobile-number-input" placeholder="5 XXXX XXXX" dir="ltr" formControlName="phoneNumber"
            aria-label="phone_number" aria-describedby="basic-addon1" >
        </div>
      </div>
      <div class="mb-2">
        <label class="form-label ms-2 fw-500" for="email">{{'LABELS.email_address' | translate}}</label>
        <div style="position: relative;">
          <input type="email" class="form-control" placeholder="{{'PLACEHOLDER.enter_email' | translate}}" formControlName="email">
        </div>
      </div>
      <div class="mb-4">
        <div class="fsb">
          <label class="form-label ms-2 fw-500" for="password">{{ 'LABELS.password' | translate}}</label>
          <label class="form-label ms-2 fw-500 cu-link" routerLink="/reset-password">{{'LINK.edit' | translate}}</label>
        </div>
        <div style="position: relative;">
          <img class="ib-icons m-2 pt-2" src="assets/images/icons/lock.png" alt="lock" style="position: absolute;">
          <input type="text" class="form-control icon-input bg-white" placeholder="********" formControlName="password">
        </div>
      </div>


    <div class="text-center">
      <button class="btn btn-primary btn-lg mb-2 login-btn" type="submit">
        {{'BUTTONS.save' | translate}}
      </button>
    </div>
  </form>
  </div>

</div>

<div class="pt-3" >
  <app-otp-biometrics-validation *ngIf="showOTP" [source]="'account-details'" (messageEvent)="otpResponse($event)" ></app-otp-biometrics-validation>
</div>


<div id="edit-phone-number" class="bg-2 vh-100 vw-100 px-3" *ngIf="!showGlobalDetails && showEditPhoneNumber">
  <app-screen-title [title]="'SETTINGS.update_number'" [color]="'color1'"></app-screen-title>
  <form [formGroup]="phoneNumberForm" (ngSubmit)="updatePhoneNumber()">
  <div class="mb-4">
    <label class="form-label ms-2">{{'LABELS.national_id' | translate}}</label>
    <div style="position: relative;">
      <input type="text" class="form-control" formControlName="nationalId" placeholder="{{'PLACEHOLDER.enter_nid' | translate}}">
    </div>
  </div>
  <div class="mb-4">
      <div class="fsb">
        <label class="form-label ms-2">{{ 'LABELS.mobile_number' | translate}}</label>
          <label class="form-label ms-2  text-secondary fss">{{'LABELS.phone_number_registered_in_absher' | translate}}</label>
      </div>
    <div class=""  style="position: relative;">
      <span class="m-2 p-2 mobile-span">+966</span>
      <input type="number" class="form-control cpl mobile-number-input"  placeholder="5 XXXX XXXX" formControlName="phoneNumber"
        aria-label="phone_number" aria-describedby="basic-addon1" (input)="checklength($event)" dir="ltr">
    </div>
  </div>
  <div class="text-center">
    <button type="submit" class="btn btn-primary btn-lg mb-2 login-btn">
      {{'BUTTONS.update_mobile_number' | translate}}
    </button>
  </div>
</form>
</div>


