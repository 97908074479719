import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from 'src/app/ib-services/shared.service';
import { LoanEntityIB } from 'src/app/shared/Entities/loan.entity';
import { ScheduleEntity } from 'src/app/shared/Entities/schedule.entity';

@Component({
  selector: 'app-schedule-services',
  templateUrl: './schedule-services.component.html',
  styleUrls: ['./schedule-services.component.scss']
})
export class ScheduleServicesComponent implements OnInit {

  public showServicesMenu : boolean = true;
  public showEarlyPayment : boolean = false;
  public showRefinance : boolean = false;
  public loan : LoanEntityIB;
  public paymentStatus : number;
  public schedules : ScheduleEntity[] = [];
  public termPaid :number=0;
  public donePayment : number;
  public remainingAmount : number;
  public listAmountPaid : string[] = [];
  public lastRepaymentDate : string;
  public nextRepaymentDate : string;
  public lang : string;


  constructor(public sharedService : SharedService,private router: Router,private translate : TranslateService) { }

  ngOnInit() {
    this.lang = localStorage.getItem('lang');
    this.loan = this.sharedService.getLoanDeal();
    this.schedules = this.sharedService.getSchedules();
    this.processSchedules();
    this.calculatePaymentStatus();
    this.calculateRemainingAmount();
  }

  private processSchedules(): void {
    this.lastRepaymentDate = this.schedules[this.schedules.length - 1].repaymentDate;
    this.nextRepaymentDate = this.schedules[0].repaymentDate;

    this.schedules.forEach((schedule, index) => {
      const amountPaid = schedule.interestAmtPaid != null ? this.calculateTotalAmountPaid(index) : schedule.loanRepaymentPaid;
      this.listAmountPaid.push(amountPaid);

      if (schedule.statusLabel === 'Fully Paid') {
        this.termPaid++;
        if (index < this.schedules.length - 1) {
          this.nextRepaymentDate = this.schedules[index + 1].repaymentDate;
        }
      }
    });
  }

  private calculatePaymentStatus(): void {
    this.paymentStatus = this.termPaid / this.loan.termPeriodNum;
    if (this.termPaid === this.loan.termPeriodNum) {
      this.paymentStatus = 100;
    }
  }

  private calculateRemainingAmount(): void {
    this.donePayment = this.loan.normalPayment * this.termPaid;
    this.remainingAmount = this.loan.approvelAmount - this.donePayment;
  }

  calculateTotalAmountPaid( index:number ) : string{
    let a = this.schedules[index].loanRepaymentPaid;
    let b = this.schedules[index].interestAmtPaid;
    let valueA = this.extractNumberFromAmount(a);
    let valueB = 0;
    if (b != null){
      valueB = this.extractNumberFromAmount(b);
    }
    let res = valueB + valueA;
    let result = "SAR"+res.toFixed(2);
    return result;
  }
  extractNumberFromAmount(amountString : string) : number {
    let x = amountString.replace('SAR','');
    let convertedNumber = Number(x);
    return   convertedNumber;
  }

  earlyPayment(){
    this.showServicesMenu = false;
    this.showEarlyPayment = true;
    this.showRefinance = false;
  }

  refinance(){
    // this.showServicesMenu = false;
    // this.showEarlyPayment = false;
    // this.showRefinance = true;
    this.sharedService.showInfo(this.translate.instant('TOAST.feature_not_available'))
  }
  payer(){
    this.sharedService.setLoanDeal(this.loan);
    this.router.navigate(['new-transaction']);
  }


  getPercentage(): number {
    return Math.round(this.paymentStatus * 100);
  }

}
