<div class="input-group" [dir]="direction">
    <input
      class="form-control text-center"
      ngbDatepicker
      [placeholder]="placeholder"
      [firstDayOfWeek]="7"
      [minDate]="minDateStruct$ | async"
      [maxDate]="maxDateStruct$ | async"
      (dateSelect)="dateSelect($event,true)"
      (click)="datepicker.toggle()"
      #datepicker="ngbDatepicker"
      [(ngModel)]="hijriDate"
    />
  </div>
