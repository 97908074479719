import { CustomerFocalRequestDTO } from "./CustomerFocalRequest.entity";
import { DeviceFocalRequestDTO } from "./DeviceFocalRequest.entity";

export class EventFocalRequestDTO{
  public account_reference : string;
  public customer : CustomerFocalRequestDTO;
  public device : DeviceFocalRequestDTO;
  public ip : string;
  public location :string;
  public reference : string;
  public session_reference : string;
  public timestamp : number;
  public type : string;
}
