
import { AddressEntity } from "./Address.entity";
import { CustomerLinksRelationshipEntity } from "./CustomerLinksRelationship.entity";
import { UDFLinksGroupeFieldsEntity } from "./udfLinksGroupeFields.entity";
import { UserDefinedFieldsLinksEntity } from "./userDefinedFieldsLinks.entity";

export class CustomerEntity {
    value : any;
    id: number;
    customerIdExtern: number;
    customerNumber: string;
    customerName: string;
    customerNameNoPipe: string;
    firstName: string;
    secondName: string;
    middleName: string;
    lastName: string;
    customerOpenDate: Date;
    dateOfBirth: Date;
    dateOfBirthHijri: string;
    accountPortfolioID: string;
    accountPortfolioCode: string;
    accountPortfolioDescription: string;
    altName: string;
    customerAddress: string;
    branchId: number;
    branchesName: string;
    branchesDescription: string;
    age: number;
    arrearDay: number;
    arrearSchedule: number;
    telephone1: string;
    telephone2: string;
    telephone3: string;
    image: any;
    customerType: string;
    customerCategory: string;
    organisationId: number;
    groupeId: number;
    telephone: string;
    fax: string;
    registerNumber: string;
    webSite: string;
    sector: string;
    organisationIdExtern: string;
    email: string;
    gender: string;
    organizationName: string;
    accountYearEnd: Date;
    solidarityName: string;
    listAddress: AddressEntity[];
    // use only Complete Loan data
    addressList: AddressEntity[];
    registrationNumberDateOfIssue: Date;
    registrationNumberDateOfExpiry: Date;
    projectDescription: string;
    userDefinedFieldsLinksDTOs: UserDefinedFieldsLinksEntity[];
    role: string;
    amountGuarantor: number;
    dateInsertion: Date;
    customerLinksRelationshipDTOs: CustomerLinksRelationshipEntity[];
    identity: string;
    /** The id loan => USED only to save history of customer change. */
    idLoan: number;
    // Marital Status
    maritalStatus: string;
    ibCustomerId: number;
    udfLinksGroupeFieldsDTOs :UDFLinksGroupeFieldsEntity[] ;
    sendToACM : boolean;
    otherInformations : string;
    motifDeleteAccount : string;
    constructor() {
    }
}
