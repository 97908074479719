import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CreditService } from 'src/app/ib-services/credit.service';
import { CustomerService } from 'src/app/ib-services/customer.service';
import { LoanService } from 'src/app/ib-services/loan.service';
import { ParametrageService } from 'src/app/ib-services/parametrage.service';
import { SharedService } from 'src/app/ib-services/shared.service';
import { SettingDataStorageIbEntity } from 'src/app/shared/Entities/SettingDataStorageIb.entity';
import { CustomerEntity } from 'src/app/shared/Entities/customer.entity';
import { WorkInformationEntity } from 'src/app/shared/Entities/workInformation.entity';
import { PathConstants } from 'src/app/shared/mb-constants/path-constants';

@Component({
  selector: 'app-editWorkInformation',
  templateUrl: './editWorkInformation.component.html',
  styleUrls: ['./editWorkInformation.component.scss']
})
export class EditWorkInformationComponent implements OnInit {
  @ViewChild('selectElement') selectElement: any;

  public mode : string;
  public employerName: string;
  public dateJoining: Date;
  public militarySector: boolean;
  public customer : CustomerEntity
  public workInfo : WorkInformationEntity;
  public militaryRankList : SettingDataStorageIbEntity[] = [];
  public militaryRank: SettingDataStorageIbEntity = new SettingDataStorageIbEntity();
  public workSectionList : SettingDataStorageIbEntity[]= [];
  public workSection: SettingDataStorageIbEntity = new SettingDataStorageIbEntity();
  public lang: string

  constructor(private route: ActivatedRoute,private sharedService: SharedService, private loanService: LoanService, private router: Router,
              private translate : TranslateService,private paramService: ParametrageService, private customerService : CustomerService){}

  ngOnInit() {
    this.lang =  localStorage.getItem('lang');
    this.customer = this.sharedService.getCustomer();
    this.route.queryParams.subscribe(params => {
      this.mode = params['mode'];
      });
      this.paramService.findAllDataStorageIb().subscribe((res) => {
        res.forEach((data) => {
          switch (data.category) {
            case 'MILITARY_RANK':
              this.militaryRankList.push(data);
              break;
              case 'WORK_SECTION':
                this.workSectionList.push(data);
                break;
          }
        });
        if (this.mode==='update-customer'|| this.mode==='update') {
          this.workInfo = this.sharedService.getWorkInfo();
          if (this.workInfo){
          this.workSection= this.workSectionList?.filter(workSection => workSection.libelle === this.workInfo.workSection)[0] ;
          this.dateJoining= this.workInfo.dateJoining;
          this.militarySector= this.workInfo.militarySector;
          this.employerName= this.workInfo.employerName;
          this.militaryRank = this.militaryRankList?.filter(militaryRank =>  militaryRank.libelle===this.workInfo.militaryRank)[0];
        }
      }
      })

  }
  open(){
    this.selectElement.nativeElement.click();
  }
  checkMilitaryRank(){
    if (this.militarySector === true ){
      if (!this.militaryRank || !this.militaryRank.libelle) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }
  submit(){
    if ( !this.workSection || !this.dateJoining || this.militarySector===null || !this.employerName || !this.checkMilitaryRank() ) {
      this.sharedService.showError(this.translate.instant('ERR.fill_all_form'));
    }else{
      let workInformation = {
        workSection: this.workSection.libelle,
        dateJoining: this.dateJoining,
        militarySector: this.militarySector,
        employerName: this.employerName,
        militaryRank: this.militarySector? this.militaryRank.libelle : null
      };
    if (this.mode==='update-customer' || this.mode==='update') {
      const customerAdditionalInformation = {
        personalInformation : this.sharedService.getPersonalInfo(),
        workInformation : workInformation,
        incomeDetails : this.sharedService.getIncomeDetails(),
        expensesLiabilities : this.sharedService.getExpensesObligations(),
        bankInformation: this.sharedService.getBankInformation()
       }
        this.customer.otherInformations = JSON.stringify(customerAdditionalInformation);
        if (this.mode==='update-customer') {
          this.customerService.updateCustomer(this.customer).subscribe(res=>{
            this.sharedService.setCustomer(res);
            this.router.navigate(['/' + PathConstants.PATH_PERSONAL_INFORMATION]);
          });
        } else if (this.mode === 'update'){
          let loan = this.sharedService.getLoanDeal();
          loan.otherInformations = JSON.stringify(customerAdditionalInformation);
          this.loanService.updateLoan(loan).toPromise().then((res) => {
            this.customerService.updateCustomer(this.customer).subscribe(data=>{
              this.sharedService.setCustomer(data);
            });
            this.sharedService.setLoanDeal(res);
            this.router.navigate([loan.stepPath]);
          })
         }
    }
  }
}
  }
