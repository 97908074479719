<!-- [@fadeInOut] -->
<!-- <div style="display: contents !important;">
  <div id="main-authentication">
    <div class="mt-4 text-center cp-lr-10">
      <div class="ms-2 mb-3 mt-2  flc inline-flex align-items-center"
        [ngClass]="{'margintoploginimg' : sharedService.getDeviceInfo().os.toLowerCase() === 'ios'}">
        <img class="img-logo-sanad" src="assets/images/mainlogo2.png" alt="My Image">
      </div>
    </div>
  </div>
</div> -->

<!-- <app-screen-title [title]="'SETTINGS.frequent_questions_1'" [color]="'white'"
[backTo]="'/settings'"></app-screen-title> -->
<div class=" flc  pt-5 pb-4 ">
  <img class="img-logo-sanad" src="assets/images/mainlogo2.png" alt="mainlogo2">
</div>

<!-- LOGIN && SIGNUP TABS -->
<div class="px-3 pb-2">
  <div class="card tab-card">
    <div class="btn-group flex cp-3">
      <button type="button" class="btn tab-btn" [ngClass]="{'tab-btn-active': showLogin}" (click)="actLogin()">
        {{'BUTTONS.login' | translate}}
      </button>
      <button type="button" class="btn tab-btn" [ngClass]="{'tab-btn-active': showSignUp}" (click)="actSignup()">

        {{'BUTTONS.sign_up' | translate}}
      </button>
    </div>
  </div>
</div>

<!-- LOGIN -->
<div id="login" class="card card-main fixed-bottom card-fb-body-login" *ngIf="!showSignUp && showLogin">
  <div class="card-header card-header-main text-center color7">
    <!-- {{viewportWidth}} * {{viewportheight}} -->
    {{'CARD_HEADERS.header_signin' | translate}}
  </div>
  <div class="card-body  p-3">
    <div id="login-auth-normal">
      <form [formGroup]="loginForm">
        <div class="mb-2">
          <label class="form-label ms-2">{{'LABELS.national_id' | translate}}</label>
          <div style="position: relative;">
            <img class="ib-icons m-2 pt-2" src="assets/images/icons/identity.png" alt="identity"
              style="position: absolute;">
            <input type="text" class="form-control icon-input" placeholder="{{'PLACEHOLDER.enter_nid' | translate}}"
              maxlength="10"
              [ngClass]="{'fc-error': submittedLogin && loginForm.controls['username'].invalid  || passwordError}"
              formControlName="username" (input)="letterRemover()">
          </div>
        </div>

        <div class="mb-1">
          <label class="form-label ms-2">{{'LABELS.password' | translate}}</label>
          <div style="position: relative;">
            <img class="ib-icons m-2 pt-2" src="assets/images/icons/lock.png" alt="lock" style="position: absolute;">
            <input [type]="showPassword ? 'text' : 'password'" class="form-control icon-input"
              [ngClass]="{'fc-error': submittedLogin && loginForm.controls['password'].invalid || passwordError}"
              placeholder="{{'PLACEHOLDER.enter_pwd' | translate}}" formControlName="password">
          </div>
          <button class="btn" type="button" (click)="togglePasswordVisibility()"
            [ngClass]="isFloatRight ? 'float-right' : 'float-left'" style="position: relative; top: -40px;">
            <i class="bx" [ngClass]="showPassword ? 'bx-hide' : 'bx-show'" style="font-size: 20px !important;"></i>
          </button>
        </div>
      </form>
      <div>
        <div class="fsb col-11">
          <div>
            <input type="checkbox" id="terms-conditions" class="m-1">
            <label for="myCheckbox" class="label-main fw-650 fss m-2">
              {{'LABELS.remember_me' | translate}}
            </label>
          </div>
          <a routerLink="/forgetpassword" class="fw-650 fss m-1" style="padding-top: 5px;">{{'LABELS.forgot_password' |
            translate}}</a>
        </div>
      </div>

      <div class="text-center" *ngIf="biometricsStatus" (click)="launchBiometricsAuth()">
        <div class="m-1 inline-flex align-items-center">
          <img class="ib-icons" src="assets/images/biometrics.png" alt="biometrics">
        </div>
      </div>

      <div class="text-center pt-4 pb-3 px-1">
        <button type="button" class="btn btn-primary btn-lg mb-1 login-btn" (click)="login()">
          {{'BUTTONS.login' | translate}}
        </button>
        <a routerLink="claims">{{'LABELS.add_new_claim' |translate}}</a>
      </div>
    </div>

  </div>
</div>

<!-- SIGN UP -->
<div id="sign_up" class="card card-main fixed-bottom card-fb-body-login" *ngIf="showSignUp && !showLogin">
  <div class="card-header card-header-main text-center fss color7">
    {{'CARD_HEADERS.header_signup' | translate}}
  </div>

  <div class="card-body p-3">

    <div id="signupinprocess" *ngIf="!signUpSuccess">
      <form [formGroup]="signupForm">
        <div class="mb-2">
          <label class="form-label ms-2 " for="first_name">{{ 'LABELS.first_name' | translate}} <span
              class="ms-1 red">*</span></label>
          <div style="position: relative;">
            <input type="text" class="form-control" placeholder="{{'PLACEHOLDER.enter_first_name' | translate}}"
              [ngClass]="{'fc-error': submittedSignup && (signupForm.controls['first_name'].invalid && signupForm.controls['first_name'].touched)}"
              formControlName="first_name">
          </div>
        </div>
        <div class="mb-2">
          <label class="form-label ms-2" for="last_name">{{ 'LABELS.last_name' | translate}} <span
              class="ms-1 red">*</span></label>
          <div style="position: relative;">
            <input type="text" class="form-control" placeholder="{{'PLACEHOLDER.enter_last_name' | translate}}"
              [ngClass]="{'fc-error': submittedSignup && (signupForm.controls['last_name'].invalid&& signupForm.controls['last_name'].touched) }"
              formControlName="last_name">
          </div>
        </div>
        <div class="mb-2">
          <div class="fsb">
            <label class="form-label ms-2" for="mobileNumber">{{ 'LABELS.phone_number' | translate}} <span
                class="ms-1 red">*</span></label>
            <label class="form-label ms-2  text-secondary fss">{{'LABELS.phone_number_registered_in_absher' |
              translate}}</label>
          </div>
          <div class="" style="position: relative;">
            <span class="m-2 p-2 mobile-span" *ngIf=" sharedService.getLang() != 'ar' ">+ 966</span>
            <span class="m-2 p-2 mobile-span" *ngIf=" sharedService.getLang() == 'ar' ">966 +</span>
            <input type="number" class="form-control mobile-number-input" placeholder="5 XXXX XXXX"
              [ngClass]="{'fc-error': submittedSignup && (signupForm.controls['mobileNumber'].invalid  && signupForm.controls['mobileNumber'].touched)}"
              aria-label="phone_number" formControlName="mobileNumber" maxlength="9"
              (input)="checklength($event,'mobileNumber')" dir="ltr">
          </div>
        </div>
        <div class="mb-2">
          <label class="form-label ms-2" for="nationalId">{{'LABELS.national_id' | translate}}<span
              class="m-1 red">*</span></label>
          <div style="position: relative;">
            <img class="ib-icons m-2 pt-2" src="assets/images/icons/identity.png" alt="identity"
              style="position: absolute;">
            <input type="number" class="form-control icon-input" placeholder="{{'PLACEHOLDER.enter_nid' | translate}}"
              [ngClass]="{'fc-error': submittedSignup && (signupForm.controls['nationalId'].invalid && signupForm.controls['nationalId'].touched)}"
              formControlName="nationalId" (input)="checklength($event,'nationalId')">
          </div>
        </div>
        <div class="mb-2">
          <label class="form-label ms-2" for="email">{{'LABELS.email_address' | translate}} <span
              class="m-1 red">*</span></label>
          <div style="position: relative;">
            <img class="ib-icons m-2 pt-2" src="assets/images/icons/mail.png" alt="mail" style="position: absolute;">
            <input type="email" class="form-control icon-input" placeholder="{{'PLACEHOLDER.enter_email' | translate}}"
              [ngClass]="{'fc-error': submittedSignup && (signupForm.controls['email'].invalid && signupForm.controls['email'].touched)}"
              formControlName="email">
          </div>
        </div>
        <div class="mb-2">
          <label class="form-label" for="password">{{ 'LABELS.password' | translate}} <span class="m-1 red">*</span></label>
          <div style="position: relative;">  <!-- This container will be the reference for the tooltip -->
            <img class="ib-icons m-2 pt-2" src="assets/images/icons/lock.png" alt="lock" style="position: absolute;">
            <input type="password" [type]="showPassword ? 'text' : 'password'" class="form-control icon-input"
              (focus)="showTooltip()" (blur)="hideTooltip()"
              [ngClass]="{'fc-error': submittedSignup && (signupForm.controls['password'].invalid && signupForm.controls['password'].touched)}"
              placeholder="{{'PLACEHOLDER.enter_pwd' | translate}}" formControlName="password">
            <div class="px-2">
              <button class="btn" type="button" (click)="togglePasswordVisibility()"
                style="position: relative; top:-43px"
                [ngClass]="{'float-right': isFloatRight, 'float-left': !isFloatRight}">
                <i class="bx" [ngClass]="showPassword ? 'bx-hide' : 'bx-show'"
                  style="font-size: 20px !important; color: #707fc2"></i>
              </button>
            </div>
            <div *ngIf="passwordStrength > 80" class="ul-pw-str mb-3">
              <span class="op-70 li-pw-strength color5">{{'INFO.password_valid' | translate}}</span>
              <i class='bx bx-check-circle fw-bold check-icon strength-text'></i>
            </div>
            <!-- Tooltip -->
            <div class="password-tooltip" *ngIf="tooltipVisible && passwordStrength < 81" @fade>
                <div class="px-2 mt-2" style="display: flex; align-items: center;gap: 5px;" *ngIf="passwordStrength<81" >
            <i class='bx bx-info-circle mt-1 color1'></i>
            <span style="font-size: 12px;color:black" class="op-70">{{ 'password_requirments' |translate}}</span>
          </div>
              <ul *ngIf="passwordStrength < 81" class="my-3 ul-pw-str">
                <li class="op-70 li-pw-strength color3" *ngIf="!conditionsMet[0]" @fade
                  [ngClass]="{'li-pw-strength-cu color5' : conditionsMet[0]}">{{'PW_STRENGTH.uppercases' | translate}}</li>
                <li class="op-70 li-pw-strength color3" *ngIf="!conditionsMet[1]" @fade
                  [ngClass]="{'li-pw-strength-cu color5' : conditionsMet[1]}">{{'PW_STRENGTH.lowercase' | translate}}</li>
                <li class="op-70 li-pw-strength color3" *ngIf="!conditionsMet[2]" @fade
                  [ngClass]="{'li-pw-strength-cu color5' : conditionsMet[2]}">{{'PW_STRENGTH.number' | translate}}</li>
                <li class="op-70 li-pw-strength color3" *ngIf="!conditionsMet[3]" @fade
                  [ngClass]="{'li-pw-strength-cu color5' : conditionsMet[3]}">{{'PW_STRENGTH.symobols' | translate}}</li>
                <li class="op-70 li-pw-strength color3" *ngIf="!conditionsMet[4]" @fade
                  [ngClass]="{'li-pw-strength-cu color5' : conditionsMet[4]}">{{'PW_STRENGTH.8_char' | translate}}</li>
              </ul>
            </div>
          </div>
        </div>

        <div class="mb-2">
          <label class="form-label ms-2 " for="confirmPassword">{{ 'LABELS.confirm_password' | translate}} <span
              class="ms-1 red">*</span></label>
          <div style="position: relative;">
            <img class="ib-icons m-2 pt-2" src="assets/images/icons/lock.png" alt="lock" style="position: absolute;">
            <input type="password" [type]="showConfirmPassword ? 'text' : 'password'" class="form-control icon-input"
              [ngClass]="{'fc-error': submittedSignup && (signupForm.controls['confirmPassword'].invalid && signupForm.controls['confirmPassword'].touched)}"
              placeholder="{{'PLACEHOLDER.confirm_pwd' | translate}}" formControlName="confirmPassword">
          </div>
          <button class="btn" type="button" (click)="toggleConfirmPasswordVisibility()"
            [ngClass]="isFloatRight ? 'float-right' : 'float-left'" style="position: relative; top: -45px;">
            <i class="bx" [ngClass]="showConfirmPassword ? 'bx-hide' : 'bx-show'"
              style="font-size: 20px !important;color: #707fc2;"></i>
          </button>
        </div>
        <div class="mb-2">
          <label class="form-label ms-2 " for="dateOfBirth">{{ 'LABELS.date_of_birth' | translate}} <span
              class="ms-1 red">*</span></label>
          <div style="position: relative;">
            <img class="ib-icons m-2 pt-2" src="assets/images/icons/calendar-icon.png" alt="calendar-icon"
              style="position: absolute;">
            <input type="date" class="form-control text-center" formControlName="dateOfBirth"
              (change)="convertToHijri()"
              [ngClass]="{'fc-error': submittedSignup && (signupForm.controls['dateOfBirth'].invalid && signupForm.controls['dateOfBirth'].touched) }">
          </div>
        </div>

        <div class="mb-2">
          <label class="form-label ms-2 " for="dateOfBirth">{{ 'LABELS.date_of_birth_hijri' | translate}}<span
              class="ms-1 red">*</span></label>
          <div style="position: relative;">
            <img class="ib-icons m-2 pt-2" src="assets/images/icons/calendar-icon.png" alt="calendar-icon"
              style="position: absolute;z-index: 1111;">
            <flx-localized-datepicker [maxDate$]="toDateWithStart$" (selectDate)="fromDate$.next($event)"
              (sendDateHijriEvent)="handleHijriDate($event)"></flx-localized-datepicker>
          </div>
        </div>
        <div class="mt-4 mb-4" style="display: flex;">
          <input type="checkbox" id="terms-conditions" class="m-1" formControlName="isCheckedTerms">
          <label for="myCheckbox" class="label-main fw-650 fss" for="isCheckedTerms" (click)="openModalTerms()">
            {{'INFO_terms_agreements' | translate}}
          </label>
        </div>
      </form>

      <div class="text-center">
        <button type="button" class="btn btn-primary btn-lg mb-2 login-btn" (click)="createAccount()"
          [disabled]="passwordStrength < 80">
          {{'BUTTONS.create_account' | translate}}
        </button>
      </div>
    </div>


    <!-- Signup Success DIV -->
    <div id="success SignUp" *ngIf="signUpSuccess">
      <div class="p-3 py-5">
        <div class="animated-success-icon animated-success-icon__outer-wrapper">
          <div class="animated-success-icon__middle-wrapper">
            <div class="animated-success-icon__inner-wrapper">
              <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M41.4104 0.991406C43.6282 2.46549 44.2312 5.45841 42.7571 7.6763L20.3236 41.4297C19.4567 42.734 18.0103 43.536 16.4448 43.5806C14.8793 43.6251 13.3897 42.9065 12.4501 41.6536L1.13029 26.559C-0.467441 24.4284 -0.0355215 21.4061 2.09501 19.8083C4.22554 18.2106 7.2479 18.6425 8.84563 20.7731L16.0702 30.4068L34.7255 2.33818C36.1995 0.1203 39.1925 -0.482674 41.4104 0.991406Z"
                  fill="#CECED2" />
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M40.5792 0.975226C42.7027 2.41795 43.28 5.34722 41.8687 7.51793L20.3897 40.5533C19.5597 41.8299 18.1749 42.6149 16.676 42.6585C15.1771 42.7021 13.7509 41.9988 12.8513 40.7725L2.0132 25.999C0.483455 23.9137 0.896994 20.9557 2.93687 19.3919C4.97674 17.8282 7.87048 18.2509 9.40023 20.3361L16.3173 29.7649L34.1788 2.29336C35.5901 0.122649 38.4557 -0.467499 40.5792 0.975226Z"
                  fill="white" />
              </svg>
            </div>
          </div>
        </div>
        <legend class="fsxl text-center text-success mt-2"> {{'BUTTONS.sup_success ' | translate}} </legend>
      </div>
      <div class="text-center fixed-bottom p-3">
        <button type="button" class="btn btn-primary btn-lg mb-2 login-btn" (click)="backToSignIn()">
          {{'BUTTONS.back_signin' | translate}}
        </button>
      </div>
    </div>

  </div>

</div>

<!-- Dialog Terms & Agreements-->
<div id="modal-dialog">
  <p-dialog [(visible)]="showModalTerms" [breakpoints]="{'80vh': '80vw'}" [style]="styleDialog" [closable]="false"
    [modal]="true" overlay="true">
    <div style="text-align: justify;">
      <h3 class="mb-4 mt-2 flc color1 fw-500">{{'INFO.terms_conditions' | translate}}</h3>
      <span class="mb-4 mt-2 flc fss color1 fw-500">
        {{'TERMS.first_part' |translate}}
      </span>
      <li><a class="u fw-600 fss">{{'TERMS.terms_lecture' | translate}} </a></li>
      <span class="mb-4 mt-2 flc fss color1 fw-500">
        {{'TERMS.second_part' |translate}}
      </span>
      <li><a class="u fw-600 fss">{{'TERMS.definitions' | translate}} </a></li>
      <span class="mb-4 mt-2 flc fss color1 fw-500">
        {{'TERMS.third_part' |translate}}
      </span>
      <li><a class="u fw-600 fss">{{'TERMS.needs_subscription' | translate}} </a></li>
      <span class="mb-4 mt-2 flc fss color1 fw-500">
        {{'TERMS.fourth_part' |translate}}
      </span>
      <li><a class="u fw-600 fss">{{'TERMS.security_usecases' | translate}} </a></li>
      <span class="mb-4 mt-2 flc fss color1 fw-500">
        {{'TERMS.fifth_part' |translate}}
      </span>
      <li><a class="u fw-600 fss">{{'TERMS.security_confidentiality' | translate}} </a></li>
      <span class="mb-4 mt-2 flc fss color1 fw-500">
        {{'TERMS.sixth_part' |translate}}
      </span>
      <li><a class="u fw-600 fss">{{'TERMS.disclaimer_compensation' | translate}} </a></li>
      <span class="mb-4 mt-2 flc fss color1 fw-500">
        {{'TERMS.seventh_part' |translate}}
      </span>
      <li><a class="u fw-600 fss">{{'TERMS.terms_changes' | translate}} </a></li>
      <span class="mb-4 mt-2 flc fss color1 fw-500">
        {{'TERMS.eighth_part' |translate}}
      </span>

      <legend class="fss fw-600 black"> {{'INFO.check_payment_society'| translate}}</legend>
      <a class="u fw-600 fss flc"> {{'LINK.rules_protection' | translate}}</a>

      <li><a class="u fw-600 fss">{{'TERMS.followed_law' | translate}} </a></li>
      <span class="mb-4 mt-2 flc fss color1 fw-500">
        {{'TERMS.nineth_part' |translate}}
      </span>

      <li><a class="u fw-600 fss">{{'TERMS.agent_acceptance' | translate}} </a></li>
      <span class="mb-4 mt-2 flc fss color1 fw-500">
        {{'TERMS.tenth_part' |translate}}
      </span>

    </div>
    <div class="row">
      <div class="flc p-3">
        <button (click)="confirmTerms()" class="btn-pill btn btn-sm btn-outline-primary cf-hover-btn color1"><i
            class='bx bx-check-square'></i> {{'BUTTONS.confirm_term' | translate}} </button>
      </div>
    </div>
  </p-dialog>
</div>
