export class ClaimNoteEntity {

    /** The id. */
    id : number ;

    /** The visibility. */
    visibility :string;

    /** The claim id. */
    claimId : number;

    /** The comment. */
    comment : string;

     /** The insertBy. */
    insertBy : string;

    /** The date insertion. */
    dateInsertion :Date;

     constructor() {
               }
}
