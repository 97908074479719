import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { dateToDateStruct } from '../utils/date-to-date-stuct';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { dateStructToDate } from '../utils/date-struct-to-date';


interface LangChangeEvent {
    lang: string;
  }

// ! Remove mock
const translate = {
  onLangChange: new Subject(),
  currentLang: 'ar'
}

@Component({
  selector: 'flx-localized-datepicker',
  templateUrl: './localized-datepicker.component.html',
})
export class LocalizedDatepickerComponent implements OnInit {
  constructor() {}
  @Input() maxDate$ = of(new Date(3000, 1, 1));
  @Input() minDate$ = of(new Date(1000, 1, 1));
  @Output() selectDate = new Subject<Date>();
  @Output() sendDateHijriEvent: EventEmitter<any> = new EventEmitter<any>();

  lang$: Observable<string>;

  selectDateBound = (date: Date) => this.selectDate.next(date);

  ngOnInit() {
  }

  handleDateEvent(event){
    this.sendDateHijriEvent.emit(event);
  }

}
