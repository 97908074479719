import { Component, OnInit } from '@angular/core';
import { Browser } from '@capacitor/browser';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from 'src/app/ib-services/shared.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsComponent implements OnInit {
  constructor(public sharedService : SharedService,private translate : TranslateService) {}

  ngOnInit() {}

  redirectToLinks(context : string) {
    Browser.open({ url: context });
  }

  copyToClipboard(context : string,label : string) {
    if (context) {
      navigator.clipboard.writeText(context).then(() => {
        console.log('Phone number copied to clipboard!');
        if (label === 'phone_number'){
          this.sharedService.showInfo(
            this.translate.instant('INFO.phone_number_copied')
          );
        }else if(label === 'email'){
          this.sharedService.showInfo(
            this.translate.instant('INFO.email_copied')
          );
        }

      }).catch(err => {
        console.error('Failed to copy: ', err);
      });
    }
  }

}
