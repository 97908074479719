import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NativeBiometric } from '@capgo/capacitor-native-biometric';
import { NgbCarousel, NgbSlideEvent } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { AppComponent } from 'src/app/app.component';
import { SharedService } from 'src/app/ib-services/shared.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-advertisement',
  templateUrl: './advertisement.component.html',
  styleUrls: ['./advertisement.component.scss']
})
export class AdvertisementComponent implements OnInit,OnDestroy {

  @Output() closeAdvertisement = new EventEmitter<void>();
  @Output() hideSplash = new EventEmitter<void>();


  public adv1 : boolean = true;
  public adv2 : boolean = false;
  public adv3 : boolean = false;

  public lang : string;

  advImages = [
    { src: 'assets/images/adv1.png', alt: 'adv1' },
    { src: 'assets/images/adv2.png', alt: 'adv2' },
    { src: 'assets/images/adv3.png', alt: 'adv3' }
  ];

  activeIndex = 0;

  @ViewChild('carousel') carousel: NgbCarousel | undefined;

  constructor( private translate : TranslateService, public sharedService : SharedService, private router : Router ) {
    this.lang = localStorage.getItem('lang').toUpperCase();
  }

  ngOnInit() {
    let disableBiometric = this.disableBiometric();
    this.sharedService.setUserHadBiometricCredentials(false);
    if (disableBiometric) {console.log('Biometric Disabled in Advertisement');}else {console.log('Not Found Credentials')}
  }

  langSelection(){
    if (this.lang === 'EN' ){
      this.translate.use('ar');
      localStorage.setItem('lang', 'ar');
      AppComponent.direction = 'rtl';
      this.lang = 'AR';
    } else {
      this.translate.use('en');
      localStorage.setItem('lang', 'en');
      AppComponent.direction = 'ltr';
      this.lang = 'EN';
    }
  }

  carouselSlide(event: NgbSlideEvent) {
    // Handle the carousel slide event here
    if (event.current == 'ngb-slide-0') this.showAdv2(false);
    if (event.current == 'ngb-slide-1') this.showAdv3(false);
    if (event.current == 'ngb-slide-2') this.closeAd();
  }

  /** Next Action Swiper  */
  next() {
    if (this.carousel) {
      this.carousel.next();
    }
  }

  /** Previous Action Swiper  */
  prev() {
    if (this.carousel) {
      this.carousel.prev();
    }
  }

  setIndex(index: number) {
    this.activeIndex = index;
  }

  showAdv2(action? : boolean){
    this.adv1 = false;
    this.adv2 = true;
    this.adv3 = false;
    if (action)this.next();
  }

  showAdv3(action? : boolean){
    this.adv1 = false;
    this.adv2 = false;
    this.adv3 = true;
    if (action)this.next();
  }

  ngOnDestroy(): void {
  }

  async disableBiometric() {
    // Method To Disable Biometrics
    try {
      await NativeBiometric.deleteCredentials({
        server: environment.oauthUrl,
      });
      console.log('Biometric authentication disabled successfully.');
      return true;
    } catch (error) {
      console.error('Failed to disable biometric authentication:', error);
      return false;
    }
  }

  closeAd() {
    setTimeout(() => {
      this.hideSplash.emit();
    }, 3000);
  }



}
