import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, filter, interval, switchMap, take, takeWhile } from 'rxjs';
import { CreditService } from 'src/app/ib-services/credit.service';
import { SharedService } from 'src/app/ib-services/shared.service';
import { ThirdPartyService } from 'src/app/ib-services/third-party.service';
import { PaymentApiSanadEntity } from 'src/app/shared/Entities/PaymentApiSanad.entity';
import { ThirdPartyHistorique } from 'src/app/shared/Entities/ThirdPartyHistorique.entity';
import { TransactionRequest } from 'src/app/shared/Entities/TransactionRequest.entity';
import { CustomerEntity } from 'src/app/shared/Entities/customer.entity';
import { LoanEntityIB } from 'src/app/shared/Entities/loan.entity';
import { PathConstants } from 'src/app/shared/mb-constants/path-constants';

@Component({
  selector: 'app-new-transaction',
  templateUrl: './new-transaction.component.html',
  styleUrls: ['./new-transaction.component.scss'],
})
export class NewTransactionComponent{
  public loan: LoanEntityIB = new LoanEntityIB();
  public currentStep: number = 0;
  public isOptionSelected: boolean = false;
  public amount: number;
  public htmlContent: string;
  public urlWithQuery: string;
  public showPaymentFrame: boolean = false;
  public iframeUrl: SafeResourceUrl;
  public customer: CustomerEntity;
  public securityLink : string;
  public paymentId : string;
  public selectedCardType : string;
  public srcSelectedCard : string;

  /** New Approach Transaction Values */
  public transactionForm: FormGroup;
  public transactionSuccess : boolean = false;
  public transactionPending : boolean = false;
  public transactionFailed : boolean = false;

  public showRedirectLink : boolean = false;

  public showCardTypesList : boolean = true;

  /** MASKS */
  public yearPattern = /^20\d{2}$/;
  public monthPattern = /^[0-3][0-9]$/;
  public cvvPattern = /^\d{3}$/;

  /** Loader  */
  private intervalSubscription: Subscription;

  constructor(
    private router: Router,
    public translate: TranslateService,
    public creditService: CreditService,
    public sharedService: SharedService,
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private thirdPartyService : ThirdPartyService
  ) {}

  ngOnInit(): void {
    this.loan = this.sharedService.getLoanDeal();
    this.customer = this.sharedService.getCustomer();
    this.createTransactionForm();
  }

  validatePayment() {
    this.showPaymentFrame = true;
    const paymentDto: PaymentApiSanadEntity = new PaymentApiSanadEntity();
    paymentDto.amount = this.loan.normalPayment.toString() + '.00';
    paymentDto.zipCode = '13321';
    paymentDto.country = 'Saudi Arabia';
    paymentDto.city = 'Riyadh';
    paymentDto.state = 'riaydh';
    paymentDto.address = '8118 AlArid';
    paymentDto.customerEmail = this.customer.email;
    paymentDto.contactNumber = this.customer.telephone1;

    this.thirdPartyService
      .findTargetUrlAndPaymentId(paymentDto)
      .toPromise()
      .then((data) => {
        if (data.responseCode === '001') {
          // Data to send as query parameters
          const dataToSend = {
            paymentId: data.payid,
            idLoan: this.sharedService.getLoanDeal().idIbLoan.toString(),
            amount: this.sharedService.getLoanDeal().normalPayment.toString(),
          };

          // Serialize the data object to a query string
          this.urlWithQuery = data.targetUrl + data.payid;
          console.log('URL QUERY : ', this.urlWithQuery);
          this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
            this.urlWithQuery
          );
          // this.checkResponsePayment(data.payid);
          let iframeContainer = document.getElementById('iframeContainer');
          iframeContainer.style.zIndex = '10';
          let iframe = document.createElement('iframe');
          iframe.style.left = '0';
          iframe.style.width = '100%';
          iframe.style.height = '90%';
          iframe.style.border = 'none';
          iframe.style.borderRadius = '10px';
          let container = document.getElementById('iframeContainer');
          container.innerHTML = '';
          container.appendChild(iframe);
        } else {
          this.sharedService.showError(this.translate.instant('Payment With GCCPAY Unsuccessfull '));
          this.router.navigate(['/' + PathConstants.PATH_HOME]);
        }
      });
  }

  /*** New Approach For Transaction  */

  onOptionSelected(selectedCard : string) {
    this.selectedCardType = selectedCard;
    this.isOptionSelected = true;
  }

  confirmCardSelection(){
    switch (this.selectedCardType){
      case 'Apple Pay':
        this.srcSelectedCard = 'assets/images/paiementTypes/applePay.PNG';
        break;
      case 'Visa Card':
        this.srcSelectedCard = 'assets/images/paiementTypes/visa.png';
        break;
      case 'Master Card' :
        this.srcSelectedCard = 'assets/images/paiementTypes/masterCard.png';
        break;
      case 'Mada Card':
        this.srcSelectedCard = 'assets/images/paiementTypes/madaPay.PNG';
        break;
      case 'STC Card':
        this.srcSelectedCard = 'assets/images/paiementTypes/stcPay.PNG'
        break;
      case 'Qitaf Card' :
        this.srcSelectedCard = 'assets/images/paiementTypes/qitafPay.PNG';
        break;
      case 'American Express Card':
        this.srcSelectedCard = 'assets/images/paiementTypes/americanExpressPay.PNG';
        break;
    }
    this.showCardTypesList = false;
  }

  createTransactionForm() {
    this.transactionForm = this.formBuilder.group({
      cardOwner: ['', Validators.required],
      cardNumber: ['', Validators.required],
      expirationYear: ['', [Validators.required, Validators.pattern(this.yearPattern)]],
      expirationMonth: ['', [Validators.required, Validators.pattern(this.monthPattern)]],
      cvvCode: ['', [Validators.required, Validators.pattern(this.cvvPattern)]],
    });

  }

  sendTransaction() {
    if (this.transactionForm.controls['cardNumber'].value == '' || this.transactionForm.controls['cardOwner'].value == '' || this.transactionForm.controls['expirationMonth'].value == ''
          || this.transactionForm.controls['expirationYear'].value == '' || this.transactionForm.controls['cvvCode'].value == ''){
            this.sharedService.showError(this.translate.instant('ERROR.Enter you credit card credentials '));
    }else if(!this.transactionForm.valid){
      if (!this.transactionForm.controls['cardNumber'].valid){
        /** Mask Checker To Be Done in Card Number */
      }else  if (!this.transactionForm.controls['expirationMonth'].valid){
        this.sharedService.showError(this.translate.instant('ERROR.expiration_month_mask_error'));
      }else if (!this.transactionForm.controls['expirationYear'].valid){
        this.sharedService.showError(this.translate.instant('ERROR.expiration_year_mask_error'));
      }else if (!this.transactionForm.controls['cvvPattern'].valid){
        this.sharedService.showError(this.translate.instant('ERROR.cvv_mask_error'));
      }
    } else {
      let newTransaction = new TransactionRequest();
      newTransaction.country = 'Saudi Arabia';
      newTransaction.city = 'Riyadh';
      newTransaction.merchantIp = '10.10.10.110';
      newTransaction.state = 'riaydh';
      newTransaction.zipCode = '13321';
      newTransaction.instrumentType = 'CCI';
      newTransaction.action = '1';
      newTransaction.customerIp = '10.10.10.10';

      newTransaction.udf1 = this.loan.idIbLoan.toString();
      newTransaction.udf2 = this.customer.ibCustomerId.toString();
      newTransaction.udf3 = this.customer.ibCustomerId.toString();

      /** From Form */
      newTransaction.cardNumber = this.transactionForm.controls['cardNumber'].value;
      newTransaction.cardHolderName = this.transactionForm.controls['cardOwner'].value;
      newTransaction.expMonth = this.transactionForm.controls['expirationMonth'].value;
      newTransaction.expYear = this.transactionForm.controls['expirationYear'].value;
      newTransaction.cvv2 = this.transactionForm.controls['cvvCode'].value;


      /** From Customer */
      newTransaction.amount = this.loan.normalPayment.toString() + '.00';
      newTransaction.cutomerEmail = this.customer.email;
      newTransaction.address = '';

      this.thirdPartyService.sendTransaction(newTransaction,this.loan.idIbLoan).subscribe(res => {
        if (res.responseCode === '000'){
          this.paymentId = res.payid;
          this.sharedService.showSuccess(this.translate.instant('TOAST.payment_successfull'));
          this.transactionSuccess = true;
        }else if (res.responseCode === '001'){
          this.paymentId = res.payid;
          this.securityLink = res.targetUrl + res.payid;
          this.transactionPending = true;
          this.sharedService.showInfo(this.translate.instant('TOAST.pending_payment'));
        }else {
          this.paymentId = res.payid;
          this.sharedService.showError(this.translate.instant('ERROR.Transaction Failed'));
          this.transactionFailed = true;
        }
      })
    }

  }

  maskCreditCard(cardNumber: string): string {
    let visibleDigits = 4;
    const maskedDigits = cardNumber.length - visibleDigits;

    // Mask all digits except the last 'visibleDigits' digits
    const maskedPart = '*'.repeat(maskedDigits);
    const visiblePart = cardNumber.slice(maskedDigits);

    // Combine masked and visible parts
    const maskedCardNumber = `${maskedPart}${visiblePart}`;

    return maskedCardNumber;
  }

  triggerClickLink(){
    this.showRedirectLink = true;
    this.webhookHandler();
  }

  paymentResponseChecker(){
    let transactionReponseHistorique = new ThirdPartyHistorique();
    transactionReponseHistorique.category = 'PaymentApi/webhook';
    transactionReponseHistorique.sanadAmerId = this.paymentId;
    this.creditService.getThirdParties(transactionReponseHistorique).subscribe((resultPaymentWebhook) => {});
  }

  webhookHandler(){
    let transactionReponseHistorique = new ThirdPartyHistorique();
    transactionReponseHistorique.category = 'PaymentApi/webhook';
    transactionReponseHistorique.sanadAmerId = this.paymentId;
    transactionReponseHistorique.idLoan = this.loan.idIbLoan;
    transactionReponseHistorique.idCustomer = this.customer.ibCustomerId;
    this.intervalSubscription = interval(5000).pipe(switchMap(() => this.creditService.getThirdParties(transactionReponseHistorique)),
      filter(resultPaymentWebhook => resultPaymentWebhook.length !== 0),
      take(1)
    )
    .subscribe(resultPaymentWebhook => {
      let result = this.fetchStatusFromStringJSON(resultPaymentWebhook[0].responseValue);
      if (result == "Successful"){
        this.sharedService.showSuccess('Payment Sent Successfully ');
        this.transactionPending = false;
        this.transactionSuccess = true;
      } else if (result == 'UnSuccessful'){
        this.sharedService.showError(this.translate.instant('ERROR.Transaction Failed'));
        this.transactionPending = false;
        this.transactionFailed = true;
      }

    });
  }

  ngOnDestroy(): void {
    // Unsubscribe to prevent memory leaks when the component is destroyed
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }
  }

  fetchStatusFromStringJSON(jsonString: string) {
    let match = jsonString.match(/result=(\w+)/);
    if (match) {
      const result = match[1];
      console.log('result:', result);
      return result;
    } else {
      console.log('result not found in the string.');
      return null;
    }
  }

}
