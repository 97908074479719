import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthentificationService } from 'src/app/ib-services/authentification.service';
import { SharedService } from 'src/app/ib-services/shared.service';
import { UserEntity } from 'src/app/shared/Entities/user.entity';
import { AcmConstants } from 'src/app/shared/mb-constants/mb-constants';
import { NativeBiometric } from '@capgo/capacitor-native-biometric';
import { environment } from 'src/environments/environment';
import { PathConstants } from 'src/app/shared/mb-constants/path-constants';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent {
  public currentPwd : string;
  public newpwd : string;
  public confirmpwd : string;
  public user : UserEntity;
  public errorPwd : Boolean = false;
  public errorMsgPwd : string = '';
  public submitted : boolean = false;
  public showPassword: boolean = false;
  public showNewPassword : boolean = false;
  public showConfirmPassword : boolean = false;
  public isFloatRight: Boolean = true;

  public showModalConfirmation: boolean = false;

    /** Dialog Style */
    public styleDialog = {
      width: '50vw',
      background: '#ebedf8',
      padding: '10px 10px 10px 10px',
      borderRadius: '20px',
      color: 'white',
      filter: 'drop-shadow(2px 4px 6px black)',
    };

  constructor(private router: Router,private sharedService :SharedService,private authService : AuthentificationService,private translate : TranslateService) { }

   ngOnInit() {
    this.user = this.sharedService.getUser();
    if (this.user === undefined) this.router.navigate(['/' + PathConstants.PATH_LOGIN]);
    this.isFloatRight =
    localStorage.getItem('lang') === null
      ? false
      : localStorage.getItem('lang') !== 'ar';
   }

   saveChanges(stayLogged : boolean){
      this.user.pwd = this.currentPwd;
      this.user.pwdNew = this.newpwd;
      this.user.pwdConfirm = this.confirmpwd;
      this.authService.changePwd(this.user).subscribe(res => {
        this.sharedService.showSuccess(this.translate.instant('INFO.change_password_success'));
        this.submitted = false;
        this.disableBiometric();
        if (!stayLogged) {
          this.authService.logout();
        }else {
          this.router.navigate(['/home']);
        }
      },
      ((err) => {
        if (err.error.errorCode === AcmConstants.PASSWORD_HISTORY)
        {
          this.sharedService.showError(this.translate.instant('ERR.pwd_history')) ;
        }
        else if (err.error.errorCode === AcmConstants.PASSWORD_INVALID)
        {
          this.sharedService.showError(this.translate.instant('ERR.pwd_incorrect')) ;
        }
        else if (err.error.errorCode === AcmConstants.PASSWORD_DONT_MATCH)
         {
          this.sharedService.showError(this.translate.instant('ERR.pwd_not_identical')) ;
        }
        else{
          this.sharedService.showError(this.translate.instant('ERR.internal_error')) ;
        }

        this.showModalConfirmation = false;

      }))

   }

  /** Disable Biometrics by deleting credentials */
  async disableBiometric() {
    try {
      await NativeBiometric.deleteCredentials({
        server: environment.oauthUrl,
      });
      console.log('Biometric authentication disabled successfully.');
      this.sharedService.showSuccess(this.translate.instant('BIOMETRIC.biometric_disabled'));
      return true;
    } catch (error) {
      console.error(this.translate.instant('BIOMETRIC.ERROR.biometric_disabled_failed'), error);
      return false;
    }
  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }

  toggleNewPasswordVisibility() {
    this.showNewPassword = !this.showNewPassword;
  }

  toggleConfirmPasswordVisibility() {
    this.showConfirmPassword = !this.showConfirmPassword;
  }

  openModal() {
    this.submitted = true;
    if ((this.newpwd == '' || this.newpwd == null) || (this.confirmpwd == '' || this.confirmpwd == null)){
      this.sharedService.showError(this.translate.instant('ERR.enter_credentials'));
    }else {
      if (this.newpwd === this.confirmpwd){
        this.showModalConfirmation = true;
      }else {
        this.sharedService.showError(this.translate.instant('ERR.pwd_not_match')) ;
      }

    }
  }

  close(){
    this.showModalConfirmation = false;
  }

}
