import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoanService } from 'src/app/ib-services/loan.service';
import { SharedService } from 'src/app/ib-services/shared.service';
import { CustomerEntity } from 'src/app/shared/Entities/customer.entity';
import { LoanEntityIB } from 'src/app/shared/Entities/loan.entity';
import { UserEntity } from 'src/app/shared/Entities/user.entity';
import { PathConstants } from 'src/app/shared/mb-constants/path-constants';

@Component({
  selector: 'app-loan-applications',
  templateUrl: './loan-applications.component.html',
  styleUrls: ['./loan-applications.component.scss'],
})
export class LoanApplicationsComponent implements OnInit {

  public showLoansInProcess: boolean = false;
  public showMyLoanStatuses: boolean = false;
  public showAppliesMenu: boolean = true;
  public user: UserEntity;
  public loansList: LoanEntityIB[] = [];
  public loansInProcess: LoanEntityIB[] = [];
  public value: number = 60;
  public lang: string;
  private modeDisplay : string;

  constructor( public sharedService: SharedService, private router: Router, private loanService: LoanService,private route : ActivatedRoute) {}

  async ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.modeDisplay = params['modeDisplay'];
    });
    this.loansList = this.sharedService.getListLoans();
    this.lang = localStorage.getItem('lang');
    this.user = this.sharedService.getUser();
    this.fetchListLoansAndDispatch();
    if (this.modeDisplay === 'showLoansInProcess'){
      this.showAppliesMenu = false;
      this.showMyLoanStatuses = false;
      this.showLoansInProcess = true;
    }
  }

  listLoanAppliesStatuses() {
    this.showAppliesMenu = false;
    this.showMyLoanStatuses = true;
    this.showLoansInProcess = false;
  }

  listLoansInProcess() {
    this.showAppliesMenu = false;
    this.showMyLoanStatuses = false;
    this.showLoansInProcess = true;
  }

  fetchListLoansAndDispatch() {
    this.loansInProcess = [];
    let customer = new CustomerEntity();
    customer.ibCustomerId = this.user.customerId;
    let loan = new LoanEntityIB();
    loan.customerDTO = customer;
    if (!this.loansList){
      this.loanService.getLoanByCustomerNID(loan).subscribe((loans) => {
        this.loansList = [...loans];
        this.loansList.forEach((loan) => {
          if (loan.statut == '8') {
            this.loansInProcess.push(loan);
          }
        });
        this.calculatePercentages();
      });
    }else {
      this.loansList.forEach((loan) => {
        if (loan.statut == '8') {
          this.loansInProcess.push(loan);
        }
      });
      this.calculatePercentages();
    }

  }

  showLoanSchedule(index: number) {
    this.sharedService.setLoanDeal(this.loansInProcess[index]);
    this.router.navigate(['/' + PathConstants.PATH_LOAN_SCHEDULE_DETAILS]);
  }

  async calculatePercentages() {
    let nextRepaymentDate: string;
    this.loansInProcess.forEach((loan) => {
      /*** Dynamic Percentage */
      this.loanService.getSchedulesByLoanId(loan.idIbLoan).subscribe((res) => {
        if (res.length != 0) {
          let schedules = [...res];
          let sc = schedules.find(result => result.statusLabel == 'Unpaid');
          if (sc) loan.totalRepayment = sc.totalRepayment;
          let termPaid = 0;
          for (let i = 0; i < schedules.length - 1; i++) {
            if (schedules[i].statusLabel === 'Fully Paid') {
              nextRepaymentDate = schedules[i + 1].repaymentDate;
              termPaid++;
            }
          }
          /** Fill Payment Percentage */
          let percentage = termPaid / loan.termPeriodNum;
          if (termPaid === loan.termPeriodNum - 1) percentage = 100;
          loan.percentagePayment = percentage;
        }
      });
    });
  }

  getPercentage(paymentStatus, index): number {
    return Math.round(paymentStatus * 100);
  }

  syncSchedule() {
    let user = this.sharedService.getUser();
    let customerId = user.customerId.toString();
    this.loanService.getLoansSchedules(customerId).subscribe((res) => {});
  }

}
