export class UserEvaluation {

  id : number;
  evalStatus : string;
  customerIdentity : string;
  idCustomer : number;
  idloan : number;
  creactionDate : Date;
  description : string;
}
