import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { CustomerEntity } from '../shared/Entities/customer.entity';
import { AcmURLConstants } from '../shared/mb-constants/mb-url-constants';
import { SettingDataStorageIbEntity } from '../shared/Entities/SettingDataStorageIb.entity';
import { AcmEnvironnementEntity } from '../shared/Entities/acmEnvironnement.entity';
import { ProductEntity } from '../shared/Entities/product.entity copy';


@Injectable({
  providedIn: 'root'
})
export class ParametrageService {

  private dataSubject = new BehaviorSubject<any>(null);
  public data$ = this.dataSubject.asObservable();

  constructor(public httpClient: HttpClient) {
  }

  /**From Environment Table */
  getCustomer(keys: string[]):Observable<CustomerEntity[]> {
    return this.httpClient.post<CustomerEntity[]>(AcmURLConstants.SETTING_ENVIRONMENT_FIND, keys);
  }

  findAllDataStorageIb(): Observable<SettingDataStorageIbEntity[]> {
    return this.httpClient.get<SettingDataStorageIbEntity[]>(AcmURLConstants.ALL_DATA_STORAGE);
  }

  findDataStorageByCategory(settingDataStorageIbEntity : SettingDataStorageIbEntity) : Observable<SettingDataStorageIbEntity[]>{
    return this.httpClient.post<SettingDataStorageIbEntity[]>(AcmURLConstants.DATA_STORAGE_BY_CATEGORY, settingDataStorageIbEntity);
  }

  /**
   * getEnvirementValueByKey
   * @param key String
   */
  getEnvirementValueByKey(key: string): Observable<AcmEnvironnementEntity> {
    return this.httpClient.get<AcmEnvironnementEntity>(AcmURLConstants.SETTING_ENVIRONMENT_FIND_BY_KEY + key);
  }

  /** Get Banks Details from SettingListValues */
  getBanks() : Observable<any[]>{
    return this.httpClient.get<any[]>(AcmURLConstants.BANKS_FIND);
  }

  sendData(data: any) {
    this.dataSubject.next(data);
    console.log('PARAM SERVICE')
  }


  /** MOVED METHODS HERE */
  getProducts(product: ProductEntity): Observable<ProductEntity[]> {
    return this.httpClient.post<ProductEntity[]>(AcmURLConstants.PRODUCT_FIND, product);
  }

}
