export class HPRequestCheckoutDTO{
	entityId : string;
	/** The amount. */
	amount: string;
	/** The currency. */
	currency: string;
	/** The payment type. */
	paymentType: string;
	/** The integrity. */
	integrity: string;
	/** The customer. */
	customer : Customer;
	/** The billing. */
	billing : Billing;
	/** The test mode. */
	testMode : string;
}


export class Customer{
		/** The email. */
		email : string;
		/** The given name. */
		givenName: string;
		/** The surname. */
		surname: string;
}

export class Billing{
		/** The street 1. */
		street1: string;
		/** The city. */
		city: string;
		/** The state. */
		state: string;
		/** The country. */
		country: string;
		/** The postcode. */
		postcode: string;
}
