import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, Output, ViewEncapsulation } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { AuthentificationService } from 'src/app/ib-services/authentification.service';
import { SharedService } from 'src/app/ib-services/shared.service';
import { AddressEntity } from 'src/app/shared/Entities/Address.entity';
import { RequestSendSmsDeewanApiEntity } from 'src/app/shared/Entities/RequestSendSmsDeewanApi.entity';
import { nationalityOptions } from 'src/app/shared/Entities/constants';
import { CustomerEntity } from 'src/app/shared/Entities/customer.entity';
import { UserEntity } from 'src/app/shared/Entities/user.entity';
import { toGregorian, toHijri } from 'hijri-converter';
import { TranslateService } from '@ngx-translate/core';
import { NativeBiometric } from '@capgo/capacitor-native-biometric';
import { environment } from 'src/environments/environment';
import { Device } from '@capacitor/device';
import { Observable, Subject, combineLatest, of, startWith } from 'rxjs';
import { formatDate } from '@angular/common';
import { ParametrageService } from 'src/app/ib-services/parametrage.service';
import { PathConstants } from 'src/app/shared/mb-constants/path-constants';
import { CredentialsDTO } from 'src/app/shared/Entities/credentials.entity';
import { EventFocalRequestDTO } from 'src/app/shared/Entities/EventFocalRequest.entity';
import { CustomerService } from 'src/app/ib-services/customer.service';
import { UserService } from 'src/app/ib-services/user.service';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  animations: [
    trigger('fade', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('800ms ease-in', style({ opacity: 1 })),
      ]),
      transition(':leave', [animate('800ms ease-out', style({ opacity: 0 }))]),
    ]),
  ],
})
export class LoginComponent implements OnInit {
  public activeTab: string = '';
  public nationalityList = nationalityOptions;
  public loginForm: FormGroup;
  public signupForm: FormGroup;
  public newUsername: string;
  public customerExist: Boolean = false;
  public showPassword: boolean = false;
  public showConfirmPassword: boolean = false;
  public isFloatRight: Boolean = true;
  public lang: string;
  public showModalTerms: boolean = false;

  // Initialize flags for password requirements
  public hasUppercase = false;
  public hasLowercase = false;
  public hasNumeric = false;
  public hasSpecialCharacter = false;
  public isMinLength = false;

  // login form
  public nationality: string;
  public mobileNumber: number;
  public dateOfBirth: Date;
  public nationalId: string = '';
  public email: string = '';
  public password: string;
  public confirmPassword: string;
  public isCheckedTerms: Boolean = false;

  // errors
  public nationalIdError = false;
  public emailError = false;
  public phoneNumberError = false;
  public phoneIdentityError = false;

  public signUpSuccess = false;

  //HIJRI Variable Declarations
  today = new Date();
  today$ = of(this.today);
  fromDate$ = new Subject<Date>();
  toDate$ = new Subject<Date>();
  toDateWithStart$ = this.toDate$.pipe(startWith(this.today));
  dateRange$: Observable<Date[]> = combineLatest([
    this.fromDate$,
    this.toDate$,
  ]);
  @Output() selectDateRange = this.dateRange$;

  public conditionsMet: boolean[] = [];

  /** Biometrics Values */
  public credentials: any;
  public isFaceID: any;
  public verified: any;
  public result: any;
  public showBiometricSynchronisation: Boolean = false;
  public errorBiometricAuth: Boolean = false;
  public showLoginInsteadOfBiometrics: boolean = false;

  public year: any;
  public month: any;
  public day: any;

  public deviceId: any;
  public user: UserEntity = new UserEntity();
  public requestSendSms: RequestSendSmsDeewanApiEntity =
    new RequestSendSmsDeewanApiEntity();
  public nationalIdPattern = /^(1|2)+\d{9}$/;
  public mobileNumberPattern = /^5[013456789]\d{7}$/;
  public emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  public showLogin: boolean = false;
  public showSignUp: boolean = false;
  public passwordStrength: number = 0;
  public passwordStrengthText: string = '';
  public biometricErrorMessage: string = '';
  public biometricsStatus: Boolean = false;
  public isActiveFp: boolean = false;

  public submittedLogin: boolean = false;
  public passwordError: boolean = false;

  public submittedSignup: boolean = false;
  public tooltipVisible = false;
  public hijriDate: any;
  public viewportWidth : number;
  public viewportheight : number;

  /** Dialog Style */
  public styleDialog = {
    width: '50vw',
    background: '#ebedf8',
    padding: '10px 10px 10px 10px',
    borderRadius: '20px',
    color: 'white',
    filter: 'drop-shadow(2px 4px 6px black)',
  };

  constructor(
    public sharedService: SharedService,
    private authService: AuthentificationService,
    private formBuilder: FormBuilder,
    private router: Router,
    private translate: TranslateService,
    private paramService: ParametrageService,
    private userService: UserService,
    private customerService: CustomerService
  ) {}

  showTooltip() {
    this.tooltipVisible = true;
  }

  hideTooltip() {
    this.tooltipVisible = false;
  }

  ngOnInit() {
    this.testHeightWidth()
    this.actLogin();
    this.getDeviceId();
    this.lang = localStorage.getItem('lang');

    /** Check Language Set */
    this.lang = localStorage.getItem('lang');
    if (!this.lang) {
      localStorage.setItem('lang', 'ar');
    }
    if (
      !this.sharedService.getLang() ||
      this.sharedService.getLang() === undefined
    ) {
      this.sharedService.setLang('ar');
    }

    this.isFloatRight =
      localStorage.getItem('lang') === null
        ? false
        : localStorage.getItem('lang') !== 'ar';
    this.nationality = this.nationalityList[0];
    this.createLoginForm();
    this.createSignupForm();

    /** Biometrics Check If Credentials is Stored */
    this.hasStoredCredentials().then((hasCredentials) => {
      if (hasCredentials) {
        console.log('Stored credentials found.');
        this.biometricsStatus = true;
        this.isActiveFp = true;
      } else {
        console.log('No stored credentials found.');
        this.biometricsStatus = false;
        this.isActiveFp = false;
      }
    });
  }

  /** FormGroup Creation Methods */
  createLoginForm() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  testHeightWidth(){
    this.viewportWidth = window.innerWidth;
    this.viewportheight = window.innerHeight;
  }

  createSignupForm() {
    this.signupForm = this.formBuilder.group(
      {
        first_name: ['', Validators.required],
        second_name: [''],
        middle_name: [''],
        last_name: ['', Validators.required],
        nationalId: [
          '',
          [Validators.required, Validators.pattern(this.nationalIdPattern)],
        ],
        mobileNumber: [
          '',
          [Validators.required, Validators.pattern(this.mobileNumberPattern)],
        ],
        dateOfBirth: ['', Validators.required],
        dateOfBirthHijri: [{ value: '', disabled: true }, Validators.required],
        email: [
          '',
          [
            Validators.required,
            Validators.email,
            Validators.pattern(this.emailPattern),
          ],
        ],
        password: [
          '',
          [
            Validators.required,
            Validators.minLength(8),
            this.passwordStrengthValidator,
          ],
        ],
        confirmPassword: ['', Validators.required],
        isCheckedTerms: [
          { value: false, disabled: true },
          Validators.requiredTrue,
        ],
      },
      { validators: this.passwordsMatchValidator }
    );
    this.signupForm.get('password')?.valueChanges.subscribe(() => {
      this.calculatePasswordStrength();
    });
  }

  /** Authentication Methods To Signup & Login (With & Without Biometrics) */
  login() {
    this.submittedLogin = true;
    const lang = localStorage.getItem('lang');
    this.sharedService.setLang(lang ? lang : 'ar');
    localStorage.clear();

    if (this.loginForm.valid) {
      let credentials = new CredentialsDTO();
      credentials.password = this.loginForm.value.password;
      credentials.username = this.loginForm.value.username;
      let eventFocalRequest = new EventFocalRequestDTO();
      eventFocalRequest.device = this.sharedService.getDeviceInfo();
      credentials.eventFocalRequest = eventFocalRequest;
      if (this.sharedService.getIpAddress()){
        credentials.eventFocalRequest.ip = this.sharedService
        .getIpAddress()
        .toString();
      }else {
        credentials.eventFocalRequest.ip = null;
      }
      credentials.eventFocalRequest.location = this.sharedService.getLocation();
      this.authService
        .newLoginMethod(credentials)
        .toPromise()
        .then((dataReceived) => {
          if (dataReceived.error) {
            this.authService.setIsLoggedIn(false);
            this.sharedService.showError(
              this.translate.instant('ERR.wrong_credentials_login')
            );
            this.passwordError = true;
            this.customerExist = true;
          } else {
            localStorage.setItem('currentUser', dataReceived.access_token);
            localStorage.setItem('expiredIn', dataReceived.expires_in);
            localStorage.setItem('refreshToken', dataReceived.refresh_token);
            const expireDate = new Date(
              new Date().getTime() + 1000 * dataReceived.expires_in
            );
            localStorage.setItem('dateExpiration', expireDate.toString());
            localStorage.setItem('type', 'user');
            localStorage.setItem('pw', this.loginForm.value.password);
            localStorage.setItem('lang', this.sharedService.getLang());
            this.user.deviceId = this.deviceId;
            this.user.login = this.loginForm.value.username;
            this.userService.checkDevice(this.user).subscribe(
              (data) => {
                if (data !== null) {
                  this.sharedService.setPhoneNumberCustomer(data);
                }

                this.sharedService.setNationalId(this.loginForm.value.username);
                this.sharedService.setPassword(this.loginForm.value.password);
                this.router.navigate(['/' + PathConstants.PATH_OTP_BIOMETRICS]);
                this.authService.setIsLoggedIn(true);
              },
              (error) => {
                this.sharedService.showError(
                  this.translate.instant('ERR.otp.limit')
                );
                localStorage.clear();
                localStorage.setItem('lang', 'ar');
                // localStorage.setItem('CapacitorStorage.firstLaunch', 'true');
              }
            );
          }
        });
    } else {
      this.sharedService.showInfo(
        this.translate.instant('ERR.enter_credentials')
      );
      localStorage.setItem('lang', lang);
    }
  }

  async authenticateBiometrics(username: string, password: string) {
    const lang = localStorage.getItem('lang');
    this.sharedService.setLang(lang ? lang : 'ar');
    localStorage.clear();
    let credentials = new CredentialsDTO();
    credentials.password = password;
    credentials.username = username;
    let eventFocalRequest = new EventFocalRequestDTO();
    eventFocalRequest.device = this.sharedService.getDeviceInfo();
    credentials.eventFocalRequest = eventFocalRequest;
    credentials.eventFocalRequest.ip = this.sharedService.getIpAddress().toString();
    credentials.eventFocalRequest.location = this.sharedService.getLocation();
    await this.authService
      .newLoginMethod(credentials)
      .toPromise()
      .then(
        (dataReceived: any) => {
          localStorage.setItem('currentUser', dataReceived.access_token);
          localStorage.setItem('expiredIn', dataReceived.expires_in);
          localStorage.setItem('refreshToken', dataReceived.refresh_token);
          const expireDate = new Date(
            new Date().getTime() + 1000 * dataReceived.expires_in
          );
          localStorage.setItem('dateExpiration', expireDate.toString());
          localStorage.setItem('type', 'user');
          localStorage.setItem('lang', this.sharedService.getLang());
          localStorage.setItem('BAuth','1');
          this.authService
            .curentUser()
            .toPromise()
            .then((data) => {
              this.sharedService.setUser(data);
              this.authService.setIsLoggedIn(true);
              this.router.navigate(['/' + PathConstants.PATH_HOME]);
            });
        },
        (err: HttpErrorResponse) => {
          this.authService.setIsLoggedIn(false);
          this.sharedService.showError(
            this.translate.instant('ERR.wrong_credentials_login')
          );
          this.customerExist = true;
        }
      );
  }

  async launchBiometricsAuth() {
    const result = await NativeBiometric.getCredentials({
      server: environment.oauthUrl,
    });

    if (result && result.username && result.password) {
      // Use biometric verification here if available
      const verified = await NativeBiometric.verifyIdentity({
        reason: 'For easy log in',
        title: 'Biometric Authentication',
        subtitle: '',
        description: 'Easy authentication via biometrics',
      })
        .then(() => true)
        .catch(() => false);
      if (verified) {
        console.log('Biometric verification successful, proceed with login');
        this.authenticateBiometrics(result.username, result.password);
      } else {
        console.log('Biometric verification failed');
        this.biometricErrorMessage = this.translate.instant(
          'BIOMETRIC.ERROR.error_fp'
        );
      }
    } else {
      console.log('No stored credentials found');
    }
  }

  createAccount() {
    this.submittedSignup = true;
    this.mobileNumber = this.signupForm.value.mobileNumber.toString();
    this.dateOfBirth = this.signupForm.value.dateOfBirth;
    this.nationalId = this.signupForm.value.nationalId;
    this.email = this.signupForm.value.email;
    this.password = this.signupForm.value.password;
    this.confirmPassword = this.signupForm.value.confirmPassword;
    this.isCheckedTerms = this.signupForm.value.isCheckedTerms;

    if (this.signupForm.valid && this.validateConfirmPassword()) {
      this.saveCustomer();
    } else if (!this.validateConfirmPassword()) {
      this.sharedService.showError(this.translate.instant('ERR.pwd_not_match'));
    } else {
      Object.keys(this.signupForm.controls).forEach((controlName) => {
        const control = this.signupForm.get(controlName);

        if (control?.invalid) {
          const errors = control.errors;
          if (errors) {
            Object.keys(errors).forEach((errorKey) => {
              switch (errorKey) {
                case 'required':
                  switch (controlName) {
                    case 'first_name':
                      this.sharedService.showError(
                        this.translate.instant('ERR.first_name_required')
                      );
                      break;
                    case 'last_name':
                      this.sharedService.showError(
                        this.translate.instant('ERR.last_name_required')
                      );
                      break;
                    case 'nationalId':
                      this.sharedService.showError(
                        this.translate.instant('ERR.national_id_incorrect')
                      );
                      break;
                    case 'mobileNumber':
                      this.sharedService.showError(
                        this.translate.instant('ERR.phone_number_required')
                      );
                      break;
                    case 'dateOfBirth':
                      this.sharedService.showError(
                        this.translate.instant('ERR.date_required')
                      );
                      break;
                    case 'email':
                      this.sharedService.showError(
                        this.translate.instant('ERR.email_required')
                      );
                      break;
                    case 'password':
                      this.sharedService.showError(
                        this.translate.instant('ERR.password_required')
                      );
                      break;
                    case 'confirmPassword':
                      this.sharedService.showError(
                        this.translate.instant('ERR.confirm_pwd_required')
                      );
                      break;
                    case 'isCheckedTerms':
                      this.sharedService.showError(
                        this.translate.instant('ERR.check_terms_required')
                      );
                      break;
                  }
                  break;
                case 'pattern':
                  switch (controlName) {
                    case 'nationalId':
                      this.sharedService.showError(
                        this.translate.instant('ERR.nat_id_invalid_format')
                      );
                      break;
                    case 'mobileNumber':
                      this.sharedService.showError(
                        this.translate.instant('ERR.invalid_phone_format')
                      );
                      break;
                    case 'email':
                      this.sharedService.showError(
                        this.translate.instant('ERR.email_error')
                      );
                      break;
                  }
                  break;
              }
            });
          }
        }
      });
    }
  }

  async saveCustomer() {
    let customer = new CustomerEntity();
    customer.customerIdExtern = 0;
    customer.branchId = 1;
    customer.identity = this.nationalId;
    customer.firstName = this.signupForm.value.first_name;
    customer.secondName = this.signupForm.value.first_name;
    customer.middleName = this.signupForm.value.last_name;
    customer.customerName =
      this.signupForm.value.first_name +
      '|' +
      this.signupForm.value.first_name +
      '|' +
      this.signupForm.value.last_name +
      '|' +
      this.signupForm.value.last_name;
    customer.lastName = this.signupForm.value.last_name;
    customer.email = this.signupForm.value.email;
    customer.accountPortfolioID = '1';
    customer.customerOpenDate = new Date();

    // Date Of Birth Customer Convertion from string to date
    const dateParts = this.signupForm.value.dateOfBirth.split('-');
    if (dateParts.length === 3) {
      const year = parseInt(dateParts[0], 10);
      const month = parseInt(dateParts[1], 10);
      const day = parseInt(dateParts[2], 10);
      customer.dateOfBirth = new Date();
      customer.dateOfBirth.setFullYear(year);
      customer.dateOfBirth.setMonth(month);
      customer.dateOfBirth.setDate(day);
    } else {
      console.error('Invalid date format');
    }

    customer.dateOfBirthHijri = this.hijriDate;

    let address = new AddressEntity();
    address.addressTypeId = 1;
    address.isPrimary = true;
    customer.listAddress = [];
    customer.listAddress.push(address);
    customer.telephone1 = this.mobileNumber.toString();
    customer.customerType = 'INDIV';
    customer.sendToACM = false;

    await this.customerService
      .checkExistCustomer(customer)
      .toPromise()
      .then((checkExistRes) => {
        if (checkExistRes.message === '') {
          this.customerService.addCustomer(customer).subscribe((resCust) => {
            this.sharedService.setCustomer(resCust);
            this.saveUser(resCust);
          });
        } else if (checkExistRes.message === 'National ID Already Exist') {
          this.sharedService.showError(
            this.translate.instant('ERR.nat_id_exist')
          );
          this.nationalIdError = true;
        } else if (checkExistRes.message === 'Email Address Already Exist') {
          this.sharedService.showError(
            this.translate.instant('ERR.email_exist')
          );
          this.emailError = true;
        } else if (checkExistRes.message === 'Phone Number Already Exist') {
          this.sharedService.showError(
            this.translate.instant('ERR.phone_number_exist')
          );
          this.phoneNumberError = true;
        } else if (checkExistRes.message === 'PHONE_IDENTITY_NOT_MATCHED') {
          this.sharedService.showError(
            this.translate.instant('ERR.phone_identity_dont_match')
          );
          this.phoneIdentityError = true;
        } else {
          this.sharedService.showError(
            this.translate.instant('ERR.system_error')
          );
        }
      });
  }

  saveUser(customer: CustomerEntity) {
    let user = new UserEntity();
    user.login = this.nationalId;
    user.nom = customer.firstName + ' ' + customer.secondName;
    user.prenom = customer.middleName + ' ' + customer.lastName;
    user.email = this.email;
    user.fullName =
      customer.firstName +
      ' ' +
      customer.secondName +
      ' ' +
      customer.middleName +
      ' ' +
      customer.lastName;
    user.userExternId = '0';
    user.branchID = 0;
    user.customerId = customer.ibCustomerId;
    user.pwd = this.password;
    user.password = this.password;
    user.pwdConfirm = this.confirmPassword;
    user.firstLogin = true;
    user.deviceId = this.deviceId;
    this.userService.addUser(user).subscribe(
      (res) => {
        this.signUpSuccess = true;
        this.disableBiometric();
      },
      (error) => {
        // this.signUpSuccess = true;
        // this.disableBiometric();
        this.sharedService.showSuccess(
          this.translate.instant('ERROR_error_create_user')
        );
      }
    );
  }

  /** Biometrics Methods */
  async hasStoredCredentials() {
    // Check If Credentials Exists in Biometrics
    try {
      const result = await NativeBiometric.getCredentials({
        server: environment.oauthUrl,
      });
      return result && result.username && result.password;
    } catch (error) {
      console.error('Error checking stored credentials:', error);
      return false;
    }
  }

  async disableBiometric() {
    // Method To Disable Biometrics
    try {
      await NativeBiometric.deleteCredentials({
        server: environment.oauthUrl,
      });
      console.log('Biometric authentication disabled successfully.');
      this.biometricsStatus = false;
      return true;
    } catch (error) {
      console.error('Failed to disable biometric authentication:', error);
      return false;
    }
  }

  /** Password Methods Validators */
  passwordsMatchValidator(
    control: AbstractControl
  ): { [key: string]: boolean } | null {
    const password = control.get('password').value;
    const confirmPassword = control.get('confirmPassword').value;
    return password === confirmPassword ? null : { passwordMismatch: true };
  }

  passwordStrengthValidator(
    control: FormControl
  ): { [key: string]: boolean } | null {
    const password = control.value;
    const passwordPattern =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordPattern.test(password) ? null : { weakPassword: true };
  }

  validateConfirmPassword(): boolean {
    return (
      this.signupForm.get('password').value ===
      this.signupForm.get('confirmPassword').value
    );
  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }

  toggleConfirmPasswordVisibility() {
    this.showConfirmPassword = !this.showConfirmPassword;
  }

  calculatePasswordStrength() {
    const password = this.signupForm.get('password')?.value;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[@$!%*?&]/.test(password);
    const isLengthValid = password.length >= 8;
    this.conditionsMet = [
      hasUpperCase,
      hasLowerCase,
      hasNumber,
      hasSpecialChar,
      isLengthValid,
    ];

    const conditionsMetCount = this.conditionsMet.filter(
      (condition) => condition
    ).length;

    // Calculate strength as a percentage
    this.passwordStrength =
      (conditionsMetCount / this.conditionsMet.length) * 100;

    // Set password strength text based on conditions met
    if (conditionsMetCount === this.conditionsMet.length) {
      this.passwordStrengthText = 'Strong Password';
    } else if (conditionsMetCount === 4) {
      this.passwordStrengthText = 'Almost Valid';
    } else if (conditionsMetCount === 3) {
      this.passwordStrengthText = 'Medium';
    } else if (conditionsMetCount === 2) {
      this.passwordStrengthText = 'Almost Medium';
    } else {
      this.passwordStrengthText = 'Weak';
    }
  }

  /** Other Methods */
  convertToHijri() {
    let dateGreg = this.signupForm.value.dateOfBirth;
    this.retrieveDateYearMonth(dateGreg);
    let hijriDateObj = toHijri(this.year, this.month, this.day);
    let hijriDateLatin =
      hijriDateObj.hy + '-' + hijriDateObj.hm + '-' + hijriDateObj.hd;
    let dateHijri = this.convertHijriToArabic(hijriDateLatin);
    this.signupForm.controls['dateOfBirthHijri'].setValue(hijriDateLatin);
    this.hijriDate = hijriDateLatin;
    this.triggerDataInHijriDatePicker(hijriDateObj);
  }

  convertToGregorian(event) {
    let year = event.year;
    let month = event.month;
    let day = event.date;
    let hijriDate = year + '-' + month + '-' + day;
    let gregorianDateObj = toGregorian(year, month, day);
    let gregorianDate = formatDate(
      new Date(
        gregorianDateObj.gy,
        gregorianDateObj.gm - 1,
        gregorianDateObj.gd
      ),
      'yyyy-MM-dd',
      'en-US'
    );
    this.signupForm.controls['dateOfBirth'].setValue(gregorianDate);
  }

  retrieveDateYearMonth(dateString: string) {
    const dateObj = new Date(dateString);
    this.year = dateObj.getFullYear();
    this.month = dateObj.getMonth() + 1;
    this.day = dateObj.getDate();
  }

  convertHijriToArabic(latinDate: string): string {
    const [year, month, day] = latinDate.split('-').map(Number);
    const arabicYear = this.convertToArabicDigits(year);
    const arabicMonth = this.convertToArabicDigits(month);
    const arabicDay = this.convertToArabicDigits(day);
    return `${arabicYear}-${arabicMonth}-${arabicDay}`;
  }

  convertToArabicDigits(number: number): string {
    const arabicDigits = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩'];
    return number
      .toString()
      .split('')
      .map((digit) => arabicDigits[parseInt(digit)])
      .join('');
  }

  handleHijriDate(event) {
    this.convertToGregorian(event);
  }

  triggerDataInHijriDatePicker(data: any) {
    this.paramService.sendData(data);
  }

  // Confirm Terms Method
  confirmTerms() {
    this.signupForm.controls['isCheckedTerms'].setValue(true);
    this.signupForm.controls['isCheckedTerms'].enable();
    this.showModalTerms = false;
  }

  // Open Dialog Method
  openModalTerms() {
    this.showModalTerms = true;
  }

  // Get Device ID
  async getDeviceId() {
    this.deviceId = (await Device.getId()).identifier;
  }

  letterRemover() {
    let usernameValue = this.loginForm.controls['username'].value;
    if (!isNaN(Number(usernameValue[usernameValue.length - 1]))) {
    } else {
      this.loginForm.controls['username'].setValue(
        usernameValue.slice(0, usernameValue.length - 1)
      );
    }
  }

  checklength(event: any, type: string) {
    const inputValue = event.target.value;
    if (type === 'mobileNumber' && inputValue.length >= 9) {
      const limitedValue = inputValue.substring(0, 9);
      this.signupForm.controls['mobileNumber'].setValue(limitedValue);
    }

    if (type === 'nationalId' && inputValue.length >= 10) {
      const limitedValue = inputValue.substring(0, 10);
      this.signupForm.controls['nationalId'].setValue(limitedValue);
    }
  }

  backToSignIn() {
    this.signUpSuccess = false;
    this.signupForm.reset();
    this.signupForm.markAsPristine();
    this.signupForm.markAsUntouched();
    this.signupForm.clearValidators();
    this.signupForm.clearAsyncValidators();
    this.signupForm.updateValueAndValidity();
    this.actLogin();
    this.loginForm.reset();
    this.loginForm.markAsPristine();
    this.loginForm.markAsUntouched();
    this.loginForm.clearValidators();
    this.loginForm.updateValueAndValidity();
  }

  /** Tabs Change Between Signup & Login */
  actSignup() {
    this.showLogin = false;
    this.showSignUp = true;
  }

  actLogin() {
    this.showLogin = true;
    this.showSignUp = false;
  }
}
