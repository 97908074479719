import { environment } from "src/environments/environment";

export class AcmURLConstants {
    public static readonly AUTHENTICATION_SERVICE = environment.oauthUrl;
    public static readonly CREDIT_SERVICE = environment.apiUrl + 'credit-service';
    public static readonly PARAMETRAGE_SERVICE = environment.apiUrl + 'parametrage-service';
    public static readonly GED_SERVICE = environment.apiUrl + 'ged-service';
    public static readonly REPORTING_SERVICE = environment.apiUrl + 'reporting-service';

    public static readonly TRANSVERSE_SERVICE = environment.apiUrl + 'transvers-service';

    /**
     * AUTHENTICATION_SERVICE
     */
    public static readonly LOGIN = AcmURLConstants.AUTHENTICATION_SERVICE + 'users/login';
    public static readonly AUTHENTICATION_URL_API = AcmURLConstants.AUTHENTICATION_SERVICE + 'oauth/token?';
    public static readonly USER_PARAMETRAGE = AcmURLConstants.AUTHENTICATION_SERVICE + 'users/connected/';
    public static readonly RESET_PWD = AcmURLConstants.AUTHENTICATION_SERVICE + 'users/forget-pwd/';
    public static readonly UPDATE_PWD = AcmURLConstants.AUTHENTICATION_SERVICE + 'users/update-pwd/';
    public static readonly UPDATE_FIRST_LOGIN = AcmURLConstants.AUTHENTICATION_SERVICE + 'users/first-login/';
    public static readonly UPDATE_FORGOTTEN_PWD = AcmURLConstants.AUTHENTICATION_SERVICE + 'users/update-new-forgotten-pwd';
    public static readonly UPDATE_DEFAULT_LANG = AcmURLConstants.AUTHENTICATION_SERVICE + 'users/set-default-lang';
    public static readonly FIND_USERS = AcmURLConstants.AUTHENTICATION_SERVICE + 'users/';

    /**
     * CREDIT_SERVICE
     */
    public static readonly FIND_LOANS = AcmURLConstants.CREDIT_SERVICE + '/loans/';
    public static readonly FIND_LOANS_PAGINATIONS = AcmURLConstants.CREDIT_SERVICE + '/loans/find-pagination';
    public static readonly CALCULATE_LOAN_SCHEDULES = AcmURLConstants.CREDIT_SERVICE + '/loans/load-data-api-abacus/calculate-loan-schedules';
    public static readonly UPDATE_LOAN = AcmURLConstants.CREDIT_SERVICE + '/loans/update-loan/';
    public static readonly UPDATE_LOAN_ASSIGN_CUSTOMER = AcmURLConstants.CREDIT_SERVICE + '/loans/update-assign-customer';

    // CUSTOMER LIST
    public static readonly GET_CUSTOMERS = AcmURLConstants.CREDIT_SERVICE + '/customers/';
    public static readonly FIND_CUSTOMERS = AcmURLConstants.CREDIT_SERVICE + '/customers/';
    public static readonly GET_CUSTOMERS_PAGINATION = AcmURLConstants.CREDIT_SERVICE + '/customers/find-pagination';
    public static readonly GET_CUSTOMERS_ACCOUNT_BY_CUSTOMER = AcmURLConstants.CREDIT_SERVICE + '/customers/customer-account/';
    public static readonly GET_ADDRESS_TYPE = AcmURLConstants.PARAMETRAGE_SERVICE + '/address-settings/find-address-type';
    public static readonly GET_ADDRESS_SETTING = AcmURLConstants.PARAMETRAGE_SERVICE + '/address-settings/find-settings-address';
    public static readonly GET_ADDRESS_LIST = AcmURLConstants.PARAMETRAGE_SERVICE + '/address-settings/find-address-list';
    public static readonly GET_ADDRESS_LIST_VALUE = AcmURLConstants.PARAMETRAGE_SERVICE + '/address-settings/find-address-list-value';
    public static readonly GET_CUSTOMER_ADDRESS = AcmURLConstants.CREDIT_SERVICE + '/address/';
    public static readonly GET_CUSTOMERS_TOTAL_LOAN = AcmURLConstants.CREDIT_SERVICE + '/customers/customer-total-loan/';
    public static readonly GET_CUSTOMERS_TOTAL_GUARANTEEING = AcmURLConstants.CREDIT_SERVICE + '/customers/customer-total-guaranteeing/';
    public static readonly UPDATE_CUSTOMER_FOR_APPLICATION = AcmURLConstants.CREDIT_SERVICE + '/customers/update-for-application';
    public static readonly GET_CUSTOMER_ACTIVE_ACCOUNT = AcmURLConstants.CREDIT_SERVICE + '/customers/customer-active-account/';
    public static readonly CHECK_REGISTRATION_NUMBER = AcmURLConstants.CREDIT_SERVICE + '/customers/check-registrationNumber/';
    public static readonly CHECK_IDENTITY = AcmURLConstants.CREDIT_SERVICE + '/customers/check-identity/';
    public static readonly CHECK_IDENTITY_MEMBER = AcmURLConstants.CREDIT_SERVICE + '/customers/check-identity-member/';
    public static readonly CHECK_EXISIT_CUSTOMER_GUARANTOR = AcmURLConstants.CREDIT_SERVICE + '/customers/check-customer-exist-guarantor/';
    public static readonly FIND_ALL_CUSTOMER_INFORMATION = AcmURLConstants.CREDIT_SERVICE + '/customers/find-all-customer-information';

    // LOAN DOCUMENT SERVICE
    public static readonly SAVE_LIST_DOCUMENT = AcmURLConstants.CREDIT_SERVICE + '/loans-documents/saveToGed';
    public static readonly GET_LIST_DOCUMENT_LOAN = AcmURLConstants.CREDIT_SERVICE + '/loans-documents/';
    public static readonly DELETE_DOCUMENT_LOAN = AcmURLConstants.CREDIT_SERVICE + '/loans-documents/delete/';
    public static readonly DISABLE_DOCUMENT = AcmURLConstants.CREDIT_SERVICE + '/loans-documents/disable-document';
    public static readonly GET_ALL_TYPES_DOCUMENT = AcmURLConstants.PARAMETRAGE_SERVICE + '/setting-document-types/';
    public static readonly GET_ALL_TYPES_DOCUMENT_PAGINATION = AcmURLConstants.CREDIT_SERVICE + '/loans-documents/find-pagination';
    public static readonly SAVE_IMAGE_IN_GED = AcmURLConstants.CREDIT_SERVICE + '/loans-documents/save-image-to-ged';
    public static readonly CREATE_DOCUMENT = AcmURLConstants.CREDIT_SERVICE + '/loans-documents/create';
    public static readonly GET_LIST_DOCUMENT_CUSTOMER = AcmURLConstants.CREDIT_SERVICE + '/loans-documents/find-documents-customer/';

    // CUSTOMER NOTE
    public static readonly GET_CUSTOMER_NOTE = AcmURLConstants.CREDIT_SERVICE + '/customer-decision/';

    // GET REQUIRED DOCUMENT
    public static readonly GET_REQUIRED_DOCUMENT = AcmURLConstants.CREDIT_SERVICE + '/loans/findRequiredDocument';

    /**
     *   GED_SERVICE
     */
    public static readonly DISPLAY_DOCUMENT = AcmURLConstants.GED_SERVICE + '/documents/display/';
    public static readonly FIND_DOCUMENTS_BY_TAGS = AcmURLConstants.GED_SERVICE + '/documents/find-by-tags';
    public static readonly GET_DETAIL_DOCUMENT = AcmURLConstants.GED_SERVICE + '/documents/document/';

    // EDITION
    public static readonly GENERATE_LOAN_REPORT = AcmURLConstants.REPORTING_SERVICE + '/edition/generatePDF/loanReport';
    /**
     * PARAMETRAGE_SERVICE
     */
    public static readonly GET_LOAN_PROCESS = AcmURLConstants.PARAMETRAGE_SERVICE + '/setting-statut-workflows/';
    // Setting_Acm_Environment
    public static readonly SETTING_ENVIRONMENT_FIND = AcmURLConstants.PARAMETRAGE_SERVICE + '/acm-environnements/find-by-keys';
    public static readonly SETTING_ENVIRONMENT_FIND_BY_KEY = AcmURLConstants.PARAMETRAGE_SERVICE + '/acm-environnements/';
    public static readonly IB_SETTING_DATA = AcmURLConstants.PARAMETRAGE_SERVICE + '/ib-setting-data/';
    // PRODUCT
    public static readonly PRODUCT_FIND = AcmURLConstants.PARAMETRAGE_SERVICE + '/products/';
    public static readonly PRODUCT_DETAILS = AcmURLConstants.PARAMETRAGE_SERVICE + '/products/';

    // SETTING LIST VALUES
    public static readonly BRANCHE_FIND = AcmURLConstants.PARAMETRAGE_SERVICE + '/settings-list-values/find-branches';
    public static readonly BANKS_FIND = AcmURLConstants.PARAMETRAGE_SERVICE + '/settings-list-values/find-banks';
    public static readonly RELATIONSHIPS_FIND = AcmURLConstants.PARAMETRAGE_SERVICE + '/settings-list-values/find-relationships';
    public static readonly ROLES_FIND = AcmURLConstants.PARAMETRAGE_SERVICE + '/settings-list-values/find-roles-abacus';
    public static readonly LOAD_PRODUCT_LOAN_REASON = AcmURLConstants.PARAMETRAGE_SERVICE + '/settings-list-values/find-product-loan-leasons';
    public static readonly LOAD_SOURCE_OF_FUNDS = AcmURLConstants.PARAMETRAGE_SERVICE + '/settings-list-values/find-loan-source-funds';
    public static readonly INDUSTRY_FIND = AcmURLConstants.PARAMETRAGE_SERVICE + '/settings-list-values/find-industrys';

    //SETTING IB DATA STORAGE
    public static readonly ALL_DATA_STORAGE = AcmURLConstants.PARAMETRAGE_SERVICE + '/setting-data-storage-ib/find-all';
    public static readonly DATA_STORAGE_BY_CATEGORY = AcmURLConstants.PARAMETRAGE_SERVICE + '/setting-data-storage-ib/';

    // IB Loan
    public static readonly CREATE_LOAN_IB = AcmURLConstants.CREDIT_SERVICE + '/loans-ib/create';
    public static readonly CREATE_LOAN_IB_FOR_CONNECT_USER = AcmURLConstants.CREDIT_SERVICE + '/loans-ib/create-for-connect-user';
    // PORTFOLIO
    public static readonly FIND_PORTFOLIO = AcmURLConstants.AUTHENTICATION_SERVICE + 'users/find-portfolio';
    // Email
    public static readonly SEND_CONTACT_CUSTOMER = AcmURLConstants.CREDIT_SERVICE + '/customer-contact/create';
    public static readonly SEND_MAIL_CUSTOMER = AcmURLConstants.CREDIT_SERVICE + '/customer-contact/create-mail';
    public static readonly FIND_MAIL_CUSTOMER = AcmURLConstants.CREDIT_SERVICE + '/customer-contact/';
    public static readonly UPDATE_MAIL_CUSTOMER = AcmURLConstants.CREDIT_SERVICE + '/customer-contact/disable-message';

    // UDF SETTING
    public static readonly FIND_UDF_GROUP = AcmURLConstants.PARAMETRAGE_SERVICE + '/udf-settings/udf-group-setting';
    public static readonly FIND_UDF_FIELD = AcmURLConstants.PARAMETRAGE_SERVICE + '/udf-settings/udf-fields-setting';
    public static readonly FIND_UDF_LISTE_VALUE = AcmURLConstants.PARAMETRAGE_SERVICE + '/udf-settings/udf-list-value';
    public static readonly GET_ALL_UDF_LINK = AcmURLConstants.CREDIT_SERVICE + '/udf-links/';
    public static readonly FIND_UDF_LISTE_SUBJECT_MAIL = AcmURLConstants.PARAMETRAGE_SERVICE + '/setting-motif-rejets/';
    public static readonly GET_ALL_UDF_LINK_GROUP = AcmURLConstants.CREDIT_SERVICE + '/udf-links/find-udf-groupby/';

    // GUARANTOR
    public static readonly SAVE_GUARANTOR = AcmURLConstants.CREDIT_SERVICE + '/customers/add-guarantors';
    // RELATIONSHIPS
    public static readonly GET_CUSTOMER_LINKS_RELATIONSHIP = AcmURLConstants.CREDIT_SERVICE + '/customer-link-relationship/';
    public static readonly GET_ALL_CUSTOMER_GUARANTORS = AcmURLConstants.CREDIT_SERVICE + '/customers/find-all-loan-guarantors';
    public static readonly GET_MEMBER_BY_CUSTOMER = AcmURLConstants.CREDIT_SERVICE + '/customer-link-relationship/find-members';
    // fee repayment
    public static readonly GET_FEE_REPAYMENT = AcmURLConstants.CREDIT_SERVICE + '/loans/load-data-abacus/fee-repayment/';
    // Third Party
    public static readonly THIRD_PARTY_HISTORY_LIST = AcmURLConstants.CREDIT_SERVICE + '/third-party-historiques/';
    public static readonly THIRD_PARTY_VALIDATE = AcmURLConstants.CREDIT_SERVICE + '/third-party/validate';
    public static readonly THIRD_PARTY_CONFIRM = AcmURLConstants.CREDIT_SERVICE + '/third-party/confirm';

    // check exist mobile number
    public static readonly CHECK_EXIST_PHONE_AND_MOBILE_NUMBER = AcmURLConstants.CREDIT_SERVICE + '/customers/checkExistPhoneAndMobileNumber/';
    public static readonly VALIDATE_LOAN_IN_ACM = AcmURLConstants.TRANSVERSE_SERVICE + '/rabbitMq-endPoint/send-validate-loan';
    public static readonly CANCEL_LOAN_IN_ACM = AcmURLConstants.TRANSVERSE_SERVICE + '/rabbitMq-endPoint/send-cancel-loan';


    public static readonly  FIND_CLAIMS_SETTING = AcmURLConstants.PARAMETRAGE_SERVICE + '/setting-claims/';
    public static readonly  SAVE_CLAIMS = AcmURLConstants.CREDIT_SERVICE + '/claims/create';
    public static readonly  FIND_CLAIMS = AcmURLConstants.CREDIT_SERVICE + '/claims/';

      // Claim_Notes
      public static readonly GET_CLAIM_NOTE = AcmURLConstants.PARAMETRAGE_SERVICE + '/claim-note/';
      public static readonly CREATE_CLAIM_NOTE = AcmURLConstants.PARAMETRAGE_SERVICE + '/claim-note/create';

      // User Evaluation
      public static readonly  ADD_USER_EVALUATION = AcmURLConstants.CREDIT_SERVICE + '/user-evaluation/add';

      // TarabutService
      public static readonly  IBAN_ACCOUNT_VERIFIER = AcmURLConstants.TRANSVERSE_SERVICE + '/tarabut-api/account-verification';

      // FOCAL SERVICES
      public static readonly  FOCAL_EVENT_RULES_EVALUATION = AcmURLConstants.TRANSVERSE_SERVICE + '/focal-api/evaluate-rules-focal';

}
