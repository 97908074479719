import { Component, OnInit } from '@angular/core';
import { LoanService } from 'src/app/ib-services/loan.service';
import { SharedService } from 'src/app/ib-services/shared.service';
import { LoanEntityIB } from 'src/app/shared/Entities/loan.entity';
import { ScheduleEntity } from 'src/app/shared/Entities/schedule.entity';

@Component({
  selector: 'app-loan-schedule-details',
  templateUrl: './loan-schedule-details.component.html',
  styleUrls: ['./loan-schedule-details.component.scss']
})
export class LoanScheduleDetailsComponent implements OnInit {

  public schedules : ScheduleEntity[] = [];
  public loan : LoanEntityIB;
  public totalRepayment : number = 0;
  public totalPrinciple : number = 0;
  public totalInterestTaken : number = 0 ;
  public lastRepaymentDate : string;
  public nextRepaymentDate : string;
  public termPaid : number;
  public paymentStatus : number;
  public lang : string;

  constructor(public sharedService : SharedService,private loanService : LoanService) { }

  async ngOnInit() {
    try {
      this.loan = this.sharedService.getLoanDeal();
      this.lang = localStorage.getItem('lang');
      const schedules = await this.loanService.getSchedulesByLoanId(this.loan.idIbLoan).toPromise();
      let sc = schedules.find(result => result.statusLabel == 'Unpaid');
          if (sc) this.loan.totalRepayment = sc.totalRepayment;
      if (schedules && schedules.length > 0) {
        this.schedules = [...schedules];
        this.sharedService.setSchedules(this.schedules);

        this.calculateTotals();
        this.updateLoanIfNeeded();

        this.lastRepaymentDate = this.schedules[this.schedules.length - 1].repaymentDate;
        this.nextRepaymentDate = this.schedules[0].repaymentDate;

        this.calculateTermPaid();
        this.calculatePaymentStatus();

        this.sharedService.setTermPaid(this.termPaid);
      }
    } catch (error) {
      console.error('Error fetching schedules:', error);
    }
  }

  private calculateTotals(): void {
    this.schedules.forEach(schedule => {
      this.totalRepayment = Number((this.totalRepayment + schedule.totalRepayment).toFixed(3));
      this.totalPrinciple = Number((this.totalPrinciple + schedule.loanRepayment).toFixed(3));
      this.totalInterestTaken = Number((this.totalInterestTaken + Number(schedule.interestRepayment)).toFixed(3));
    });
  }

  private async updateLoanIfNeeded(): Promise<void> {
    if (this.schedules[0].totalRepayment !== this.loan.normalPayment) {
      this.loan.normalPayment = this.schedules[0].totalRepayment;
      const updatedLoan = await this.loanService.updateLoan(this.loan).toPromise();
      this.sharedService.setLoanDeal(updatedLoan);
    }
  }

  private calculateTermPaid(): void {
    this.termPaid = 0;
    this.schedules.forEach((schedule, index) => {
      if (schedule.statusLabel === 'Fully Paid') {
        this.termPaid++;
        if (index < this.schedules.length - 1) {
          this.nextRepaymentDate = this.schedules[index + 1].repaymentDate;
        }
      }
    });
  }

  private calculatePaymentStatus(): void {
    this.paymentStatus = (this.termPaid / this.loan.termPeriodNum);
    if (this.termPaid === this.loan.termPeriodNum) {
      this.paymentStatus = 100;
    }
  }

  getPercentage(): number {
    return Math.round(this.paymentStatus * 100);
  }

}
